<template>
  <div class="row col-sm-12"
      :class="klass">
    <div class="col-sm-12 secoes-header">
      <h3>{{ formCaption }}</h3>
    </div>

    <div class="col-sm-12 secoes-list">
      <form :id="form_id"
            class="simple_form"
            :action="url_teste"
            data-remote="true">

        <group-render v-for="(group, index) in groupsCollection"
          :key="index"
          :group_opts="groupGroupOpts(group, index)"
          :options="groupOptions"
          class="list-group">
        </group-render>

      </form>
    </div>

    <div class="row col-sm-3 col-sm-offset-9  secoes-footer">
      <button type="submit"
             class="btn btn-primary"
             :form="form_id"
             value="Teste seu formulário!">
             Teste seu formulário!
      </button>
    </div>

    <teste-form-render ref="testeformrender"

                       :receivedrecord="result">
    </teste-form-render>

  </div>
</template>

<script>
  import GroupRender from "./group_render.vue";
  import TesteForm from "./teste_form_render.vue";

  export default {
    name: 'GroupsRender',
    components: {
      'group-render' :  GroupRender,
      'teste-form-render' : TesteForm
    },
    props: ['storename'],
    data: function () {
      return {
        klass: 'groups-editor',

        model_name: 'exemplo',
        form_id: 'exemplo_form',

        result : {}
      }
    },
    mounted: function() {
      var that = this;
      this.$root.$on('show_form_teste', function (result) {
        that.showTesteResult(result)
      })
    },
    methods: {
      getGroup: function(index) {
        return this.groupsCollection[index] ;
      },
      // setGroup: function(index, new_group) {
      //   this.schema.groups[index] = new_group ;
      // },
      showTesteResult: function(result) {
        this.$set(this.$data, 'result', result)
        this.$refs.testeformrender.setResult(result)
      },
      groupGroupOpts: function(group, index) {
        return {
          group: group,
          index: index
        }
      }
    },
    computed: {
      groupsCollection() {
        return this.$store.getters['FormularioStore/groupsCollection']
      },
      formModel: function() {
        return this.$store.getters['FormularioStore/model']
      },
      url_teste: function() {
        return '/formularios/' + this.formModel.id + '/teste.js' ;
      },
      formCaption: function() {
        return this.formModel.codigo + ' - ' + this.formModel.nome ;
      },
      groupOptions: function() {
        return {
          model_name: this.model_name,
          storename: this.storename,
          hide_panel: true
        }
      }
    },
    watch: {
    }
  };
</script>

