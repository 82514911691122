export default {
    namespaced: true,
    state: {
      user_id: 0,
      root_url: '',
      intialized: false,

      emails: [],

      conversa: {},
      to_reply: {},

      inbox: {
        messages: [],
        count:    0,
        page:     1,
        total_pages:     null,
        loaded:   false
      },
      sent: {
        messages: [],
        count:    0,
        page:     1,
        total_pages:     null,
        loaded:   false
      },
      trash: {
        messages: [],
        count: 0,
        page:     1,
        total_pages:     null,
        loaded: false
      },
      unread: {
        messages: [],
        count: 0,
        page:     1,
        total_pages:     null,
        loaded: false
      },
      unread_q: 0
    },
    mutations: {
      init(state, data) {
        if (!state.intialized) {
          state.user_id = data.user_id
          state.root_url     = '/users/' + state.user_id
          state.intialized = true
        }
        return state;
      },
      load(state, payload) {
        var box = state[payload.box]
        if (! box.loaded || (payload.data.page == box.page)) {
          box.messages    = payload.data.msgs;
          box.count       = payload.data.count;
          box.page        = payload.data.page;
          box.total_pages = payload.data.total_pages;
          box.loaded      = true ;
        } else if (payload.data.page == box.page + 1) {
          box.messages = box.messages.concat(payload.data.msgs);
        }
      },
      loadConversa(state, payload) {
        state.conversa = payload
        var index_msg = state.inbox.messages.findIndex(msg => msg.id == payload.id) ;
        if (index_msg > -1) {
          state.inbox.messages[index_msg].un_read = false ;
        }
        return state;
      },
      setReply(state, conv) {
        state.to_reply = conv ;
      },
      loadEmails(state, payload) {
        state.emails = payload
        return state;
      }
    },
    actions: {
      init(context, conf) {
        if (! context.state.intialized) {
          context.commit('init', conf)
        }
        // context.dispatch('load', {box: 'unread', only_count: true})
      },
      // Carrega endereços de email para compose email
      loadEmails(context) {
        var that = this
        $.ajax({
          url: context.state.root_url + '/messages/users_email.json',
          data: {
          },
          type: 'get',
          format: 'json',
          success: function(data) {
            context.commit('loadEmails', data.data)
          }
        })
      },
      load(context, opts) {
        var that = this
        if (context.state[opts.box].loaded && !opts.refresh) {return} ;
        var page = context.state[opts.box].page
        if (opts.next_page) { page++ }
        $.ajax({
          url: context.state.root_url + '/messages.json',
          data: {
            owner_type: 'User',
            owner_id:   context.state.user_id,
            box:        opts.box,
            page:       page,
            only_count: opts.only_count
          },
          type: 'get',
          format: 'json',
          success: function(data) {
            context.commit('load', {
              box:     opts.box,
              refresh: opts.refresh,
              data:    data
            })
          },
          error: function(error) {
          },
          complete: function(response) {
          }
        })
      },
      loadConversa(context, conv_id) {
        var that = this
        $.ajax({
          url: context.state.root_url + '/messages/' + conv_id + '.json',
          data: {
          },
          type: 'get',
          format: 'json',
          success: function(data) {
            context.commit('loadConversa', data)
          }
        })
      },
      deleteConv(context, conv) {
        var that = this
        $.ajax({
          url: context.state.root_url + '/messages/' + conv.conv.id + '/to_trash.json',
          data: {},
          type: 'post',
          format: 'json',
          success: function(data) {
            context.dispatch('load', {box: conv.folder})
            context.dispatch('load', {box: 'trash'})
            window.vm.alert('success', (data.msg || 'Mensagem removida com sucesso!'));
          },
          error: function(error) {
          }
        })
      },
      readConv(context, conv) {
        var that = this

        $.ajax({
          url: context.state.root_url + '/messages/' + conv.conv.id + '/to_read.json',
          data: {},
          type: 'post',
          format: 'json',
          success: function(data) {
            context.dispatch('load', {box: conv.folder})
            context.dispatch('load', {box: 'unread'})
            // Não mostra msg pois é muito usada
          },
          error: function(error) {
          }
        })
      },
      sendEmail(context, email) {
        var that = this
        $.ajax({
          url: context.state.root_url + '/messages/post_conv.json',
          data: {
            email: email
          },
          type: 'post',
          format: 'json',
          success: function(data) {
            context.dispatch('load', {box: 'sent'})
            window.vm.alert('success', (data.msg || 'Mensagem enviada com sucesso!'));
          },
          error: function(error) {
          }
        })
      },
      setReply(context, conv) {
        context.dispatch('set_reply', conv)
      },
      replyEmail(context, emailData) {
        var that = this
        $.ajax({
          url: context.state.root_url + '/messages/' + emailData.conv_id + '/reply_conv.json',
          data: {
            user_id: context.state.user_id,
            conv_id: emailData.conv_id,
            body:    emailData.body
          },
          type: 'post',
          format: 'json',
          success: function(data) {
            context.dispatch('load', {box: 'sent'})
            window.vm.alert('success', (data.msg || 'Mensagem enviada com sucesso!'));
          },
          error: function(error) {
          }
        })
      }
    },
    getters: {
      inbox: state => {
        return state.inbox.messages ;
      },
      box: (state) => (name) => {
        return state[name].messages ;
      },
      state_box: (state) => (name) => {
        return state[name] ;
      },
      unread: state => {
        return state.unread.messages ;
      },
      unread_q: state => {
        // var inb = state.inbox.messages
        // var cont = 0
        // inb.forEach(function(mess) {
        //   if (mess.un_read) {
        //     cont += 1
        //   }
        // })
        // return cont

        return state.unread.count ;
        // return state.unread.messages.length ;
      },
      emailslist: state => {
        return state.emails ;
      },
      reply: state => {
        return state.to_reply ;
      },
      conversa: state => {
        return state.conversa ;
      }
    }
  }
