// store_loader.vue


export default {
    methods: {
      initStore: function(to_loadStore, dataInit) {
        const store = this.$store;

        // Carrega o módulo da store
        if (!(store && store.state && store.state[this.storeName])) {
          store.registerModule(this.storeName, to_loadStore);
        } else {
          console.log(`reusing module: ${this.storeName}`);
        }

        // Inicia com parametros da obra
        this.$store.dispatch( this.storeName + '/init', dataInit)
      }
    },
    computed: {
    }

  }


