<template>
  <div :class="klass">
    <cr-model-btns :options="opts.options.buttons">
    </cr-model-btns>
    <div id="calendar_wrap">
      <div
          :id="opts.options.calendar_id"
          ref="calendar_div"
          :onload="initCalendar()">
      </div>
    </div>
  </div>
</template>

<script>
  // import ModelMessageReply  from './model_message_reply.vue'
  import CrModelBtns          from './cr_model_btns.vue'

  export default {
    name: 'CrCalendar',
    components: {
      'cr-model-btns'     : CrModelBtns
    },
    props: ['options', 'index'],
    data: function () {
      return {
        klass: 'cr-calendar',
        domid: 'msgs_domid',
        type: 'cr_aba_messagens',

        opts: this.options,

        conversa: this.options.conversa,
        contRefresh: 0,
        can_reply: true,
        messagens: []
      }
    },
    mounted: function() {
      // this.conversa = this.options.conversa;
      // this.loadMessages()
    },
    methods: {
      refresh: function () {
        // this.loadMessages()
      },
      initCalendar: function () {
        var that = this ;
        $(document).ready(function() {
          console.log('Init Calendar')
          // var cal_div = $(that.$refs.cal_div).DataTable({retrieve: true});
          // tabl.destroy();

          $(that.$refs.calendar_div).fullCalendar(
            that.calendarOptions()
          );

        });
      },
      calendarOptions: function() {
        var conf_opts = this.opts.options ;
        var opts = {
          contentHeight: 'auto',
          lang: 'pt-br',
          firstDay: 1,   // Inicio em Segunda-feira
          fixedWeekCount: false,  // Exibir 6 semanas x mês?
          header: {
            right:  'prev next',
            center: 'title',
            left:   'today'
          },
          titleFormat: 'DD, MMMM/YYYY',
          aspectRatio: 3,

          defaultDate: conf_opts.defaultDate ,

          resourceOrder: conf_opts.resourceorder,
          eventOrder:    conf_opts.resourceorder,
          events: {
            url: conf_opts.datasource,
            type: 'POST',
            error: function() {
                alert('there was an error while fetching events!');
            },
          },
          eventClick: function(event) {
            if (event.dataremote) {
              $.ajax({
                url: event.url + '.js',
                dataType: "script"
              });
              return false;
            };
            if (event.url) {
              // window.open(event.url);
              window.location.assign(event.url);
              return false;
            }
          }
        }
        return opts
      }
    },
    computed: {
      // conversa: function () {
      //   return this.options.conversa ;
      // }
    }
  };
</script>
