// email_compose_inbox.vue
<template>
  <div class="col-lg-9 animated fadeInRight p-l-5 p-r-5">
    <div class="mail-box-header">
      <div class="pull-right tooltip-demo">
        <a href="mailbox.html" class="btn btn-danger btn-sm" data-toggle="tooltip" data-placement="top" title="Descartar"><i class="fa fa-times"></i> Cancelar</a>
      </div>
      <h2>
        {{ nova_msg() ? 'Escrever mensagem' : 'Responder mensagem' }}
      </h2>
    </div>
    <div class="mail-box">


      <div class="mail-body">

        <form class="form-horizontal">
          <div class="form-group"><label class="col-sm-2 control-label">Para:</label>
            <div class="col-sm-10">

              <cmp-v-select
                v-if="nova_msg()"
                :required="true"
                :multiple="true"
                name="email_dests"
                :options="emailslist"
                v-model="mail.tos"
              >
              </cmp-v-select>
              <input v-if="! nova_msg()"
                class="form-control"
                readonly="readonly"
                value="Destinatários da mesagem original"
              >
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label">Assunto:</label>
            <div class="col-sm-10">
              <input v-if="nova_msg()"
                     type="text"
                     class="form-control"
                     v-model="mail.subject">
              <input v-if="! nova_msg()"
                     type="text"
                     readonly="readonly"
                     class="form-control"
                     v-model="mail.subject">
            </div>
          </div>
        </form>

      </div>

      <div class="mail-text h-200">
        <div class="rows">
          <textarea class="form-control text optional"
             id="text_editxx"
             rows="5"
             v-model="mail.body"
          ></textarea>
        </div>

      </div>
      <div class="mail-body text-right tooltip-demo">
        <a class="btn btn-sm btn-primary"
           :class="(canSend ? '' : 'disabled')"
           data-toggle="tooltip" data-placement="top" title="Enviar email"
           @click="sendEmail()">
          <i class="fa fa-reply"></i>
          Enviar
        </a>
        <a class="btn btn-white btn-sm"
           data-toggle="tooltip" data-placement="top" title="Descartar email">
          <i class="fa fa-times"></i>
          Cancelar
        </a>

      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
  import mbStore from 'packs/vuex/stores/mailboxer_store.js'

  import stUtils from 'packs/vuex/stores/utils_store.js'
  import Utils from './utils.vue'
  import { mapGetters } from 'vuex'

  import CmpVSelect from './../comp/cmp-v-select.vue'

export default {
    name: 'email_compose_panel',
    mixins: [stUtils, Utils],
    components: {
      'cmp-v-select' : CmpVSelect
    },
    props: ['options'],
    data: function () {
      return {
        mounted: false,
        storeName: 'mailBoxerStore',
        user_id: this.options.user_id,

        enableDestinatarios: true,

        mail: {
          tos:     [],
          subject: '',
          body:    ''
        }
      }
    },
    created: function () {
      this.initStore(this.storeName, mbStore, {user_id: this.user_id})
      this.dispach(this.storeName, 'loadEmails')
      this.mounted = true
    },
    mounted: function() {
      if (this.$parent.conv_to_reply) {
        var ctr = this.$parent.conv_to_reply
        this.mail.tos = []
        this.mail.subject = ctr.subject
        this.enableDestinatarios = false
      } else {
        this.mail.tos = []
        this.mail.subject = ''
        this.enableDestinatarios = true
      }
    },
    methods: {
      sendEmail: function() {
        var conv_to_reply = this.conv_to_reply()
        if (conv_to_reply && conv_to_reply.id) {
          this.dispach(this.storeName, 'replyEmail', {
            conv_id: conv_to_reply.id,
            body: this.mail.body
          })
        } else {
          this.dispach(this.storeName, 'sendEmail', this.mail)
        }
        this.setAction('show_folder', 'inbox')
      },
      conv_to_reply: function() {
        return this.$parent.conv_to_reply
      },
      nova_msg: function() {
        return (! this.$parent.conv_to_reply)
      },
      loadReply: function() {
        this.mail.subject = this.$parent.conv_to_reply.subject ;
        this.mail.body    = '' ;
        this.enableDestinatarios = false ;
      }
    },
    computed: {
      canSend: function() {
        return ( ((this.mail.tos.length > 0) || (! this.enableDestinatarios)) &&
                 (! this.mail.subject == '') &&
                 (! this.mail.body == '') )
      },
      ...mapGetters('mailBoxerStore', {
        reply: 'reply',
        unread_q: 'unread_q',
        emailslist: 'emailslist'
      })
    }
  }
</script>
