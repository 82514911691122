// email_show_inbox.vue
<template>
  <div class="col-lg-9 animated fadeInRight p-l-5 p-r-5">
    <div class="mail-box-header"
         style="border-bottom: 1px solid #e7eaec;">
      <div class="pull-right tooltip-demo">
        <a @click="replyMsg(conversa)"
           class="btn btn-white btn-sm"
           data-toggle="tooltip" data-placement="top" title="Responder">
          <i class="fa fa-mail-reply-all"></i>
          Responder
        </a>
        <a href="mailbox.html" class="btn btn-white btn-sm" data-toggle="tooltip" data-placement="top" title="Excluir"><i class="fa fa-trash-o"></i> </a>
      </div>
      <h2>
        Mensagem
      </h2>
      <div v-if="conversa"
           class="mail-tools tooltip-demo m-t-md">
        <h3>
          <span class="font-noraml">Assunto:</span>
          {{ conversa.subject }}
        </h3>
        <h4>
          <a v-if="conversa.link_obj && (conversa.link_obj != '')"
             :href="conversa.link_obj.href"
             target="_blank">
            {{ conversa.link_obj.cap }}
            <i class="fa fa-external-link"></i>
          </a>
        </h4>
        <h5>
          <span class="pull-right font-noraml">{{ conversa.time_ago }}</span>
          <span class="font-noraml">From: </span>
          {{ conversa.sender.nome }}
        </h5>
      </div>
    </div>
    <div class="mail-box p-5">

      <div class="feed-activity-list">
        <div v-for="(mess, index) in conversa.messages"
             class="feed-element">
          <a href="#" class="pull-left">
            <img alt="image" class="img-circle"
                 :src="mess.sender.img">
          </a>
          <div class="media-body ">
            <small class="pull-right">{{ mess.time_ago }}</small>
            <strong>{{ mess.sender.nome }}</strong>
            {{ (index == 0 ? 'escreveu' : 'respondeu')}}
            <br>
            <div class="well"
                 style="font-size: 14px;">{{ mess.body }}</div>
          </div>
        </div>
      </div>

      <div v-if="false"
           class="mail-body text-right tooltip-demo">
        <a class="btn btn-sm btn-white"
           @click="replyMsg(conversa)">
          <i class="fa fa-mail-reply-all"></i>
          Responder
        </a>
        <button title="" data-placement="top" data-toggle="tooltip" data-original-title="Excluir" class="btn btn-sm btn-white"><i class="fa fa-trash-o"></i> Excluir</button>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</template>

<script>
  import mbStore from 'packs/vuex/stores/mailboxer_store.js'
  import stUtils from 'packs/vuex/stores/utils_store.js'
  import Utils from './utils.vue'
  import { mapGetters } from 'vuex'

export default {
    name: 'email_show_panel',
    mixins: [stUtils, Utils],
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        mounted: false,
        storeName: 'mailBoxerStore',
        user_id: this.options.user_id,
      }
    },
    created: function () {
      this.initStore(this.storeName, mbStore, {user_id: this.user_id})
      this.mounted = true
    },
    mounted: function() {
    },
    methods: {
    },
    computed: {
      ...mapGetters('mailBoxerStore', {
        unread_q: 'unread_q',
        conversa: 'conversa'
      })
    }
  }
</script>
