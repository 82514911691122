// ibox-list.vue
<template>
  <div class="ibox border-grayed m-b-5">

    <div class="ibox-title  small-margin">
      <h5>{{ options.title.caption }}</h5>
      <div class="ibox-tools">
        <a v-if="(options.title.button && askGetter(options.title.button.if.getter))"
           :class="options.title.button.class"
           @click="(options.title.button.block())"
        >{{options.title.button.caption}}</a>
        <a v-if="options.title.buttonCollapse"
           class="collapse-link">
          <i class="fa fa-chevron-up"></i>
        </a>
        <a v-if="options.title.buttonClose"
           @click="(options.title.buttonClose.block())"
           class="close-link">
          <i class="fa fa-times"></i>
        </a>
      </div>
    </div>
    <div class="ibox-content">
      <ul class="todo-list small-list">
        <li v-for="item in list"
            style="display: inline; padding: 2px 6px 2px 12px;"
        >
          <span class="m-l-xs">{{item.nome}}</span>
          <a v-if="options.itemActions.destroy" class="check-link"
             @click="removeItem(item)">
            <i class="fa fa-times"></i>
          </a>

          <a v-for="action in options.itemOtherActions"
             @click="(action.block(item))">
             <i :class="action.icon"></i>
          </a>

        </li>
      </ul>
    </div>
  </div>

</template>

<script>

  import inputUtils from './../mixins/inputUtils.vue'

  export default {
    name: 'IBoxList',
    mixins: [inputUtils],
    components: {
    },
    props: ['options'],

    data: function () {
      return {

      }
    },

    mounted: function() {

    },

    methods: {
      removeItem: function(item) {
        return this.$store.dispatch(this.options.store + '/removeItem', item)
      },
      askGetter: function(getter) {
        return this.$store.getters[this.options.store + '/' + getter]
      },
      ExecAction: function(action, item) {
        return this.$store.dispatch(this.options.store + '/' + action, item)
      }
    },

    computed: {
      list: function() {
        return this.$store.getters[this.options.store + '/' + this.options.collection]
      }
    }

  };
</script>
