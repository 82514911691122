<template>
  <div class="social-feed-box conversa_reply">
    <div class="media-body">

      <div class="social-avatar">
        <a :href="user.href"
           class="pull-left">
          <img alt="image"
              :src="user.img">
        </a>

        {{ user.name }}

        <div class="pull-right tooltip-demo">
          <a v-show="replybody != ''"
             class="btn btn-primary btn-sm" data-toggle="tooltip"
             data-placement="top" title="" data-original-title="Reply"
             @click="sendMessagem()"
          >
            <i class="fa fa-reply"></i>
            Salvar
          </a>
        </div>

      </div>

      <div class="social-body">
        <textarea class="form-control"
                  placeholder="Observação..."
                  v-model="replybody">
        </textarea>
      </div>
    </div>

  </div>
</template>

<script>
  import inputUtils from 'packs/components/mixins/inputUtils.vue'

  export default {
    name: 'ConversaReply',
    components: {
    },
    props: ['conversa', 'model', 'user'],
    data: function () {
      return {
        what: 'Im ConversaReply!',
        conver: this.conversa,

        replybody: ''
      }
    },
    mounted: function () {
    },
    watch: {
    },
    methods: {
      sendMessagem: function() {
        if (this.conver) {
          this.sendReply()
        } else {
          this.postMessagem()
        }
      },
      postMessagem: function() {
        var that = this;
        $.ajax({
          method: 'post',
          data: {
            model_type: that.model.type,
            model_id: that.model.id,
            subject:  'Observação',
            body:     that.replybody
          },
          url: '/messages/post_conv.json',
          success: function(res) {
            that.replybody = '';
            that.$parent.loadMessages();
          },
          error: function(res) {
          }
        });
      },
      sendReply: function() {
        var that = this;
        $.ajax({
          method: 'post',
          data: {
            conversa_id: that.conver.id,
            box: 'sent',
            body:        that.replybody
          },
          url: '/messages/' + that.conver.id + '/reply_conv.json',
          success: function(res) {
            that.replybody = '';
            that.$parent.loadMessages();
          },
          error: function(res) {
          }
        });
      }
    },
    computed: {
    }
  }
</script>
