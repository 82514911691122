<template>
  <div :class="klass">
    <div class="row m-t-sm">
      <div class="col-lg-12">
        <cr-title-btns
           v-if="options.buttons"
           :buttons="options.buttons">
        </cr-title-btns>
        <div class="panel blank-panel">
          <div class="panel-heading">
            <div class="panel-options">
              <ul class="nav nav-tabs">
                <li v-for="(aba, index) in abas"
                    v-show="aba.cap !== ''"
                    :id="aba.id"
                    :class="aba.class">
                  <a data-toggle="tab"
                     :ref="'tab_' + aba.id"
                     :href="'#tab-' + aba.id">
                    <span>{{ aba.cap }}</span>
                    <span v-if="abasLinesCounter[index]"
                      class='label badge label-info icon-counts'>
                        {{ abasLinesCounter[index] }}
                    </span>
                    <span v-if="sum_result_children"
                      class='label badge label-info icon-counts'>
                        {{ sum_result_children }}
                    </span>

                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="panel-body">
            <div class="tab-content">
              <div v-for="(aba, index) in abas"
                   :id="'tab-' + aba.id"
                   class="tab-pane"
                   :class="semPullRight(aba.class)">

                <component :is="abaCommponent(aba)"
                           :options="aba.content"
                           :sum_parent="aba.sum_parent"
                           :index="index"
                           :key="(refreshId + index)"
                           :ref="aba.content.type">
                </component>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CrTitleBtns            from 'packs/components/crud/cr_title_btns.vue'
  import CrAbaArquivos          from 'packs/components/crud/cr_aba_arquivos.vue'
  import CrAbaChart             from 'packs/components/crud/cr_aba_chart.vue'
  import CrAbaFormulariosEditor from 'packs/components/crud/cr_aba_formularios_editor.vue'
  import CrAbaMessagens         from 'packs/components/crud/cr_aba_messagens.vue'
  import CrAbaTextEditor        from 'packs/components/crud/cr_aba_text_editor.vue'
  import CrCalendar             from 'packs/components/crud/cr_calendar.vue'
  import CrCalendarShow210      from 'packs/components/crud/calendar_show210.vue'
  import CrDataTable            from 'packs/components/crud/cr_data_table.vue'
  import CrGantt                from 'packs/components/crud/cr_gantt.vue'
  import CrGBubble                from 'packs/components/crud/cr_gbubble.vue'
  import CrGoogleMaps           from 'packs/components/crud/cr_google_map.vue'
  import CrIndexCausas          from 'packs/components/causas/index_causas.vue'
  import CrIndexDatasPagas      from 'packs/components/pagamento/index_datas_pagas.vue'
  import CrIndexDatasPrevs      from 'packs/components/pagamento/index_datas_prevs.vue'
  import CrIndexPlanejamento    from 'packs/components/planejamento/index.vue'
  import CrRawHtml              from 'packs/components/crud/cr_raw_html.vue'
  import TopicoShow             from 'packs/components/topicos/topico_show.vue'
  import TopicoEditor           from 'packs/components/topicos/topico_editor.vue'
  import CrRawScrollHtml        from 'packs/components/crud/cr_raw_scroll_html.vue'
  import CrVerticalTreeEtapans  from 'packs/components/etapan/cr_vertical_tree_etapans.vue'


// Vue.component('index-datas-pagas', require('./pagamento/index_datas_pagas.vue') ) ;
// Vue.component('index-datas-prevs', require('./pagamento/index_datas_prevs.vue') ) ;


  export default {
    name: 'CrAbas',
    components: {
      'calendar-show210'          : CrCalendarShow210,
      'cr-title-btns'             : CrTitleBtns,
      'cr-aba-arquivos'           : CrAbaArquivos,
      'cr-aba-chart'              : CrAbaChart,
      'cr-aba-formularios-editor' : CrAbaFormulariosEditor,
      'cr-aba-messagens'          : CrAbaMessagens,
      'cr-aba-text-editor'        : CrAbaTextEditor,
      'cr-calendar'               : CrCalendar,
      'cr-data-table'             : CrDataTable,
      'cr-gantt'                  : CrGantt,
      'cr-gbubble'                : CrGBubble,
      'cr-google-maps'            : CrGoogleMaps,
      'cr-raw-html'               : CrRawHtml,
      'topico-show'               : TopicoShow,
      'topico-editor'             : TopicoEditor,
      'cr-raw-scroll-html'        : CrRawScrollHtml,
      'cr-vertical-tree-etapans'  : CrVerticalTreeEtapans,
      'index-causas'              : CrIndexCausas,
      'index-datas-pagas'         : CrIndexDatasPagas,
      'index-datas-prevs'         : CrIndexDatasPrevs,
      'planejamento-index'        : CrIndexPlanejamento

    },
    props: ['options', 'sum_parent', 'index'],
    data: function () {
      return {
        klass: 'cr-abas',
        abas: this.options.options,

        refreshId: 0,

        sum_result_children: 0,

        linesCounter: []
      }
    },
    mounted: function() {
      this.$on('setlineCount', function(msg) {
        this.setLineCount(msg)
      })
      if (this.options.options) {
        this.linesCounter = Array(this.options.options.length)
      }
    },
    methods: {
      abaCommponent: function(aba) {
        var comp_name = ''
        switch(String(aba.content.type)) {
          case 'aba_arquivos'          : comp_name = 'cr-aba-arquivos';           break;
          case 'abas'                  : comp_name = 'cr-abas';                   break;
          case 'aba_chart'             : comp_name = 'cr-aba-chart';              break;
          case 'aba_text_editor'       : comp_name = 'cr-aba-text-editor';        break;
          case 'calendar_show210'      : comp_name = 'calendar-show210';          break;
          case 'cr_calendar'           : comp_name = 'cr-calendar';               break;
          case 'data_table'            : comp_name = 'cr-data-table';             break;
          case 'edit_form'             : comp_name = 'cr-edit-form';              break;
          case 'fb_full_editor'        : comp_name = 'cr-aba-formularios-editor'; break;
          case 'google_maps'           : comp_name = 'cr-google-maps';            break;
          case 'index_causas'          : comp_name = 'index-causas';              break;
          case 'index_datas_pagas'     : comp_name = 'index-datas-pagas';         break;
          case 'index_datas_prevs'     : comp_name = 'index-datas-prevs';         break;
          case 'messages_list'         : comp_name = 'cr-aba-messagens';          break;
          case 'planejamento_index'    : comp_name = 'planejamento-index';        break;
          case 'raw_html'              : comp_name = 'cr-raw-html';               break;
          case 'raw_scroll_html'       : comp_name = 'cr-raw-scroll-html';        break;
          case 'topico_show'           : comp_name = 'topico-show'  ;             break;
          case 'topico_editor'         : comp_name = 'topico-editor';             break;
          case 'show_form'             : comp_name = 'cr-show-form';              break;
          case 'table_gantt'           : comp_name = 'cr-gantt';                  break;
          case 'table_gbubble'         : comp_name = 'cr-gbubble';                break;
          case 'vertical_tree_etapans' : comp_name = 'cr-vertical-tree-etapans';  break;
        }
        return comp_name;
      },
      collect_children: function() {
        var total = 0;
        total = this.linesCounter.reduce(function(aba_count, val) { return aba_count + val; }, 0)

        this.$parent.$emit('setlineCount', {
          id: this.index,
          count: total
        })

      },
      setLineCount: function(msg) {
        // console.log(`Atualizada aba ${msg.id} com ${msg.count} registros`)
          this.$set(this.linesCounter, msg.id, msg.count)

          if ((this.$parent.klass == 'cr-abas') &&
              (this.sum_parent)) {
            this.collect_children();
          }
      },
      semPullRight: function(classe) {
        if (classe) {
          return classe.replace('pull-right', '') ;
        } else {
          return classe
        }
      }
    },
    watch: {
      abas: function () {
        this.refreshId += 1;
        // this.$forceUpdate();
      }
    },

    computed:{
      abasLinesCounter: function () {
        return this.linesCounter ;
      }
    }
  };
</script>
