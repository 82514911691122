// user/user_avatar.vue
<template>
  <div class="avatar col-sm-8">
    <div v-if="action == 'showing'"
         class="photos">
      <a target="_blank"
         :href="avatar.img">
        <img alt="image"
             class="feed-photo"
             :src="avatar.img">
      </a>
      <button type="button"
              class="btn btn-primary btn-sm btn-block"
              @click="action = 'editing'">
        <i class="fa fa-camera p-r-5"></i>
        Mudar Imagem
      </button>
    </div>
    <div v-if="action == 'editing'">

      <croppa :canvas-color="'default'"
              :placeholder="'Escolha uma imagem'"
              :placeholder-font-size="14"
              v-model="img_croppada"
      >

      </croppa>

      <button type="button"
              class="btn btn-primary btn-sm"
              @click="saveImage()">
        <i class="fa fa-camera p-r-5"></i>
        Salvar Imagem
      </button>

       <button type="button"
              class="btn btn-default btn-sm"
              @click="action = 'showing'">
        <i class=""></i>
        Cancelar
      </button>
    </div>
  </div>
</template>

<script>

  import Croppa from 'vue-croppa';

  export default {
    name: 'UserAvatar',
    components: {
      'croppa': Croppa.component
    },
    props: ['options'],

    data: function () {
      return {
        klass: 'user-avatar',
        domid: '',
        type: 'user-avatar',

        action: 'showing',
        opts: this.options.options,

        avatar: {
          img: this.options.options.avatar_url,
          can_edit: this.options.options.can_edit,
          edit_url: this.options.options.edit_url
        },

        img_croppada: {},

        objectUrl: null,
        objectBlob: null,

        loaded   : false
      }
    },

    mounted: function() {
    },

    methods: {
      saveImage() {
        var that = this
        var blob_data = null
        this.img_croppada.generateBlob((blob) => {

          var fd = new FormData()
          fd.append('user[avatar]', blob)
          fd.append('id', that.opts.user_id)

          $.ajax({
            url: that.avatar.edit_url ,
            data: fd,
            type: 'PATCH',
            processData: false,
            contentType: false,
            success: function(res) {
              that.$set(that.avatar, 'img', res.record.avatar.url)
              that.action = 'showing'
              window.vm.alert('success', (res.msg || 'Imagem atualizada com sucesso!')) ;
            }
          })
        })
      }
    },

    computed: {
    }
  };
</script>
