var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "col-sm-xx form-horizontal" }, [
    _c("div", { staticClass: "form-group select optional" }, [
      _c(
        "label",
        {
          staticClass: "control-label select required col-sm-2",
          attrs: { for: "user_id" }
        },
        [_vm._v("Usuário:")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-10" },
        [
          _c("cmp-v-select", {
            attrs: {
              input_class: "form-control select select2",
              options: _vm.options.users,
              required: true,
              multiple: false,
              value: _vm.user_id,
              onChange: _vm.avisaMudanca()
            },
            model: {
              value: _vm.user_id,
              callback: function($$v) {
                _vm.user_id = $$v
              },
              expression: "user_id"
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group select optional" }, [
      _c(
        "label",
        {
          staticClass: "control-label select required col-sm-2",
          attrs: { for: "obra_id" }
        },
        [_vm._v("Obra:")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-10" },
        [
          _c("cmp-v-select", {
            attrs: {
              input_class: "form-control select select2",
              options: _vm.options.obras,
              required: true,
              multiple: false,
              value: _vm.obra_id
            },
            model: {
              value: _vm.obra_id,
              callback: function($$v) {
                _vm.obra_id = $$v
              },
              expression: "obra_id"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }