var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: " float-e-margins",
      class: _vm.localWidth,
      staticStyle: {
        "border-width": "1px",
        "border-color": "lightgray",
        "border-style": "solid",
        display: "table"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "ibox-title ui-sortable",
          staticStyle: { "border-top-width": "1px" }
        },
        [_vm._t("ibox-title"), _vm._v(" "), _vm._m(0)],
        2
      ),
      _vm._v(" "),
      _vm._t("ibox-content")
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ibox-tools" }, [
      _c("a", { staticClass: "collapse-link" }, [
        _c("i", { staticClass: "fa fa-chevron-up" })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }