export default {
    namespaced: true,
    state: {
      url: '',
      notificacoes: [],
      total_count: 0
    },
    mutations: {
      init(state, data) {
        state.url          = data.url;
        state.url_count    = data.url_count;
        state.read_all     = data.read_all;
        return state;
      },
      count(state, data) {

        state.total_count= data.total_count;
        return state;
      },
      many(state, data) {
        state.notificacoes= data.notificacoes;
        state.total_count= data.total_count;
        return state;
      }
    },
    actions: {
      init(context, conf) {
        context.commit('init', conf);
        // context.dispatch('load')
        context.dispatch('load_count');
      },
      load_count(context) {
        var that = this
        $.ajax({
          url: context.state.url,
          data: {
            only_count: true
          },
          type: 'get',
          format: 'json',
          success: function(data) {
            context.commit('count', data)
          }
        })
      },
      load(context) {
        var that = this
        if (context.state.notificacoes.length > 0) {
          return true ;
        }
        $.ajax({
          url: context.state.url,
          data: {
          },
          type: 'get',
          format: 'json',
          success: function(data) {
            context.commit('many', data)
          },
          error: function(error) {
          },
          complete: function(error) {
          }
        })
      },
      load_notificacoes(context) {
      },
      markAllRead(context) {
        var that = this
        $.ajax({
          url: context.state.read_all,
          data: {
          },
          type: 'get',
          format: 'json',
          success: function(data) {
            context.commit('many', data)
          },
          error: function(error) {
          }
        })
        context.dispatch('load')
      }
    },
    getters: {
      index: state => {
        return state.notificacoes ;
      },
      total_count: state => {
        return state.total_count ;
      }

    }
  }
