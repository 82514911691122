var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model.opts
    ? _c(
        "div",
        { staticClass: "ibox" },
        [
          _vm.model.opts.title &&
          _vm.model.opts.title != "" &&
          _vm.model.opts.title != "no_title"
            ? _c("cr-title", {
                attrs: {
                  title: _vm.model.opts.title,
                  subtitle: _vm.model.opts.subtitle,
                  buttons: _vm.model.opts.title_buttons,
                  actions: _vm.model.opts.actions
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ibox-content" },
            _vm._l(_vm.model.opts.body, function(row, index) {
              return _c(
                "div",
                { staticClass: "row" },
                _vm._l(row, function(div) {
                  return _c(
                    "div",
                    { class: div.class },
                    _vm._l(div.components, function(compo, indexComp) {
                      return _c(_vm.bodyCommponent(compo.ctype), {
                        key: _vm.refreshId + indexComp,
                        ref: compo.ref,
                        refInFor: true,
                        tag: "component",
                        class: compo.class,
                        attrs: { id: compo.id, options: compo }
                      })
                    }),
                    1
                  )
                }),
                0
              )
            }),
            0
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }