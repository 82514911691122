// left-main-menu.vue
<template>
  <div id='left-main-menu'>

    <div class="menu"
         v-for="(menu, index) in menu_ativado"
         :key="refreshId + index">
      <ul class="nav metismenu"
          id="left-metismenu"
          :onload="ativaMetisMenu()">
        <li v-for="(li, index2) in menu"
           :key="refreshId + 3 + index2"
           :class="{ active: li.active }">
          <a :href='li.href' :class="li.class">
            <i v-if='li.i' :class="li.i"></i>
            <span :class='li.span'>
              {{ li.caption }}
            </span>
            <span v-if='li.itens' class="fa arrow"></span>
          </a>
          <ul v-if='li.itens'
              class="nav nav-second-level collapse"
              :class="{ on: li.active }"
              :style="{ 'height: 0px;': ! li.active }" >
            <li v-for="item in li.itens" :class="{ active: item.active }">
              <a :href="item.href"
                :data-remote="item.remote">
                <span v-if='item.span' :class='item.span'></span>
                {{ item.caption }}
                <span v-if='item.itens' class="fa arrow"></span>
              </a>
              <ul v-if='item.itens'
                  class="nav nav-third-level collapse"
                  :class="{ on: item.active }"
                  :style="{ 'height: 0px;': ! item.active }" >
                <li v-for="item3 in item.itens" :class="{ active: item3.active }">
                  <a :href="item3.href"
                     :data-remote="item3.remote">
                    <span v-if='item3.span' :class="item3.span"></span>
                    {{ item3.caption }}
                  </a>
                </li>
              </ul>
            </li>
         </ul>
        </li>
      </ul>
      <div class="divider" style="height: 20px;"></div>
    </div>
  </div>
</template>

<script>

export default {
    name: 'LeftMainMenu',
    props: ['menus', 'page', 'refresh_url'],
    data: function () {
      return {
        loaded: false,
        domid: 'left-main-menu',
        input_menu: this.menus,
        menu_ativo: [],
        refreshId: 0

      }
    },
    components: {
    },
    methods: {
      // menu_ativado: function() {
      //   if (this.loaded) {
      //     return this.menu_ativo ;
      //   } else {
      //     this.AtivaMenu(this.input_menu) ;
      //     this.menu_ativo = this.menus ;
      //     this.loaded = true ;
      //     return this.menu_ativo ;
      //   }
      // },
      AtivaMenu: function(pmenu) {
        var item_ativado = false ;
        if ($.isArray(pmenu)) {
          for (var i = 0; i < pmenu.length; i++) {
            item_ativado = item_ativado || this.AtivaMenu(pmenu[i])
          }
        } else {
          if (pmenu.itens) {
            if (this.AtivaMenu(pmenu.itens)) {
              pmenu.active = true
              item_ativado = true
            }
          }
          if (pmenu.url) {
            pmenu.active = (pmenu.url.controller &&
              (pmenu.url.controller == this.page.controller))
            if ((pmenu.active) && (pmenu.url.action)) {
              pmenu.active = (pmenu.url.action == this.page.action)
            }
            if ((pmenu.active) && (pmenu.url.id)) {
              pmenu.active = (pmenu.url.id == this.page.id)
            }
            if ((pmenu.active) && (pmenu.url.obra_id)) {
              pmenu.active = (pmenu.url.obra_id == this.page.obra_id)
            }
            if ((pmenu.active) && (pmenu.url.projeto_id)) {
              pmenu.active = (pmenu.url.projeto_id == this.page.projeto_id)
            }
            if ((pmenu.active) && (pmenu.url.reunible_id)) {
              pmenu.active = (pmenu.url.reunible_id == this.page.reunible_id)
            }
            if ((pmenu.active) && (pmenu.url.alocable_id)) {
              pmenu.active = (pmenu.url.alocable_id == this.page.alocable_id)
            }
            item_ativado = pmenu.active
          }
        }
        return item_ativado ;
      },
      as_array: function (span) {
        return (! $.isArray(span)) ? [span] : span ;
      },
      refresh: function() {
        var that = this;
        $.ajax({
          method: 'get',
          url: that.refresh_url + '.json',
          format: 'json',
          success: function(res) {
            that.$set(that, 'input_menu', res.menu);
            // that.input_menu = res.menu;
            that.loaded = false ;
            that.refreshId += 100 ;
          }
        });

      },
      ativaMetisMenu: function() {
        $('ul.metismenu').each(function(i) {
          $(this).metisMenu();
        });
      }
    },
    computed: {
      menu_ativado() {
        if (this.loaded) {
          return this.menu_ativo ;
        } else {
          this.AtivaMenu(this.input_menu) ;
          this.menu_ativo = this.input_menu ;
          this.loaded = true ;
          return this.menu_ativo ;
        }
      }
    },
    mounted: function() {
      // this.$store.dispatch(
      //   'set_current_user', this.current.user
      // )
      // this.$store.dispatch(
      //   'ProjetoStore/init_id', this.current.projeto.id
      // )

    }
  }
</script>
