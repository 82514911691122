var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.apontamento.equipe_id && _vm.apontamento.equipe_id > 0
    ? _c("div", { staticClass: "card", attrs: { id: "novo_apo" } }, [
        _c("div", { staticClass: "card-header ch-alt" }, [
          _c("div", { staticClass: "media-body m-t-5" }, [
            _c("div", { staticClass: "col-sm-10" }, [
              _c("h2", [_vm._v(_vm._s(_vm.titleNovoApo))])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card-body card-padding" }, [
          _vm.apontamento.data
            ? _c("div", { staticClass: "row" }, [
                _vm.errorsStr(_vm.model_e, "data")
                  ? _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v(
                        "Data: " + _vm._s(_vm.errorsStr(_vm.model_e, "data"))
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("form", { staticClass: "col-sm-3 form-horizontal" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label col-sm-2",
                        attrs: { for: "select_local" }
                      },
                      [_vm._v("Local")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-sm-10" },
                      [
                        _c("cmp-select2", {
                          attrs: {
                            adata: _vm.locais2Sel,
                            value: "servico.local_id",
                            options: {
                              placeholder: "Selecione um local"
                            }
                          },
                          model: {
                            value: _vm.servico.local_id,
                            callback: function($$v) {
                              _vm.$set(_vm.servico, "local_id", $$v)
                            },
                            expression: "servico.local_id"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("form", { staticClass: "col-sm-3 form-horizontal" }, [
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      class: {
                        error: _vm.errorsStr(_vm.servico_e, "percentual")
                      }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "control-label col-sm-4",
                          attrs: { for: "apo_perc" }
                        },
                        [_vm._v("Percentual")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-sm-6" },
                        [
                          _vm.limites_medicao_terceiros.length == 0
                            ? _c("vue-numeric", {
                                staticClass: "form-control ",
                                attrs: {
                                  id: "apo_perc",
                                  separator: ".",
                                  precision: 2
                                },
                                model: {
                                  value: _vm.servico.percentual,
                                  callback: function($$v) {
                                    _vm.$set(_vm.servico, "percentual", $$v)
                                  },
                                  expression: "servico.percentual"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.limites_medicao_terceiros.length > 0
                            ? _c("cmp-select2", {
                                attrs: {
                                  adata: _vm.limites_medicao_terceiros,
                                  sssvalue: "servico.local_id",
                                  options: {}
                                },
                                model: {
                                  value: _vm.servico.percentual,
                                  callback: function($$v) {
                                    _vm.$set(_vm.servico, "percentual", $$v)
                                  },
                                  expression: "servico.percentual"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.errorsStr(_vm.servico_e, "percentual"))
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm.equipe.da_casa
                  ? _c(
                      "div",
                      { staticClass: "col-sm-2" },
                      [
                        _c("btn-novo-funcionario", {
                          attrs: {
                            funcoes: _vm.funcoesPraNovo(),
                            funcionarios: _vm.funcionariosPraNovo()
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _vm.equipe.membros && _vm.equipe.membros.length > 0
              ? _c(
                  "table",
                  {
                    staticClass: "table table-inner table-vmiddle",
                    attrs: { id: "table_membros" }
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      { attrs: { id: "membros" } },
                      _vm._l(_vm.equipe.membros, function(membro, index) {
                        return _c(
                          "tr",
                          {
                            attrs: {
                              id:
                                "row_membro-membro_id" +
                                membro.id +
                                membro.calculo_equipe_id +
                                membro.funcionario_id
                            }
                          },
                          [
                            _c("td", [
                              _c("div", { staticClass: "dropdown" }, [
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "dropdown-toggle kd_botao_remover btn btn-xs btn-danger waves-effect",
                                    attrs: {
                                      href: "#",
                                      "data-toggle": "dropdown",
                                      "aria-expanded": "false"
                                    }
                                  },
                                  [_vm._v("Remover")]
                                ),
                                _vm._v(" "),
                                _c("ul", { staticClass: "dropdown-menu" }, [
                                  _c(
                                    "li",
                                    { attrs: { role: "presentation" } },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            role: "menuitem",
                                            tabindex: "-1"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.removeMembro(
                                                membro.funcionario_id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                          Remover\n                        "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "li",
                                    { attrs: { role: "presentation" } },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            role: "menuitem",
                                            tabindex: "-1"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.removeMembroDaEquipe(
                                                membro.funcionario_id
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                          Remover da equipe\n                        "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ])
                              ])
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-left" }, [
                              _vm._v(_vm._s(membro.nome))
                            ]),
                            _vm._v(" "),
                            _c("td", { staticClass: "text-left" }, [
                              _vm._v(_vm._s(membro.funcao))
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-group decimais optional apontamento_membros_horas m-b-0",
                                  class: {
                                    error: _vm.errorsStr(
                                      _vm.membros_e[index],
                                      "horas"
                                    )
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.ja_alocadas("horas", membro) +
                                            _vm.estimadas(membro)
                                        ) +
                                        "\n                  "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("vue-numeric", {
                                    staticClass: "form-control ",
                                    attrs: { separator: ".", precision: 2 },
                                    model: {
                                      value: _vm.equipe.membros[index].horas,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.equipe.membros[index],
                                          "horas",
                                          $$v
                                        )
                                      },
                                      expression: "equipe.membros[index].horas"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.errorsStr(
                                          _vm.membros_e[index],
                                          "horas"
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("td", [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "form-group decimais optional apontamento_membros_extras m-b-0",
                                  class: {
                                    error: _vm.errorsStr(
                                      _vm.membros_e[index],
                                      "extras"
                                    )
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.ja_alocadas("extras", membro)
                                        ) +
                                        "\n                  "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("vue-numeric", {
                                    staticClass: "form-control ",
                                    attrs: { separator: ".", precision: 2 },
                                    model: {
                                      value: membro.extras,
                                      callback: function($$v) {
                                        _vm.$set(membro, "extras", $$v)
                                      },
                                      expression: "membro.extras"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.errorsStr(
                                          _vm.membros_e[index],
                                          "extras"
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.apontamento.data
              ? _c("div", { staticClass: "col-md-4 col-md-offset-8" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary waves-effect",
                      on: { click: _vm.postApontamento }
                    },
                    [_vm._v("\n            Confirmar Apontamento\n          ")]
                  )
                ])
              : _vm._e()
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "text-left", staticStyle: { width: "5%" } }, [
          _vm._v("Ação")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-left", staticStyle: { width: "50%" } }, [
          _vm._v("Funcionario")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-left", staticStyle: { width: "15%" } }, [
          _vm._v("Funcão")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-left", staticStyle: { width: "15%" } }, [
          _vm._v("Horas")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-left", staticStyle: { width: "15%" } }, [
          _vm._v("Extras")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }