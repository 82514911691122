var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-lg-9 animated fadeInRight p-l-5 p-r-5" },
    [
      _c("div", { staticClass: "mail-box-header" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("h2", [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.nova_msg() ? "Escrever mensagem" : "Responder mensagem"
              ) +
              "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mail-box" }, [
        _c("div", { staticClass: "mail-body" }, [
          _c("form", { staticClass: "form-horizontal" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-2 control-label" }, [
                _vm._v("Para:")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-10" },
                [
                  _vm.nova_msg()
                    ? _c("cmp-v-select", {
                        attrs: {
                          required: true,
                          multiple: true,
                          name: "email_dests",
                          options: _vm.emailslist
                        },
                        model: {
                          value: _vm.mail.tos,
                          callback: function($$v) {
                            _vm.$set(_vm.mail, "tos", $$v)
                          },
                          expression: "mail.tos"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.nova_msg()
                    ? _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          readonly: "readonly",
                          value: "Destinatários da mesagem original"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "col-sm-2 control-label" }, [
                _vm._v("Assunto:")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-10" }, [
                _vm.nova_msg()
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.mail.subject,
                          expression: "mail.subject"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.mail.subject },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.mail, "subject", $event.target.value)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.nova_msg()
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.mail.subject,
                          expression: "mail.subject"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text", readonly: "readonly" },
                      domProps: { value: _vm.mail.subject },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.mail, "subject", $event.target.value)
                        }
                      }
                    })
                  : _vm._e()
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mail-text h-200" }, [
          _c("div", { staticClass: "rows" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.mail.body,
                  expression: "mail.body"
                }
              ],
              staticClass: "form-control text optional",
              attrs: { id: "text_editxx", rows: "5" },
              domProps: { value: _vm.mail.body },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.mail, "body", $event.target.value)
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mail-body text-right tooltip-demo" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-sm btn-primary",
              class: _vm.canSend ? "" : "disabled",
              attrs: {
                "data-toggle": "tooltip",
                "data-placement": "top",
                title: "Enviar email"
              },
              on: {
                click: function($event) {
                  return _vm.sendEmail()
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-reply" }),
              _vm._v("\n        Enviar\n      ")
            ]
          ),
          _vm._v(" "),
          _vm._m(1)
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "clearfix" })
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pull-right tooltip-demo" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-danger btn-sm",
          attrs: {
            href: "mailbox.html",
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: "Descartar"
          }
        },
        [_c("i", { staticClass: "fa fa-times" }), _vm._v(" Cancelar")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-white btn-sm",
        attrs: {
          "data-toggle": "tooltip",
          "data-placement": "top",
          title: "Descartar email"
        }
      },
      [
        _c("i", { staticClass: "fa fa-times" }),
        _vm._v("\n        Cancelar\n      ")
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }