import { render, staticRenderFns } from "./topico_show.vue?vue&type=template&id=700ef3dc&"
import script from "./topico_show.vue?vue&type=script&lang=js&"
export * from "./topico_show.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/cp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('700ef3dc')) {
      api.createRecord('700ef3dc', component.options)
    } else {
      api.reload('700ef3dc', component.options)
    }
    module.hot.accept("./topico_show.vue?vue&type=template&id=700ef3dc&", function () {
      api.rerender('700ef3dc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/packs/components/topicos/topico_show.vue"
export default component.exports