// in_columns_list.vue
<template>
  <a :data-remote="options.others['data-remote']"
     :data-toggle="options.others['data-toggle']"
     :data-placement="options.others['data-placement']"
     :title="options.others.title"
     :href="options.href">
    <i :class="options.i_class"
       :id="options.others.id_li"
    ></i>
  </a>
</template>

<script>

  export default {
    name: 'CrAction',
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'cr-action'
      }
    }
  }
</script>

