export default {
    methods: {
      initStore: function(storeName, planStore, initPayLoad) {
        const store = this.$store;
        // Carrega o módulo da store
        if (!(store && store.state && store.state[storeName])) {
          store.registerModule(storeName, planStore);
        } else {
          console.log(`reusing module: ${storeName}`);
        }
        this.$store.dispatch(this.storeName + '/init', initPayLoad)

      },
      dispach: function(storeName, action, payload) {
        this.$store.dispatch(this.storeName + '/' + action, payload)
      }
    },
    computed: {
    }

  }


