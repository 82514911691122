var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.klass }, [
    _c("div", { staticClass: "ibox float-e-margins col-sm-12" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-2" }, [
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.state == "closed",
                  expression: "(state == 'closed')"
                }
              ],
              staticClass: "btn btn-primary waves-effect",
              on: {
                click: function($event) {
                  return _vm.openFiltro()
                }
              }
            },
            [
              _vm._v(" Filtrar registros\n        "),
              _c("i", { staticClass: "fa fa-filter" })
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _vm.state == "open"
        ? _c(
            "div",
            [
              _c("filtro-fin-tr", {
                ref: "filtro_form",
                attrs: { options: _vm.opts }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.state == "open",
                expression: "(state == 'open')"
              }
            ],
            staticClass: "col-sm-4 col-sm-offset-8"
          },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-outline btn-success",
                on: {
                  click: function($event) {
                    return _vm.cleanFilter()
                  }
                }
              },
              [_vm._v(" Limpar filtro\n        ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary waves-effect",
                on: {
                  click: function($event) {
                    return _vm.applyFilter()
                  }
                }
              },
              [
                _vm._v(" Aplicar filtro\n        "),
                _c("i", { staticClass: "fa fa-filter" })
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline btn-success",
                on: {
                  click: function($event) {
                    return _vm.closeFilter()
                  }
                }
              },
              [
                _vm._v(" Fechar\n        "),
                _c("i", { staticClass: "fa fa-angle-double-up" })
              ]
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }