// li_menu_icon.vue
<template>
  <li class="dropdown">
    <a class="right-sidebar-toggle dropdown-toggle count-info"
       ref="show_mess_btn"
       href="#">
      <i class="fa fa-envelope"></i>
      <span v-if="(mounted && (unread_q > 0))"
            class="label label-warning" style="">{{ unread_q }}</span>
    </a>
  </li>
</template>

<script>
  import mbStore from 'packs/vuex/stores/mailboxer_store.js'
  import stUtils from 'packs/vuex/stores/utils_store.js'
  import { mapGetters } from 'vuex'

export default {
    name: 'li_menu_icon',
    mixins: [stUtils],
    components: {
    },
    props: ['user_id'],
    data: function () {
      return {
        what: 'Im LiMenuIcon!',

        mounted: false,
        storeName: 'mailBoxerStore'
      }
    },
    created: function () {
      this.initStore(this.storeName, mbStore, {user_id: this.user_id})
      this.mounted = true
    },
    mounted: function () {
      var that = this;
      this.dispach(this.storeName, 'load', {box: 'inbox'})
      $(this.$refs.show_mess_btn).click(function(e){
        that.loadMessages();
        e.preventDefault();
      });
    },
    watch: {
    },
    methods: {
      loadMessages: function() {
        console.log('Load Messages clicado')
        this.dispach(this.storeName, 'load', {box: 'unread'})
      }
    },
    computed: {
      ...mapGetters('mailBoxerStore', {
        unread_q: 'unread_q'
      })
    }
  }
</script>
