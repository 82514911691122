// email_index.vue
<template>
  <div class="row">
    <email-left-panel
         :options="{user_id: user_id, folder: folder}">
    </email-left-panel>

    <email-folder-panel v-show="action == 'show_folder'"
                       :options="{user_id: user_id, folder: folder}">
    </email-folder-panel>

    <email-show-panel v-show="action == 'show'"
                       :options="{user_id: user_id, folder: folder}">
    </email-show-panel>

    <email-compose-panel v-show="action == 'compose'"
                         ref="compose"
                       :options="{user_id: user_id, folder: folder}">
    </email-compose-panel>

  </div>
</template>

<script>
  import LeftPanel    from './email_left_panel.vue'
  import FolderPanel   from './email_folder_panel.vue'
  import ShowPanel    from './email_show_panel.vue'
  import ComposePanel from './email_compose_panel.vue'

  import mbStore from 'packs/vuex/stores/mailboxer_store.js'
  import stUtils from 'packs/vuex/stores/utils_store.js'
  import { mapGetters } from 'vuex'

export default {
    name: 'email_index',
    mixins: [stUtils],
    components: {
      'email-left-panel': LeftPanel,
      'email-folder-panel': FolderPanel,
      'email-show-panel': ShowPanel,
      'email-compose-panel': ComposePanel
    },
    props: ['options'],
    data: function () {
      return {
        mounted: false,
        storeName: 'mailBoxerStore',
        user_id: this.options.owner.id,

        action: 'show_folder',
        folder: 'inbox',

        conv_to_reply: null
      }
    },
    created: function () {
      this.initStore(this.storeName, mbStore, {user_id: this.user_id})
      this.dispach(this.storeName, 'load', {box: 'inbox' }) //, only_count: true})
      this.dispach(this.storeName, 'load', {box: 'sent'  }) //, only_count: true})
      this.dispach(this.storeName, 'load', {box: 'trash' }) //, only_count: true})
      this.dispach(this.storeName, 'load', {box: 'unread'}) //, only_count: true})
      this.mounted = true

      var that = this
      this.$on('change_email_action', function(change) {
        // Quando é chamado com change.action == 'show' não ha folder para carregar
        if (change.folder) { this.dispach(this.storeName, 'load', {box: change.folder}); }
        that.$set(that, 'action', change.action);
        if (change.folder) { that.$set(that, 'folder', change.folder); }
      })
    },
    mounted: function() {
      var that = this
      if ((this.options.conversa) && (this.options.conversa.id > 0)) {
        this.dispach(this.storeName, 'loadConversa', this.options.conversa.id)
        that.$set(that, 'action', 'show');
      }
    },
    methods: {
    },
    computed: {
      // ...mapGetters('mailBoxerStore', {
      //   unread_q: 'unread_q'
      // })
    }
  }
</script>
