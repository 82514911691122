// topico/topico_item.vue
<template>
  <div class="feed-element">
    <a :href="options.link">
      <div class="media-body ">
        <!-- <small class="float-right">5m ago</small> -->
        <strong>
          {{ options.title }}
        </strong>
        <br>
        {{ options.publicado_by }}
        <small class="text-muted">{{ options.publicado_desde }}</small>
      </div>
    </a>
  </div>
</template>

<script>

  export default {
    name: 'topico_item',
    props: ['options'],
    components: {
      // 'ibox2' : Ibox2
    },
    data: function () {
      return {
        refreshIndex: 0,
        loaded: false,
        storeName: 'TopicoStore'
      }
    },
    created: function () {
    },
    methods: {
    },
    computed: {
    }
  }

</script>

