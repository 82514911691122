var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "col-sm-12", attrs: { id: "btn" } }, [
      _c(
        "button",
        {
          staticClass: " btn btn-primary",
          on: {
            click: function($event) {
              return _vm.show_mapa()
            }
          }
        },
        [_vm._v("\n        Mostrar Gantt\n      ")]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row col-sm-12" },
      [
        _c("GChart", {
          key: _vm.show_inc,
          attrs: {
            type: "BubbleChart",
            settings: _vm.ganttSettings,
            data: _vm.chartDataComp(),
            options: _vm.chartOptions()
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }