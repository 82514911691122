// cr_gantt.vue
<template>
  <div>
    <div
      class="col-sm-12"
         id="btn">
      <button
        class=" btn btn-primary"
        @click="show_mapa()">
        Mostrar Gantt
      </button>
    </div>
    <div class="row col-sm-12">

      <GChart
        type="BubbleChart"
        :key="show_inc"
        :settings="ganttSettings"
        :data="chartDataComp()"
        :options="chartOptions()"
      />
<!--
        :create-chart="drawGanttChart"
 -->
    </div>

  </div>
</template>

<script>

  import { GChart } from 'vue-google-charts'


  export default {
    name: 'CrGBubble',
    components: {
      GChart
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'gbubble',

        ganttSettings: {
          packages: ['corechart'],
          language: 'pt_BR'
        },

        columnsDef: [ 'ID', 'Dia', 'Local', 'Serviço', 'outro' ],
        // columnsDef: ['ID', 'Life Expectancy', 'Fertility Rate', 'Region', 'Population'],

        crudData: [
          [ 'PAV1', 1, 1, '003.02.01.01' , 4],
          [ 'PAV2', 2, 2, '003.02.01.02' , 4],
          [ 'PAV3', 3, 3, '003.02.01.03' , 4],
          [ 'PAV4', 4, 4, '003.02.01.04' , 4],
          [ 'PAV5', 5, 5, '003.02.01.05' , 4],
          [ 'PAV6', 6, 6, '003.02.01.06' , 4]
        ],

        // crudData: [
        //   ['CAN',    80.66,              1.67,      'North America',  33739900],
        //   ['DEU',    79.84,              1.36,      'Europe',         81902307],
        //   ['DNK',    78.6,               1.84,      'Europe',         5523095],
        //   ['EGY',    72.73,              2.78,      'Middle East',    79716203],
        //   ['GBR',    80.05,              2,         'Europe',         61801570],
        //   ['IRN',    72.49,              1.7,       'Middle East',    73137148],
        //   ['IRQ',    68.09,              4.77,      'Middle East',    31090763],
        //   ['ISR',    81.55,              2.96,      'Middle East',    7485600],
        //   ['RUS',    68.6,               1.54,      'Europe',         141850000],
        //   ['USA',    78.09,              2.05,      'North America',  307007000]
        // ],

        show_inc: 0

      }
    },
    mounted: function() {
      // this.show_mapa()
    },
    methods: {

      // drawGanttChart: function(el, google){
      //   if(el === undefined){
      //     return
      //   }
      //   return new google.visualization.Gantt(el)
      // },

      chartOptions: function() {
        var opts = {
          width:  1200,
          height:  1200,

          hAxis: {
            title: 'Dias',
            // ticks: [1, 50]
            ticks: [
              { v:5,  f:'05/11'},
              { v:10, f:'15/11'},
              { v:15, f:'20/11'},
              { v:20, f:'25/11'},
              { v:25, f:'30/11'},
              { v:30, f:'05/12'}
            ]
          },
          vAxis: {title: 'Locais'},


        };
        opts['height'] = this.crudData.length * 70 ;
        return opts ;
      },

      daysToMilliseconds: function(days) {
        return days * 24 * 60 * 60 * 1000;
      },

      chartDataComp: function() {
        return [this.columnsDef].concat(this.parsedGanttDataTable) ;
      },

      show_mapa: function() {
        var that = this ;

        // Teste
        that.show_inc += 1;

        // $.ajax({
        //   url: that.options.url + '.json',
        //   type: 'get',
        //   format: 'json',
        //   success: function(data) {
        //     that.$set(that, 'crudData', data);
        //     that.show_inc += 1;
        //   },
        //   complete: function(response) {
        //   }
        // });

      },
      daysToMilliseconds: function(days) {
        return days * 24 * 60 * 60 * 1000;
      }

    },
    computed: {
      parsedGanttDataTable() {
        var that = this ;

        // this.crudData.forEach((linha, index) => {
        //   if (index > -1) {   // Trap, sempre é
        //     // if (linha[2]) {
        //     //   that.crudData[index][2] = new Date(linha[2])
        //     // }
        //     if (linha[3]) {
        //       that.crudData[index][3] = new Date(linha[3])
        //     }
        //     if (linha[4]) {
        //       that.crudData[index][4] = new Date(linha[4])
        //     }
        //   }

        // });

        return this.crudData ;
      }
    }
  }
</script>
