<template>
  <div class="auth-shared-links">

    <div v-if="action == 'login'">
      <a @click="action = 'forgot_pass'">
        Esqueceu a sua senha?
      </a>
    </div>
    <div v-if="(action == 'forgot_pass') || (action == 'change_pass')">
      <a @click="action = 'login'">
        Iniciar sessão (Login)
      </a>
    </div>

  </div>
</template>

<script>

export default {
    name: 'AuthSharedLinks',
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        what: 'Im AuthSharedLinks!',
        opts: this.options
      }
    },
    mounted: function () {
    },
    methods: {
    },
    computed: {
      action: {
        get: function() {
          return this.$parent.action ;
        },
        set: function (newValue) {
          this.$parent.setAction(newValue) ;
        }
      }
    }
  }
</script>
