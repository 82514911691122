var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ibox2",
    {
      staticClass: "float-e-margins ui-sortable col-md-6",
      class: { hidden: _vm.eventos.length < 1 }
    },
    [
      _c("div", { attrs: { slot: "ibox-title" }, slot: "ibox-title" }, [
        _c("h5", [_vm._v(_vm._s(_vm.titleBox()))])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ibox-content inspinia-timeline",
          attrs: { slot: "ibox-content" },
          slot: "ibox-content"
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _vm.eventos.length > 0
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("cr-data-table", {
                          attrs: { options: _vm.DtOptions() }
                        })
                      ],
                      1
                    )
                  ])
                : _c("div", [
                    _c("p"),
                    _c("h3", [_vm._v("Sem tarefas pendentes")]),
                    _vm._v(" "),
                    _c("p")
                  ])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }