const ProjetoStore = {
    namespaced: true,
    state: {
      projeto: {},
      projeto_users: []
    },
    mutations: {
      init_id(state, projeto_id) {
        state.projeto.id       = projeto_id;
        return state;
      },
      init(state, data) {
        state.projeto       = data.projeto;
        state.projeto_users = data.projeto_users;
        return state;
      }
    },
    getters: {
      projeto: state => {
        return state.projeto ;
      },

      users: state => {
        return state.projeto_users ;
      },

      users_ids: state => {
        return state.projeto_users.map((user) => {
          return user.id
        }) ;
      }

    },

    actions: {
      init(context, projet_id) {
        context.commit('init_id', projet_id);
        context.dispatch('load', context);
      },

      setFromCurrent(context, current) {
        context.commit('init', {
          projeto:       current.projeto,
          projeto_users: current.projeto_users
        })
      }
    }
  };

export default ProjetoStore ;
