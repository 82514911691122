var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.klass }, [
    _c(
      "table",
      {
        ref: "table_arquivos",
        staticClass: "table table-hover",
        attrs: { id: _vm.opts.id }
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.arquivos, function(arquivo, index) {
            return _c("tr", [
              _c("td", { domProps: { innerHTML: _vm._s(arquivo.title_link) } }),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(arquivo.responsavel))]),
              _vm._v(" "),
              _c("td", { domProps: { innerHTML: _vm._s(arquivo.user_link) } }),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(arquivo.data))]),
              _vm._v(" "),
              _c("td", [_vm._v(_vm._s(arquivo.validade))]),
              _vm._v(" "),
              _c("td", { domProps: { innerHTML: _vm._s(arquivo.acoes) } })
            ])
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Documento")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Responsavel")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Arquivado por")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Arquivado em")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Validade")]),
        _vm._v(" "),
        _c(
          "th",
          { staticClass: "project-actions", staticStyle: { width: "10%" } },
          [_vm._v("Ações")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }