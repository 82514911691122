var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row clearfix p-b-10" },
    [
      _c("ibox3", { attrs: { opened: false } }, [
        _c(
          "div",
          {
            staticStyle: { float: "left" },
            attrs: { slot: "ibox-title" },
            slot: "ibox-title"
          },
          [
            _c(
              "h5",
              {
                staticClass: "no-margins",
                staticStyle: { display: "block", float: "none" }
              },
              [_vm._v("\n        Condições para conclusão\n      ")]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "ibox-content inspinia-timeline",
            staticStyle: {
              padding: "10px",
              "max-height": "200px",
              "overflow-x": "auto"
            },
            style: "display: none;",
            attrs: { slot: "ibox-content" },
            slot: "ibox-content"
          },
          [
            _c(
              "ul",
              { staticClass: "todo-list small-list" },
              _vm._l(_vm.checks, function(check, index) {
                return _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loaded,
                        expression: "loaded"
                      }
                    ]
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "check-link",
                        on: {
                          click: function($event) {
                            return _vm.toggleCheck(check)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa",
                          class: check.used ? "fa-check-square" : "fa-square-o"
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "m-l-xs " }, [
                      _vm._v(_vm._s(check.title))
                    ]),
                    _vm._v(" "),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: check.used,
                          expression: "check.used"
                        }
                      ],
                      staticClass: "fa",
                      class: _vm.classCheckPassed(check),
                      staticStyle: {
                        float: "right",
                        "font-size": "x-large",
                        padding: "2px 5px"
                      },
                      style: _vm.styleCheckPassed(check)
                    })
                  ]
                )
              }),
              0
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }