var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "avatar col-sm-8" }, [
    _vm.action == "showing"
      ? _c("div", { staticClass: "photos" }, [
          _c("a", { attrs: { target: "_blank", href: _vm.avatar.img } }, [
            _c("img", {
              staticClass: "feed-photo",
              attrs: { alt: "image", src: _vm.avatar.img }
            })
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary btn-sm btn-block",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  _vm.action = "editing"
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-camera p-r-5" }),
              _vm._v("\n      Mudar Imagem\n    ")
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.action == "editing"
      ? _c(
          "div",
          [
            _c("croppa", {
              attrs: {
                "canvas-color": "default",
                placeholder: "Escolha uma imagem",
                "placeholder-font-size": 14
              },
              model: {
                value: _vm.img_croppada,
                callback: function($$v) {
                  _vm.img_croppada = $$v
                },
                expression: "img_croppada"
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-sm",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.saveImage()
                  }
                }
              },
              [
                _c("i", { staticClass: "fa fa-camera p-r-5" }),
                _vm._v("\n      Salvar Imagem\n    ")
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-default btn-sm",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    _vm.action = "showing"
                  }
                }
              },
              [_c("i", {}), _vm._v("\n      Cancelar\n    ")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }