var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel panel-default" }, [
    _c("div", { staticClass: "panel-heading" }, [
      _c("div", { staticClass: "title", staticStyle: { float: "left" } }, [
        _c(
          "h5",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.editing,
                expression: "! editing"
              }
            ]
          },
          [_vm._v(_vm._s(_vm.groupTitle()))]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.editing,
                expression: "editing"
              }
            ],
            staticClass: "edit-header-label"
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.group.caption,
                  expression: "group.caption"
                }
              ],
              attrs: { type: "text" },
              domProps: { value: _vm.group.caption },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.group, "caption", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-xs",
                on: {
                  click: function($event) {
                    _vm.editing = false
                  }
                }
              },
              [_vm._v("\n            Ok\n          ")]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ibox-tools col-sm-3 col-sm-offset-9" }, [
        _c(
          "a",
          {
            on: {
              click: function($event) {
                return _vm.togglePanel()
              }
            }
          },
          [
            _c("i", {
              staticClass: "fa",
              class: _vm.toggleExpanded ? "fa-chevron-up" : "fa-chevron-down",
              attrs: { "data-placement": "top", title: "Mostra/Oculta" }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "copy-link",
            on: {
              click: function($event) {
                return _vm.copy()
              }
            }
          },
          [
            _c("i", {
              staticClass: "fa fa-copy",
              attrs: { "data-placement": "top", title: "Copiar grupo" }
            })
          ]
        ),
        _vm._v(" "),
        _vm.index > 0
          ? _c(
              "a",
              {
                staticClass: "up-link",
                on: {
                  click: function($event) {
                    return _vm.up()
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "fa fa-arrow-up",
                  attrs: { "data-placement": "top", title: "Subir grupo" }
                })
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.index + 1 < _vm.groupsLength
          ? _c(
              "a",
              {
                staticClass: "down-link",
                on: {
                  click: function($event) {
                    return _vm.down()
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "fa fa-arrow-down",
                  attrs: { "data-placement": "top", title: "Descer grupo" }
                })
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "edit-link",
            on: {
              click: function($event) {
                _vm.editing = !_vm.editing
              }
            }
          },
          [
            _c("i", {
              staticClass: "fa fa-pencil",
              attrs: { "data-placement": "top", title: "Editar título" }
            })
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "close-linka",
            on: {
              click: function($event) {
                return _vm.remove()
              }
            }
          },
          [
            _c("i", {
              staticClass: "fa fa-times",
              attrs: { "data-placement": "top", title: "Excluir seção" }
            })
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "panel-body collapse",
        class: _vm.toggleExpanded ? "in" : "",
        attrs: { id: "grupo-body-" + _vm.index }
      },
      [
        _c("div", {
          ref: "editor_slot" + _vm.index,
          staticClass: "fields-group-slot",
          attrs: { id: "editor_slot" + _vm.index }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }