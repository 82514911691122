<template>
  <ul :class="klass"
       class="col-sm-12"
       :style="c_ul_style">

    <li v-for="btn in options.options"
         v-html="btn"
         :style="c_li_style">
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'CrModelBtns',
    props: ['options'],
    data: function () {
      return {
        klass: 'cr_model_btns'

      }
    },
    computed: {
      c_ul_style: function() {
        if (this.options.ul_style) {
          return this.options.ul_style
        } else {
          return '' ;
        }
      },
      c_li_style: function() {
        if (this.options.li_style) {
          return this.options.li_style
        } else {
          return 'float: left; list-style: none;' ;
        }
      }
    }
  };
</script>
