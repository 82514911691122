<template>
  <div class="row col-sm-12"
      :class="klass">
    <div class="col-sm-12 secoes-header">
      <h3>Seções</h3>
    </div>
    <div
       :key="refreshGroups"
       class="col-sm-12 accordion secoes-list">

      <group-editor v-for="(group, index) in groupsCollection"
                :index="index"
                :key="index"
                :groupsLength="groupsCollection.length"
                class="list-group">
      </group-editor>

    </div>

<!--     <div class="row col-sm-12 secoes-footer">
      <br/>

      <div class="col-sm-4">
        <button type="button" class="btn btn-w-m btn-primary"
                @click="addNewGroup()">
          Inserir nova Seção
        </button>
        <label>Posição</label>
        <input type="number" min="1"
               style="max-width: 50px;"
               v-model="newGroupPos"
               :max="groupsCollection.length + 1">
      </div>

      <div class="col-sm-5"></div>
      <div v-show="false && edited"
           class="col-sm-3">
        <button type="button" class="btn btn-w-m btn-primary"
                @click="saveFormSchema()">
          Salvar modelo de formulário
        </button>
      </div>
    </div>
 -->

  </div>
</template>

<script>
  import GroupEditor from "./group_editor.vue";

  export default {
    name: 'GroupsEditor',
    components: {
      'group-editor' :  GroupEditor
    },
    props: ['storename', 'model'],
    data: function () {
      return {
        klass: 'groups-editor',

        edited: false,

        blankGroup: {
          id: '',
          caption: 'Nova Seção ',
          fields: '[]'
        },
        newGroupPos: 0,
        refreshGroups: 10
      }
    },
    mounted: function() {
      this.initStore() ;
    },

    computed: {
      fullFormBase: function() {
        return this.$store.getters[this.storename + '/fullForm']
      },
      schema: function() {
        return (this.fullFormBase ? this.fullFormBase.formulario.corpo.schema : null);
      },

      groupsCollection() {
        return this.$store.getters['FormularioStore/groupsCollection']
      }
    },

    methods: {
      initStore: function() {
        this.$store.dispatch(
          'FormularioStore/init', {
            model:  this.model
          }
        )
        this.refreshNewGroupPos()
      },
      refreshNewGroupPos() {
        this.newGroupPos = this.groupsCollection.length + 1
      },
      saveGroupSchema: function(index, groupSchema) {
        this.$store.dispatch(
          'FormularioStore/saveGroupSchema', {
            index: index,
            groupSchema: groupSchema
          }
        )
        this.edited = true
      },
      removeGroup: function(index) {
        this.$store.dispatch(
          'FormularioStore/removeGroup', index
        )
        this.refreshNewGroupPos()
        this.refreshGroups ++;
        this.edited = true
      },
      up: function(index) {
        this.$store.dispatch(
          'FormularioStore/upGroup', index
        )
        this.$children[index].refreshGroupEditor()
        this.$children[index-1].refreshGroupEditor()
        this.edited = true
      },
      down: function(index) {
        this.$store.dispatch(
          'FormularioStore/downGroup', index
        )
        this.$children[index].refreshGroupEditor()
        this.$children[index+1].refreshGroupEditor()
        this.edited = true
      },
      copyGroup: function(index) {
        this.$store.dispatch(
          'FormularioStore/copyGroup', index
        )
        this.refreshNewGroupPos()
        this.edited = true
      },
      addNewGroup: function() {
        var newGroup = jQuery.extend(true, {}, this.blankGroup);
        newGroup.caption = 'Nova Seção ' + String(this.newGroupPos);
        newGroup.id = Math.trunc(Math.random() * 89999 + 10000)

        this.$store.dispatch(
          'FormularioStore/addNewGroup', {
            newGroup: newGroup,
            newPos:   this.newGroupPos
          }
        )
        this.refreshNewGroupPos()
      },
      saveFormSchema: function() {
        this.$store.dispatch('FormularioStore/saveFormSchema', {})
      }
    },
    watch: {
    }
  };
</script>

