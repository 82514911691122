// topicos/topico_editor.vue
<template>
  <div class="row col-sm-12">
    <div class="ibox col-sm-9">
      <div :class="options.class"
           :id="options.id">

        <div class="form-group string required titulo">
          <label for="titulo" class="control-label string required">
            <abbr title="obrigatorio">*</abbr>
            Título
          </label>
          <input type="text"
                 v-model="topico.title"
                 id="titulo"
                 class="form-control string required">
        </div>

        <div class="form-group string required resumo">
          <label for="resumo" class="control-label string required">
            <abbr title="obrigatorio">*</abbr>
            Resumo
          </label>
          <textarea type="text"
                 v-model="topico.resumo"
                 id="resumo"
                 class="form-control string required">
          </textarea>
        </div>

        <div class="form-group string required body">
          <label for="body" class="control-label string required">
            <abbr title="obrigatorio">*</abbr>
            Corpo do tópico
          </label>
          <wysiwyg v-model="topico.body" />
        </div>

      </div>
    </div>
    <div class="col-sm-3">
      <div class="modal-footer" style="border-top: none;">
        <button type="submit"
                class="btn btn-primary"
                @click="saveChanges()">
          Gravar Alterações
        </button>
        <a id="close_save_plus_btn"
           href="#"
           class="btn"
           @click="cancelChanges()">
          Cancelar
        </a>
      </div>
      <div class="ibox"
           style="border: none; margin-bottom: 0px;">
        <div class="ibox-title" style="border: none;">
          <h3>Grupos</h3>
        </div>
        <div class="ibox-content" style="border: none;">
          <cmp-v-select
            input_class="form-control select select2"
            :options="all_grupos"
            :required="false"
            :multiple="true"
            name="funcionario_id"
            v-model="topico.grupo_ids">
          </cmp-v-select>
        </div>
      </div>

      <div class="ibox"
           style="border: none; margin-bottom: 0px;">
        <div class="ibox-title" style="border: none;">
          <h3>Tags</h3>
        </div>
        <div class="ibox-content" style="border: none;">
          <cmp-v-select
            input_class="form-control select select2"
            :key="refreshIndex"
            :options="all_tags"
            :required="false"
            :multiple="true"
            name="funcionario_id"
            v-model="topico.tag_list">
          </cmp-v-select>
        </div>
      </div>

      <div class="ibox"
           v-if="topico.id"
           style="border: none; margin-bottom: 0px;">
        <div class="ibox-title" style="border: none;">
          <h3>Arquivos anexos</h3>
        </div>
        <div class="ibox-content" style="border: none;"
             v-if="topico.arquivos.length > 0">
          <div class="feed-activity-list">
            <div class="feed-element" style="display: grid;">
              <div v-for="(arq, indexArq) in topico.arquivos"
                   class="col-sm-12">
                <a class="col-sm-10"
                   :key="refreshIndex + indexArq"
                   :href="arq.url"
                  >
                    {{ arq.label }}
                </a>

                <a data-remote="true" data-toggle="tooltip" data-placement="top"
                   class="ibox-tools col-sm-2"
                   title="Excluir"
                   :href="arq.del_url">
                  <i class="fa fa-trash"></i>
                </a>

              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer" style="border-top: none;">
          <a type="submit"
             data-remote="true"
             class="btn btn-primary"
             :href="topico.arquivos_new_url">
            Novo arquivo anexo
          </a>
        </div>

      </div>

    </div>
  </div>
</template>

<!-- <style src="vue-wysiwyg/dist/vueWysiwyg.css">
</style>
 -->
<script>

  import CmpVSelect from 'packs/components/comp/cmp-v-select.vue'

  export default {
    name: 'TopicoEditor',
    components: {
      'cmp-v-select'           : CmpVSelect
    },
    props: ['options'],
    data: function () {
      return {
        refreshIndex: 0,
        domid: (this.options.domid || 'fakedomid'),
        opts: this.options,

        topico: this.options.topico,

        all_grupos: this.options.all_grupos,
        all_tags:   this.options.all_tags,

        klass: 'cr-raw-html',
        content: this.options.options
      }
    },
    mounted: function () {
      var that = this;
      this.$parent.$on('refresh_topico', function (refresh_url) {
        that.refresh(refresh_url)
      });
    },
    methods: {
      refresh: function (refresh_url) {
        var that = this;

        refresh_url = (refresh_url || that.topico.refresh_url)
        $.ajax({
          method: 'get',
          url: refresh_url + '.json',
          success: function(data) {
            that.topico = data ;
            document.getElementById('show_page_title').innerHTML = that.topico.title ;
            that.$set(this, 'refreshIndex', this.refreshIndex + 1)
          },
          error: function(res) {
          }
        });
      },
      cancelChanges: function() {
        console.log('Cancelando ...')
      },
      saveChanges: function() {
        console.log('Salvando ...')
        var that = this
        $.ajax({
          url: that.opts.post_url.url + '.json',
          type: that.opts.post_url.method,
          format: 'json',
          data: {
            topico: {
              title:     that.topico.title,
              resumo:    that.topico.resumo,
              body:      that.topico.body,
              grupo_ids: that.topico.grupo_ids,
              tag_list:   that.topico.tag_list
            }
          },
          success: function(data) {
            that.topico.refresh_url = data.record.refresh_url
            if (window.location.href.includes('topicos/new')) {
              window.location.href = that.topico.refresh_url
            } else {
              that.$parent.$emit('refresh_topico', data.record.refresh_url)
            }
          },
          error: function(error) {
            console.log('Erro ...')
          },
          complete: function(response) {
          }
        })

      }
    }
  }
</script>

