<template>
  <div class="auth-login">

    <h5>Grave uma nova senha</h5>
    <form class="m-t" role="form"
          method="post"
          :action="opts.change_pass_url">

      <input name="utf8" type="hidden" value="✓">
      <input type="hidden" name="_method" value="put">
      <input type="hidden" name="authenticity_token"
             :value="opts.form_token">
      <input type="hidden" name="user[reset_password_token]"
             :value="opts.reset_password_token">

      <div class="error text-danger"
           v-html="opts.html_errors"></div>

      <div class="form-group">
        <label class="password required" id="user_password" for="user_password"
               style="width: 100%;text-align: left !important;">Nova senha</label>
        <input type="password" class="form-control"
               placeholder="Senha" required=""
               name="user[password]">
      </div>

      <div class="form-group">
        <label class="password required" id="user_password" for="user_password"
               style="width: 100%;text-align: left !important;">Redigite a nova senha</label>
        <input type="password" class="form-control"
               placeholder="Senha" required=""
               name="user[password_confirmation]">
      </div>

      <button type="submit" class="btn btn-primary block full-width m-b">Alterar senha</button>

<!--
      <input type="submit"
             class="btn btn-primary block full-width m-b"
             value="Alterar senha">
 -->
    </form>

  </div>
</template>

<script>

export default {
    name: 'ChangePass',
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        what: 'Im ChangePass!',
        opts: this.options
      }
    },
    methods: {
    }
  }
</script>
