<template>
  <div v-if="model.opts" class="ibox">
    <cr-title
       v-if="(model.opts.title) && (model.opts.title != '') && (model.opts.title != 'no_title')"
       :title="model.opts.title"
       :actions="model.opts.actions">
    </cr-title>
    <div class="ibox-content">

      <div v-for="(row, index) in model.opts.body"
          :key="index"
           class="row">
        <cr-show-body-div v-for="(div, index2) in row"
           :options="div"
           :key="index"
           :class="div.class">


        </cr-show-body-div>
      </div>
    </div>

  </div>
</template>

<script>
  import inputUtils from 'packs/components/mixins/inputUtils.vue'
  import errorStr   from 'packs/components/mixins/errorStr.vue'

  import CrTitle        from './cr_title.vue'
  import CrShowBodyDiv  from './cr_show_body_div.vue'

  export default {
    name: 'CrShowMulti',
    components: {
      'cr-show-body-div'  : CrShowBodyDiv,
      'cr-title'          : CrTitle
    },
    props: ['opts'],
    data: function () {
      return {
        what: 'Im CrShow!',
        type: 'cr_show',
        domid: this.opts.domid,
        refreshId: 0,

        model : {
          opts: null
        }
      }
    },
    methods: {
      refresh: function () {
        var that = this;
        $.ajax({
          method: 'get',
          url: that.model.opts.model_url + '.json',
          data: { refresh: true },
          success: function(res) {
            that.$set(that.model, 'opts', res.opts);
            if (that.opts.force_refresh) {
              // Não usado normalmente pois as abas já são atualizadas pelo vm_main#refresh
              that.refreshId += 100
            }
          }
        });
      }
    },
    created: function () {
    },
    mounted: function () {
      this.model.opts = this.opts ;
    },
    computed: {
    }
  }
</script>
