// new_apontamento.vue
<template id="apo_rowt">
  <form id="apo-form-id"
        novalidate="novalidate"
        class="simple_form new_apontamento"
        :action="url"
        method="post"
        accept-charset="UTF-8">

    <input name="_method" type="hidden" :value="method" />
    <input name="utf8" type="hidden" value="✓">
    <input name="authenticity_token"
           type="hidden"
           id="authenticity_token"
           :value="authenticity_token">

  <div class="col-sm-12">
    <div class="card">
      <div class="card-body card-padding">
        <div class="row">

          <input name="apontamento[obra_id]" type="hidden"
                 :value="model.obra_id">
          <input name="apontamento[equipe_id]" type="hidden"
                 :value="equipe_id">
          <input name="apontamento[equipeoriginal_id]" type="hidden"
                 :value="equipeoriginal_id">
          <input name="apontamento[tarefa_id]" type="hidden"
                 :value="model.tarefa_id">
          <input name="apontamento[data]" type="hidden"
                 v-model="data">
          <input name="apontamento[apontador_id]" type="hidden"
                 :value="model.apontador_id">

          <div class="col-sm-1"></div>
          <div class="col-sm-2">
            <div class="form-group datepicker required"
                 v-bind:class="{ 'error': errorsStr(model_e, 'data') }">

              <label class="string required">
                <abbr title="obrigatorio">*</abbr>
                Data
              </label>

              <input v-if="model.servico_id != 0"
                     type="hidden"
                     :name="controlName('[servicos_attributes][][id]',0)"
                     :value="model.servico_id">

              <input type="hidden"
                     :name="controlName('[servicos_attributes][][local_id]',0)"
                     :value="model.local_id">
              <input type="hidden"
                     :name="controlName('[servicos_attributes][][tarefa_id]',0)"
                     :value="model.tarefa_id">

              <div class="row">
                <div class="col-sm-6">

                  <datepicker v-model="data_dt"
                              format='dd/MM/yyyy'
                              :value="data_dt"

                              :name="controlName('[servicos_attributes][][data]',0)"
                  ></datepicker>

                  <span>{{ errorsStr(model_e, 'data') }}</span>
                </div>
                <div class="col-sm-6">
                  <button type="submit"
                   class="btn btn-xs btn-primary waves-effect">Atualizar horas
                  </button>
                </div>
              </div>

            </div>
          </div>

          <div class="col-sm-4">
            <div id="desconsiderar_datas_btn"
                 v-html="desconsiderar_datas_btn">
            </div>
          </div>

          <div class="col-sm-2">
            <div class="form-group decimais optional"
                 v-bind:class="{ 'error': errorsStr(servico_e, 'percentual') }">
              <label class="string required">
                <abbr title="obrigatorio">*</abbr>
                Percentual já executado
              </label>
              <input class="decimal form-control"
                     type="text"
                     data-v-min="0.00" data-v-max="100.00"
                     tipo-input="decimais"
                     :value="model.percentual"
                     :name="controlName('[servicos_attributes][][percentual]',0)"
                     id="apontamento_percentual">
              <span>{{ errorsStr(servico_e, 'percentual') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header ch-alt">
        <div class="media-body m-t-5">
          <div class="col-sm-8">
            <h2>Funcionários alocados ao serviço</h2>
          </div>

          <div class="col-sm-2">
            <btn-novo-funcionario
              :funcoes="funcoesPraNovo()"
              :funcionarios="funcionariosPraNovo()"
            >
            </btn-novo-funcionario>
          </div>

        </div>
      </div>
      <div class="card-body card-padding">
        <div class="row">

          <div class="col-sm-10">
            <div class="form-group required">
              <label class="col-sm-2 string required text-right">
                <abbr title="obrigatorio">*</abbr>
                Equipe
              </label>
              <div class="col-sm-10">
                <select class="select select2 input-control"
                        v-model="equipe_id"
                        style="width: inherit;"
                        name="apontamento[equipe_id]"
                        id="aponta-equipe-select"
                        data-placeholder="Escolha uma equipe ...">

                  <option v-for="eq in equipes"
                          v-bind:value="eq.value">
                    {{ eq.text }}
                  </option>

                </select>
              </div>
            </div>
          </div>
          <div class="col-sm-2">
<!--             <a class="btn btn-info waves-effect"
               data-remote="true"
               v-bind:href="link_novo_membro">
               Incluir funcionário
            </a>
 -->
<!--            <a class="btn btn-info waves-effect"
               @click="addNovoMembro2Apo">
               Incluir funcionário
            </a>
 -->
<!--
            <div id="btn_novo_membro"
                 v-html="btn_novo_membro">
            </div>
 -->

          <div v-if="equipe.da_casa"
               class="col-sm-2">
            <btn-novo-funcionario
              :funcoes="funcoesPraNovo()"
              :funcionarios="funcionariosPraNovo()"
            >
            </btn-novo-funcionario>
           </div>


          </div>
        </div>
        <div class="row">
          <table  v-if="equipe.membros.length > 0"
                  class="table table-inner table-vmiddle"
                  id="table_membros">
            <thead>
              <tr>
                <th class="text-left" style="width: 10%">Ação</th>
                <th class="text-left" style="width: 50%">Funcionario</th>
                <th class="text-left" style="width: 20%">Funcão</th>
                <th class="text-left" style="width: 10%">Horas</th>
                <th class="text-left" style="width: 10%">Extras</th>
              </tr>
            </thead>
            <tbody id="membros">
              <tr v-for="(membro, index) in equipe.membros"
                  :id="'row_membro-membro_id' + membro.id + membro.calculo_equipe_id + membro.funcionario_id">
                <td>
                  <button class="btn-icon-text waves-effect btn-danger kd_botao_remover btn btn-xs waves-effect"
                          v-on:click="removeMembro(membro.id, membro.calculo_equipe_id, membro.funcionario_id, this)"
                          type="button">Remover</button>

                  <input v-if="(model.id != null) && (membro.id != 0)"
                         type="hidden"
                         :name="controlName('[membros_attributes][][id]',index)"
                         :value="membro.id">
                  <input type="hidden"
                         :name="controlName('[membros_attributes][][funcionario_id]',index)"
                         :value="membro.funcionario_id">
                  <input type="hidden"
                         :name="controlName('[membros_attributes][][_destroy]',index)"
                         value="0"
                         :id="'to_destroy-membro-membro_id' + membro.id + membro.calculo_equipe_id + membro.funcionario_id">
                  <input type="hidden"
                         :name="controlName('[membros_attributes][][calculo_equipe_id]',index)"
                         :value="membro.calculo_equipe_id">
                </td>

                <td class="text-left">{{ membro.nome }}</td>
                <td class="text-left">{{ membro.funcao }}</td>
                <td>
                  <div class="form-group decimais optional apontamento_membros_horas m-b-0"
                       v-bind:class="{ 'error': errorsStr(membros_e[index], 'horas') }">
                    <span>
                      {{ ja_alocadas('horas', membro) }}
                    </span>

                    <vue-numeric
                      class="form-control horas"
                      separator="." :precision="2"
                      v-model="membro.horas"
                      :name="controlName('[membros_attributes][][horas]',index)"
                      :id="'horas' + membro.funcionario_id"
                    ></vue-numeric>
                    <input type="hidden"
                           :name="controlName('[membros_attributes][][horas]',index)"
                           :value="membro.horas">

                    <span class="c-red">{{ errorsStr(membros_e[index], 'horas') }}</span>
                  </div>
                </td>
                <td>
                  <div class="form-group decimais optional apontamento_membros_extras m-b-0"
                       v-bind:class="{ 'error': errorsStr(membros_e[index], 'extras') }">
                    <span>
                      {{ ja_alocadas('extras', membro) }}
                    </span>

<!--                     <input class="form-control"
                       data-v-min="0.00" data-v-max="10.00"

                      :value="membro.extras"

                       v-on:keypress="atuaHoras(membro.funcionario_id, 'extras', event)"
                       type="text" tipo-input="decimais"
                       :name="controlName('[membros_attributes][][extras]',index)"
                       :id="'extras' + membro.funcionario_id">
 -->
                    <vue-numeric
                      class="form-control extras"
                      separator="." :precision="2"
                      v-model="membro.extras"
                      :name="controlName('[membros_attributes][][extras]',index)"
                      :id="'extras' + membro.funcionario_id"
                    ></vue-numeric>
                    <input type="hidden"
                           :name="controlName('[membros_attributes][][extras]',index)"
                           :value="membro.extras">




                    <span>{{ errorsStr(membros_e[index], 'extras') }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <a class="btn btn-danger waves-effect"
               v-bind:href="link_volta_canteiro">

              Desistir, voltar pro canteiro</a>
          </div>
          <div class="col-sm-4"></div>
          <div class="col-sm-4">
            <button class="btn btn-primary"
                    name="doit"
                    type="submit">Confirmar e voltar pro canteiro
            </button>
          </div>
        </div>

      </div>
    </div>
  </div>
  </form>
</template>

<script>
  import VueNumeric from 'vue-numeric';
  import Datepicker from 'vuejs-datepicker';

  import BtnNovoFuncionario from 'packs/components/painel_apo/btn_novo_funcionario.vue'


  export default {
    name: 'new_apontamento',
    props: ['opts'],
    components: {
      'vue-numeric'          : VueNumeric,
      'btn-novo-funcionario' : BtnNovoFuncionario,
      Datepicker
    },
    data: function () {
      return {
        loaded: false,
        model_name: 'apontamento',
        model: {},
        equipeoriginal_id: 0,
        equipe_id: 0,
        equipe: {
          membros: [],
          href_equipe: null
        },
        data: null,
        original_data: null,
        url: '',
        method: '',
        a_equipes: this.opts.equipes,
        equipes: [],
        equipes_membros: [],
        outros_funcionarios: [],

        authenticity_token : this.opts.form_token,

        funcoes: [],
        funcionarios: [],

        model_e   : {},
        servico_e : {},
        membros_e : {}
      }
    },
    mounted: function () {
      moment.locale('pt-br');

      var opts_o   = this.opts ;
      this.model   = opts_o.model ;

      this.model.local_id   = opts_o.model_servicos[0].local_id ;
      this.model.percentual = opts_o.model_servicos[0].percentual ;
      this.model.servico_id = opts_o.model_servicos[0].id ;

      this.data = opts_o.data ;
      this.original_data = opts_o.data ;

      this.equipeoriginal_id = this.model.equipe_id
      this.equipe_id = this.model.equipe_id
      this.equipe.membros     = opts_o.model_membros ;
debugger
      this.equipe.href_equipe = opts_o.equipe_href ;

      this.url        = opts_o.url ;
      this.method     = opts_o.method ;
      this.equipes    = opts_o.equipes ;
      this.equipes_membros = opts_o.equipes_membros ;

      this.outros_funcionarios = opts_o.outros_funcionarios ;

      this.model_e   = opts_o.model_e ;
      this.servico_e = opts_o.servico_e ;
      this.membros_e = opts_o.membros_e ;

      this.carregaFuncFuncionarios();

      this.delayInitCompos ;
      this.loaded = true;
    },
    watch: {
      equipe_id: function(new_equipe_id) {
        if (this.loaded) {
          this.atuaEquipe(new_equipe_id) ;
        }
      }
      // data_dt: function(newData) {
      //   if ((newData != this.original_data) &&
      //      this.isValidDate(newData)) {
      //     // this.submitForm() ;
      //   console.log('Data ok ...' + newData);
      //   }
      // }
    },
    computed: {
      data_dt: {
        get: function() {
          return moment(this.data, 'DD/MM/YYYY').toDate()
        },
        set: function(newDate) {
          this.data = moment(newDate).format('DD/MM/YYYY');
        }
      },
      link_volta_canteiro: function() {
        return this.opts.link_volta_canteiro
      },
      link_novo_membro: function() {
        return this.opts.link_novo_membro
      },
      btn_novo_membro: function() {
        return this.opts.btn_novo_membro
      },
      desconsiderar_datas_btn: function() {
        return this.opts.desconsiderar_datas_btn
      },
      lFuncoes2Sel: function () {
        return this.funcoes.map(function(item){
          return {
            value: item.id,
            label: item.nome
          }
        });
      },
      lFuncionarios2Sel: function () {
        var funcao_id = this.funcao_id
        if (funcao_id == -1) {return [];}
        return this.funcionarios[funcao_id].map(function(item){
          return {
            value: item.id,
            label: item.text
          }
        });
      }
    },
    methods: {
      carregaFuncFuncionarios: function () {
        var that = this;
        var obra_id = this.model.obra_id;
        $.ajax({
          method: 'get',
          data: {
            obra_id: obra_id
          },
          url: '/obras/' + obra_id + '/func_funcionarios.json',
          success: function(res) {
            that.funcoes = res.funcoes ;
            that.funcionarios = res.funcionarios ;
          }
        });
      },
      funcoesPraNovo: function () {
        return this.funcoes ;
      },
      funcionariosPraNovo: function () {
        return this.funcionarios ;
      },
      atuaEquipe(new_equipe_id) {
        var equi_ind = -1 ;
        var equipe_source = [];
        console.log('Atualizando equipe ...');
        if (!new_equipe_id) {return}
        if (new_equipe_id == this.equipeoriginal_id) {
          equipe_source = this.equipe.membros ;
          equi_ind = 10 ;
        } else {
          this.js_atualiza_equipe(new_equipe_id);
        }
      },
      js_atualiza_equipe(novaEquipeId) {
        var that = this;
        $.ajax({
          method: 'GET',
          data: {
            data: this.data,
          },
          url: '/equipes/' +  novaEquipeId.toString() + '/to_apontamento.json',
          success: function(res) {
            that.errors = {};
            console.log('Chegou ...');
            that.equipe.membros = res.membros ;
            that.equipeoriginal_id = res.equipe_id ;
          },
          error: function(res) {
            vm.alert('warning','Erro no processamento da solicitação') ;
          }
       });
      },



      addNovoFuncionario(novo_func) {
        var funci_id = novo_func.funci_id ;
        var permanente = novo_func.permanente ;
        // if (permanente == 1) {
        //   this.gravaNovoMembroEquipe(funci_id, this.apontamento.data) ;
        // }
        var that = this;
        $.ajax({
          method: 'get',
          data: {
            data: that.data,
            novo_funcionario_id: funci_id,
          },
          url: '/equi/equipes/' + that.equipe_id + '.json',
          success: function(res) {
            that.equipe.membros.push( res.novo_funcionario[0] );
          }
        });
      },


      addNovoMembro: function(newfuncionario) {
        this.equipe.membros.push(JSON.parse(newfuncionario));
        this.delayInitCompos ;
      },
      addNovoMembro2Apo: function() {
        var that = this;
        $.ajax({
          //method: 'GET',
          url: that.url + '/0/novo_membro' + '.js',
          success: function(res) {
            that.errors = {};

            that.addNovoMembro(res.novo_membro);
          }
        })
      },

      removeMembro(membro_id, calculo_id, funcionario_id, elem) {
        var funci_ind = -1 ;
        this.equipe.membros.forEach((membro, index) => {
          if ( (membro.id == membro_id) &&
               (membro.calculo_equipe_id == calculo_id) &&
               (membro.funcionario_id == funcionario_id) ) {
            funci_ind = index
            var mem_str = (membro_id ? membro_id.toString() :
                              ((membro_id == 0) ? '0' : 'null'))
            var cal_str = (calculo_id ? calculo_id.toString() :
                              ((calculo_id == 0) ? '0' : 'null'))
            var fun_str = (funcionario_id ? funcionario_id.toString() : 'null')

            var row_id        = "#row_membro-membro_id" + mem_str + cal_str + fun_str ;
            var to_destroy_id = "#to_destroy-membro-membro_id" +  mem_str + cal_str + fun_str ;
            $(row_id).hide();
            $(to_destroy_id).val("1");
          }
        });
      },
      atuaHoras(funcionario_id, type, evt) {
        evt = (evt) ? evt : window.event;
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      },
      ja_alocadas: function(type, membro) {
        var res = 'Já alocadas ';
        res += membro.realizadas[type].ja.toString() ;
        res += '/'
        res += membro.realizadas[type].ponto.toString() ;
        return res ;
      },
      controlName: function(att_name, index, obje) {
        var strAttrib = att_name;
        if (index != 'undefined') {
          strAttrib = strAttrib.replace('[]', '[' + index.toString() + ']');
        }
        return this.model_name + strAttrib ;
      },
      inputVal: function(type, func_id) {
        return $('#' + type + func_id).val() ;
      },
      errorsStr: function(err_obj, fields) {
        var afields = (! $.isArray(fields)) ? [fields] : fields ;
        var ret = [] ;
        if (err_obj == undefined) { return null ;}
        afields.forEach((field, index) => {
          if (err_obj[field] && (err_obj[field].length > 0)) {
            ret = ret.concat(err_obj[field]);
          }
        })
        return ret.join(',') ;
      },
      submitForm() {
        $("#apo-form-id").submit();
      },
      // Expect input as d/m/y
      isValidDate: function(s) {
        var bits = s.split('/');
        var d = new Date(bits[2], bits[1] - 1, bits[0]);

        return d &&
               (bits[0].length == 2) &&
               (bits[1].length == 2) &&
               (bits[2].length == 4) &&
               ((d.getMonth() + 1) == Number(bits[1]));
      },
      delayInitCompos() {
        this.$nextTick(function () {
          ativaJSInputs()
          this.initCompos ;
        });
      },
      initCompos() {
        $('input.form-control[tipo-input=decimais]').autoNumeric("init",{
          aSep: '.',
          aDec: ','
        });
        $('input.horas').on('change', function() {
          this.atuaHoras(15)
        });
        $("input[type=text].datepicker").datepicker({
          language: "pt-BR",
          format: "dd/mm/yyyy",
          useCurrent: true,
          autoclose: true,
          beforeShow: function(i) { if ($(i).attr('readonly')) { return false; } },
          open: function (e) { e.preventDefault(); }
        });
        $("select.select2").select2({
          allowClear: true,
          language: "pt-BR",
          width: "100%"
        });
      },
      as_array: function (span) {
        return (! $.isArray(span)) ? [span] : span ;
      }
    }
  }

  function ativaJSInputs() {
    // $("#apontamento_data").delay(1500).datepicker({
    //   language: "pt-BR",
    //   format: "dd/mm/yyyy",
    //   useCurrent: true,
    //   autoclose: true,
    //   beforeShow: function(i) { if ($(i).attr('readonly')) { return false; } },
    //   open: function (e) { e.preventDefault(); }
    // }).on(
    //   'changeDate', function (ev) {
    //
    //     this.data = $('#apontamento_data').val() ;
    //     console.log('Atualizando data');
    // });

    console.log('Ativando inputs');

    $('input.form-control[tipo-input=decimais]').delay(1500).autoNumeric("init",{
      aSep: '.',
      aDec: ','
    });

    $('form.simple_form').delay(1500).submit(function(){
      var form = $(this);
      $('input.form-control[tipo-input=reais], '+
        'input.form-control[tipo-input=decimais]').each(function(i){
        var self = $(this);
        try{
            var v = self.autoNumeric('get');
            self.autoNumeric('destroy');
            self.val(v);
        }catch(err){
            console.log("Not an autonumeric field: " + self.attr("name"));
        }
      });

      return true;
    });
  }

  ativaJSInputs();

</script>
