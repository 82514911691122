// fb_default_options.vue
<script>
  var opcoesLargura = {
    label: 'Largura',
    options: {
      '12':  '100%',
      '8' :  '3/4',
      '8' :  '2/3',
      '6' :  '1/2',
      '4' :  '1/3',
      '3' :  '1/4'
    },
    value: '12'
  } ;
  var opcoesDecimais = {
    label: 'Decimais',
    options: {
      '0 ':  'Sem decimais',
      '1' :  '1 decimal',
      '2' :  '2 decimais',
      '3' :  '3 decimais',
      '4' :  '4 decimais'
    },
    value: '2'
  } ;

  export default {
    data: function() {
      return {
        default_options: {
          i18n: {
            locale: 'pt-BR'
          },
          controlOrder: [
            'header',
            'text',
            'date',
            'textarea',
            'select',
            'checkbox-group',
            'radio-group',
            'number',
            'file'
          ],
          disabledActionButtons: [
            'clear',
            'data',
            'save'
          ],
          disableFields: [
            'button',
            'hidden',
            'autocomplete'
          ],
          disabledAttrs: [
            'access',
            'className',
            // 'description',
            'inline',
            // 'label',
            // 'max',
            // 'maxlength',
            // 'min',
            // 'multiple',
            // 'name',
            // 'options',
            // 'other',
            // 'placeholder',
            // 'required',
            // 'rows',
            // 'step',
            // 'style',
            // 'subtype',
            // 'toggle',
            'value'
          ],
          typeUserAttrs: {
            'header': {
              widthClass: opcoesLargura
            },
            'text': {
              widthClass: opcoesLargura
            },
            'date': {
              widthClass: opcoesLargura,
              dateType : {
                label: 'Data / Data e hora',
                options: {
                  'date':     'Data',
                  'datetime': 'Data e hora'
                },
                value: 'date'
              }
            },
            'textarea': {
              widthClass: opcoesLargura,
            },
            'select': {
              widthClass: opcoesLargura,
              specialSource: {
                label: 'fonte de dados',
                maxlength: '20',
                description: 'Dados'
              }
            },
            'checkbox-group': {
              widthClass: opcoesLargura
            },
            'radio-group': {
              widthClass: opcoesLargura
            },
            'file': {
              widthClass: opcoesLargura
            },
            'number': {
              widthClass: opcoesLargura,
              decimal_places : opcoesDecimais
            }
          }

        }
      }
    }
  }
</script>
