<template>
  <div v-if="model.opts" class="ibox">
    <cr-title
       v-if="(model.opts.title) && (model.opts.title != '') && (model.opts.title != 'no_title')"
       :title="model.opts.title"
       :subtitle="model.opts.subtitle"
       :buttons="model.opts.title_buttons"
       :actions="model.opts.actions">
    </cr-title>
    <div class="ibox-content">

      <div v-for="(row, index) in model.opts.body"
           class="row">
        <div v-for="div in row"

           :class="div.class">
          <component v-for="(compo, indexComp) in div.components"
               :class="compo.class"
               :is="bodyCommponent(compo.ctype)"
               :id="compo.id"
               :key="(refreshId + indexComp)"
               :ref="compo.ref"
               :options="compo">

          </component>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import CrTitle             from 'packs/components/crud/cr_title.vue'
  import CrDetails           from 'packs/components/crud/cr_details.vue'
  import CrProgressBar       from 'packs/components/crud/cr_progress_bar.vue'

  import CrEndereco          from 'packs/components/crud/cr_endereco.vue'
  import CrCustomProgressBar from 'packs/components/crud/cr_custom_progress_bar.vue'
  import CrModelBtns         from 'packs/components/crud/cr_model_btns.vue'
  import ObservadoresPanel   from 'packs/components/crud/observadores_panel.vue'
  import CrAbas              from 'packs/components/crud/cr_abas.vue'
  import CrDataTable         from 'packs/components/crud/cr_data_table.vue'
  import CrRawHtml           from 'packs/components/crud/cr_raw_html.vue'
  import TopicoShow          from 'packs/components/topicos/topico_show.vue'
  import CrImgGallery        from 'packs/components/crud/cr_img_gallery.vue'
  import inputUtils          from 'packs/components/mixins/inputUtils.vue'
  import errorStr            from 'packs/components/mixins/errorStr.vue'
  import PainelEnvio         from 'packs/components/arquivo/painel_envio.vue'
  import UserAvatar          from 'packs/components/user/user_avatar.vue'
  import CheckPanel          from 'packs/components/model_check/check_panel.vue'

  // import VcBarChart          from 'packs/components/charts/vc-bar-chart.vue'
  // import VcLineChart         from 'packs/components/charts/vc-line-chart.vue'

  import CrDocEmpVencer      from 'packs/components/relatorios_gn/form_doc_vencer_empresas.vue'

  export default {
    name: 'CrShow',
    components: {
      'cr-title'          : CrTitle,
      'cr-details'        : CrDetails,
      'cr-endereco'       : CrEndereco,
      'cr-progress-bar'   : CrProgressBar,
      'cr-custom-progress-bar': CrCustomProgressBar,
      'cr-model-btns'     : CrModelBtns,
      'observadores-panel': ObservadoresPanel,
      'painel-envio':       PainelEnvio,
      'user-avatar'       : UserAvatar,
      'check-panel'       : CheckPanel,

      // 'vc-bar-chart'   :    VcBarChart,
      // 'vc-line-chart'  :    VcLineChart,

      'cr-data-table'     : CrDataTable,
      'cr-raw-html'       : CrRawHtml,
      'topico-show'       : TopicoShow,
      'cr-img-gallery'    : CrImgGallery,
      'cr-abas'           : CrAbas,
      'rl-doc-emp-vencer' : CrDocEmpVencer
    },
    props: ['opts'],
    data: function () {
      return {
        what: 'Im CrShow!',
        type: 'cr_show',
        domid: this.opts.domid,
        refreshId: 0,

        model : {
          opts: null
        }
      }
    },
    methods: {
      refresh: function () {
        var that = this;
        $.ajax({
          method: 'get',
          url: that.model.opts.model_url + '.json',
          data: { refresh: true },
          success: function(res) {
            that.$set(that.model, 'opts', res.opts);
            if (that.opts.force_refresh) {
              // Não usado normalmente pois as abas já são atualizadas pelo vm_main#refresh
              that.refreshId += 100
            }
          }
        });
      },
      refresh_json_url: function (url) {
        var that = this;
        $.ajax({
          method: 'get',
          url: url + '.json',
          success: function(res) {
            that.$set(that.model, 'opts', res.opts);
            if (that.opts.force_refresh) {
              // Não usado normalmente pois as abas já são atualizadas pelo vm_main#refresh
              that.refreshId += 100
            }
          }
        });
      },
      bodyCommponent: function(compo_type) {
        var comp_name = ''
        switch(String(compo_type)) {
          case 'details_list'     : comp_name = 'cr-details'            ; break;
          case 'endereco'         : comp_name = 'cr-endereco'           ; break;
          case 'progress'         : comp_name = 'cr-progress-bar'       ; break;
          case 'custom_progress'  : comp_name = 'cr-custom-progress-bar'; break;
          case 'show_buttons'     : comp_name = 'cr-model-btns'         ; break;
          case 'observadores'     : comp_name = 'observadores-panel'    ; break;
          case 'painel_envio'     : comp_name = 'painel-envio'          ; break;
          case 'user_avatar'      : comp_name = 'user-avatar'           ; break;
          case 'checks'           : comp_name = 'check-panel'           ; break;
          case 'bar_chart'        : comp_name = 'vc-bar-chart'          ; break;
          case 'line_chart'        : comp_name = 'vc-line-chart'        ; break;
          case 'abas'             : comp_name = 'cr-abas'               ; break;
          case 'data_table'       : comp_name = 'cr-data-table'         ; break;
          case 'raw_html'         : comp_name = 'cr-raw-html'           ; break;
          case 'topico_show'      : comp_name = 'topico-show'           ; break;
          case 'img_gallery'      : comp_name = 'cr-img-gallery'        ; break;
          case 'rl_doc_emp_vencer' :comp_name = 'rl-doc-emp-vencer'     ; break;

          case '...': comp_name = '...'; break;
        }
        return comp_name;
      }
    },
    created: function () {
    },
    mounted: function () {
      this.model.opts = this.opts ;
    },
    computed: {
    }
  }
</script>
