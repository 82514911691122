var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-lg-9 animated fadeInRight p-l-5 p-r-5" },
    [
      _c("div", { staticClass: "mail-box-header" }, [
        _c(
          "form",
          {
            staticClass: "pull-right mail-search",
            attrs: { method: "get", action: "index.html" }
          },
          [
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.filtroItens,
                    expression: "filtroItens"
                  }
                ],
                staticClass: "form-control input-sm",
                attrs: {
                  type: "text",
                  name: "search",
                  placeholder: "Filtrar assunto"
                },
                domProps: { value: _vm.filtroItens },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.filtroItens = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _vm._m(0)
            ])
          ]
        ),
        _vm._v(" "),
        _c("h2", [_vm._v(_vm._s(_vm.inboxCaption))]),
        _vm._v(" "),
        _c("div", { staticClass: "mail-tools tooltip-demo m-t-md" }, [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-white btn-sm",
              attrs: {
                "data-toggle": "tooltip",
                "data-placement": "left",
                title: "Atualizar " + _vm.inboxCaption
              },
              on: {
                click: function($event) {
                  return _vm.refreshBox()
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-refresh" }),
              _vm._v("\n        Atualizar\n      ")
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.CanLoadNextPage(),
                  expression: "CanLoadNextPage()"
                }
              ],
              staticClass: "btn btn-white btn-sm",
              attrs: {
                "data-toggle": "tooltip",
                "data-placement": "left",
                title: "Carregar próxima página"
              },
              on: {
                click: function($event) {
                  return _vm.LoadNextPage()
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-refresh" }),
              _vm._v(
                "\n        " + _vm._s(_vm.LoadNextPageCaption()) + "\n      "
              )
            ]
          )
        ]),
        _vm._v(" "),
        false
          ? _c("div", [
              _vm._m(2),
              _vm._v(" "),
              _vm._m(3),
              _vm._v(" "),
              _vm._m(4)
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mail-box" }, [
        _c("table", { staticClass: "table table-hover table-mail" }, [
          true
            ? _c("thead", [
                _c("th", { staticClass: "text-center" }, [_vm._v("Nova?")]),
                _vm._v(" "),
                _c("th", [_vm._v("Remetente")]),
                _vm._v(" "),
                _c("th", { staticClass: "text-center" }, [_vm._v("Enviado")]),
                _vm._v(" "),
                _c("th", [_vm._v("Assunto")]),
                _vm._v(" "),
                _c("th", { staticClass: "text-center" }, [_vm._v("Ação")])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.itemsToShow, function(item, index) {
              return _c("tr", { class: item.un_read ? "unread" : "read" }, [
                _c(
                  "td",
                  {
                    staticClass: "check-mail",
                    staticStyle: { "font-size": "large" }
                  },
                  [
                    item.un_read
                      ? _c(
                          "a",
                          {
                            attrs: {
                              "data-toggle": "tooltip",
                              "data-placement": "top",
                              title: "Nova"
                            },
                            on: {
                              click: function($event) {
                                return _vm.setReaded(item)
                              }
                            }
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "label label-warning pull-right" },
                              [_vm._v("Nova")]
                            )
                          ]
                        )
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c("td", { staticClass: "mail-contact" }, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.readMessage(item)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "pull-left text-center" }, [
                        _c("img", {
                          staticClass: "img-circle message-avatar",
                          attrs: { alt: "image", src: item.sender.img }
                        })
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.readMessage(item)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.sender.name) +
                          "\n            "
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "text-right mail-date" }, [
                  _vm._v(_vm._s(item.time_ago))
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "mail-subject" }, [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.readMessage(item)
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.subject) +
                          "\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  item.link_obj && item.link_obj != ""
                    ? _c(
                        "a",
                        {
                          attrs: { href: item.link_obj.href, target: "_blank" }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.link_obj.cap) +
                              "\n              "
                          ),
                          _c("i", { staticClass: "fa fa-external-link" })
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticStyle: { "font-size": "x-large", color: "gray" } },
                  [
                    _c(
                      "a",
                      {
                        attrs: {
                          "data-toggle": "tooltip",
                          "data-placement": "top",
                          title: "Responder"
                        },
                        on: {
                          click: function($event) {
                            return _vm.replyMsg(item)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-mail-reply-all" })]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: {
                          "data-toggle": "tooltip",
                          "data-placement": "top",
                          title: "Excluir"
                        },
                        on: {
                          click: function($event) {
                            return _vm.deleteConv(item)
                          }
                        }
                      },
                      [_c("i", { staticClass: "fa fa-trash" })]
                    )
                  ]
                )
              ])
            }),
            0
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-btn" }, [
      _c(
        "button",
        { staticClass: "btn btn-sm btn-primary", attrs: { type: "submit" } },
        [_vm._v("\n            Filtrar\n          ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "btn-group pull-right" }, [
      _c("button", { staticClass: "btn btn-white btn-sm" }, [
        _c("i", { staticClass: "fa fa-arrow-left" })
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "btn btn-white btn-sm" }, [
        _c("i", { staticClass: "fa fa-arrow-right" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-white btn-sm",
        attrs: {
          "data-toggle": "tooltip",
          "data-placement": "top",
          title: "Mark as read"
        }
      },
      [_c("i", { staticClass: "fa fa-eye" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-white btn-sm",
        attrs: {
          "data-toggle": "tooltip",
          "data-placement": "top",
          title: "Mark as important"
        }
      },
      [_c("i", { staticClass: "fa fa-exclamation" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-white btn-sm",
        attrs: {
          "data-toggle": "tooltip",
          "data-placement": "top",
          title: "Move to trash"
        }
      },
      [_c("i", { staticClass: "fa fa-trash-o" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }