<template>
  <div class="auth-login">

    <h5>Para continuar, favor se identificar</h5>
    <form class="m-t" role="form"
          method="post"
          @submit="put_dominio"
          :action="opts.login_url">

      <input name="utf8" type="hidden" value="✓">
      <input type="hidden" name="authenticity_token"
             :value="opts.form_token">

      <div class="form-group">
        <label class="string required" id="user_email" for="user_email"
               style="width: 100%;text-align: left !important;">Email</label>
        <input type="text" class="form-control"
               ref="email_input"
               placeholder="Email"
               name="user[email]">
      </div>

      <div class="form-group">
        <label class="password required" id="user_password" for="user_password"
               style="width: 100%;text-align: left !important;">Senha</label>
        <input type="password" class="form-control"
               placeholder="Senha" required=""
               name="user[password]">
      </div>

      <button type="submit" class="btn btn-primary block full-width m-b">Login</button>

    </form>

  </div>
</template>

<script>

export default {
    name: 'AuthLogin',
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        what: 'Im AuthLogin!',
        opts: this.options
      }
    },
    mounted: function () {
    },
    methods: {
      put_dominio: function() {
        var ele = this.$refs.email_input
        var email = ele.value;
        var dominio = "@graficoltda.com.br"
        var posa = email.indexOf("@")
        if (posa == -1){
          ele.value = (email + dominio);
        }
        return true;
      }
    }
  }
</script>
