<script>
  import {Bar} from 'vue-chartjs'

  export default {
    name: 'VcBarCharttt',
    extends: Bar,
    props: ['options'],
    mounted: function() {
      if (this.options) {
        this.drawChart()
      }
    },
    methods: {
      drawChart: function() {
        var that = this
        this.$nextTick(function () {
          that.renderChart(that.options.data, that.options.options)
        })
      }
    }
  }
</script>
