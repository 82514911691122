var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-sm-12" }, [
    _c(
      "div",
      { staticClass: "btn-group", class: _vm.klass },
      _vm._l(_vm.options.options.steps, function(step) {
        return _c(
          "button",
          {
            staticClass: "btn btn-xs",
            class: step.klass,
            style: _vm.styleStep(step),
            attrs: { type: "button", disabled: _vm.disabledStep(step) },
            on: {
              click: function($event) {
                return _vm.setAction(step)
              }
            }
          },
          [_vm._v("\n      " + _vm._s(step.caption) + "\n    ")]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }