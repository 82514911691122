<template>
  <fb-full-editor :model="model">
  </fb-full-editor>
</template>


<script>
  import FbFullEditor       from 'packs/components/form/fb_full_editor.vue'

  export default {
    name: 'CrAbaFormulariosEditor',
    components: {
      'fb-full-editor': FbFullEditor
    },
    props: ['options'],
    data: function () {
      return {
        model: this.options.model
      }
    }
  };
</script>
