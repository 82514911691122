// ibox3.vue
<template>
  <div class="ibox float-e-margins"
       :class="opts.class"
       style="border-width: 1px; border-color: lightgray; border-style: solid; display: table"
  >
    <div class="ibox-title small-margin"
         style="border-top-width: 1px;">
      <slot name="ibox-title"></slot>
      <div class="ibox-tools">
        <a class="collapse-link"
           @click="toggleOpen()">
          <i class="fa"
             :class="(show_open ? 'fa-chevron-up' : 'fa-chevron-down')"></i>
        </a>
        <a class="dropdown-toggle" data-toggle="dropdown" href="#">
          <i class="fa fa-gear"></i>
        </a>
        <ul class="dropdown-menu dropdown-user">
          <li><a @click="localWidth = 'col-sm-12'"
              >Largo 100%</a>
          <li><a @click="localWidth = 'col-sm-6'"
              >Largo 1/2</a>
          <li><a @click="localWidth = 'col-sm-3'"
              >Largo 1/4</a>
          </li>
        </ul>
      </div>


    </div>

    <slot name="ibox-content"></slot>

    </slot>
  </div>
</template>

<script>

  export default {
    name: 'IBox3',
    components: {
    },
    props: ['opened', 'options'],

    data: function () {
      return {
        localWidth: 'col-sm-12',

        show_open: this.opened
      }
    },

    mounted: function() {
    },

    methods: {
      toggleOpen: function() {
        this.$set(this, 'show_open', ! this.show_open)
        return true
      }
    },

    computed: {
      opts: function() {
        var klass = this.localWidth
        klass += (this.options ? this.options.class : '')
        klass += (this.opened ? '' : ' border-botton' )
        return {
          class: klass
        }
      }
    }

  };
</script>
