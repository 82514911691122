// topico/topico_item_big.vue
<template>
  <div class="media nrm">

    <div class="media-body ">
      <!-- <small class="float-right">5m ago</small> -->
      <a :href="topico.link">
        <h3><strong>
          {{ topico.title }}
        </strong></h3>
      </a>

      <div v-if="topico.resumo">
        <p>
          {{ topico.resumo }}
        </p>
      </div>

      <div class="col-sm-12"></div>
      <div class="col-sm-6 text-left" style="display: block;">
        <div class="" style="">
          <i class="m-l-10 fa fa-thumbs-up"
             id="favoritedId"
             style="font-size: large;"
          ></i>
          <span>({{ topico.liked_count }})</span>

          <i class="m-l-10 fa fa-comments"
             id="favoritedId"
             style="font-size: large;"
          ></i>
          <span>({{ topico.comentarios_count }})</span>

          <i class="m-l-10 fa fa-eye"
             id="favoritedId"
             style="font-size: large;"
          ></i>
          <span>({{ topico.viewed_count }})</span>
        </div>
      </div>

      <div class="col-sm-6 text-right" style="display: block;">
        <span >{{ topico.publicado_by }}</span>
        <small class="text-muted">{{ topico.publicado_desde }}</small>
      </div>

      <br>
    </div>

  </div>
</template>

<script>

  import ComentarioItem   from './comentario_item.vue'


  export default {
    name: 'topico_item_big',
    props: ['options', 'key_id', 'index_topico'],
    components: {
      'comentario_item'   : ComentarioItem
    },
    data: function () {
      return {
        refreshIndex: 0,
        loaded: false,

        comm_editing: false,
        text_comment: '',

        topico: this.options
      }
    },
    created: function () {
    },
    methods: {
      getTopico: function() {

        var that = this
        $.ajax({
          url: that.topico.link + '.json',
          data: {
            comments: true
          },
          type: 'get',
          format: 'json',
          success: function(data) {
            that.loadTopico(data)
          },
          error: function(error) {
          },
          complete: function(response) {
          }
        })

      },
      loadTopico: function(data) {
        this.topico = data.topico
      },
      comentariosTitle: function() {
        if (this.topico.comentarios_count == 1) {
          return '1 Comentário'
        } else {
          return `${this.topico.comentarios_count} Comentários`
        }
      },
      initCommEdit: function() {
        this.text_comment = ''
        this.comm_editing = true
      },
      postComentario: function() {
        var that = this
        $.ajax({
          url: that.topico.comentarios_post_url + '.json',
          data: {
            comentario: {
              topico_id: that.topico.id,
              body:      that.text_comment
            }
          },
          type: 'post',
          format: 'json',
          success: function(data) {
            that.comm_editing = false
            that.getTopico()
          },
          error: function(error) {
          },
          complete: function(response) {
          }
        })

      }
    },
    computed: {
      canComentar() {
        return this.topico.can_comentar
      }
    }
  }

</script>

