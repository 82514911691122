// equipe_calendar.vue
<template>
  <div>
    <div id="show_calendar">
      <div class="row linha-header">
        <div id="btn_today"
             class="col-sm-3">
          <button type="button"
                  class="btn btn-outline btn-success"
                  @click="setCalendarToday()"
          >Hoje</button>
        </div>
        <div id="btn_calendar_date"
             class="col-sm-6">
          <h2 class="text-center"
          >{{ dateHeader }} </h2>
        </div>
        <div id="btns_print_xls"
             class="col-sm-2">
          <button v-if="equipesSelIds.length > 0"
              type="button"
              class="btn pull-right btn btn-primary waves-effect m-l-5"
              @click="printPlanilha('PDF')">
            <i class="zmdi zmdi-grid p-r-5"></i>
            PDF
          </button>
          <button v-if="equipesSelIds.length > 0"
              type="button"
              class="btn pull-right btn btn-primary waves-effect"
              @click="printPlanilha('XLS')">
            <i class="zmdi zmdi-grid p-r-5"></i>
            Excel
          </button>
        </div>
        <div id="btns_nav_week"
             class="col-sm-1"
             style="display: flex;">
          <button type="button"
                  class="btn btn-outline btn-success"
                  @click="prevWeek()">
            <i class="fa fa-arrow-left"></i>
          </button>
          <button type="button"
                  class="btn btn-outline btn-success"
                  @click="nextWeek()">
            <i class="fa fa-arrow-right"></i>
          </button>
        </div>
      </div>
      <div class="calendar_table row"
           style="overflow-x: scroll;">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>Equipe</th>
              <th v-for="th in tabHeader.slice(1)"> {{ th }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in tabRows">
              <td v-html="row[0]"
                  style="width: 12.5%;"
              ></td>
              <td v-for="(dia, index) in row.slice(1)"
                  style="width: 12.5%;"
              >
                <a v-if="(index < 7)" v-for="evento in dia"
                  :href="evento.url"
                >
                  <span
                        class="btn btn-xs btn-rounded"
                        :style="'width: 100%; color: #fff; background-color:' + evento.color"
                  >
                    {{ evento.title }}
                    <br/>
                  </span>
                </a>
                <span v-if="(index == 7) && (dia != '0/0')">
                  {{ dia }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

      </div>

    </div>
  </div>
</template>

<script>

  export default {
    name: 'equipe_calendar',
    props: ['options'],
    data: function () {
      return {
        // equipe: this.options.equipe,
        obra:   null,
        currentDate: null,
        currentView: null,

        storeName: 'PlanejamentoStore'

      }
    },
    mounted: function () {
      var that = this;
      if (this.options.equipes.length > 0) {
        this.initCalendar() ;
      }
    },

    methods: {
      initCalendar: function () {
      },
      refreshCalendar: function () {
      },
      setCalendarToday: function() {
        return this.$store.dispatch(this.storeName + '/setToday');
      },
      prevWeek: function() {
        return this.$store.dispatch(this.storeName + '/prevWeek');
      },
      nextWeek: function() {
        return this.$store.dispatch(this.storeName + '/nextWeek');
      },
      printPlanilha: function(formato) {
        var that = this;
        var dataIni = moment(this.beginLapse).format('DD/MM/YYYY')
        var url_req = '/obras/' + that.options.obra_id + '/planejamento/print_planilha.json'

        // // Solução com problema de comprimento de url
        // url_req += '?dataIni=' + dataIni ;
        // url_req += '&formato=' + formato ;
        // url_req += '&equipes=' + that.equipesSelIds ;
        // window.open(url_req, '_blank');

        $.ajax({
          method: 'post',
          url: url_req,
          data: {
            dataIni: dataIni,
            equipes: that.equipesSelIds,
            formato: formato
          },
          success: function(res) {
            var arq_tmp = res.filename
            window.open(arq_tmp, '_blank');
        //   //   $("body").append("<iframe src='" + res.url+ "' style='display: none;' ></iframe>");
        //   }
          }
        })
      }
    },
    computed: {
      equipes: function() {
        return this.$store.getters[this.storeName + '/equipeSelected']
      },
      equipesSelIds: function() {
        return this.$store.getters[this.storeName + '/equipesSelectedIds']
      },
      beginLapse: function() {
        return this.$store.getters[this.storeName + '/beginLapse']
      },
      dateHeader: function() {
        return this.$store.getters[this.storeName + '/dateHeader']
      },
      tabHeader: function() {
        return this.$store.getters[this.storeName + '/tabHeader']
      },
      tabRows: function() {
        return this.$store.getters[this.storeName + '/tabRows']
      },
      tabRowsDias: function() {
        return this.$store.getters[this.storeName + '/tabRows']
      }
    }
  }

</script>

