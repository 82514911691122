var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row clearfix p-b-10" }, [
    _c(
      "div",
      {
        staticClass: "ibox border-grayed m-b-5",
        staticStyle: { padding: "5px" }
      },
      [
        _c("div", { staticClass: "ibox-title  small-margin" }, [
          _c("h5", [_vm._v("Envio de Caixa")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ibox-tools" },
            [
              _vm._l(_vm.options.options.actions, function(act, index) {
                return _c(
                  "a",
                  {
                    attrs: {
                      "data-remote": act.others["data-remote"],
                      "data-toggle": "tooltip",
                      "data-placement": "top",
                      title: "",
                      href: act.href,
                      "data-original-title": act.others.title
                    }
                  },
                  [_c("i", { class: act.i_class })]
                )
              }),
              _vm._v(" "),
              _vm._m(0)
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ibox-content" }, [
          _c(
            "table",
            { staticClass: "table table-hover no-margins no-entrelinhas" },
            [
              _c("tbody", [
                _c("tr", [
                  _c("td", [_vm._v("Assunto:")]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.options.options.envio.nome))])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Status:")]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.options.options.envio.status))])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Remetente:")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(_vm.options.options.envio.remetente))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Destinatário:")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(_vm.options.options.envio.destinatario))
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Emprestimo:")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(_vm._s(_vm.options.options.envio.emprestimo))
                  ])
                ])
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ibox-footer" },
          [
            _vm._l(_vm.buttons, function(btt, index) {
              return _c(
                "a",
                {
                  key: _vm.refresh_buttons,
                  staticClass: "btn btn-primary btn-xs m-l-5 m-r-5",
                  attrs: { type: "button", href: btt[1], "data-remote": "true" }
                },
                [_vm._v(_vm._s(btt[0]))]
              )
            }),
            _vm._v(" "),
            _vm.options.options.aviso && _vm.buttons.length == 0
              ? _c("span", { key: _vm.refresh_buttons }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.options.options.aviso) +
                      "\n      "
                  )
                ])
              : _vm._e()
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { staticClass: "collapse-link" }, [
      _c("i", { staticClass: "fa fa-chevron-up" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }