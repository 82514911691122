<template>
  <div class="panel blank-panel">
    <div class="panel-heading">
      <div class="panel-options">
        <ul class="nav nav-tabs">
          <li id="editor" class="active">
            <a data-toggle="tab" href="#tab-editor">
              Configuração do formulário
            </a>
          </li>
          <li id="render" class="">
            <a data-toggle="tab" href="#tab-render">
              Teste da entrada de dados
            </a>
          </li>
<!--
          <li id="result" class="">
            <a data-toggle="tab" href="#tab-result">
              Resultado
            </a>
          </li>
 -->
        </ul>
      </div>
    </div>
    <div class="panel-body">
      <div class="tab-content">

        <div id="tab-editor" class="tab-pane active">

          <groups-editor
              :model="model"
              :storename="storename">
          </groups-editor>

        </div>

        <div id="tab-render" class="tab-pane">

          <groups-render
              :storename="storename">
          </groups-render>

        </div>
<!--
        <div id="tab-result" class="tab-pane"></div>
 -->
      </div>
    </div>
  </div>
</template>

<script>
  import DataStore      from 'packs/vuex/stores/form_data_store.js'
  import GroupsEditor from "./groups_editor.vue";
  import GroupsRender from "./groups_render.vue";

  export default {
    name: 'FullEditForm',
    mixins: [DataStore],
    components: {
      'groups-editor' :  GroupsEditor,
      'groups-render' :  GroupsRender
    },
    // model é o formulario.rb
    props: ['model'],
    data: function () {
      return {
        storename: 'modalFormStore',

        klass: 'full-editor',

        eleEditor : null,
        eleRender : null,

        fbEditor : null,
        fbRender : null

      }
    },
    mounted: function () {
      this.initStore(this.storename)

      var that = this
      that.loadStore({
        formulario: that.model
      })
    },
    methods: {
      // storeName: function() {
      //   return this.storename ;
      // },
      saveForm: function() {
        return 'x'
      }
    },
    watch: {
    }
  };
</script>

