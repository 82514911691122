// topicos/topicos_index.vue
<template>
  <div class="">
<!--
    <div class="forum-title">
      <h3>Tópicos</h3>
    </div>
 -->
    <div class="ibox-content" style="border: none;">

      <topico_item_big v-for="(topico, indexTopico) in topicos"
         :options="topico"
         :index_topico="indexTopico"
         :key_id="indexTopico"
         :key="refreshIndex + topico.id">
      </topico_item_big>

    </div>
  </div>
 </template>

<script>

  // import DataStore from    './data_store.vue'

  import TopicoStore from 'packs/vuex/stores/topico_store.js'
  import TopicoItemBig   from './topico_item_big.vue'


  export default {
    name: 'topicos_index',
    props: ['options'],
    components: {
      'topico_item_big' : TopicoItemBig
    },
    // mixins: [StoreLoader],
    data: function () {
      return {
        refreshIndex: 0,
        loaded: false,
        storeName: 'TopicoStore'
      }
    },
    created: function () {
      // var configs = {
      //   user_id:   this.options.user_id,
      //   url_grupos:       this.options.grupos_url,
      //   url_latest:       this.options.latest_url,
      //   url_populares:    this.options.populares_url
      // }

      // // this.$store.dispatch(this.storeName + '/init', configs)

      // this.initStore(TopicoStore, configs);
      // // this.refreshIndex += 1;
      // // this.$set(this, 'refreshIndex', this.refreshIndex + 1)
    },
    methods: {
    },

    computed: {
      // grupos() {
      //   return this.$store.getters[this.storeName + '/grupos']
      // },
      // latest() {
      //   return this.$store.getters[this.storeName + '/latest']
      // },
      topicos() {
        return this.$store.getters[this.storeName + '/topicos']
      }
    }
  }

</script>

