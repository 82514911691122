var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model.opts
    ? _c(
        "div",
        [
          _vm.model.opts.scopes
            ? _c(
                "div",
                { staticClass: "select_scope" },
                _vm._l(_vm.model.opts.scopes, function(scope, index) {
                  return _c(
                    "div",
                    { class: scope.div_class },
                    [
                      scope.options
                        ? _c("cmp-v-select", {
                            key: "refreshId88",
                            attrs: {
                              input_class: "form-control select",
                              options: scope.options,
                              required: true,
                              value: scope.value
                            },
                            model: {
                              value: _vm.scope_sel[index],
                              callback: function($$v) {
                                _vm.$set(_vm.scope_sel, index, $$v)
                              },
                              expression: "scope_sel[index]"
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      scope.type == "button"
                        ? _c(
                            "button",
                            {
                              class: scope.btn_class,
                              on: {
                                click: function($event) {
                                  return _vm.getButton(scope.url)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(scope.caption) +
                                  "\n      "
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _c("cr-show", {
            key: _vm.refreshId,
            ref: "show_comp",
            attrs: { opts: _vm.opts2cr_show }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }