var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.klass }, [
    _c("div", { staticClass: "ibox float-e-margins" }, [
      _c("div", { staticClass: "ibox-title" }, [
        _c("h2", [_vm._v(_vm._s(_vm.options.title))]),
        _vm._v(" "),
        _c("div", { staticClass: "ibox-tools" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-primary btn-xs",
              on: {
                click: function($event) {
                  return _vm.refresh()
                }
              }
            },
            [_vm._v("\n         Atualizar\n       ")]
          )
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ibox-content",
          staticStyle: { "border-style": "none" }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("vc-line-chart", {
                  ref: "thechart",
                  attrs: { options: _vm.opts2Chart }
                })
              ],
              1
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }