var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user_index" }, [
    _c("div", { staticClass: "ibox-title ch-alt col-sm-12" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-4" },
        _vm._l(_vm.options.scopes, function(scope, index) {
          return _c(
            "div",
            { class: scope.div_class },
            [
              scope.options
                ? _c("cmp-v-select", {
                    key: _vm.scopeRefresh,
                    attrs: {
                      input_class: "form-control select",
                      options: scope.options,
                      required: true,
                      value: scope.value,
                      index: index,
                      onSelected: _vm.updateScope
                    },
                    model: {
                      value: _vm.scope_sel[index],
                      callback: function($$v) {
                        _vm.$set(_vm.scope_sel, index, $$v)
                      },
                      expression: "scope_sel[index]"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm._m(1)
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ibox-title col-sm-12" }, [
      _c("div", { staticClass: "ibox col-sm-9" }, [
        _c("div", {}, [
          _c(
            "div",
            { staticClass: "ibox-content", staticStyle: { border: "none" } },
            _vm._l(_vm.topicos, function(topico, indexTopico) {
              return _c("topico_item_big", {
                key: _vm.refreshIndex * 10 + indexTopico,
                attrs: { options: topico, key_id: topico.id }
              })
            }),
            1
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-3" }, [
        _c("div", { staticClass: "ibox" }, [
          _c("div", { staticClass: "ibox-title" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "ibox-content" }, [
              _c(
                "div",
                { staticClass: "feed-activity-list" },
                _vm._l(_vm.latest, function(topico, indexTopico) {
                  return _c("topico_item", {
                    key: _vm.refreshIndex * 10000 + indexTopico,
                    attrs: { options: topico }
                  })
                }),
                1
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "ibox" }, [
          _vm._m(3),
          _vm._v(" "),
          _c("div", { staticClass: "ibox-content" }, [
            _c(
              "div",
              { staticClass: "feed-activity-list" },
              _vm._l(_vm.populares, function(topico, indexTopico) {
                return _c("topico_item", {
                  key: _vm.refreshIndex * 1000 + indexTopico,
                  attrs: { options: topico }
                })
              }),
              1
            )
          ])
        ]),
        _vm._v(" "),
        this.options.pendentes_url
          ? _c("div", { staticClass: "ibox" }, [
              _vm._m(4),
              _vm._v(" "),
              _c("div", { staticClass: "ibox-content" }, [
                _c(
                  "div",
                  { staticClass: "feed-activity-list" },
                  _vm._l(_vm.pendentes, function(topico, indexTopico) {
                    return _c("topico_item", {
                      key: _vm.refreshIndex * 100 + indexTopico,
                      attrs: { options: topico }
                    })
                  }),
                  1
                )
              ])
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-4" }, [
      _c("h2", { attrs: { id: "show_page_title" } }, [
        _vm._v("Gestão do Conhecimento")
      ]),
      _vm._v(" "),
      _c("h5")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-4" }, [
      _c("div", { staticClass: "ibox-tools" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-primary waves-effect",
            attrs: { href: "/topic/topicos/new", target: "_blank" }
          },
          [
            _c("div", [
              _c("i", { staticClass: "p-r-5 fa fa-external-link" }),
              _vm._v("\n            Novo tópico\n          ")
            ])
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "btn btn-primary waves-effect",
            attrs: { href: "/topic/conf_page" }
          },
          [_vm._v("\n          Configuração\n        ")]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "forum-title", staticStyle: { border: "none" } },
      [_c("h3", [_vm._v("Novos Tópicos")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "ibox-title", staticStyle: { border: "none" } },
      [
        _c("div", { staticClass: "forum-title" }, [
          _c("h3", [_vm._v("Tópicos mais populares")])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "ibox-title", staticStyle: { border: "none" } },
      [
        _c("div", { staticClass: "forum-title" }, [
          _c("h3", [_vm._v("Pendentes de publicação")])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }