export default {
    namespaced: true,
    state: {
      obra_id: 0,
      url: '',
      url_planejamento: '',
      equipes: [],
      equipes_selected_ids: [],

      soComAgendamento: false,

      day_number: 7,
      begin_lapse: null,
      date_header: '',
      tab_header: [],
      tab_rows:   []

    },
    mutations: {
      init(state, data) {
        state.obra_id     = data.obra_id;
        state.url         = data.url;
        state.url_planejamento = data.url_planejamento;
        return state;
      },
      load(state, data) {
        state.equipes     = data.equipes;
        return state;
      },
      load_planejamento(state, data) {
        state.tab_header  = data.header;
        state.tab_rows    = data.rows;
        state.date_header = data.title;
        state.begin_lapse = moment(data.data_ini, 'DD/MM/YYYY').toDate();
        return state;
      },
      selectEquipe(state, eqSel) {
        var indSel = state.equipes_selected_ids.indexOf(eqSel.equipe_id) ;
        if (indSel > -1) {
          state.equipes_selected_ids.splice(indSel, 1);
        } else {
          state.equipes_selected_ids.push(eqSel.equipe_id);
        }
      },
      selectTodas(state, bool_value) {
        if (bool_value) {
          var neq = state.equipes.map((eq) => { return eq.equipe_id })
          state.equipes_selected_ids = neq
        } else {
          state.equipes_selected_ids = []
        }
      },
      setToday(state) {
        state.begin_lapse = new Date() ;
      },
      prevWeek(state) {
        if (state.begin_lapse) {
          var novadata = state.begin_lapse.getDate() - 7
          state.begin_lapse = moment(state.begin_lapse).subtract(7, 'd')
        }
      },
      nextWeek(state) {
        if (state.begin_lapse) {
          state.begin_lapse = moment(state.begin_lapse).add(7, 'd')
        }
      },
      toggleSoComAgendamento(state  ){
        state.soComAgendamento = ! state.soComAgendamento
      }
    },
    actions: {
      init(context, conf) {
        context.commit('init', conf)
        context.dispatch('load')
      },
      // load inicial
      load(context) {
        var that = this
        $.ajax({
          url: context.state.url,
          data: {
          },
          type: 'get',
          format: 'json',
          success: function(data) {
            context.commit('load', data)
          },
          error: function(error) {
          },
          complete: function(error) {
          }
        })
      },
      // load de calendario
      load_planejamento(context) {
        var dini = null
        if (context.state.begin_lapse) {
          dini = moment(context.state.begin_lapse).format('DD/MM/YYYY')
        }
        var params = {
          data_ini: dini,
          equipes_ids: context.state.equipes_selected_ids
        }
        var that = this
        $.ajax({
          url: context.state.url_planejamento + '.json',
          data: params,
          type: 'post',
          format: 'json',
          success: function(data) {
            context.commit('load_planejamento', data)
          },
          error: function(error) {
          },
          complete: function(error) {
          }
        })
      },
      selectEquipe(context, equipe) {
        context.commit('selectEquipe', equipe)
        context.dispatch('load_planejamento')
      },
      selectTodas(context, bool_value) {
        context.commit('selectTodas', bool_value)
        context.dispatch('load_planejamento')
      },
      setToday(context) {
        context.commit('setToday')
        context.dispatch('load_planejamento')
      },
      prevWeek(context) {
        context.commit('prevWeek')
        context.dispatch('load_planejamento')
      },
      nextWeek(context) {
        context.commit('nextWeek')
        context.dispatch('load_planejamento')
      },
      toggleSoComAgendamento(context){
        context.commit('toggleSoComAgendamento')
      }
    },
    getters: {
      equipes: state => {
        return state.equipes ;
      },
      equipesSelectedIds: state => {
        return state.equipes_selected_ids ;
      },
      beginLapse: state => {
        return state.begin_lapse ;
      },
      tabHeader: state => {
        return state.tab_header ;
      },
      tabRows: state => {
        return state.tab_rows ;
      },
      dateHeader: state => {
        return state.date_header ;
      },
      soComAgendamento: state => {
        return state.soComAgendamento ;
      }
    }
  }


