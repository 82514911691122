import { render, staticRenderFns } from "./topicos_index.vue?vue&type=template&id=5597f088&"
import script from "./topicos_index.vue?vue&type=script&lang=js&"
export * from "./topicos_index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/deploy/cp/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5597f088')) {
      api.createRecord('5597f088', component.options)
    } else {
      api.reload('5597f088', component.options)
    }
    module.hot.accept("./topicos_index.vue?vue&type=template&id=5597f088&", function () {
      api.rerender('5597f088', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/packs/components/topicos/topicos_index.vue"
export default component.exports