// filtro/fin_tr.vue
<template>
  <div :class="klass">
    <form v-if="bigFiltro()">

      <div class="col-sm-12"></div>

      <div class="col-sm-4">
        <div class="form-group">
          <label class="control-label text optional">Obra/Negócio</label>
          <div>
            <cmp-v-select
              input_class="form-control select select2"
              :key="refreshId"
              :options="obrasOptions()"
              :required="true"
              :multiple="true"
              v-model="obra_ids">
            </cmp-v-select>
          </div>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="form-group">
          <label class="control-label text optional">Data de Emissão (desde)</label>
          <cmp-datepicker
            :key="refreshId"
            id="emissao_ini"
            v-model="emissao_ini">
          </cmp-datepicker>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="form-group">
          <label class="control-label text optional">Data de Emissão (até)</label>
          <cmp-datepicker
            :key="refreshId"
            id="emissao_fim"
            v-model="emissao_fim">
          </cmp-datepicker>
        </div>
      </div>

      <div class="col-sm-2 col-sm-offset-2">
        <div class="form-group">
          <label class="control-label text optional">Linhas por página</label>
          <div>
            <cmp-v-select
              input_class="form-control select select2"
              :key="refreshId"
              :options="perpageOptions()"
              :required="true"
              v-model="per_page">
            </cmp-v-select>
          </div>
        </div>
      </div>

      <!-- Segunda linha
       -->

      <div class="col-sm-12"></div>
      <div class="col-sm-2 col-sm-offset-4">
        <div class="form-group">
          <label class="control-label text optional">Data de Vencimento (desde)</label>
          <cmp-datepicker
            :key="refreshId"
            id="vencimento_ini"
            v-model="vencimento_ini">
          </cmp-datepicker>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="form-group">
          <label class="control-label text optional">Data de Vencimento (até)</label>
          <cmp-datepicker
            :key="refreshId"
            id="vencimento_fim"
            v-model="vencimento_fim">
          </cmp-datepicker>
        </div>
      </div>


      <div class="col-sm-12"></div>
      <div class="col-sm-2 col-sm-offset-4">
        <div class="form-group">
          <label class="control-label text optional">Data Prevista (desde)</label>
          <cmp-datepicker
            :key="refreshId"
            id="prevista_ini"
            v-model="prevista_ini">
          </cmp-datepicker>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="form-group">
          <label class="control-label text optional">Data Prevista (até)</label>
          <cmp-datepicker
            :key="refreshId"
            id="prevista_fim"
            v-model="prevista_fim">
          </cmp-datepicker>
        </div>
      </div>



      <div class="col-sm-12"></div>
      <div class="col-sm-4">
        <div class="form-group">
          <label class="control-label text optional">Status</label>
          <div>
            <cmp-v-select
              input_class="form-control select select2"
              :key="refreshId"
              :options="statusOptions()"
              :required="true"
              :multiple="true"
              v-model="status">
            </cmp-v-select>
          </div>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="form-group">
          <label class="control-label text optional">Valor (desde)</label>
          <cmp-autonumeric
            :key="refreshId"
            v-model="valor_ini"
            :decimal_places="2"
            :min="0"
          ></cmp-autonumeric>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="form-group">
          <label class="control-label text optional">Valor (até)</label>
          <cmp-autonumeric
            :key="refreshId"
            v-model="valor_fim"
            :decimal_places="2"
            :min="0"
          ></cmp-autonumeric>
        </div>
      </div>

      <div class="col-sm-12"></div>

      <div class="col-sm-4">
        <div class="form-group optional text_search">
          <label class="control-label text_search optional">Pesquisar texto</label>
          <input type="text"
                 :key="refreshId"
                 v-model="text_ts"
                 id="text_search"
                 class="form-control string text_search optional">
        </div>
      </div>

      <div class="col-sm-4">
        <div class="form-group">
          <label class="control-label text optional">... nos seguntes campos</label>
          <div>
            <cmp-v-select
              input_class="form-control select select2"
              :key="refreshId"
              :options="fieldsOptions()"
              :required="true"
              :multiple="true"
              v-model="fields_ts">
            </cmp-v-select>
          </div>
        </div>
      </div>

    </form>



    <form v-else>

      <div class="col-sm-12"></div>


      <div class="col-sm-4">
        <div class="form-group">
          <label class="control-label text optional">Obra/Negócio</label>
          <div>
            <cmp-v-select
              input_class="form-control select select2"
              :key="refreshId"
              :options="obrasOptions()"
              :required="true"
              :multiple="true"
              v-model="obra_ids">
            </cmp-v-select>
          </div>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="form-group">
          <label class="control-label text optional">Data de Vencimento (desde)</label>
          <cmp-datepicker
            :key="refreshId"
            id="vencimento_ini"
            v-model="vencimento_ini">
          </cmp-datepicker>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="form-group">
          <label class="control-label text optional">Data de Vencimento (até)</label>
          <cmp-datepicker
            :key="refreshId"
            id="vencimento_fim"
            v-model="vencimento_fim">
          </cmp-datepicker>
        </div>
      </div>

      <div class="col-sm-2 col-sm-offset-2">
        <div class="form-group">
          <label class="control-label text optional">Linhas por página</label>
          <div>
            <cmp-v-select
              input_class="form-control select select2"
              :key="refreshId"
              :options="perpageOptions()"
              :required="true"
              v-model="per_page">
            </cmp-v-select>
          </div>
        </div>
      </div>

      <!-- Segunda linha
       -->

      <div class="col-sm-12"></div>
      <div class="col-sm-2 col-sm-offset-4">
        <div class="form-group">
          <label class="control-label text optional">Data Prevista (desde)</label>
          <cmp-datepicker
            :key="refreshId"
            id="prevista_ini"
            v-model="prevista_ini">
          </cmp-datepicker>
        </div>
      </div>

      <div class="col-sm-2">
        <div class="form-group">
          <label class="control-label text optional">Data Prevista (até)</label>
          <cmp-datepicker
            :key="refreshId"
            id="prevista_fim"
            v-model="prevista_fim">
          </cmp-datepicker>
        </div>
      </div>

    </form>
  </div>
</template>

<script>

  import CmpVSelect     from 'packs/components/comp/cmp-v-select.vue'
  import cmpAutonumeric from 'packs/components/comp/cmp-autonumeric.vue'
  import CompDatePicker from 'packs/components/comp/cmp-datepicker.vue'

  export default {
    name: 'FiltroFinTr',
    components: {
      'cmp-v-select'           : CmpVSelect,
      'cmp-autonumeric'        : cmpAutonumeric,
      'cmp-datepicker'         : CompDatePicker
    },
    props: ['options'],
    data: function () {
      return {
        type: 'cr_chart',
        klass: 'filtro-fin-tr',

        refreshId: 0,
        per_page: 10,

        opts: this.options,

        obra_ids: [0],
        emissao_ini: null,
        emissao_fim: null,
        vencimento_ini:  null,
        vencimento_fim:  null,
        prevista_ini:  null,
        prevista_fim:  null,

        status: ['0'],
        valor_ini: '0',
        valor_fim: '0',

        text_ts: null,
        fields_ts: ['0'],

        zummy: null
      }
    },
    mounted: function() {
    },
    methods: {
      cleanFiltro: function() {
        this.obra_ids     =  [0];
        this.emissao_ini  =  null;
        this.emissao_fim  =  null;
        this.vencimento_ini  =  null;
        this.vencimento_fim  =  null;
        this.prevista_ini  =  null;
        this.prevista_fim  =  null;
        this.status       = ['0'];
        this.valor_ini    = '0';
        this.valor_fim    = '0';

        this.refreshId += 1;

        return true ;
      },
      perpageOptions: function() {
        return [
          { label: '10', value: 10 },
          { label: '25', value: 25 },
          { label: '50', value: 50 },
          { label: '100', value: 100 } ] ;
      },
      obrasOptions: function() {
        return this.opts.obra_objs ;
      },
      statusOptions: function() {
        return this.opts.status_objs ;
      },
      fieldsOptions: function() {
        return this.opts.fts_objs ;
      },
      bigFiltro: function() {
        return ! this.opts.small ;
      }
    },
    computed: {
      filtro: function() {
        var filtro = {};
        filtro.obra_ids    = this.obra_ids ;
        if (this.emissao_ini) { filtro.emissao_ini = this.emissao_ini }
        if (this.emissao_fim) { filtro.emissao_fim = this.emissao_fim }
        if (this.vencimento_ini) { filtro.vencimento_ini = this.vencimento_ini }
        if (this.vencimento_fim) { filtro.vencimento_fim = this.vencimento_fim }
        if (this.prevista_ini) { filtro.prevista_ini = this.prevista_ini }
        if (this.prevista_fim) { filtro.prevista_fim = this.prevista_fim }

        filtro.status    = this.status ;
        if (this.valor_ini) { filtro.valor_ini = this.valor_ini } ;
        if (this.valor_fim) { filtro.valor_fim = this.valor_fim } ;

        if (this.text_ts) { filtro.text_ts = this.text_ts } ;
        filtro.fields_ts = this.fields_ts ;

        filtro.per_page = this.per_page ;

        return filtro ;
      }
    }
  };
</script>
