var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ibox border-grayed m-b-5" }, [
    _c("div", { staticClass: "ibox-title  small-margin" }, [
      _c("h5", [_vm._v(_vm._s(_vm.options.title.caption))]),
      _vm._v(" "),
      _c("div", { staticClass: "ibox-tools" }, [
        _vm.options.title.button &&
        _vm.askGetter(_vm.options.title.button.if.getter)
          ? _c(
              "a",
              {
                class: _vm.options.title.button.class,
                on: {
                  click: function($event) {
                    _vm.options.title.button.block()
                  }
                }
              },
              [_vm._v(_vm._s(_vm.options.title.button.caption))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.options.title.buttonCollapse
          ? _c("a", { staticClass: "collapse-link" }, [
              _c("i", { staticClass: "fa fa-chevron-up" })
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.options.title.buttonClose
          ? _c(
              "a",
              {
                staticClass: "close-link",
                on: {
                  click: function($event) {
                    _vm.options.title.buttonClose.block()
                  }
                }
              },
              [_c("i", { staticClass: "fa fa-times" })]
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "ibox-content" }, [
      _c(
        "ul",
        { staticClass: "todo-list small-list" },
        _vm._l(_vm.list, function(item) {
          return _c(
            "li",
            { staticStyle: { display: "inline", padding: "2px 6px 2px 12px" } },
            [
              _c("span", { staticClass: "m-l-xs" }, [
                _vm._v(_vm._s(item.nome))
              ]),
              _vm._v(" "),
              _vm.options.itemActions.destroy
                ? _c(
                    "a",
                    {
                      staticClass: "check-link",
                      on: {
                        click: function($event) {
                          return _vm.removeItem(item)
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-times" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.options.itemOtherActions, function(action) {
                return _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        action.block(item)
                      }
                    }
                  },
                  [_c("i", { class: action.icon })]
                )
              })
            ],
            2
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }