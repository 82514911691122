var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-lg-9 animated fadeInRight p-l-5 p-r-5" },
    [
      _c(
        "div",
        {
          staticClass: "mail-box-header",
          staticStyle: { "border-bottom": "1px solid #e7eaec" }
        },
        [
          _c("div", { staticClass: "pull-right tooltip-demo" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-white btn-sm",
                attrs: {
                  "data-toggle": "tooltip",
                  "data-placement": "top",
                  title: "Responder"
                },
                on: {
                  click: function($event) {
                    return _vm.replyMsg(_vm.conversa)
                  }
                }
              },
              [
                _c("i", { staticClass: "fa fa-mail-reply-all" }),
                _vm._v("\n        Responder\n      ")
              ]
            ),
            _vm._v(" "),
            _vm._m(0)
          ]),
          _vm._v(" "),
          _c("h2", [_vm._v("\n      Mensagem\n    ")]),
          _vm._v(" "),
          _vm.conversa
            ? _c("div", { staticClass: "mail-tools tooltip-demo m-t-md" }, [
                _c("h3", [
                  _c("span", { staticClass: "font-noraml" }, [
                    _vm._v("Assunto:")
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.conversa.subject) + "\n      "
                  )
                ]),
                _vm._v(" "),
                _c("h4", [
                  _vm.conversa.link_obj && _vm.conversa.link_obj != ""
                    ? _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.conversa.link_obj.href,
                            target: "_blank"
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.conversa.link_obj.cap) +
                              "\n          "
                          ),
                          _c("i", { staticClass: "fa fa-external-link" })
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("h5", [
                  _c("span", { staticClass: "pull-right font-noraml" }, [
                    _vm._v(_vm._s(_vm.conversa.time_ago))
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "font-noraml" }, [
                    _vm._v("From: ")
                  ]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.conversa.sender.nome) + "\n      "
                  )
                ])
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mail-box p-5" }, [
        _c(
          "div",
          { staticClass: "feed-activity-list" },
          _vm._l(_vm.conversa.messages, function(mess, index) {
            return _c("div", { staticClass: "feed-element" }, [
              _c("a", { staticClass: "pull-left", attrs: { href: "#" } }, [
                _c("img", {
                  staticClass: "img-circle",
                  attrs: { alt: "image", src: mess.sender.img }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "media-body " }, [
                _c("small", { staticClass: "pull-right" }, [
                  _vm._v(_vm._s(mess.time_ago))
                ]),
                _vm._v(" "),
                _c("strong", [_vm._v(_vm._s(mess.sender.nome))]),
                _vm._v(
                  "\n          " +
                    _vm._s(index == 0 ? "escreveu" : "respondeu") +
                    "\n          "
                ),
                _c("br"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "well", staticStyle: { "font-size": "14px" } },
                  [_vm._v(_vm._s(mess.body))]
                )
              ])
            ])
          }),
          0
        ),
        _vm._v(" "),
        false
          ? _c("div", { staticClass: "mail-body text-right tooltip-demo" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-sm btn-white",
                  on: {
                    click: function($event) {
                      return _vm.replyMsg(_vm.conversa)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fa fa-mail-reply-all" }),
                  _vm._v("\n        Responder\n      ")
                ]
              ),
              _vm._v(" "),
              _vm._m(1)
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "clearfix" })
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "btn btn-white btn-sm",
        attrs: {
          href: "mailbox.html",
          "data-toggle": "tooltip",
          "data-placement": "top",
          title: "Excluir"
        }
      },
      [_c("i", { staticClass: "fa fa-trash-o" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-sm btn-white",
        attrs: {
          title: "",
          "data-placement": "top",
          "data-toggle": "tooltip",
          "data-original-title": "Excluir"
        }
      },
      [_c("i", { staticClass: "fa fa-trash-o" }), _vm._v(" Excluir")]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }