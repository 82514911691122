// index_planejamento.vue
<template>
  <div>
    <div class="col-sm-2 p-l-0">
      <div class="ibox float-e-margins m-0">
        <div class="ibox-content relativo p-l-0 p-r-0">
          <h4>Função</h4>
          <cmp-v-select
                  input_class="form-control select select2"
                  :options="funcoes"
                  :multiple="false"
                  v-model="sel_funcao">
          </cmp-v-select>

          <h4>Seção</h4>
          <cmp-v-select
                  input_class="form-control select select2"
                  :options="secoes"
                  :multiple="false"
                  v-model="sel_secao">
          </cmp-v-select>


        </div>
      </div>

      <div class="ibox float-e-margins m-0">
        <div class="ibox-content relativo p-l-0 p-r-0"
             id="cmp-datepicker-container">
          <cmp-datepicker
              v-model="data"
              :inline="true"
          >
          </cmp-datepicker>
        </div>
      </div>

    </div>
    <div class="col-sm-10">
      <div class="col-sm-12">

        <div class="ibox float-e-margins m-0 p-b-20">
          <div class="ibox-content">

            <h2 class="col-sm-5 no-margins">
              {{ data_em_extenso }}
            </h2>

            <h2 class="col-sm-5 no-margins">
              Custo diário nao apropriado
              <span class="red"
                :style="styleRedOrBlack">
                {{ total_valor_na_str }}
              </span>
            </h2>
            <div class="col-sm-2 no-margins text-right">
              <button v-show="(linhas && (linhas.length > 0))"
                      class="btn btn-sm btn-info"
                      @click="exportarPlanilha()">
                <i class="fa fa-file-excel-o"></i>
                Exportar planilha
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <cr-data-table
             :options="compDtOptions"
             :key="refreshTableInc"
          ></cr-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import DataStore from './data_store_hna.vue'

  import FuncionariosHnaStore from 'packs/vuex/stores/funcionarios_hna_store.js'
  import mathUtils            from 'packs/components/mixins/mathUtils.vue'
  import CmpDatepicker        from 'packs/components/comp/cmp-datepicker.vue'
  import CmpVSelect           from 'packs/components/comp/cmp-v-select.vue'
  import CrDataTable          from 'packs/components/crud/cr_data_table.vue'

  export default {
    name: 'index_funcionarios_hna',
    props: ['options'],
    components: {
      'cmp-datepicker'    : CmpDatepicker,
      'cmp-v-select'      : CmpVSelect,
      'cr-data-table'     : CrDataTable
    },
    mixins: [DataStore, mathUtils],
    data: function () {
      return {

        loaded: false,
        storeName: 'FuncionariosHnaStore',

        sel_funcao: null,
        sel_secao:  null,

        columns: {
          adef: [
          ]
        },

        refreshTableInc: 0

      }
    },
    // mounted: function () {
    //   this.initStore()
    // },
    created: function () {
      this.initStore(FuncionariosHnaStore, this.options)
      moment.locale('pt-br');
    },
    methods: {
      exportarPlanilha: function() {
        var qdata = this.data
        var url_req = ''
        url_req += `/obras/${this.options.obra_id}/funcionarios/hna`
        url_req += '.xlsx?data=' + qdata

        window.open(url_req, '_blank');
      }
    },
    computed: {
      data: {
        get: function() {
          return this.$store.getters[this.storeName + '/data']
        },
        set: function(ndata) {
          this.$store.dispatch( this.storeName + '/setData', ndata)
          this.$set(this, 'refreshTableInc', this.refreshTableInc + 1);
        }
      },
      col_def: function() {
        return this.$store.getters[this.storeName + '/col_def']
      },
      linhas: function() {
        return this.$store.getters[this.storeName + '/linhas']
      },
      linhas_filt: function() {
        var filtered = this.$store.getters[this.storeName + '/linhas']
        if (this.sel_funcao) {
          var that_filtro = this.sel_funcao ;
          filtered = filtered.filter(function (lin) {
            return (lin[8] === that_filtro)
          })
        }
        if (this.sel_secao) {
          var that_filtro = this.sel_secao ;
          filtered = filtered.filter(function (lin) {
            return (lin[9] === that_filtro)
          })
        }
        return filtered;
      },
      funcoes: function() {
        var funcs = this.$store.getters[this.storeName + '/funcoes']
        var res = funcs.map((fun) => {
          return {
            label: fun,
            value: fun
          }
        })
        return res
      },
      secoes: function() {
        var secs = this.$store.getters[this.storeName + '/secoes']
        var res = secs.map((sec) => {
          return {
            label: sec,
            value: sec
          }
        })
        return res
      },
      total_valor_na: function() {
        if (! this.linhas_filt) {return 0}
        var total = 0
        var index = 0
        for(index in this.linhas_filt) {
          total += this.strBr2Number(this.linhas_filt[index][7])
        }
        return total;
      },
      total_valor_na_str: function() {
        return this.number2Real(this.total_valor_na);
      },
      styleRedOrBlack: function() {
        if (this.total_valor_na > 0) {
          return 'color: red;'
        }
      },
      data_em_extenso: function() {
        var st_data = this.$store.getters[this.storeName + '/data']
        return moment(st_data, 'DD/MM/YYYY').format('LL')
      },
      compDtOptions: function () {
        var coll = (this.linhas_filt || []);
        var len = coll.length;
        return {
          id: 'hna_dtable',
          search: false,
          columns: this.col_def,
          collection: coll,
          record_count: len
        }
      }
    }
  }

</script>
