export default {
    namespaced: true,
    state: {
      model: {},
      schema: {},
      groups: []
    },
    mutations: {
      init(state, data) {
        state.model  = data.model;
        state.schema = data.model.corpo.schema;
        state.groups = state.schema.groups
        return state;
      },
      saveGroupSchema(state, data) {
        var group = state.groups[data.index]
        group['fields'] = data.groupSchema['fields']
        state.groups.splice(data.index, 1, group) ;
      },
      removeGroup(state, index) {
        state.groups.splice(index,1) ;
      },
      up(state, index) {
        var old_index = index
        var new_index = index - 1
        state.groups.splice(new_index, 0, state.groups.splice(old_index, 1)[0]);
      },
      down(state, index) {
        var old_index = index
        var new_index = index + 1
        state.groups.splice(new_index, 0, state.groups.splice(old_index, 1)[0]);
      },
      copyGroup(state, index) {
        var newGroup = jQuery.extend(true, {}, state.groups[index]);
        var insertPos = index + 1
        state.groups.splice(insertPos, 0, newGroup)
        this.$set(state, insertPos, newGroup) // Caveat refresh
      },
      addNewGroup(state, dataGroup) {
        var insertPos = dataGroup.newPos - 1
        state.groups.splice(insertPos, 0, dataGroup.newGroup)
      }
      // },
      // load(state, data) {
      //   state.observadores = data.observadores;
      //   state.outros_usuarios = data.outros_usuarios;
      //   return state;

    },
    actions: {
      init(context, obj_pars) {
        context.commit('init', obj_pars);
      },
      saveGroupSchema(context, data) {
        context.commit('saveGroupSchema', data)
        context.dispatch('saveFormSchema')
      },
      removeGroup(context, index) {
        context.commit('removeGroup', index)
      },
      upGroup(context, index) {
        context.commit('up', index)
      },
      downGroup(context, index) {
        context.commit('down', index)
      },
      copyGroup(context, index) {
        context.commit('copyGroup', index)
      },
      addNewGroup(context, dataGroup) {
        context.commit('addNewGroup', dataGroup)
      },
      saveFormSchema(context, _dummy) {
        var that = this;
        $.ajax({
          method: 'PUT',
          dataType: 'json',
          data: {
            id: context.state.model.id,
            formulario: {
              corpo: {
                schema: context.state.schema
              }
            }
          },
          // contentType: 'application/json; charset=utf-8',
          url: '/formularios/' + context.state.model.id + '.json',
          success: function(res) {
            that.edited = false;
          }
        });
      }
    },

    getters: {
      groupsCollection: (state, getters, rootState, rootGetters) => {
        return state.groups ;
      },
      model: (state, getters, rootState, rootGetters) => {
        return state.model ;
      }
    },
  }




