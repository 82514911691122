var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "table-responsive" }, [
    _c("table", { staticClass: "table table-striped big-datatable" }, [
      _c("thead", [
        _c(
          "tr",
          _vm._l(_vm.cab_pagas, function(cab) {
            return _c("th", [
              _vm._v("\n          " + _vm._s(cab) + "\n        ")
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.linhas_ciclos_ordenados, function(ciclo) {
          return _c("tr", [
            _c("td", [
              _c("a", { attrs: { href: ciclo.equipe.tarefa.link.href } }, [
                _vm._v(
                  "\n            " +
                    _vm._s(ciclo.equipe.tarefa.link.caption) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("td", [_vm._v(" " + _vm._s(ciclo.equipe.tarefa.nome) + " ")]),
            _vm._v(" "),
            _c("td", [_vm._v(" " + _vm._s(ciclo.equipe.unidade) + " ")]),
            _vm._v(" "),
            _c("td", [
              _c("a", { attrs: { href: ciclo.equipe.link.href } }, [
                _vm._v(
                  "\n            " +
                    _vm._s(ciclo.equipe.link.caption) +
                    "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("td", [
              _c("a", { attrs: { href: ciclo.link.href } }, [
                _vm._v(
                  "\n            " + _vm._s(ciclo.link.caption) + "\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c("td", [_vm._v(" " + _vm._s(ciclo.valor_total_unidades) + " ")]),
            _vm._v(" "),
            _c("td", [_vm._v(" " + _vm._s(ciclo.custo_mdo) + " ")]),
            _vm._v(" "),
            _c("td", [_vm._v(" " + _vm._s(ciclo.premio_pago_func) + " ")]),
            _vm._v(" "),
            _c("td", [_vm._v(" " + _vm._s(ciclo.premio_encarregado) + " ")]),
            _vm._v(" "),
            _c("td", [_vm._v(" " + _vm._s(ciclo.economia_gerada) + " ")]),
            _vm._v(" "),
            _c(
              "td",
              [
                _vm.temos_datas_pro_ciclo
                  ? _c("td-ciclo-muda", {
                      attrs: { datas: _vm.datas_pro_ciclo, ciclo_id: ciclo.id }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }