// opcao_mudar_ciclo.vue
<template>
  <div class="dropdown">
    <a href="#" class="dropdown-toggle btn btn-info waves-effect" data-toggle="dropdown" aria-expanded="false">Realocar ciclo</a>
    <ul class="dropdown-menu pull-right">
      <li v-for="data in datas" role="presentation">
        <a role="menuitem" tabindex="-1">
          <span v-on:click="mudaPagamento(data,ciclo_id)" > Mudar para {{ data }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>

  export default {
    name: 'td_ciclo_muda',
    props: ['datas', 'ciclo_id'],
    methods: {
      mudaPagamento: function (fecha,ciclo_id) {
        var that = this;
        $.ajax({
          method: 'GET',
          data: {
            data: fecha
          },
          url: '/ciclos/' + ciclo_id.toString() + '/mudar_pagamento.js',
          error: function(res) {
            if (res.status == 200 && res.statusText == 'OK') {
              window.location.reload();
            }
            else {
              mdnotify('Problemas', 'danger', 'top', 'right');
            }
          }
        })
      }
    },
    computed: {
      controle: function () {
        return 'controle!!'
      }
    }
  }

</script>
