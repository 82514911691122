// cr_raw_html.vue
<template>

  <div class="vertical-tree-container">
    <div class="top-wrapper">
      <div class="top-scrollbar"
           id="top-scroll-bar">
      </div>
    </div>
    <div class="content-wrapper">
      <div class="tree-content"
           id="tree-content">

        <div :class="options.class"
             :id="options.id"
             v-html="content">

        </div>

      </div>
    </div>
  </div>

</template>

<script>

  export default {
    name: 'CrRawHtml',
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        domid: (this.options.domid || 'fakedomid'),

        klass: 'cr-raw-html',
        content: this.options.options
      }
    },
    methods: {
      refresh: function () {
        if (! this.options.url) {return false} ;
        var that = this;
        $.ajax({
          method: 'get',
          url: that.options.url + '.json',
          success: function(res) {
            debugger
            that.$set(that, 'content', res.options);
          },
          error: function(res) {
          }
        });
      }
    },

    mounted: function() {
      $(function(){
        $(".top-wrapper").scroll(function(){
          $(".content-wrapper").scrollLeft($(".top-wrapper").scrollLeft());
        });
        $(".content-wrapper").scroll(function(){
          $(".top-wrapper").scrollLeft($(".content-wrapper").scrollLeft());
        });
        $("#top-scroll-bar").width(function() { return $('#table-tree').width() });
      });
    }

  }
</script>

