var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-login" }, [
    _c("h5", [_vm._v("Para continuar, favor se identificar")]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "m-t",
        attrs: { role: "form", method: "post", action: _vm.opts.login_url },
        on: { submit: _vm.put_dominio }
      },
      [
        _c("input", { attrs: { name: "utf8", type: "hidden", value: "✓" } }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "authenticity_token" },
          domProps: { value: _vm.opts.form_token }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "label",
            {
              staticClass: "string required",
              staticStyle: { width: "100%", "text-align": "left !important" },
              attrs: { id: "user_email", for: "user_email" }
            },
            [_vm._v("Email")]
          ),
          _vm._v(" "),
          _c("input", {
            ref: "email_input",
            staticClass: "form-control",
            attrs: { type: "text", placeholder: "Email", name: "user[email]" }
          })
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary block full-width m-b",
            attrs: { type: "submit" }
          },
          [_vm._v("Login")]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c(
        "label",
        {
          staticClass: "password required",
          staticStyle: { width: "100%", "text-align": "left !important" },
          attrs: { id: "user_password", for: "user_password" }
        },
        [_vm._v("Senha")]
      ),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: {
          type: "password",
          placeholder: "Senha",
          required: "",
          name: "user[password]"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }