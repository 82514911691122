<template>
  <div class="col-lg-4">
    <div class="ibox categoria">
      <div class="ibox-title">
        <div class="title-body">
          <h5>{{ cat.nome }}</h5>
          <div class="ibox-tools">
            <a v-if="cat.descricao"
               @click="show_desc = !show_desc">
              <i class="fa fa-info-circle info-cat"></i>
            </a>
          </div>
        </div>
        <small v-if="show_desc">{{ cat.descricao }}</small>
      </div>
      <div class="ibox-content table-responsive">
        <table class="table table-hover no-margins causas">
          <tbody>
            <tr v-for="(causa, index) in causas">
              <td class="nome-col">
                <div><strong>{{ causa.nome }}</strong></div>
                <div v-for="(subcausa, sindex) in causa.subcausas"
                     class="sub-causa-nome">
                  {{ subcausa.fullname }}
                </div>
              </td>
              <td class="trash-col">
                <a v-if="(causa.can_edit && causa.edit_url)"
                   data-remote="true"
                   :href="causa.edit_url">
                   <i class="fa fa-pencil"></i>
                </a>
                <a v-if="(causa.can_edit && causa.delete_url)"
                   data-remote="true"
                   :href="causa.delete_url">
                   <i class="fa fa-trash"></i>
                </a>
              </td>
            </tr>
            <tr v-if="cat.can_edit"
                class="button-add">
              <a class="btn btn-primary btn-xs"
                 data-remote="true"
                 :href="cat.new_url">
                Incluir Nova Causa
              </a>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

  // import LeafEtapan  from './leaf_etapan.vue'

  export default {
    name: 'CatCausas',
    components: {
      // 'leaf-etapan': LeafEtapan
    },
    props: ['categoria'],
    data: function () {
      return {
        klass: 'cat-causas',
        monted: false,

        show_desc: false,

        nada: 'nada'
      }
    },
    mounted: function() {
      // this.refresh() ;
      this.monted = true

    },
    computed: {
      cat: function() {
        return this.categoria
      },
      causas: function() {
        return (this.categoria ? this.categoria.causas : [])
      }
    },
    methods: {
      // newCausa: function() {

      // }
    }
  };
</script>
<style scoped>
  .ibox.categoria {
    border-style: solid;
    border-color: lightgray;
  }
  tr.button-add {
    text-align: right;
  }
  td.nome-col {
    width: 90%;
  }
  td.trash-col {
    width: 10%;
    font-size: large;
    padding-left: 0px;
    padding-right: 0px;
  }
  td.trash-col i{
    color: gray;
  }

  table.causas td {
    border-top: 0px;
  }

  .sub-causa-nome {
    margin-left: 10px;
  }

  .info-cat {
    font-size: x-large;
  }
/*  .title-body small {
    display: inline-block;
  }
  .categoria .ibox-title {
    display: flex;
  }
*/
</style>
