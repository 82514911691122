// noti_menu.vue
<template>
  <li class="dropdown">
    <a class="dropdown-toggle count-info"
       id="show_noto_btn"
       data-toggle="dropdown" href="#">
      <i :class="options.icon"></i>
      <span v-show="total_count > 0"
            class="label label-warning">{{total_count}}</span>
    </a>
<!--
    <div class="dropdown-menu dropdown-messages p-l-0 p-r-0">
 -->
    <div class="ibox dropdown-menu dropdown-messages">
      <div class="ibox-content p-0">

        <ul class="dropdown-messages p-0"
            style="height: auto; max-height: 330px; overflow-x: hidden;"
        >
          <div v-for="notificacao in notificacoes">
            <li>
              <div class="dropdown-messages-box">
                <a v-if="notificacao.user.url"
                   :href="notificacao.user.url"
                   class="pull-left p-l-10">
                  <img alt="image" class="img-circle" :src="secureUrl(notificacao.user.image_url)">
                </a>
                <a v-else
                   style="cursor: default;"
                   class="pull-left p-l-10">
                  <img alt="image" class="img-circle" :src="secureUrl(notificacao.user.image_url)">
                </a>
                <div class="media-body m-r-10">
    <!--
                  <strong>{{notificacao.user.nome}}</strong> {{notificacao.tkey}} <strong>{{ notificacao.trackable.to_s }}</strong>. <br>
     -->
                  <span :class="(notificacao.unread ? 'bold' : '')">{{notificacao.user.nome}}</span>
                  {{notificacao.tkey}}
                  <span :class="(notificacao.unread ? 'bold' : '')">{{ notificacao.trackable.to_s }}</span>. <br>



                  <small class="text-muted">{{ notificacao.time_ago }}</small>
                  <small class="pull-right">{{ notificacao.created_at }}</small>

                </div>
              </div>
            </li>
            <li class="divider"></li>
          </div>
        </ul>
      </div>
      <div class="ibox-footer p-0">
        <div class="text-center link-block">
          <a @click="markAllRead()">
            <i class="fa fa-envelope"></i> <strong>Marcar tudo como lido</strong>
          </a>
        </div>
      </div>

    </div>

  </li>
</template>


<script>

  // const store = new Vuex.Store(NotificacaoStore)

export default {
    name: 'NotiMenu',
    props: ['options'],
    data: function () {
      return {
        loaded: false,
        opts: this.options

        // store: NotificacaoStore
      }
    },
    mounted: function () {
      var that = this;
      moment.locale('pt-br');

      var configs = {}
      configs.url = this.options.datasource
      configs.read_all = this.options.read_all

      $('#show_noto_btn').click(function(e){
        that.loadMessages();
        e.preventDefault();
      });

      this.$store.dispatch(this.storeName + '/init', configs)
    },
    watch: {
    },
    computed: {
      aviso_showed: function () {
        return this.$store.getters['aviso_showed']
      },
      storeName: function () {
        return this.options.store_name ;
      },
      notificacoes: function() {
        return this.$store.getters[this.storeName + '/index']
      },
      total_count: function() {
        return this.$store.getters[this.storeName + '/total_count']
      }
    },
    methods: {
      loadMessages: function() {
        console.log('Load Messages clicado notificacoes')
        this.$store.dispatch(this.storeName + '/load')
      },
      markAllRead: function() {
        this.$store.dispatch(this.storeName + '/markAllRead', {})
      },
      secureUrl: function(url) {
        if (url.includes("http://cp")) {
          return url.replace('http:', 'https:') ;
        }
        return url
      }
    }
  }

</script>


