var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "right-sidebar" } }, [
    _c("div", { staticClass: "sidebar-container" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "tab-content" }, [
        _c("div", { staticClass: "tab-pane active", attrs: { id: "tab-1" } }, [
          _c("div", { staticClass: "sidebar-title" }, [
            _c("div", { staticClass: "p-b-5" }, [
              _vm._m(1),
              _vm._v(" "),
              _vm.unread_q > 0
                ? _c("small", [
                    _c("i", { staticClass: "fa fa-tim " }),
                    _vm._v(
                      "\n                Você tem " +
                        _vm._s(_vm.unread_q) +
                        " novas mensagens\n              "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary btn-sm btn-block waves-effect",
                  attrs: { href: _vm.options.msg_href, type: "button" }
                },
                [
                  _c("i", { staticClass: "fa fa-envelope-o p-r-5" }),
                  _vm._v("\n                Mensagens\n              ")
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            _vm._l(_vm.unread, function(msg, index) {
              return _vm.mounted
                ? _c(
                    "div",
                    {
                      staticClass: "sidebar-message",
                      attrs: { "xxxxv-for": "(msg, index) in box('unread')" }
                    },
                    [
                      _c("a", { attrs: { href: msg.href } }, [
                        _c("div", { staticClass: "pull-left text-center" }, [
                          _c("img", {
                            staticClass: "img-circle message-avatar",
                            attrs: { alt: "image", src: msg.sender.img }
                          })
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "media-body" }, [
                          _c("strong", [_vm._v(_vm._s(msg.sender.name) + ":")]),
                          _vm._v(
                            "\n                  " +
                              _vm._s(msg.subject) +
                              "\n                  "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v(_vm._s(msg.time_ago))
                          ])
                        ])
                      ])
                    ]
                  )
                : _vm._e()
            }),
            0
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "nav nav-tabs navs-3" }, [
      _c("li", { staticClass: "active" }, [
        _c("a", { attrs: { "data-toggle": "tab", href: "#tab-1" } }, [
          _vm._v("\n            Mensagens\n          ")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h3", { staticStyle: { float: "left" } }, [
      _c("i", { staticClass: "fa fa-comments-o" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }