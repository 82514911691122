var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "simple_form new_apontamento",
      attrs: {
        id: "apo-form-id",
        novalidate: "novalidate",
        action: _vm.url,
        method: "post",
        "accept-charset": "UTF-8"
      }
    },
    [
      _c("input", {
        attrs: { name: "_method", type: "hidden" },
        domProps: { value: _vm.method }
      }),
      _vm._v(" "),
      _c("input", { attrs: { name: "utf8", type: "hidden", value: "✓" } }),
      _vm._v(" "),
      _c("input", {
        attrs: {
          name: "authenticity_token",
          type: "hidden",
          id: "authenticity_token"
        },
        domProps: { value: _vm.authenticity_token }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body card-padding" }, [
            _c("div", { staticClass: "row" }, [
              _c("input", {
                attrs: { name: "apontamento[obra_id]", type: "hidden" },
                domProps: { value: _vm.model.obra_id }
              }),
              _vm._v(" "),
              _c("input", {
                attrs: { name: "apontamento[equipe_id]", type: "hidden" },
                domProps: { value: _vm.equipe_id }
              }),
              _vm._v(" "),
              _c("input", {
                attrs: {
                  name: "apontamento[equipeoriginal_id]",
                  type: "hidden"
                },
                domProps: { value: _vm.equipeoriginal_id }
              }),
              _vm._v(" "),
              _c("input", {
                attrs: { name: "apontamento[tarefa_id]", type: "hidden" },
                domProps: { value: _vm.model.tarefa_id }
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.data,
                    expression: "data"
                  }
                ],
                attrs: { name: "apontamento[data]", type: "hidden" },
                domProps: { value: _vm.data },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.data = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("input", {
                attrs: { name: "apontamento[apontador_id]", type: "hidden" },
                domProps: { value: _vm.model.apontador_id }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-1" }),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group datepicker required",
                    class: { error: _vm.errorsStr(_vm.model_e, "data") }
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _vm.model.servico_id != 0
                      ? _c("input", {
                          attrs: {
                            type: "hidden",
                            name: _vm.controlName(
                              "[servicos_attributes][][id]",
                              0
                            )
                          },
                          domProps: { value: _vm.model.servico_id }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "hidden",
                        name: _vm.controlName(
                          "[servicos_attributes][][local_id]",
                          0
                        )
                      },
                      domProps: { value: _vm.model.local_id }
                    }),
                    _vm._v(" "),
                    _c("input", {
                      attrs: {
                        type: "hidden",
                        name: _vm.controlName(
                          "[servicos_attributes][][tarefa_id]",
                          0
                        )
                      },
                      domProps: { value: _vm.model.tarefa_id }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-sm-6" },
                        [
                          _c("datepicker", {
                            attrs: {
                              format: "dd/MM/yyyy",
                              value: _vm.data_dt,
                              name: _vm.controlName(
                                "[servicos_attributes][][data]",
                                0
                              )
                            },
                            model: {
                              value: _vm.data_dt,
                              callback: function($$v) {
                                _vm.data_dt = $$v
                              },
                              expression: "data_dt"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(_vm._s(_vm.errorsStr(_vm.model_e, "data")))
                          ])
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._m(1)
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-4" }, [
                _c("div", {
                  attrs: { id: "desconsiderar_datas_btn" },
                  domProps: { innerHTML: _vm._s(_vm.desconsiderar_datas_btn) }
                })
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group decimais optional",
                    class: { error: _vm.errorsStr(_vm.servico_e, "percentual") }
                  },
                  [
                    _vm._m(2),
                    _vm._v(" "),
                    _c("input", {
                      staticClass: "decimal form-control",
                      attrs: {
                        type: "text",
                        "data-v-min": "0.00",
                        "data-v-max": "100.00",
                        "tipo-input": "decimais",
                        name: _vm.controlName(
                          "[servicos_attributes][][percentual]",
                          0
                        ),
                        id: "apontamento_percentual"
                      },
                      domProps: { value: _vm.model.percentual }
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm.errorsStr(_vm.servico_e, "percentual")))
                    ])
                  ]
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header ch-alt" }, [
            _c("div", { staticClass: "media-body m-t-5" }, [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-sm-2" },
                [
                  _c("btn-novo-funcionario", {
                    attrs: {
                      funcoes: _vm.funcoesPraNovo(),
                      funcionarios: _vm.funcionariosPraNovo()
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body card-padding" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-10" }, [
                _c("div", { staticClass: "form-group required" }, [
                  _vm._m(4),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.equipe_id,
                            expression: "equipe_id"
                          }
                        ],
                        staticClass: "select select2 input-control",
                        staticStyle: { width: "inherit" },
                        attrs: {
                          name: "apontamento[equipe_id]",
                          id: "aponta-equipe-select",
                          "data-placeholder": "Escolha uma equipe ..."
                        },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.equipe_id = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      _vm._l(_vm.equipes, function(eq) {
                        return _c("option", { domProps: { value: eq.value } }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(eq.text) +
                              "\n                  "
                          )
                        ])
                      }),
                      0
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _vm.equipe.da_casa
                  ? _c(
                      "div",
                      { staticClass: "col-sm-2" },
                      [
                        _c("btn-novo-funcionario", {
                          attrs: {
                            funcoes: _vm.funcoesPraNovo(),
                            funcionarios: _vm.funcionariosPraNovo()
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm.equipe.membros.length > 0
                ? _c(
                    "table",
                    {
                      staticClass: "table table-inner table-vmiddle",
                      attrs: { id: "table_membros" }
                    },
                    [
                      _vm._m(5),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        { attrs: { id: "membros" } },
                        _vm._l(_vm.equipe.membros, function(membro, index) {
                          return _c(
                            "tr",
                            {
                              attrs: {
                                id:
                                  "row_membro-membro_id" +
                                  membro.id +
                                  membro.calculo_equipe_id +
                                  membro.funcionario_id
                              }
                            },
                            [
                              _c("td", [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-icon-text waves-effect btn-danger kd_botao_remover btn btn-xs waves-effect",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.removeMembro(
                                          membro.id,
                                          membro.calculo_equipe_id,
                                          membro.funcionario_id,
                                          this
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Remover")]
                                ),
                                _vm._v(" "),
                                _vm.model.id != null && membro.id != 0
                                  ? _c("input", {
                                      attrs: {
                                        type: "hidden",
                                        name: _vm.controlName(
                                          "[membros_attributes][][id]",
                                          index
                                        )
                                      },
                                      domProps: { value: membro.id }
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("input", {
                                  attrs: {
                                    type: "hidden",
                                    name: _vm.controlName(
                                      "[membros_attributes][][funcionario_id]",
                                      index
                                    )
                                  },
                                  domProps: { value: membro.funcionario_id }
                                }),
                                _vm._v(" "),
                                _c("input", {
                                  attrs: {
                                    type: "hidden",
                                    name: _vm.controlName(
                                      "[membros_attributes][][_destroy]",
                                      index
                                    ),
                                    value: "0",
                                    id:
                                      "to_destroy-membro-membro_id" +
                                      membro.id +
                                      membro.calculo_equipe_id +
                                      membro.funcionario_id
                                  }
                                }),
                                _vm._v(" "),
                                _c("input", {
                                  attrs: {
                                    type: "hidden",
                                    name: _vm.controlName(
                                      "[membros_attributes][][calculo_equipe_id]",
                                      index
                                    )
                                  },
                                  domProps: { value: membro.calculo_equipe_id }
                                })
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-left" }, [
                                _vm._v(_vm._s(membro.nome))
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-left" }, [
                                _vm._v(_vm._s(membro.funcao))
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group decimais optional apontamento_membros_horas m-b-0",
                                    class: {
                                      error: _vm.errorsStr(
                                        _vm.membros_e[index],
                                        "horas"
                                      )
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.ja_alocadas("horas", membro)
                                          ) +
                                          "\n                    "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("vue-numeric", {
                                      staticClass: "form-control horas",
                                      attrs: {
                                        separator: ".",
                                        precision: 2,
                                        name: _vm.controlName(
                                          "[membros_attributes][][horas]",
                                          index
                                        ),
                                        id: "horas" + membro.funcionario_id
                                      },
                                      model: {
                                        value: membro.horas,
                                        callback: function($$v) {
                                          _vm.$set(membro, "horas", $$v)
                                        },
                                        expression: "membro.horas"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("input", {
                                      attrs: {
                                        type: "hidden",
                                        name: _vm.controlName(
                                          "[membros_attributes][][horas]",
                                          index
                                        )
                                      },
                                      domProps: { value: membro.horas }
                                    }),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "c-red" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errorsStr(
                                            _vm.membros_e[index],
                                            "horas"
                                          )
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group decimais optional apontamento_membros_extras m-b-0",
                                    class: {
                                      error: _vm.errorsStr(
                                        _vm.membros_e[index],
                                        "extras"
                                      )
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm.ja_alocadas("extras", membro)
                                          ) +
                                          "\n                    "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("vue-numeric", {
                                      staticClass: "form-control extras",
                                      attrs: {
                                        separator: ".",
                                        precision: 2,
                                        name: _vm.controlName(
                                          "[membros_attributes][][extras]",
                                          index
                                        ),
                                        id: "extras" + membro.funcionario_id
                                      },
                                      model: {
                                        value: membro.extras,
                                        callback: function($$v) {
                                          _vm.$set(membro, "extras", $$v)
                                        },
                                        expression: "membro.extras"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c("input", {
                                      attrs: {
                                        type: "hidden",
                                        name: _vm.controlName(
                                          "[membros_attributes][][extras]",
                                          index
                                        )
                                      },
                                      domProps: { value: membro.extras }
                                    }),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errorsStr(
                                            _vm.membros_e[index],
                                            "extras"
                                          )
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-4" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-danger waves-effect",
                    attrs: { href: _vm.link_volta_canteiro }
                  },
                  [_vm._v("\n\n              Desistir, voltar pro canteiro")]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-4" }),
              _vm._v(" "),
              _vm._m(6)
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "string required" }, [
      _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")]),
      _vm._v("\n                Data\n              ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-6" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-xs btn-primary waves-effect",
          attrs: { type: "submit" }
        },
        [_vm._v("Atualizar horas\n                  ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "string required" }, [
      _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")]),
      _vm._v("\n                Percentual já executado\n              ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-8" }, [
      _c("h2", [_vm._v("Funcionários alocados ao serviço")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "col-sm-2 string required text-right" }, [
      _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")]),
      _vm._v("\n                Equipe\n              ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "text-left", staticStyle: { width: "10%" } }, [
          _vm._v("Ação")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-left", staticStyle: { width: "50%" } }, [
          _vm._v("Funcionario")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-left", staticStyle: { width: "20%" } }, [
          _vm._v("Funcão")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-left", staticStyle: { width: "10%" } }, [
          _vm._v("Horas")
        ]),
        _vm._v(" "),
        _c("th", { staticClass: "text-left", staticStyle: { width: "10%" } }, [
          _vm._v("Extras")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-4" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { name: "doit", type: "submit" }
        },
        [_vm._v("Confirmar e voltar pro canteiro\n            ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }