var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "data_detail" }, [
    _c("div", { staticClass: "col-sm-6" }, [
      _c("div", { staticClass: "pmbb-view" }, [
        _c("dl", { staticClass: "dl-horizontal" }, [
          _c("dt", [_vm._v("Corte")]),
          _vm._v(" "),
          _c("dd", [_vm._v(" " + _vm._s(_vm.date.data) + " ")])
        ]),
        _vm._v(" "),
        _c("dl", { staticClass: "dl-horizontal" }, [
          _c("dt", [_vm._v("Pagamento")]),
          _vm._v(" "),
          _c("dd", [_vm._v(" " + _vm._s(_vm.date.pagamento) + " ")])
        ]),
        _vm._v(" "),
        _c("dl", { staticClass: "dl-horizontal" }, [
          _c("dt", [_vm._v("Total prêmio líquido")]),
          _vm._v(" "),
          _c("dd", [_vm._v(" " + _vm._s(_vm.date.totais.liquido) + " ")]),
          _vm._v(" "),
          _c("dt", [_vm._v("Total prêmio bruto")]),
          _vm._v(" "),
          _c("dd", [_vm._v(" " + _vm._s(_vm.date.totais.bruto) + " ")])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-6" }, [
      _c(
        "div",
        { staticClass: "pmbb-view" },
        [
          _vm.date.ciclos_nao_aprovados_href
            ? _c("dl", { staticClass: "dl-horizontal" }, [
                _c("dt", [_vm._v("Não aprovados")]),
                _vm._v(" "),
                _c("dd", [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.date.ciclos_nao_aprovados_href)
                    }
                  })
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("dl", { staticClass: "dl-horizontal" }, [
            _c("dt", [_vm._v("Status")]),
            _vm._v(" "),
            _c("dd", [_vm._v(" " + _vm._s(_vm.date.status) + " ")])
          ]),
          _vm._v(" "),
          _vm.date && !_vm.date.ciclos_nao_aprovados_href
            ? _c("btn-muda-status-data", {
                attrs: { dataid: _vm.date.id, mudarpara: _vm.date.mudar_para }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.date && _vm.date.btn_historico
            ? _c("dl", { staticClass: "dl-horizontal" }, [
                _c("dt", [_vm._v("Ver")]),
                _vm._v(" "),
                _c("dd", {
                  domProps: { innerHTML: _vm._s(_vm.date.btn_historico) }
                })
              ])
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }