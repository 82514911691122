

export default {
    namespaced: true,
    state: {
      user_id: 0,
      url: '',
      eventos: []

    },
    mutations: {
      init(state, data) {
        state.user_id     = data.user_id;
        state.url         = data.url;
        return state;
      },
      load(state, data) {
        state.eventos     = data.eventos;
        return state;
      }
    },
    actions: {
      init(context, conf) {
        context.commit('init', conf)
        context.dispatch('load')
      },
      // load inicial
      load(context) {
        var that = this
        $.ajax({
          url: context.state.url,
          data: {
          },
          type: 'get',
          format: 'json',
          success: function(data) {
            context.commit('load', data)
          },
          error: function(error) {
          },
          complete: function(response) {
          }
        })
      }
    },
    getters: {
      eventos: state => {
        return state.eventos ;
      }
    }
  }


