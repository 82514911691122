// import axios from 'axios'
// import customAxios from './../../utils/custom-axios.js'

// const csrfToken = document.querySelector("meta[name=csrf-token]").content
// customAxios.defaults.headers.common['Access-Token'] = csrfToken

const CurrentUserStore = {
  namespaced: true,
  state: {
    status: '',
    current_user: {
      // email: 'gustavo@43',
      // nome: 'Gustavo',
      // url: '/users/1',
      // id: 1
    },
    menus: []
  },
  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    SET_CURRENT_USER (state, curr_user) {
      state.current_user = curr_user
      return state;
    }
  },
  methods: {
    // mtoken: function() {
    //   return $('meta[name="csrf-token"]').attr('content');
    // }
  },
  actions: {
    set_current_user(context, curr) {
      context.commit('SET_CURRENT_USER', curr);
    },
    loadCurrentUser: function() {
      var that = this
      $.ajax({
        method: 'get',
        url: '/session_info.json',
        // data: {
        //   current_user: true,
        //   pro_users: true
        // },
        // type: 'get',
        success: function(data) {
          if (data && data.current) {
            that.dispatch('CurrentUserStore/set_current_user', data.current.user)

            // that.dispatch('ProjetoStore/setFromCurrent', data.current)
          }
        },
        error:  function(data) {
          // context.commit('init', data)
        }
      })
    }
  },
  getters : {
    current_user: state => {
      return state.current_user
    },
    isLoggedIn: state => {
      return (state.current_user.id > 0)
    }
  }
};

export default CurrentUserStore ;
