var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.klass }, [
    false
      ? _c("div", { staticClass: "mail-box-header" }, [
          _c("h2", [_vm._v("Observações")])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { key: _vm.contRefresh, staticClass: "mail-box" },
      [
        _vm.conversa
          ? _c(
              "div",
              _vm._l(_vm.conversa.messages, function(mess, index) {
                return _vm.conversa != null
                  ? _c("div", { staticClass: "social-feed-box" }, [
                      _c("div", { staticClass: "social-avatar" }, [
                        _c(
                          "a",
                          { class: _vm.euADireita(mess), attrs: { href: "" } },
                          [
                            _c("img", {
                              attrs: { alt: "image", src: mess.sender.img }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "media-body" }, [
                          _c("a", { attrs: { href: "#" } }, [
                            _vm._v(_vm._s(mess.sender.name))
                          ]),
                          _vm._v(" "),
                          _c("small", { staticClass: "text-muted" }, [
                            _vm._v(_vm._s(mess.time_ago))
                          ])
                        ])
                      ]),
                      _vm._v(" "),
                      _c("div", {
                        key: _vm.contRefresh,
                        staticClass: "social-body",
                        domProps: { innerHTML: _vm._s(mess.body) }
                      })
                    ])
                  : _vm._e()
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.can_reply
          ? _c("model-message-reply", {
              attrs: {
                conversa: _vm.conversa,
                model: _vm.opts.model,
                user: _vm.opts.user
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }