var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.model.opts
    ? _c(
        "div",
        { staticClass: "ibox" },
        [
          _vm.model.opts.title &&
          _vm.model.opts.title != "" &&
          _vm.model.opts.title != "no_title"
            ? _c("cr-title", {
                attrs: {
                  title: _vm.model.opts.title,
                  actions: _vm.model.opts.actions
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ibox-content" },
            _vm._l(_vm.model.opts.body, function(row, index) {
              return _c(
                "div",
                { key: index, staticClass: "row" },
                _vm._l(row, function(div, index2) {
                  return _c("cr-show-body-div", {
                    key: index,
                    class: div.class,
                    attrs: { options: div }
                  })
                }),
                1
              )
            }),
            0
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }