<template>

  <div id="right-sidebar">
    <div class="sidebar-container">
      <ul class="nav nav-tabs navs-3">
        <li class="active">
          <a data-toggle="tab" href="#tab-1">
            Mensagens
          </a>
        </li>
      </ul>

      <div class="tab-content">

        <div id="tab-1" class="tab-pane active">
          <div class="sidebar-title">
            <div class="p-b-5">
              <h3 style="float: left;">
                <i class="fa fa-comments-o"></i>
              </h3>
              <small v-if="unread_q > 0">
                <i class="fa fa-tim "></i>
                Você tem {{unread_q}} novas mensagens
              </small>
            </div>
            <div>
              <a :href="options.msg_href"
                 type="button"
                 class="btn btn-primary btn-sm btn-block waves-effect">

                <i class="fa fa-envelope-o p-r-5"></i>
                Mensagens
              </a>
            </div>
          </div>
          <div>

            <div v-if="mounted"
                 xxxxv-for="(msg, index) in box('unread')"
                 v-for="(msg, index) in unread"
                 class="sidebar-message">
              <a :href="msg.href">
                <div class="pull-left text-center">
                  <img alt="image"
                       class="img-circle message-avatar"
                       :src="msg.sender.img">
<!--
                  <div class="m-t-xs">
                    <i class="fa fa-star text-warning"></i>
                    <i class="fa fa-star text-warning"></i>
                  </div>
 -->
                </div>
                <div class="media-body">
                  <strong>{{ msg.sender.name }}:</strong>
                  {{ msg.subject }}
                  <br>
                  <small class="text-muted">{{ msg.time_ago }}</small>
                </div>
              </a>
            </div>

          </div>
        </div>
      </div>
    </div>


</div>


</template>

<script>
  // import inputUtils from 'comps/mixins/inputUtils.vue'

  import mbStore from 'packs/vuex/stores/mailboxer_store.js'
  import stUtils from 'packs/vuex/stores/utils_store.js'

  import { mapGetters } from 'vuex'

export default {
    name: 'RightPanel',
    mixins: [stUtils],
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        what: 'Im RightPanel!',

        owner_id: this.options.owner_id,
        msg_href: this.options.msg_href,

        mounted: false,
        storeName: 'mailBoxerStore'
      }
    },
    created: function () {
      this.initStore(this.storeName, mbStore, {user_id: this.owner_id})
      // Melhor carregar desde o store
      // this.dispach(this.storeName, 'load', {box: 'unread'})
      this.mounted = true
    },
    methods: {
    },
    computed: {
      ...mapGetters('mailBoxerStore', {
        box: 'box',
        unread: 'unread',
        unread_q: 'unread_q'
      })
    }
  }
</script>
