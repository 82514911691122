// quem_e_quem_informes.vue
<template>
  <div v-if="mounted"
       class="quemequem row col-sm-12">
    <div class="col-sm-12 header p-b-20">
      <h1 class="col-sm-2"></h1>
      <div class="col-sm-4">
        <cmp-v-select
          :options="asetores"
          :value="selected_setor_index"
          v-model="selected_setor_index"
        ></cmp-v-select>
      </div>
    </div>

    <div v-if="setor">
      <div v-for="(informe, index) in setor.informes">
        <div class="ibox col-sm-12">
          <div class="ibox-title">
            <h5>{{informe.nome}}</h5>
            <div class="ibox-tools">
              <span>{{data_br(informe.data)}}</span>
            </div>
          </div>
          <div class="ibox-content">
            <img class="quem-e-quem"
               :src="informe.imagem_url">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

  import CmpVSelect         from 'packs/components/comp/cmp-v-select.vue'

  export default {
    name: 'QuemEQuemInformes',
    props: ['options'],
    data: function () {
      return {
        mounted: false,
        menu_ativo: [],

        asetores: this.options.setores,
        selected_setor_index: null
      }
    },
    components: {
      'cmp-v-select'    : CmpVSelect
    },
    methods: {
      data_br: function(data) {
        return moment(data).format('DD/MM/YYYY');
      }
    },
    computed: {
      setor: function () {
        if (this.selected_setor_index > -1) {
          return this.asetores[this.selected_setor_index]
        } else {
          return null
        }
      }
    },
    mounted: function() {
      if ((this.selected_setor_index != "undefined") && (this.selected_setor_index > -1)) {
        this.$set(this, 'selected_setor_index', this.asetores[0].value)
      }
      this.mounted = true
    }
  }
</script>

<style scoped>

</style>

