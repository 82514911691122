var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vertical-tree-container" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "content-wrapper" }, [
      _c(
        "div",
        { staticClass: "tree-content", attrs: { id: "tree-content" } },
        [
          _c("div", {
            class: _vm.options.class,
            attrs: { id: _vm.options.id },
            domProps: { innerHTML: _vm._s(_vm.content) }
          })
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-wrapper" }, [
      _c("div", {
        staticClass: "top-scrollbar",
        attrs: { id: "top-scroll-bar" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }