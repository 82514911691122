// module_store.vue

<script>
  export default {
    methods: {
      mountCustomStore: function(storeName, tomountStore, intOpts) {
        const store = this.$store;

        // Carrega o módulo da store
        if (!(store && store.state && store.state[storeName])) {
          store.registerModule(storeName, tomountStore);
        } else {
          console.log(`reusing module: ${storeName}`);
        }

        // Inicia com parametros da obra
        store.dispatch( storeName + '/init', intOpts)
      },
    }
  }
</script>

