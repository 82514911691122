var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { display: "inline-block" } }, [
    _c(
      "a",
      {
        staticClass: "dropdown-toggle",
        attrs: {
          "data-toggle": "dropdown",
          "data-placement": "auto top",
          title: _vm.options.others.title,
          href: "#",
          "aria-expanded": "false"
        }
      },
      [_c("i", { class: _vm.options.icon_class })]
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "dropdown-menu dropdown-menu-right" },
      _vm._l(_vm.options.d_actions, function(subact) {
        return _c("li", [
          _c("a", { attrs: { href: subact.href } }, [
            _vm._v(_vm._s(subact.caption))
          ])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }