// mailboxer/utils.vue
<script>

export default {
    methods: {
      setAction: function(action, folder) {
        this.$parent.$emit('change_email_action', {action: action, folder: folder})
      },
      folderCaption: function(folder) {
        if (folder == 'inbox') {
          return 'Caixa de Entrada'
        }
        if (folder == 'sent') {
          return 'Enviadas'
        }
        if (folder == 'trash') {
          return 'Lixeira'
        }
      },
      replyMsg: function(conv) {
        this.dispach(this.storeName, 'readConv', {conv: conv, folder: 'inbox'})
        this.$parent.conv_to_reply = conv
        this.setAction('compose')
        this.$parent.$refs.compose.loadReply()

      }
    }
  }

</script>
