var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "col-sm-12", class: _vm.klass, style: _vm.c_ul_style },
    _vm._l(_vm.options.options, function(btn) {
      return _c("li", {
        style: _vm.c_li_style,
        domProps: { innerHTML: _vm._s(btn) }
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }