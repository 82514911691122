var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { id: "btn_novo_membro" } }, [
      _c(
        "button",
        {
          staticClass: "btn btn-info waves-effect waves-effect",
          attrs: { type: "button" },
          on: { click: _vm.showModalForm }
        },
        [_vm._v("\n\n        Incluir funcionário\n      ")]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "modal-novo-funcionario",
        staticClass: "modal fade in",
        staticStyle: { display: "none" },
        attrs: {
          id: "modal-novo-funcionario",
          role: "dialog",
          "aria-hidden": "false"
        }
      },
      [
        _c("div", { staticClass: "modal-dialog modal-lg" }, [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body card-padding" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-group funcao_id optional novo_membro_funcao_id"
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "funcao_id optional",
                          attrs: { for: "novo_membro_funcao_id" }
                        },
                        [_vm._v("Função")]
                      ),
                      _vm._v(" "),
                      _c("cmp-v-select", {
                        attrs: {
                          input_class: "form-control select select2",
                          options: this.lFuncoes2Sel,
                          required: true,
                          multiple: false
                        },
                        model: {
                          value: _vm.funcao_id,
                          callback: function($$v) {
                            _vm.funcao_id = $$v
                          },
                          expression: "funcao_id"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-4" }, [
                  _c(
                    "div",
                    { staticClass: "form-group boolean optional permanente" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "boolean optional",
                          attrs: { for: "user_ativo" }
                        },
                        [_vm._v("Membro Permanente")]
                      ),
                      _vm._v(" "),
                      _c("label", { staticClass: "checkbox" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.permanente,
                              expression: "permanente"
                            }
                          ],
                          attrs: {
                            checked: "checked",
                            id: "permanente",
                            type: "checkbox",
                            "true-value": 1,
                            "false-value": 0
                          },
                          domProps: {
                            checked: Array.isArray(_vm.permanente)
                              ? _vm._i(_vm.permanente, null) > -1
                              : _vm._q(_vm.permanente, 1)
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.permanente,
                                $$el = $event.target,
                                $$c = $$el.checked ? 1 : 0
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.permanente = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.permanente = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.permanente = $$c
                              }
                            }
                          }
                        }),
                        _vm._v(" "),
                        _c("i", { staticClass: "input-helper" })
                      ])
                    ]
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-8" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "form-group funcionario_id optional novo_membro_funcionario_id"
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "funcionario_id optional",
                          attrs: { for: "novo_membro_funcionario_id" }
                        },
                        [_vm._v("Funcionário")]
                      ),
                      _vm._v(" "),
                      _c("cmp-v-select", {
                        attrs: {
                          input_class: "form-control select select2",
                          options: _vm.lFuncionarios2Sel,
                          required: true,
                          multiple: false
                        },
                        model: {
                          value: _vm.funcionario_id,
                          callback: function($$v) {
                            _vm.funcionario_id = $$v
                          },
                          expression: "funcionario_id"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn btn-default primary",
                  attrs: { name: "button" },
                  on: { click: _vm.alocaFuncionario }
                },
                [_vm._v("\n            Alocar Funcionário\n          ")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn",
                  attrs: { "data-dismiss": "modal", href: "#" }
                },
                [_vm._v("Cancelar")]
              )
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", { staticClass: "modal-title" }, [_vm._v("Novo Funcionário")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }