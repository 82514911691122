// ibox2.vue
<template>
  <div class=" float-e-margins"
       :class="localWidth"
       style="border-width: 1px; border-color: lightgray; border-style: solid; display: table"
  >
    <div class="ibox-title ui-sortable"
         style="border-top-width: 1px;">
      <slot name="ibox-title"></slot>
      <div class="ibox-tools">
        <a class="collapse-link">
          <i class="fa fa-chevron-up"></i>
        </a>
<!--
        <a class="dropdown-toggle" data-toggle="dropdown" href="#">
          <i class="fa fa-gear"></i>
        </a>
        <ul class="dropdown-menu dropdown-user">
          <li><a @click="localWidth = 'col-sm-12'"
              >Largo 100%</a>
          <li><a @click="localWidth = 'col-sm-6'"
              >Largo 1/2</a>
          <li><a @click="localWidth = 'col-sm-3'"
              >Largo 1/4</a>
          </li>
        </ul>
 -->
<!--
        <a class="close-link">
          <i class="fa fa-times"></i>
        </a>
 -->
      </div>


    </div>

    <slot name="ibox-content"></slot>

    </slot>
  </div>
</template>

<script>

  export default {
    name: 'IBox2',
    components: {
    },
    props: ['options'],

    data: function () {
      return {
      }
    },

    mounted: function() {
    },

    methods: {
    },

    computed: {
      localWidth: function() {
        if (this.options) {
          return (this.options.class_with || 'col-sm-6');
        } else {
          return 'col-sm-6';
        }
      }
    }

  };
</script>
