// data_store.vue
<script>

  export default {
    methods: {
      initStore: function(planStore) {
        const store = this.$store;

        // Carrega o módulo da store
        if (!(store && store.state && store.state[this.storeName])) {
          store.registerModule(this.storeName, planStore);
        } else {
          console.log(`reusing module: ${this.storeName}`);
        }

        // Inicia com parametros da obra
        this.$store.dispatch( this.storeName + '/init', {
          user_id: this.options.user_id,
          url:     this.options.url
        })
      },
      selectEquipe: function(equipe) {
        this.$store.dispatch( this.storeName + '/selectEquipe', equipe)
      },
      selectTodas: function(bool_value) {
        this.select.todas = ! this.select.todas ;
        this.$store.dispatch( this.storeName + '/selectTodas',
          this.select.todas)
      }
    },

    computed: {
    }

  }
</script>

