// cr_img_gallery.vue
<template>
  <div :class="options.class">
  </div>
</template>

<script>

  // Congelada, sem gallery

  export default {
    name: 'CrImgGallery',
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'cr-raw-html',

        index: null
      }
    }
  }
</script>

