var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.equipes.length > 0
    ? _c("div", [
        _c("div", { staticClass: "ms-search hidden-xs p-t-0" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("label", {
            staticClass: "checkbox checkbox-inline m-r-20 hidden"
          }),
          _vm._v(" "),
          _c("label", { staticClass: "checkbox checkbox-inline m-r-20" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.eq_so_minhas,
                  expression: "eq_so_minhas"
                }
              ],
              staticClass: "m-r-10",
              attrs: { type: "checkbox", "true-value": 1, "false-value": 0 },
              domProps: {
                checked: Array.isArray(_vm.eq_so_minhas)
                  ? _vm._i(_vm.eq_so_minhas, null) > -1
                  : _vm._q(_vm.eq_so_minhas, 1)
              },
              on: {
                change: function($event) {
                  var $$a = _vm.eq_so_minhas,
                    $$el = $event.target,
                    $$c = $$el.checked ? 1 : 0
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.eq_so_minhas = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.eq_so_minhas = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.eq_so_minhas = $$c
                  }
                }
              }
            }),
            _vm._v(" "),
            _c("i", { staticClass: "input-helper" }),
            _vm._v("\n      Minhas equipes\n    ")
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "checkbox checkbox-inline m-r-20" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.eq_so_dacasa,
                  expression: "eq_so_dacasa"
                }
              ],
              staticClass: "m-r-10",
              attrs: { type: "checkbox", "true-value": 1, "false-value": 0 },
              domProps: {
                checked: Array.isArray(_vm.eq_so_dacasa)
                  ? _vm._i(_vm.eq_so_dacasa, null) > -1
                  : _vm._q(_vm.eq_so_dacasa, 1)
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.eq_so_dacasa,
                      $$el = $event.target,
                      $$c = $$el.checked ? 1 : 0
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.eq_so_dacasa = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.eq_so_dacasa = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.eq_so_dacasa = $$c
                    }
                  },
                  _vm.checaSoEmpreiteiraOuDaCasa
                ]
              }
            }),
            _vm._v(" "),
            _c("i", { staticClass: "input-helper" }),
            _vm._v("\n      Equipes da casa\n    ")
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "checkbox checkbox-inline m-r-20" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.eq_so_empreiteiras,
                  expression: "eq_so_empreiteiras"
                }
              ],
              staticClass: "m-r-10",
              attrs: { type: "checkbox", "true-value": 1, "false-value": 0 },
              domProps: {
                checked: Array.isArray(_vm.eq_so_empreiteiras)
                  ? _vm._i(_vm.eq_so_empreiteiras, null) > -1
                  : _vm._q(_vm.eq_so_empreiteiras, 1)
              },
              on: {
                change: [
                  function($event) {
                    var $$a = _vm.eq_so_empreiteiras,
                      $$el = $event.target,
                      $$c = $$el.checked ? 1 : 0
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.eq_so_empreiteiras = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.eq_so_empreiteiras = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.eq_so_empreiteiras = $$c
                    }
                  },
                  _vm.checaSoEmpreiteiraOuDaCasa
                ]
              }
            }),
            _vm._v(" "),
            _c("i", { staticClass: "input-helper" }),
            _vm._v("\n      Empreiteiras\n    ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "fg-line p-t-5" }, [
            _c("i", { staticClass: "zmdi zmdi-search" }),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.eq_filtro,
                  expression: "eq_filtro"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "Filtrar..." },
              domProps: { value: _vm.eq_filtro },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.eq_filtro = $event.target.value
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "list-group",
            staticStyle: { "overflow-y": "auto", height: "600px" }
          },
          _vm._l(_vm.equipes_to_show, function(eq) {
            return _c(
              "a",
              {
                staticClass: "list-group-item media",
                class: { active: eq == _vm.equipe },
                attrs: { equipe: "eq.id" },
                on: {
                  click: function($event) {
                    return _vm.setaEquipe(eq)
                  }
                }
              },
              [
                _c("div", { staticClass: "media-body" }, [
                  _c("div", { staticClass: "lgi-heading" }, [
                    _vm._v("\n          " + _vm._s(eq.nome) + "\n        ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "lgi-text era-small" }, [
                    _vm._v(
                      "\n          " + _vm._s(eq.tarefa_str) + "\n        "
                    )
                  ])
                ])
              ]
            )
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("b", [_vm._v("Mostrar apenas:")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }