var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-forgot-pass" }, [
    _c("h5", { staticClass: "font-bold" }, [_vm._v("Esqueceu a sua senha")]),
    _vm._v(" "),
    _c("p"),
    _c("h5", [
      _vm._v("Informe seu email e receberá instruções para configurar uma nova")
    ]),
    _vm._v(" "),
    _c("p"),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "m-t",
        attrs: {
          role: "form",
          method: "post",
          action: _vm.opts.forgot_pass_url
        }
      },
      [
        _c("input", { attrs: { name: "utf8", type: "hidden", value: "✓" } }),
        _vm._v(" "),
        _c("input", {
          attrs: { type: "hidden", name: "authenticity_token" },
          domProps: { value: _vm.opts.form_token }
        }),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary block full-width m-b",
            attrs: { type: "submit" }
          },
          [_vm._v("Cadastrar nova senha")]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-group" }, [
      _c(
        "label",
        {
          staticClass: "string required",
          staticStyle: { width: "100%", "text-align": "left !important" },
          attrs: { id: "user_email", for: "user_email" }
        },
        [_vm._v("Email")]
      ),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: {
          type: "email",
          placeholder: "Email",
          required: "",
          name: "user[email]"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }