var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-sm-2 p-l-0" }, [
      _c("div", { staticClass: "ibox float-e-margins m-0" }, [
        _c(
          "div",
          {
            staticClass: "ibox-content relativo p-l-0 p-r-0",
            attrs: { onload: _vm.paintDias(), id: "cmp-datepicker-container" }
          },
          [
            _c("cmp-datepicker", {
              attrs: {
                inline: true,
                onUpdateHandler: this.calendarUpdate,
                onChangeHandler: this.calendarUpdate,
                onShowHandler: this.calendarShow,
                id: "dp210"
              },
              model: {
                value: _vm.data,
                callback: function($$v) {
                  _vm.data = $$v
                },
                expression: "data"
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-10" }, [
      !_vm.no_record_msg
        ? _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("cr-show", {
                ref: "show_ref",
                attrs: { opts: _vm.cr_show_opts }
              })
            ],
            1
          )
        : _c("div", { staticClass: "col-sm-12" }, [
            _c("h1", [
              _vm._v("\n          " + _vm._s(_vm.no_record_msg) + "\n        ")
            ])
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }