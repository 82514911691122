var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "feed-element", class: _vm.klass }, [
    _c("div", { staticClass: "media-body " }, [
      _c("a", { attrs: { href: _vm.eta.href } }, [
        _c("strong", [
          _vm._v(_vm._s(_vm.eta.codigo) + ": " + _vm._s(_vm.eta.nome))
        ]),
        _c("br")
      ]),
      _vm._v(" "),
      _c("div", { domProps: { innerHTML: _vm._s(_vm.eta.foot_line) } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "icons-counts" },
        _vm._l(_vm.eta.icon_counts, function(icon) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: icon.value > 0,
                  expression: "icon.value > 0"
                }
              ],
              staticClass: "icon-count-wrapper"
            },
            [
              _c("i", {
                class: icon.icon_class,
                attrs: {
                  "data-toggle": "tooltip",
                  "data-placement": "top",
                  title: icon.tooltip
                }
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "label badge label-info icon-counts" },
                [_vm._v(_vm._s(icon.value))]
              )
            ]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }