var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row clearfix p-b-10" },
    [
      _c("ibox-list", { attrs: { options: _vm.optionsToIbox() } }),
      _vm._v(" "),
      _vm.outrosUsuarios.length > 0 && !_vm.incluindo
        ? _c("div", { staticClass: "pull-right text-right ibox-tools" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-primary btn-xs",
                on: {
                  click: function($event) {
                    _vm.incluindo = true
                  }
                }
              },
              [
                _c("i", { staticClass: "fa fa-eye" }),
                _vm._v("\n      Incluir seguidor\n    ")
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      false && _vm.outrosUsuarios.length > 0 && _vm.incluindo
        ? _c("div", { staticClass: "pull-right text-right ibox-tools" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-primary btn-xs",
                on: {
                  click: function($event) {
                    _vm.incluindo = false
                  }
                }
              },
              [_vm._v("\n      Concluir inclusão\n    ")]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.outrosUsuarios.length > 0 && _vm.incluindo
        ? _c("ibox-list", { attrs: { options: _vm.optionsOutrosToIbox() } })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }