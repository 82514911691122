<script>
  import {Line} from 'vue-chartjs'

  export default {
    extends: Line,
    props: ['options'],
    mounted: function() {
      this.drawChart()
    },
    methods: {
      drawChart: function() {
        var that = this
        this.$nextTick(function () {
          that.renderChart(that.options.data, that.options.options)
        })
      }
    }
  }
</script>
