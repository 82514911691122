// desempenho/item_perc.vue
<template>
  <div v-if="loaded && showing"
       class="p-10">
    <h4 class="text-center">{{ item.indicador.nome }}</h4>

    <vc-bar-chart
      :id="indicador.valor_id"
      :options="opts2Chart"
      ref="thechart">
    </vc-bar-chart>

  </div>
</template>

<script>
//  import VcBarChart from 'packs/components/charts/vc-bar-chart.vue';

  export default {
    name: 'item_perc',
    props: ['item'],
    components: {
      // 'vc-bar-chart':  VcBarChart
    },
    data: function () {
      return {
        // loaded: true,
        storeName: 'DesempenhoStore',

        showing: true,

        indi_index: this.item.indicador.id
      }
    },
    mounted: function() {
      this.refresh()
    },
    updated: function() {
      var that = this
    },
    computed: {
      loaded: function() {
        return this.$store.getters[this.storeName + '/loaded']
      },
      indicador: function() {
        let indicadores = this.$store.getters[this.storeName + '/indicadores']
        return indicadores[this.indi_index]
      },
      opts2Chart: function() {
        return {
          options: this.barOptions(),
          data: this.barData()
        }
      }
    },
    watch: {
      loaded: function(new_loaded) {
        this.refresh()
      }
    },
    methods: {
      refresh: function() {
        var that = this
        // that.$set(that, 'showing', false)

        this.$nextTick(function () {
          that.$set(that, 'showing', true)
        })

        if (this.$refs.thechart) {
          console.log('Refreshing')
          this.$refs.thechart.drawChart()
        }
      },
      aquiMetodoDummy: function() {
        return 'faznada' ;
      },
      barData: function() {
        return {

          labels: [
            this.indicador.valor_s,
            'Meta ' + this.indicador.indicador.meta_s,
          ],

          datasets: [
            {
              label: false,

              backgroundColor: [
                this.indicador.indicador.cor_meta,
                this.indicador.indicador.cor_valor
              ],

              data: [
                Number(this.indicador.valor),
                Number(this.indicador.indicador.meta)
              ]
            }
          ]
        }
      },
      barOptions: function() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [{
              stacked: true
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                min: 0,
                max: 100
              }
            }]
          },

          legend: {
            display: false,
          }

        }
      }
    }
  }

</script>
