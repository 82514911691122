var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-shared-links" }, [
    _vm.action == "login"
      ? _c("div", [
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  _vm.action = "forgot_pass"
                }
              }
            },
            [_vm._v("\n      Esqueceu a sua senha?\n    ")]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.action == "forgot_pass" || _vm.action == "change_pass"
      ? _c("div", [
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  _vm.action = "login"
                }
              }
            },
            [_vm._v("\n      Iniciar sessão (Login)\n    ")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }