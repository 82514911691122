var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.eventos.length > 0
    ? _c(
        "ibox2",
        {
          key: _vm.refreshIndex,
          staticClass: "ibox",
          attrs: { options: { class_with: "nenhuma" } }
        },
        [
          _c("div", { attrs: { slot: "ibox-title" }, slot: "ibox-title" }, [
            _c("h5", [_vm._v("Agenda")])
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "ibox-content inspinia-timeline",
              attrs: { slot: "ibox-content" },
              slot: "ibox-content"
            },
            _vm._l(_vm.eventos, function(evento, index) {
              return _vm.eventos.length > 0
                ? _c("div", { staticClass: "timeline-item" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-xs-2 date" }, [
                        _c("i", { class: evento.i_class }),
                        _vm._v(" "),
                        _c("span", { class: evento.data_color }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(evento.data) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("span", { class: evento.data_color }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(evento.horario) +
                              "\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c("small", { staticClass: "text-navy" }, [
                          _vm._v(_vm._s(evento.horario_relativo))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-xs-9 content no-top-border" },
                        [
                          _c("p", { staticClass: "m-b-xs" }),
                          _c("div", {
                            domProps: { innerHTML: _vm._s(evento.title) }
                          }),
                          _vm._v(" "),
                          _c("p"),
                          _vm._v(" "),
                          _c("p", {
                            domProps: { innerHTML: _vm._s(evento.html) }
                          })
                        ]
                      )
                    ])
                  ])
                : _vm._e()
            }),
            0
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }