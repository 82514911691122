// novo_apo.vue
<template>
  <div v-if="apontamento.equipe_id && (apontamento.equipe_id > 0)"
       id="novo_apo"
       class="card">
    <div class="card-header ch-alt">
      <div class="media-body m-t-5">
        <div class="col-sm-10">
          <h2>{{ titleNovoApo }}</h2>
        </div>
      </div>
    </div>
    <div class="card-body card-padding">
      <div v-if="apontamento.data"
           class="row">
        <span v-if="errorsStr(model_e, 'data')" style="color: red">Data: {{ errorsStr(model_e, 'data') }}</span>

        <form class="col-sm-3 form-horizontal">


          <div class="form-group">
            <label class="control-label col-sm-2" for="select_local">Local</label>
            <div class="col-sm-10">

              <cmp-select2
                :adata="locais2Sel"
                v-model="servico.local_id"
                value="servico.local_id"
                :options="{
                  placeholder: 'Selecione um local' }">
              </cmp-select2>

            </div>
          </div>
        </form>

        <form class="col-sm-3 form-horizontal">
          <div class="form-group"
               v-bind:class="{ 'error': errorsStr(servico_e, 'percentual') }">
            <label class="control-label col-sm-4" for="apo_perc">Percentual</label>

            <div class="col-sm-6">
              <vue-numeric v-if="limites_medicao_terceiros.length == 0"
                class="form-control "
                id="apo_perc"
                separator="." :precision="2"
                v-model="servico.percentual"
              ></vue-numeric>

              <cmp-select2 v-if="limites_medicao_terceiros.length > 0"
                :adata="limites_medicao_terceiros"
                v-model="servico.percentual"
                sssvalue="servico.local_id"
                :options="{}">
              </cmp-select2>

              <span>{{ errorsStr(servico_e, 'percentual') }}</span>
            </div>
          </div>
        </form>

        <div v-if="equipe.da_casa"
             class="col-sm-2">
          <btn-novo-funcionario
            :funcoes="funcoesPraNovo()"
            :funcionarios="funcionariosPraNovo()"
          >
          </btn-novo-funcionario>
        </div>

      </div>

      <div class="row">
        <table  v-if="equipe.membros && (equipe.membros.length > 0)"
                class="table table-inner table-vmiddle"
                id="table_membros">
          <thead>
            <tr>
              <th class="text-left" style="width: 5%">Ação</th>
              <th class="text-left" style="width: 50%">Funcionario</th>
              <th class="text-left" style="width: 15%">Funcão</th>
              <th class="text-left" style="width: 15%">Horas</th>
              <th class="text-left" style="width: 15%">Extras</th>
            </tr>
          </thead>
          <tbody id="membros">
            <tr v-for="(membro, index) in equipe.membros"
                :id="'row_membro-membro_id' + membro.id + membro.calculo_equipe_id + membro.funcionario_id">
              <td>
<!--
                <button class="btn-icon-text waves-effect btn-danger kd_botao_remover btn btn-xs waves-effect"
                        v-on:click="removeMembro(membro.id, membro.calculo_equipe_id, membro.funcionario_id, this)"
                        type="button">Remover</button>
 -->
                <div class="dropdown">
                  <a href="#" class="dropdown-toggle kd_botao_remover btn btn-xs btn-danger waves-effect" data-toggle="dropdown" aria-expanded="false">Remover</a>
                  <ul class="dropdown-menu">
                      <li role="presentation">
                        <a role="menuitem"
                           tabindex="-1"
                           v-on:click="removeMembro(membro.funcionario_id)">
                          Remover
                        </a>
                      </li>
                      <li role="presentation">
                        <a role="menuitem"
                           tabindex="-1"
                           v-on:click="removeMembroDaEquipe(membro.funcionario_id)">
                          Remover da equipe
                        </a>
                      </li>
                  </ul>
                </div>

              </td>

              <td class="text-left">{{ membro.nome }}</td>
              <td class="text-left">{{ membro.funcao }}</td>
              <td>
                <div class="form-group decimais optional apontamento_membros_horas m-b-0"
                     v-bind:class="{ 'error': errorsStr(membros_e[index], 'horas') }">
                  <span>
                    {{ ja_alocadas('horas', membro) + estimadas(membro) }}
                  </span>

                  <vue-numeric
                    class="form-control "
                    separator="." :precision="2"
                    v-model="equipe.membros[index].horas"
                  ></vue-numeric>

                  <span>{{ errorsStr(membros_e[index], 'horas') }}</span>
                </div>
              </td>
              <td>
                <div class="form-group decimais optional apontamento_membros_extras m-b-0"
                     v-bind:class="{ 'error': errorsStr(membros_e[index], 'extras') }">
                  <span>
                    {{ ja_alocadas('extras', membro) }}
                  </span>

                  <vue-numeric
                    class="form-control "
                    separator="." :precision="2"
                    v-model="membro.extras"
                  ></vue-numeric>

                  <span>{{ errorsStr(membros_e[index], 'extras') }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="apontamento.data"
             class="col-md-4 col-md-offset-8">
          <button class="btn btn-primary waves-effect"
                  @click="postApontamento">
            Confirmar Apontamento
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import errorStr from       'packs/components/mixins/errorStr.vue'
  import numberMask from     'packs/components/mixins/numberMask.vue'
  import inputUtils from     'packs/components/mixins/inputUtils.vue'
  import cmpAutonumeric from 'packs/components/comp/cmp-autonumeric.vue'
  import CmpSelect2  from    'packs/components/comp/cmp-select2.vue'

  import Datepicker from 'vuejs-datepicker';
  import VueNumeric from 'vue-numeric'

  import BtnNovoFuncionario from './btn_novo_funcionario.vue'

  export default {
    name: 'novo_apo',
    // props: ['equipe_id'],
      // 'cmp-autonumeric'      : cmpAutonumeric,
    components: {
      'cmp-select2'          : CmpSelect2,
      'cmp-autonumeric'      : cmpAutonumeric,
      'vue-numeric'          : VueNumeric,
      'datepicker'           : Datepicker,
      'btn-novo-funcionario' : BtnNovoFuncionario
    },
    mixins: [errorStr, numberMask, inputUtils],
    data: function () {
      return {
        obra: null,
        data: null,
        equipe: {
          membros: []
        },
        limites_medicao_terceiros: [],
        locais: [],

        apontamento: {
          obra_id: 0,
          equipe_id: 0,
          tarefa_id: 0,
          data: null,
          servicos: [
            {}
          ],
          membros: [
            {}
          ]
        },
        servico: {
          local_id: 0,
          tarefa_id: 0,
          data: null,
          percentual: 100
        },

        recordToPost: {},

        errors    : {},
        model_e   : {},
        servico_e : {},
        membros_e : {}
      }
    },
    mounted: function () {
      var that = this ;
      this.$parent.$on('painel_apo_novo_apo', function (novo_apo) {
        that.setaNovoApo(novo_apo) ;
      });
      this.$parent.$on('painel_apo_novo_funcionario', function (funcionario_id) {
        that.addNovoFuncionario(novo_func) ;
      });
      this.delayInitCompos() ;
    },
    methods: {
      delayInitCompos() {
        this.$nextTick(function () {
          // ativaJSInputs()
          this.initCompos() ;
        });
      },
      setaNovoApo: function (n_apo) {
        if (n_apo) {
          if (n_apo.equipe) {
            this.equipe  = n_apo.equipe ;
            this.apontamento.tarefa_id  = n_apo.equipe.tarefa_id ;
            this.apontamento.equipe  = n_apo.equipe ;
            this.apontamento.equipe_id  = n_apo.equipe.id ;
            this.loadLocais();
          }

          if (n_apo.apo) {
            this.apontamento.data       = moment(n_apo.apo.data, "YYYY-MM-DD").format('DD/MM/YYYY');
            this.data = this.apontamento.data;
          }

          if (n_apo.obra) {
            this.obra = n_apo.obra ;
            this.apontamento.obra_id = n_apo.obra.id ;
          }

        } else {
          this.equipe = { membros: [] };
          this.apontamento = {
            obra_id: 0,
            equipe_id: 0,
            tarefa_id: 0,
            data: null,
            servicos: [ {} ],
            membros:  [ {} ]  };
        }

        this.loadEquipe();
      },
      loadLocais: function () {
        var that = this;
        $.ajax({
          method: 'get',
          url: '/tarefas/' + that.equipe.tarefa_id + '/locais_pra_apo.json',
          success: function(res) {
            that.locais = res.locais ;

            that.delayInitCompos() ;
            // this.initCompos ;
          }
        });
      },

      loadEquipe: function () {
        if ((!this.apontamento) ||
            (this.apontamento.equipe_id == 0) ||
            (!this.apontamento.data)) {return}
        var that = this;
        $.ajax({
          method: 'get',
          data: {
            data: that.apontamento.data
          },
          url: '/equi/equipes/' + that.apontamento.equipe_id + '.json',
          success: function(res) {

            that.equipe.id         = res.equipe.id ;
            that.equipe.nome       = res.equipe.nome ;
            that.equipe.tarefa_id  = res.equipe.tarefa_id ;
            that.limites_medicao_terceiros = res.equipe.limites_medicao_terceiros ;
            that.loadNewEqMembros(res.equipe.membros) ;
            // that.equipe.membros    = res.equipe.membros ;
          }
        });
      },

      loadNewEqMembros: function (amembros) {
        this.$set(this.equipe, 'membros', amembros)
      },

      funcoesPraNovo: function () {
        return this.$parent.$refs.select_obra.funcoes ;
      },
      funcionariosPraNovo: function () {
        return this.$parent.$refs.select_obra.funcionarios ;
      },
      // atuaHoras(funcionario_id, type, evt) {
      //   evt = (evt) ? evt : window.event;
      //   var charCode = (evt.which) ? evt.which : evt.keyCode;
      //   if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      //     evt.preventDefault();
      //   } else {
      //     return true;
      //   }
      // },
      ja_alocadas: function(type, membro) {
        var res = 'Já alocadas ';
        res += membro.realizadas[type].ja.toString() ;
        res += '/'
        res += membro.realizadas[type].ponto.toString() ;
        return res ;
      },
      estimadas: function(membro) {
        var res = '';
        if (membro.realizadas.estimadas) { res += ' Sem ponto' }
        else { res += ' Batidas' }
        return res ;
      },
      removeMembro(funcionario_id) {
        var funci_ind = -1 ;

        this.equipe.membros.forEach((membro, index) => {
          if (membro.funcionario_id == funcionario_id) {
            funci_ind = index
            this.equipe.membros.splice(funci_ind, 1);
            // $(row_id).hide();
          }
        });
      },
      removeMembroDaEquipe(funcionario_id) {
        var that = this;
        $.ajax({
          method: 'get',
          data: {
            funcionario_id: funcionario_id,
            fim: that.apontamento.data
          },
          url: '/equipes/' + that.apontamento.equipe_id + '/remove_membro.json',
          success: function(res) {
            that.$parent.alert('success', res.msg);
            that.removeMembro(funcionario_id)
          },
          error: function(res) {
            that.$parent.alert('warning', res.msg);
          }
        });

      },
      initCompos() {
        var that = this;

        $('input.form-control[tipo-input=decimais]').autoNumeric("init",{
          aSep: '.',
          aDec: ','
        });
        // $('input.horas').on('change', function() {
          // that.atuaHoras(15)
        // });
        $("input[type=text].datepicker").datepicker({
          language: "pt-BR",
          format: "dd/mm/yyyy",
          useCurrent: true,
          autoclose: true,
          beforeShow: function(i) { if ($(i).attr('readonly')) { return false; } },
          open: function (e) { e.preventDefault(); }
        });
        $("select.select2").select2({
          allowClear: true,
          language: "pt-BR",
          width: "100%"
        });
      },
      addNovoFuncionario(novo_func) {
        var funci_id = novo_func.funci_id ;
        var permanente = novo_func.permanente ;

        if (permanente == 1) {
          this.gravaNovoMembroEquipe(funci_id, this.apontamento.data) ;
        }

        var that = this;
        $.ajax({
          method: 'get',
          data: {
            data: that.apontamento.data,
            novo_funcionario_id: funci_id,
          },
          url: '/equi/equipes/' + that.apontamento.equipe_id + '.json',
          success: function(res) {
            that.equipe.membros.push( res.novo_funcionario[0] );
          }
        });
      },
      gravaNovoMembroEquipe(funci_id, inicio) {
        var that = this;
        $.ajax({
          method: 'POST',
          data: {
            funcionario_id: funci_id,
            inicio: inicio
          },
          url: '/equipes/' + that.apontamento.equipe_id + '/novo_membro.json',
          success: function(res) {
            that.$parent.alert('success', res.msg);
          },
          error: function(res) {
            that.$parent.alert('warning', res.msg);
          }
        });
      },
      postApontamento() {
        this.loadApontamento();
        this.loadMembros();
        this.loadServicos();


        var that = this;
        $.ajax({
          method: 'POST',
          data: {
            apontamento: that.recordToPost,
            doit: true
          },
          url: '/apontamentos' + '.json',
          success: function(res) {
            console.log('Apontado!')
            that.limpaErros();
            that.$parent.alert('success', res.message);
            that.$parent.$emit('painel_apo_seta_equipe', {
              equipe: that.equipe,
              obra: that.obra
            });
          },
          error: function(res) {
            vm.alert('warning','Erro no processamento da solicitação') ;

            if (res.responseJSON) {
              that.model_e   = res.responseJSON.model_e   ;
              that.servico_e = res.responseJSON.servico_e ;
              that.membros_e = res.responseJSON.membros_e ;
              that.errors    = res.responseJSON.model_e   ;

            } else {
              that.limpaErros();
            }
          }
        });

      },
      loadApontamento() {
        var r2p = {};
        r2p.obra_id             = this.apontamento.obra_id ;
        r2p.equipe_id           = this.apontamento.equipe_id
        r2p.equipeoriginal_id   = this.apontamento.equipeoriginal_id
        r2p.tarefa_id           = this.apontamento.tarefa_id
        r2p.data                = this.apontamento.data,

        r2p.apontador_id        = ''
        r2p.servicos_attributes = {}
        r2p.membros_attributes  = {}
        this.recordToPost = r2p
      },
      loadServicos() {
        var nServi = {
          local_id   : this.servico.local_id,
          tarefa_id  : this.apontamento.tarefa_id,
          data       : this.apontamento.data,
          percentual : this.servico.percentual
        }
        this.recordToPost.servicos_attributes["0"] = nServi
      },
      loadMembros() {
        var that = this;
        this.equipe.membros.forEach((membro, index) => {
          var mem = {
            funcionario_id    : membro.funcionario_id,
            calculo_equipe_id : membro.calculo_equipe_id,
            horas             : membro.horas,
            extras            : membro.extras
          }
          that.recordToPost.membros_attributes[index.toString()] = mem
        })
      },
      limpaErros() {
        this.model_e   = [] ;
        this.servico_e = [] ;
        this.membros_e = [] ;
      }
    },
    computed: {
      controle: function () {
        return 'controle!!'
      },
      locais2Sel: function() {
        var locs = this.locais ;
        locs.unshift({id: 0, text: ''})
        return locs ;
      },
      titleNovoApo: function() {
        var str = 'Novo apontamento:' ;
        if (this.equipe) {
          str = str + ' Equipe: ' + this.equipe.nome ;
        }
        if (this.apontamento.data) {

          str = str + ' - Data: ' +
                moment(this.apontamento.data, 'DD/MM/YYYY').format('DD/MM/YYYY');
        }
        return str ;
      }
    }
  }

  // function novo_apo_captureMember() {
  //   var novo_funcionario_id = 0;
  //   novo_funcionario_id = $('#sel_novo_funcionario').val() ;
  //   if (novo_funcionario_id) {
  //     window.vm.$ref.novo_apontamento.addNovoFuncionario(novo_funcionario_id);
  //   }
  // }

</script>

