var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row col-sm-12" }, [
    _c("div", { staticClass: "col-sm-12 header p-b-20" }, [
      _c("h1", { staticClass: "col-sm-2" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-4" },
        [
          _c("cmp-v-select", {
            attrs: { options: _vm.obras, value: _vm.selected_obra_id },
            model: {
              value: _vm.selected_obra_id,
              callback: function($$v) {
                _vm.selected_obra_id = $$v
              },
              expression: "selected_obra_id"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.loaded
        ? _c(
            "div",
            { staticClass: "col-sm-3" },
            [
              _c("cmp-v-select", {
                attrs: { options: _vm.periodos },
                model: {
                  value: _vm.selected_periodo_id,
                  callback: function($$v) {
                    _vm.selected_periodo_id = $$v
                  },
                  expression: "selected_periodo_id"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-3 text-right" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            on: {
              click: function($event) {
                return _vm.refreshData()
              }
            }
          },
          [_vm._v("Recalcular este período\n        ")]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-12" }, [
      _c("div", { staticClass: "col-sm-4" }, [
        _c("div", { staticClass: "ibox " }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "ibox-content p-0" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "ul",
                  { staticClass: "stat-list" },
                  [
                    _vm._l(_vm.aindiByCode("verba_orcamentaria"), function(
                      indi,
                      index
                    ) {
                      return indi
                        ? _c(
                            "li",
                            { key: "a" + index, staticClass: "desemp-indi" },
                            [
                              _c(
                                "span",
                                { staticClass: "no-margins pull-left f-20" },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.indicador.nome) +
                                      "\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "h2",
                                {
                                  staticClass: "no-margins text-right",
                                  class: indi.font_class
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.valor_s) +
                                      "\n                    "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.aindiByCode("custo_previsto"), function(
                      indi,
                      index2
                    ) {
                      return indi
                        ? _c(
                            "li",
                            { key: "b" + index2, staticClass: "desemp-indi" },
                            [
                              _c(
                                "span",
                                { staticClass: "no-margins pull-left f-20" },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.indicador.nome) +
                                      "\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "h2",
                                {
                                  staticClass: "no-margins text-right",
                                  class: indi.font_class
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.valor_s) +
                                      "\n                    "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.aindiByCode("custo_realizado"), function(
                      indi,
                      index3
                    ) {
                      return indi
                        ? _c(
                            "li",
                            { key: "c" + index3, staticClass: "desemp-indi" },
                            [
                              _c(
                                "span",
                                { staticClass: "no-margins pull-left f-20" },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.indicador.nome) +
                                      "\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "h2",
                                {
                                  staticClass: "no-margins text-right",
                                  class: indi.font_class
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.valor_s) +
                                      "\n                    "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.aindiByCode("custo_nao_apropriado"), function(
                      indi,
                      index4
                    ) {
                      return indi
                        ? _c(
                            "li",
                            { key: "d" + index4, staticClass: "desemp-indi" },
                            [
                              _c(
                                "span",
                                { staticClass: "no-margins pull-left f-20" },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.indicador.nome) +
                                      "\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "h2",
                                {
                                  staticClass: "no-margins text-right",
                                  class: indi.font_class
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.valor_s) +
                                      "\n                    "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.aindiByCode("custo_com_treinamento"), function(
                      indi,
                      index4b
                    ) {
                      return indi
                        ? _c(
                            "li",
                            { key: "e" + index4b, staticClass: "desemp-indi" },
                            [
                              _c(
                                "span",
                                { staticClass: "no-margins pull-left f-20" },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.indicador.nome) +
                                      "\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "h2",
                                {
                                  staticClass: "no-margins text-right",
                                  class: indi.font_class
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.valor_s) +
                                      "\n                    "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.aindiByCode("economia_com_margem"), function(
                      indi,
                      index5
                    ) {
                      return indi
                        ? _c(
                            "li",
                            { key: "f" + index5, staticClass: "desemp-indi" },
                            [
                              _c(
                                "span",
                                { staticClass: "no-margins pull-left f-20" },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.indicador.nome) +
                                      "\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "h2",
                                {
                                  staticClass: "no-margins text-right",
                                  class: indi.font_class
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.valor_s) +
                                      "\n                    "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.aindiByCode("economia_sem_margem"), function(
                      indi,
                      index6
                    ) {
                      return indi
                        ? _c(
                            "li",
                            { key: "g" + index6, staticClass: "desemp-indi" },
                            [
                              _c(
                                "span",
                                { staticClass: "no-margins pull-left f-20" },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.indicador.nome) +
                                      "\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "h2",
                                {
                                  staticClass: "no-margins text-right",
                                  class: indi.font_class
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.valor_s) +
                                      "\n                    "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    })
                  ],
                  2
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-4" }, [
        _c("div", { staticClass: "ibox " }, [
          _vm._m(1),
          _vm._v(" "),
          _c("div", { staticClass: "ibox-content p-0" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "ul",
                  { staticClass: "stat-list col-sm-4 p-t-20 p-l-20" },
                  [
                    _vm._l(_vm.aindiByCode("ciclos_com_economia"), function(
                      indi,
                      index11
                    ) {
                      return indi
                        ? _c("li", { key: "h" + index11 }, [
                            _c("h2", { staticClass: "no-margins" }, [
                              _vm._v(_vm._s(indi.valor_s))
                            ]),
                            _vm._v(" "),
                            _c("small", [_vm._v(_vm._s(indi.indicador.nome))])
                          ])
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.aindiByCode("ciclos_sem_economia"), function(
                      indi,
                      index12
                    ) {
                      return indi
                        ? _c("li", { key: "i" + index12 }, [
                            _c("h2", { staticClass: "no-margins" }, [
                              _vm._v(_vm._s(indi.valor_s))
                            ]),
                            _vm._v(" "),
                            _c("small", [_vm._v(_vm._s(indi.indicador.nome))])
                          ])
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm._l(
                      _vm.aindiByCode("percentual_horas_apropriadas"),
                      function(indi, index13) {
                        return indi
                          ? _c("li", { key: "ix2" + index13 }, [
                              _c("h2", { staticClass: "no-margins" }, [
                                _vm._v(_vm._s(indi.valor_s))
                              ]),
                              _vm._v(" "),
                              _c("small", [_vm._v(_vm._s(indi.indicador.nome))])
                            ])
                          : _vm._e()
                      }
                    )
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.showgraph
                  ? _c(
                      "div",
                      { staticClass: "col-sm-8" },
                      _vm._l(_vm.aindiByCode("mdo_nao_apropriado"), function(
                        indi,
                        index13
                      ) {
                        return indi
                          ? _c(_vm.itemCommponent(indi), {
                              key: "j" + index13,
                              tag: "component",
                              attrs: { item: indi }
                            })
                          : _vm._e()
                      }),
                      1
                    )
                  : _vm._e()
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-4" }, [
        _c("div", { staticClass: "ibox " }, [
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "ibox-content p-0" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "ul",
                  { staticClass: "stat-list col-sm-4 p-t-20 p-l-20" },
                  [
                    _vm._l(
                      _vm.aindiByCode("ciclos_ppc_acima_90_pcent"),
                      function(indi, index21) {
                        return indi
                          ? _c("li", { key: "k" + index21 }, [
                              _c("h2", { staticClass: "no-margins" }, [
                                _vm._v(_vm._s(indi.valor_s))
                              ]),
                              _vm._v(" "),
                              _c("small", [_vm._v(_vm._s(indi.indicador.nome))])
                            ])
                          : _vm._e()
                      }
                    ),
                    _vm._v(" "),
                    _vm._l(
                      _vm.aindiByCode("ciclos_ppc_abaixo_90_pcent"),
                      function(indi, index22) {
                        return indi
                          ? _c("li", { key: "l" + index22 }, [
                              _c("h2", { staticClass: "no-margins" }, [
                                _vm._v(_vm._s(indi.valor_s))
                              ]),
                              _vm._v(" "),
                              _c("small", [_vm._v(_vm._s(indi.indicador.nome))])
                            ])
                          : _vm._e()
                      }
                    )
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.showgraph
                  ? _c(
                      "div",
                      { staticClass: "col-sm-8" },
                      _vm._l(_vm.aindiByCode("ppc_obra"), function(
                        indi,
                        index23
                      ) {
                        return indi
                          ? _c(_vm.itemCommponent(indi), {
                              key: "m" + index23,
                              tag: "component",
                              attrs: { item: indi }
                            })
                          : _vm._e()
                      }),
                      1
                    )
                  : _vm._e()
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      false
        ? _c("div", { staticClass: "col-sm-4" }, [
            _c("p", [
              _vm._v(" Obra_id: " + _vm._s(_vm.selected_obra_id) + " ")
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(" Periodo_id: " + _vm._s(_vm.selected_periodo_id) + " ")
            ]),
            _vm._v(" "),
            _c("p", [
              _c(
                "button",
                {
                  on: {
                    click: function($event) {
                      return _vm.refresh_charts()
                    }
                  }
                },
                [_vm._v(" Refresh Charts")]
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-12" }, [
      _c("div", { staticClass: "ibox " }, [
        _vm._m(3),
        _vm._v(" "),
        _c("div", { staticClass: "row ibox-content p-0" }, [
          _c("div", { staticClass: "col-sm-3" }, [
            _c(
              "ul",
              { staticClass: "stat-list p-t-20 p-b-20 p-l-20" },
              [
                _vm._l(_vm.aindiByCode("locais_executados"), function(
                  indi,
                  index31
                ) {
                  return indi
                    ? _c(
                        "li",
                        { key: "n" + index31, staticClass: "desemp-indi" },
                        [
                          _c(
                            "span",
                            { staticClass: "no-margins pull-left f-20" },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(indi.indicador.nome) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("h2", { staticClass: "no-margins text-right" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(indi.valor_s) +
                                "\n                "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm._l(_vm.aindiByCode("locais_inspecionados"), function(
                  indi,
                  index32
                ) {
                  return indi
                    ? _c(
                        "li",
                        { key: "o" + index32, staticClass: "desemp-indi" },
                        [
                          _c(
                            "span",
                            { staticClass: "no-margins pull-left f-20" },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(indi.indicador.nome) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("h2", { staticClass: "no-margins text-right" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(indi.valor_s) +
                                "\n                "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                })
              ],
              2
            ),
            _vm._v(" "),
            _vm._m(4),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "stat-list p-t-20 p-b-20 p-l-20" },
              [
                _vm._l(_vm.aindiByCode("funcionarios_apontados"), function(
                  indi,
                  index31
                ) {
                  return indi
                    ? _c(
                        "li",
                        { key: "mdo1" + index31, staticClass: "desemp-indi" },
                        [
                          _c(
                            "span",
                            { staticClass: "no-margins pull-left f-20" },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(indi.indicador.nome) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("h2", { staticClass: "no-margins text-right" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(indi.valor_s) +
                                "\n                "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm._l(_vm.aindiByCode("funcionarios_premiados"), function(
                  indi,
                  index32
                ) {
                  return indi
                    ? _c(
                        "li",
                        { key: "mdo2" + index32, staticClass: "desemp-indi" },
                        [
                          _c(
                            "span",
                            { staticClass: "no-margins pull-left f-20" },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(indi.indicador.nome) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("h2", { staticClass: "no-margins text-right" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(indi.valor_s) +
                                "\n                "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm._l(_vm.aindiByCode("premio_medio"), function(
                  indi,
                  index32
                ) {
                  return indi
                    ? _c(
                        "li",
                        { key: "mdo3" + index32, staticClass: "desemp-indi" },
                        [
                          _c(
                            "span",
                            { staticClass: "no-margins pull-left f-20" },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(indi.indicador.nome) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("h2", { staticClass: "no-margins text-right" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(indi.valor_s) +
                                "\n                "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm._l(_vm.aindiByCode("premio_minimo"), function(
                  indi,
                  index32
                ) {
                  return indi
                    ? _c(
                        "li",
                        { key: "mdo4" + index32, staticClass: "desemp-indi" },
                        [
                          _c(
                            "span",
                            { staticClass: "no-margins pull-left f-20" },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(indi.indicador.nome) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("h2", { staticClass: "no-margins text-right" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(indi.valor_s) +
                                "\n                "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                }),
                _vm._v(" "),
                _vm._l(_vm.aindiByCode("premio_maximo"), function(
                  indi,
                  index32
                ) {
                  return indi
                    ? _c(
                        "li",
                        { key: "mdo5" + index32, staticClass: "desemp-indi" },
                        [
                          _c(
                            "span",
                            { staticClass: "no-margins pull-left f-20" },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(indi.indicador.nome) +
                                  "\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("h2", { staticClass: "no-margins text-right" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(indi.valor_s) +
                                "\n                "
                            )
                          ])
                        ]
                      )
                    : _vm._e()
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _vm.showgraph
            ? _c(
                "div",
                { staticClass: "col-sm-3" },
                _vm._l(_vm.aindiByCode("percentual_aferidos"), function(
                  indi,
                  index33
                ) {
                  return indi
                    ? _c(_vm.itemCommponent(indi), {
                        key: "p" + index33,
                        tag: "component",
                        attrs: { item: indi }
                      })
                    : _vm._e()
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showgraph
            ? _c(
                "div",
                { staticClass: "col-sm-3" },
                _vm._l(_vm.aindiByCode("percentual_nao_conforme"), function(
                  indi,
                  index34
                ) {
                  return indi
                    ? _c(_vm.itemCommponent(indi), {
                        key: "kx2" + index34,
                        tag: "component",
                        attrs: { item: indi }
                      })
                    : _vm._e()
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showgraph
            ? _c(
                "div",
                { staticClass: "col-sm-3" },
                _vm._l(_vm.aindiByCode("percentual_hh_treinamento"), function(
                  indi,
                  index35
                ) {
                  return indi
                    ? _c(_vm.itemCommponent(indi), {
                        key: "j" + index35,
                        tag: "component",
                        attrs: { item: indi }
                      })
                    : _vm._e()
                }),
                1
              )
            : _vm._e()
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-12" }, [
      _c("div", { staticClass: "col-sm-4" }, [
        _c("div", { staticClass: "ibox " }, [
          _vm._m(5),
          _vm._v(" "),
          _c("div", { staticClass: "ibox-content p-0" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "ul",
                  { staticClass: "stat-list" },
                  [
                    _vm._l(_vm.aindiByCode("total_area_construida"), function(
                      indi,
                      index2
                    ) {
                      return indi
                        ? _c(
                            "li",
                            { key: "nb" + index2, staticClass: "desemp-indi" },
                            [
                              _c(
                                "span",
                                { staticClass: "no-margins pull-left f-20" },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.indicador.nome) +
                                      "\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "h2",
                                {
                                  staticClass: "no-margins text-right",
                                  class: indi.font_class
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.valor_s) +
                                      "\n                    "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm._l(
                      _vm.aindiByCode("percentual_area_construida"),
                      function(indi, index3) {
                        return indi
                          ? _c(
                              "li",
                              {
                                key: "nc" + index3,
                                staticClass: "desemp-indi"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "no-margins pull-left f-20" },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.indicador.nome) +
                                        "\n                    "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "h2",
                                  {
                                    staticClass: "no-margins text-right",
                                    class: indi.font_class
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.valor_s) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      }
                    ),
                    _vm._v(" "),
                    _vm._l(
                      _vm.aindiByCode("total_horas_batidas_ponto"),
                      function(indi, index3) {
                        return indi
                          ? _c(
                              "li",
                              {
                                key: "nd" + index3,
                                staticClass: "desemp-indi"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "no-margins pull-left f-20" },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.indicador.nome) +
                                        "\n                    "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "h2",
                                  {
                                    staticClass: "no-margins text-right",
                                    class: indi.font_class
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.valor_s) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      }
                    ),
                    _vm._v(" "),
                    _vm._l(
                      _vm.aindiByCode("total_horas_calc_terceiros"),
                      function(indi, index4) {
                        return indi
                          ? _c(
                              "li",
                              {
                                key: "nh" + index4,
                                staticClass: "desemp-indi"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "no-margins pull-left f-20" },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.indicador.nome) +
                                        "\n                    "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "h2",
                                  {
                                    staticClass: "no-margins text-right",
                                    class: indi.font_class
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.valor_s) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      }
                    ),
                    _vm._v(" "),
                    _vm._l(
                      _vm.aindiByCode("percentual_horas_consumido"),
                      function(indi, index4b) {
                        return indi
                          ? _c(
                              "li",
                              {
                                key: "ne" + index4b,
                                staticClass: "desemp-indi"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "no-margins pull-left f-20" },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.indicador.nome) +
                                        "\n                    "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "h2",
                                  {
                                    staticClass: "no-margins text-right",
                                    class: indi.font_class
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.valor_s) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      }
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.aindiByCode("total_horas_acumuladas"), function(
                      indi,
                      index4b
                    ) {
                      return indi
                        ? _c(
                            "li",
                            { key: "ni" + index4b, staticClass: "desemp-indi" },
                            [
                              _c(
                                "span",
                                { staticClass: "no-margins pull-left f-20" },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.indicador.nome) +
                                      "\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "h2",
                                {
                                  staticClass: "no-margins text-right",
                                  class: indi.font_class
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.valor_s) +
                                      "\n                    "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.aindiByCode("horas_m2"), function(
                      indi,
                      index4b
                    ) {
                      return indi
                        ? _c(
                            "li",
                            { key: "ni" + index4b, staticClass: "desemp-indi" },
                            [
                              _c(
                                "span",
                                { staticClass: "no-margins pull-left f-20" },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.indicador.nome) +
                                      "\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "h2",
                                {
                                  staticClass: "no-margins text-right",
                                  class: indi.font_class
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.valor_s) +
                                      "\n                    "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    })
                  ],
                  2
                )
              ])
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-4" }, [
        _c("div", { staticClass: "ibox " }, [
          _vm._m(6),
          _vm._v(" "),
          _c("div", { staticClass: "ibox-content p-0" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "ul",
                  { staticClass: "stat-list" },
                  [
                    _vm._l(
                      _vm.aindiByCode("total_area_construida_acum"),
                      function(indi, index2) {
                        return indi
                          ? _c(
                              "li",
                              {
                                key: "nb_acum" + index2,
                                staticClass: "desemp-indi"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "no-margins pull-left f-20" },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.indicador.nome) +
                                        "\n                    "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "h2",
                                  {
                                    staticClass: "no-margins text-right",
                                    class: indi.font_class
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.valor_s) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      }
                    ),
                    _vm._v(" "),
                    _vm._l(
                      _vm.aindiByCode("percentual_area_construida_acum"),
                      function(indi, index3) {
                        return indi
                          ? _c(
                              "li",
                              {
                                key: "nc_acum" + index3,
                                staticClass: "desemp-indi"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "no-margins pull-left f-20" },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.indicador.nome) +
                                        "\n                    "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "h2",
                                  {
                                    staticClass: "no-margins text-right",
                                    class: indi.font_class
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.valor_s) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      }
                    ),
                    _vm._v(" "),
                    _vm._l(
                      _vm.aindiByCode("total_horas_batidas_ponto_acum"),
                      function(indi, index3) {
                        return indi
                          ? _c(
                              "li",
                              {
                                key: "nd_acum" + index3,
                                staticClass: "desemp-indi"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "no-margins pull-left f-20" },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.indicador.nome) +
                                        "\n                    "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "h2",
                                  {
                                    staticClass: "no-margins text-right",
                                    class: indi.font_class
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.valor_s) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      }
                    ),
                    _vm._v(" "),
                    _vm._l(
                      _vm.aindiByCode("total_horas_calc_terceiros_acum"),
                      function(indi, index4) {
                        return indi
                          ? _c(
                              "li",
                              {
                                key: "nh_acum" + index4,
                                staticClass: "desemp-indi"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "no-margins pull-left f-20" },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.indicador.nome) +
                                        "\n                    "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "h2",
                                  {
                                    staticClass: "no-margins text-right",
                                    class: indi.font_class
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.valor_s) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      }
                    ),
                    _vm._v(" "),
                    _vm._l(
                      _vm.aindiByCode("percentual_horas_consumido_acum"),
                      function(indi, index4b) {
                        return indi
                          ? _c(
                              "li",
                              {
                                key: "ne_acum" + index4b,
                                staticClass: "desemp-indi"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "no-margins pull-left f-20" },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.indicador.nome) +
                                        "\n                    "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "h2",
                                  {
                                    staticClass: "no-margins text-right",
                                    class: indi.font_class
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.valor_s) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      }
                    ),
                    _vm._v(" "),
                    _vm._l(
                      _vm.aindiByCode("total_horas_acumuladas_acum"),
                      function(indi, index4b) {
                        return indi
                          ? _c(
                              "li",
                              {
                                key: "ni_acum" + index4b,
                                staticClass: "desemp-indi"
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "no-margins pull-left f-20" },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.indicador.nome) +
                                        "\n                    "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "h2",
                                  {
                                    staticClass: "no-margins text-right",
                                    class: indi.font_class
                                  },
                                  [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(indi.valor_s) +
                                        "\n                    "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      }
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.aindiByCode("horas_m2_acum"), function(
                      indi,
                      index4b
                    ) {
                      return indi
                        ? _c(
                            "li",
                            {
                              key: "ni_acum" + index4b,
                              staticClass: "desemp-indi"
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "no-margins pull-left f-20" },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.indicador.nome) +
                                      "\n                    "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "h2",
                                {
                                  staticClass: "no-margins text-right",
                                  class: indi.font_class
                                },
                                [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(indi.valor_s) +
                                      "\n                    "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e()
                    })
                  ],
                  2
                )
              ])
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("img", {
        staticClass: "ponto-png",
        attrs: {
          paraque: "refresh-chart",
          width: "1",
          height: "1",
          onload: _vm.refreshChart()
        }
      })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ibox-title" }, [
      _c("h2", [_c("strong", [_vm._v("Custo")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ibox-title" }, [
      _c("h2", [_c("strong", [_vm._v("Produtividade")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ibox-title" }, [
      _c("h2", [_c("strong", [_vm._v("Planejamento")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ibox-title" }, [
      _c("h2", [_c("strong", [_vm._v("Qualidade")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ibox-title" }, [
      _c("h2", [_c("strong", [_vm._v("MDO")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ibox-title" }, [
      _c("h2", [
        _c("strong", [_vm._v("Produtividade por Área Construída no Mês")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ibox-title" }, [
      _c("h2", [
        _c("strong", [_vm._v("Produtividade por Área Construída Acumulado")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }