// cr_title_btns.vue
<template>
  <div :class="buttonsLargeClass()">
    <div class="ibox-tools">
      <a v-for="button in buttons"
          :href="button.href"
          :class="button.class"
          :data-remote="button.data_remote">
        <div>
          <i v-if="button.i_class"
             class="p-r-5"
             :class="button.i_class"></i>
          {{ button.caption }}
        </div>
        <small :style="button.small_style"
        >  {{ button.small }}
        </small>

      </a>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'CrTitleBtns',
    components: {
    },
    props: ['buttons'],
    data: function () {
      return {
        klass: 'cr_title_btns'
      }
    },
    methods: {
      buttonsLargeClass: function() {
        return 'pull-right'
      }
    },
  };
</script>

