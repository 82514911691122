var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "media nrm" }, [
    _c("div", { staticClass: "media-body " }, [
      _c("a", { attrs: { href: _vm.topico.link } }, [
        _c("h3", [
          _c("strong", [
            _vm._v("\n        " + _vm._s(_vm.topico.title) + "\n      ")
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.topico.resumo
        ? _c("div", [
            _c("p", [
              _vm._v("\n        " + _vm._s(_vm.topico.resumo) + "\n      ")
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col-sm-6 text-left",
          staticStyle: { display: "block" }
        },
        [
          _c("div", {}, [
            _c("i", {
              staticClass: "m-l-10 fa fa-thumbs-up",
              staticStyle: { "font-size": "large" },
              attrs: { id: "favoritedId" }
            }),
            _vm._v(" "),
            _c("span", [_vm._v("(" + _vm._s(_vm.topico.liked_count) + ")")]),
            _vm._v(" "),
            _c("i", {
              staticClass: "m-l-10 fa fa-comments",
              staticStyle: { "font-size": "large" },
              attrs: { id: "favoritedId" }
            }),
            _vm._v(" "),
            _c("span", [
              _vm._v("(" + _vm._s(_vm.topico.comentarios_count) + ")")
            ]),
            _vm._v(" "),
            _c("i", {
              staticClass: "m-l-10 fa fa-eye",
              staticStyle: { "font-size": "large" },
              attrs: { id: "favoritedId" }
            }),
            _vm._v(" "),
            _c("span", [_vm._v("(" + _vm._s(_vm.topico.viewed_count) + ")")])
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col-sm-6 text-right",
          staticStyle: { display: "block" }
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.topico.publicado_by))]),
          _vm._v(" "),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.topico.publicado_desde))
          ])
        ]
      ),
      _vm._v(" "),
      _c("br")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }