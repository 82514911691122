<template>
  <div class="panel panel-default">
    <div class="panel-heading">

      <div class="title" style="float:left;">
        <h5 v-show="! editing">{{ groupTitle() }}</h5>
        <div v-show="editing"
             class="edit-header-label" >
          <input type="text"
                 v-model="group.caption">
          <button class="btn btn-primary btn-xs"
                  @click="editing = false">
            Ok
          </button>
        </div>
      </div>

      <div class="ibox-tools col-sm-3 col-sm-offset-9">
        <a @click="togglePanel()">
          <i class="fa"
             :class="(toggleExpanded ? 'fa-chevron-up' : 'fa-chevron-down')"
             data-placement="top"
             title="Mostra/Oculta"
          ></i>
        </a>
        <a class="copy-link"
           @click="copy()">
          <i class="fa fa-copy"
             data-placement="top"
             title="Copiar grupo"
          ></i>
        </a>
        <a v-if="index > 0"
           class="up-link"
           @click="up()">
          <i class="fa fa-arrow-up"
             data-placement="top"
             title="Subir grupo"
          ></i>
        </a>
        <a v-if="((index + 1) < groupsLength)"
           class="down-link"
           @click="down()">
          <i class="fa fa-arrow-down"
             data-placement="top"
             title="Descer grupo"
          ></i>
        </a>
        <a class="edit-link"
           @click="editing = ! editing">
          <i class="fa fa-pencil"
             data-placement="top"
             title="Editar título"
          ></i>
        </a>
<!--
        <a class="dropdown-toggle" data-toggle="dropdown" href="#">
          <i class="fa fa-gear"></i>
        </a>
        <ul class="dropdown-menu dropdown-user">
          <li><a href="#">Config option 1</a>
          </li>
          <li><a href="#">Config option 2</a>
          </li>
        </ul>
 -->
        <a class="close-linka"
           @click="remove()">
          <i class="fa fa-times"
             data-placement="top"
             title="Excluir seção"
          ></i>
        </a>
      </div>

    </div>
    <div class="panel-body collapse"
         :class="(toggleExpanded ? 'in' : '')"
         :id="'grupo-body-' + index"
         >

      <div class="fields-group-slot"
           :id="'editor_slot' + index"
           :ref="'editor_slot' + index">
      </div>
    </div>

  </div>
</template>

<script>

  import mathUtils        from "packs/components/mixins/mathUtils.vue";
  import fbDefaultOptions from "./fb_default_options.vue";

  export default {
    name: 'GroupEditor',
    mixins: [mathUtils, fbDefaultOptions],
    components: {
    },
    props: ['index', 'groupsLength'],
    data: function () {
      return {
        klass: 'ed-group',
        editing: false,

        toggleExpanded: true,

        eleEditor : null,
        eleRender : null,

        fbEditor : null,
        fbRender : null

      }
    },
    mounted: function() {
      this.refreshGroupEditor() ;

        // var btn = document.getElementById('save_fields' + this.index);
        // btn.addEventListener('click', function() {
        //   saveFields(this.fbEditor) ;
        // });

    },

    methods: {
      togglePanel: function() {
        this.toggleExpanded = ! this.toggleExpanded

      },
      refreshGroupEditor: function() {
        var that = this
        var eleEditor = this.editorElement
        var fbEditor = null

        var opts = jQuery.extend(true, {}, this.default_options);
        opts['formData'] = this.group.fields
        opts['dataType'] = 'json'
        opts['actionButtons'] = [
          {
            id: 'restore_fields',
            className: 'btn btn-warning btn-outline',
            label: 'Descartar alterações',
            type: 'button',
            events: {
              click: function() {
                that.restoreFields()
              }
            }
          },
          {
            id: 'save_fields' + that.index,
            className: 'btn btn-primary',
            label: 'Salvar alterações',
            type: 'button',
            events: {
              click: function() {
                that.saveFields(fbEditor)
              }
            }
          }
        ]
        // Caso já estaja inicializado (ação vem do refresh após subir ou descer o grupo)
        eleEditor.html('');
        var novoEditor = eleEditor.formBuilder(opts) ;
        novoEditor.promise.then(function(editorPronto) {
          that.fbEditor = editorPronto;
        })
      },

      remove: function() {
        this.$parent.removeGroup(this.index) ;
        return false ;
      },
      up: function() {
        this.$parent.up(this.index) ;
        return false ;
      },
      down: function() {
        this.$parent.down(this.index) ;
        return false ;
      },
      copy: function() {
        this.$parent.copyGroup(this.index) ;
        return false ;
      },
      groupTitle: function() {
        return String(this.index + 1) + ' - ' + this.group.caption ;
      },
      saveFields: function(fbEditor) {
        // this.group.fields = fbEditor.actions.getData('json')
        // this.$parent.edited = true
        var fbe = this.fbEditor;
        var jsf = fbe.actions.getData('json');

        this.$parent.saveGroupSchema(this.index, {
          fields: jsf
        })
      },
      restoreFields: function() {
        this.refreshGroupEditor()
      }
    },
    computed: {
      editorElement: function() {
        return $(this.$refs['editor_slot' + this.index])
      },
      groups: function() {
        return this.$store.getters['FormularioStore/groupsCollection']
      },
      group: function() {
        return this.groups[this.index]
      }
    }
  };
</script>

