// index_planejamento.vue
<template>
  <div class="row">
    <div class="col-sm-2 p-l-0">
      <div class="ibox float-e-margins m-0">
        <div class="ibox-content relativo p-l-0 p-r-0"
             :onload="paintDias()"
             id="cmp-datepicker-container">

          <cmp-datepicker
              v-model="data"
              :inline="true"
              :onUpdateHandler="this.calendarUpdate"
              :onChangeHandler="this.calendarUpdate"
              :onShowHandler="this.calendarShow"
              id="dp210"

          >
          </cmp-datepicker>


<!--
          <airbnb-style-datepicker
            :data-one="data"
          ></airbnb-style-datepicker>
 -->


        </div>
      </div>
    </div>
    <div class="col-sm-10">
      <div class="col-sm-12"
          v-if="! no_record_msg">
        <cr-show
          ref="show_ref"
          :opts="cr_show_opts"
        >
        </cr-show>
      </div>
      <div class="col-sm-12"
           v-else>
        <h1>
          {{ no_record_msg }}
        </h1>
      </div>
    </div>
  </div>
</template>

<script>

  import CmpDatepicker from './../comp/cmp-datepicker.vue'
  // import { AirbnbStyleDatepicker } from 'vue-airbnb-style-datepicker'

  export default {
    name: 'calendar_show210',
    props: ['options'],
    components: {

      'cmp-datepicker'    : CmpDatepicker,

      // AirbnbStyleDatepicker
      // 'airbnb-style-datepicker' : AirbnbStyleDatepicker
    },
    data: function () {
      return {
        loaded: false,

        type: 'cr_datatable',
        domid: this.options.domid,

        data: this.options.data,
        obra_id: this.options.obra_id,

        cr_show_opts: {
          domid: 'diario_obra',
          model_url: this.options.url,
          classes: this.options.classes,
          force_refresh: true  // força o refresh do cr_show
        },

        no_record_msg: null
      }
    },
    mounted: function () {
      this.refresh_show()
    },
    created: function () {
      // moment.locale('pt-br');
      // this.$root.$off('refresh_calendar_datas');
      // this.$root.$on('refresh_calendar_datas', this.refreshDatas());
    },
    methods: {
      calendarShow: function() {
        // console.log('Calendar Showed')
        // this.paintDias()
      },
      calendarUpdate: function() {
        console.log('calendarUpdate')
        this.paintDias()
      },
      calendarChange: function() {
        console.log('Calendar Changed')
        this.paintDias()
      },
      refreshDatas: function() {
        console.log('Refreshing Datas')
      },
      paintDias: function() {
        var that = this;
        $('.datepicker-days .table-condensed .day').each(function() {
          that.paintDia(this) ;
        })
      },
      paintDia: function(tdele) {
        var dt = tdele.getAttribute('data-day');
        if ((!dt) || (! this.cr_show_opts.classes)) {return null}

        this.cr_show_opts.classes.forEach(function(classe) {
          var klasse = classe.class ;
          classe.datas.forEach(function(data) {
            if (data === dt) {
              $(tdele).addClass(klasse);
            }
          });
        });
      },

      refresh: function() {
        this.refresh_show() ;
      },
      refresh_show: function() {
        // let novaurl = `/obras/${this.options.obra_id}/diarios/0?data=`
        // let novaurl = `/obras/${this.options.obra_id}/diarios/0`
        // novaurl += moment(this.data, 'DD/MM/YYYY').format('DD-MM-YYYY');
        // novaurl += '&zummy=zummy'

        let novaurl = this.options.url

        var that = this;
        $.ajax({
          method: 'get',
          // url: novaurl + '.json',
          url: novaurl,
          dataType: "json",
          data: {
            refresh: true,
            data: that.data
          },
          success: function(res) {

            if (res.opts.no_record_msg) {
              that.$set(that.cr_show_opts, 'no_record_msg', res.opts.no_record_msg);
            } else {

              that.$set(that.cr_show_opts, 'no_record_msg', null);
              that.$set(that.cr_show_opts, 'model_url', res.opts.model_url);

              that.$set(that.cr_show_opts, 'classes',   res.opts.classes);

              that.$refs.show_ref.model.opts.model_url = res.opts.model_url ;
              that.paintDias()
            }
          },
          complete: function() {
            that.$refs.show_ref.refresh()
          }
        });
      },
      refresh: function() {
        this.refresh_show()
      },
      hoje: function() {
        return moment(new Date()).format('MM-DD-YYYY')
      }
      // exportarPlanilha: function() {
      //   var qdata = this.data
      //   var url_req = ''
      //   url_req += `/obras/${this.options.obra_id}/funcionarios/hna`
      //   url_req += '.xlsx?data=' + qdata

      //   window.open(url_req, '_blank');
      // }
    },
    watch: {
      data: function (data) {
        this.data = data
        this.refresh_show()
      }
    },
    computed: {
      opts_2_show: function () {
        return {
          domid: 'diario_obra'
        }
      }
      // data: {
      //   get: function() {
      //     return this.$store.getters[this.storeName + '/data']
      //   },
      //   set: function(ndata) {
      //     this.$store.dispatch( this.storeName + '/setData', ndata)
      //   }
      // },
      // compDtOptions: function () {
      //   return {
      //     id: 'hna_dtable',
      //     search: false,
      //     columns: this.col_def,
      //     collection: this.linhas_filt,
      //     record_count: this.linhas_filt.length
      //   }
      // }
    }
  }

</script>

<style type="text/css">

/*  td.day.faltando {
    background-color: coral;
  }
*/
</style>
