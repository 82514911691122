var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      attrs: {
        "data-remote": _vm.options.others["data-remote"],
        "data-toggle": _vm.options.others["data-toggle"],
        "data-placement": _vm.options.others["data-placement"],
        title: _vm.options.others.title,
        href: _vm.options.href
      }
    },
    [
      _c("i", {
        class: _vm.options.i_class,
        attrs: { id: _vm.options.others.id_li }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }