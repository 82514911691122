// topico/topico_show.vue
<template>
  <div class="row col-sm-12">
    <div class="ibox col-sm-9">

      <div class="resumo">
        <span><strong>Resumo:</strong></span>
        <p>
          {{ topico.resumo }}
        </p>
      </div>
      <br>

      <div class="body"
           v-html="topico.body">
      </div>

      <div v-if="topico.id">
        <div class="col-sm-12">
          <div class="col-sm-9">
            <ul v-if="(topico.arquivos.length > 0)">
              <li v-for="(arq, indexArq) in topico.arquivos"
                  :key="indexArq">
                <a :href="arq.href"
                    target="_blank">
                  {{ arq.caption }}
                </a>
              </li>
            </ul>
          </div>

          <div class="col-sm-12">
            <br>
            <div class="col-sm-6">
              <span>Publicado por: {{ topico.autor_nome }} {{ topico.created }} </span>
            </div>
            <div class="col-sm-3">

              <a data-toggle="tooltip"
                 data-placement="top"
                 :title="iconeCurtirTitle()"
                 @click.prevent="toggleLike">

                <i :class="iconeCurtido()"
                   id="favoritedId"
                   :key="refreshLike"
                   style="font-size: large;"
                ></i>
              </a>
              <span>({{ topico.liked_count }})</span>

              <i class="fa fa-eye"
                 id="count-view"
                 :key="refreshLike"
                 style="font-size: large;"
              ></i>
              <span>({{ topico.viewed_count }})</span>

            </div>

            <div v-if="(topico.can_comentar && ! comm_editing)"
                 class="col-sm-3" style="display: block;">
              <br>
              <a @click="initCommEdit()"
                 class="btn btn-primary waves-effect waves-effect"
              >
                Comentar
              </a>
            </div>
          </div>

        </div>

        <div v-if="comm_editing">
          <div class="form-group row">
            <div class="col-sm-10">
              <textarea type="textarea"
                     rows="3"
                     class="form-control"
                     v-model="text_comment">
              </textarea>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-4">
              <button class="btn btn-white btn-sm" type="submit">Cancelar</button>
              <button class="btn btn-primary btn-sm"
                      @click="postComentario()"
                      type="submit">Salvar comentário</button>
            </div>
          </div>
        </div>

        <div v-if="(topico.comentarios.length > 0)">
          <h5>{{ comentariosTitle() }}</h5>
          <comentario_item v-for="(comentario, indexComm) in topico.comentarios"
            :options="comentario"
            :key="refreshIndex + indexComm">
          </comentario_item>
        </div>
      </div>
    </div>

    <div class="col-sm-3">
      <div class="ibox" style="border: none; margin-bottom: 0px;">
        <div class="ibox-title" style="border: none;">
          <h3>Grupos</h3>
        </div>
        <div class="ibox-content" style="border: none;">
          <div class="feed-activity-list">
            <div class="feed-element" style="display: grid;">
              <a v-for="(grupo, indexTopico) in topico.grupos"
                 class="btn btn-primary btn-rounded"
                 style="margin-top: 1px"
                 :key="refreshIndex + indexTopico"
                 :href="grupo.url"
                >
                <div class="media-body ">

                  {{ grupo.label }}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="ibox"
           style="border: none; margin-bottom: 0px;">
        <div class="ibox-title" style="border: none;">
          <h3>Tags</h3>
        </div>
        <div class="ibox-content" style="border: none;">
          <div class="feed-activity-list">
            <div class="feed-element">
              <a v-for="(tag, indexTopico) in topico.tags"
                 class="btn btn-primary btn-rounded"
                 :key="refreshIndex + indexTopico"
                 :href="tag.url"
                >
                  {{ tag.label }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="ibox"
           v-if="topico.arquivos && (topico.arquivos.length > 0)"
           style="border: none; margin-bottom: 0px;">
        <div class="ibox-title" style="border: none;">
          <h3>Arquivos anexos</h3>
        </div>
        <div class="ibox-content" style="border: none;">
          <div class="feed-activity-list">
            <div class="feed-element" style="display: grid;">
              <a v-for="(arq, indexArq) in topico.arquivos"
                 :key="refreshIndex + indexArq"
                 :href="arq.url"
                >
                  {{ arq.label }}
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
   </div>
 </template>

<script>

  // import DataStore from    './data_store.vue'

  // import TopicoStore from 'packs/vuex/stores/topico_store.js'
  // import StoreLoader from 'packs/vuex/stores/shared/store_loader.js'

  // import TopicosIndex   from './topicos_index.vue'

  // import GrupoItem    from './grupo_line_item.vue'
  // import TopicoItem   from './topico_item.vue'

  import ComentarioItem   from './comentario_item.vue'

  export default {
    name: 'topico_show',
    props: ['options'],
    components: {
      'comentario_item'   : ComentarioItem
    },
    data: function () {
      return {
        refreshIndex: 0,
        refreshLike: 0,
        loaded: false,

        comm_editing: false,
        text_comment: '',

        topico: this.options.options
      }
    },
    mounted: function () {
      var that = this;
      this.$parent.$on('refresh_topico', function (refresh_url) {
        that.refresh(refresh_url)
      });
    },
    methods: {
      refresh: function (refresh_url) {
        var that = this;
        refresh_url = (refresh_url || that.topico.refresh_url)
        $.ajax({
          method: 'get',
          url: refresh_url + '.json',
          success: function(data) {
            that.topico = data
          },
          error: function(res) {
          }
        });
      },
      comentariosTitle: function() {
        if (this.topico.comentarios_count == 1) {
          return '1 Comentário'
        } else {
          return `${this.topico.comentarios_count} Comentários`
        }
      },
      initCommEdit: function() {
        this.text_comment = ''
        this.comm_editing = true
      },
      postComentario: function() {
        var that = this
        $.ajax({
          url: that.topico.comentarios_post_url + '.json',
          data: {
            comentario: {
              topico_id: that.topico.id,
              body:      that.text_comment
            }
          },
          type: 'post',
          format: 'json',
          success: function(data) {
            that.comm_editing = false
            that.refresh()
          },
          error: function(error) {
          },
          complete: function(response) {
          }
        })
      },
      iconeCurtido: function() {
        if (this.topico.liked == true) {
          return 'fa fa-thumbs-up'
        } else {
          return 'fa fa-thumbs-o-up'
        }
      },
      iconeCurtirTitle: function() {
        if (this.topico.liked == true) {
          return 'Descurtir'
        } else {
          return 'Curtir'
        }
      },
      toggleLike: function() {
        console.log('Mudando o like');
        var that = this
        $.ajax({
          url: that.topico.url_liked + '.json',
          data: {
          },
          type: 'get',
          format: 'json',
          success: function(data) {
            that.topico.liked = data.liked ;
            that.topico.liked_count = data.liked_count ;
            that.refreshLike += 1;
            console.log('Mudado!');
          },
          complete: function(response) {
          }
        })
        return false ;
      }
    },

    computed: {
      // grupos() {
      //   return this.$store.getters[this.storeName + '/grupos']
      // },
      // latest() {
      //   return this.$store.getters[this.storeName + '/latest']
      // },
      // populares() {
      //   return this.$store.getters[this.storeName + '/populares']
      // }
    }
  }

</script>

