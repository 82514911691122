// index_datas_prevs.vue
<template>
  <div id='index-datas-pagas'>
    <div class="col-sm-4">
      <div class="form-group">
        <label>Data de corte:</label>
        <select
         id="aponta-data_prev"
         name="datac[data_prev]"
         v-model="data_selecionada_id"
         @change="setaDataSelecionada">
          <option
           v-for="datap in datas_p"
           v-bind:value="datap.id">
            {{ datap.data }}
           </option>
        </select>
      </div>
    </div>

    <div class="col-sm-8">
      <div id="detail">
        <data-detail
         v-if="data_selecionada"
         :date="data_selecionada">
        </data-detail>
      </div>
    </div>

    <div class="col-sm-12">
      <table-datas-pagas
       v-if="data_selecionada"
       :cab_pagas="cabs"
       :linhas="data_selecionada.ciclos"
       :datas_pro_ciclo="datas_pro_ciclo">

      </table-datas-pagas>
    </div>

  </div>
</template>

<script>

  import DataDetail from './data_detail.vue'
  import TableDatasPagas from './table_datas_pagas.vue'

  export default {
    name: 'index_datas_previstas',
    // props: ['datas', 'cabs', 'datas_previstas'],
    props: ['options'],
    components: {
      'data-detail': DataDetail,
      'table-datas-pagas': TableDatasPagas
    },
    data: function () {
      return {
        datas:           this.options.datas,
        cabs:            this.options.cabs,
        datas_previstas: this.options.datas_previstas,

        datas_p: this.options.datas,
        data_selecionada_id: 0,
        data_selecionada: null
      }
    },
    mounted: function () {
      this.initComps();
    },
    methods: {
      setaDataSelecionada: function(ele) {
        var index = ele.selectedIndex;
        // this.data_selecionada = this.datas[index];
        this.loadDataSelecionada(this.datas[index].id)
      },
      loadDataSelecionada: function(data_id) {
        var that = this
        $.ajax({
          url: '/pagamentos/' + data_id + '.json',
          type: 'get',
          format: 'json',
          success: function(datas) {
            that.data_selecionada = datas.datas[0]
          }
        })
      },
      initComps: function() {
        $('#aponta-data_prev').select2({
          allowClear: true,
          language: "pt-BR",
          width: "100%"
        });

        var thisVm = this ;
        $('#aponta-data_prev').on("change", function() {
          thisVm.setaDataSelecionada(this);
        });
      }
    },
    computed: {
      datas_pro_ciclo: function () {
        var ciclos_todos = this.datas_previstas.slice() ;
        var data_par = this.data_selecionada.data ;
        var index = ciclos_todos.indexOf(data_par);
        ciclos_todos.splice(index,1);
        return ciclos_todos ;
      }
    }
  }
</script>
