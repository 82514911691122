var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "dropdown" }, [
    _c(
      "a",
      {
        ref: "show_mess_btn",
        staticClass: "right-sidebar-toggle dropdown-toggle count-info",
        attrs: { href: "#" }
      },
      [
        _c("i", { staticClass: "fa fa-envelope" }),
        _vm._v(" "),
        _vm.mounted && _vm.unread_q > 0
          ? _c("span", { staticClass: "label label-warning" }, [
              _vm._v(_vm._s(_vm.unread_q))
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }