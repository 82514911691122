var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row col-sm-12" }, [
    _c("div", { staticClass: "ibox col-sm-9" }, [
      _c("div", { staticClass: "resumo" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("p", [_vm._v("\n        " + _vm._s(_vm.topico.resumo) + "\n      ")])
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", {
        staticClass: "body",
        domProps: { innerHTML: _vm._s(_vm.topico.body) }
      }),
      _vm._v(" "),
      _vm.topico.id
        ? _c("div", [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "col-sm-9" }, [
                _vm.topico.arquivos.length > 0
                  ? _c(
                      "ul",
                      _vm._l(_vm.topico.arquivos, function(arq, indexArq) {
                        return _c("li", { key: indexArq }, [
                          _c(
                            "a",
                            { attrs: { href: arq.href, target: "_blank" } },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(arq.caption) +
                                  "\n              "
                              )
                            ]
                          )
                        ])
                      }),
                      0
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-12" }, [
                _c("br"),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("span", [
                    _vm._v(
                      "Publicado por: " +
                        _vm._s(_vm.topico.autor_nome) +
                        " " +
                        _vm._s(_vm.topico.created) +
                        " "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-3" }, [
                  _c(
                    "a",
                    {
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: _vm.iconeCurtirTitle()
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.toggleLike($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        key: _vm.refreshLike,
                        class: _vm.iconeCurtido(),
                        staticStyle: { "font-size": "large" },
                        attrs: { id: "favoritedId" }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("(" + _vm._s(_vm.topico.liked_count) + ")")
                  ]),
                  _vm._v(" "),
                  _c("i", {
                    key: _vm.refreshLike,
                    staticClass: "fa fa-eye",
                    staticStyle: { "font-size": "large" },
                    attrs: { id: "count-view" }
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("(" + _vm._s(_vm.topico.viewed_count) + ")")
                  ])
                ]),
                _vm._v(" "),
                _vm.topico.can_comentar && !_vm.comm_editing
                  ? _c(
                      "div",
                      {
                        staticClass: "col-sm-3",
                        staticStyle: { display: "block" }
                      },
                      [
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass:
                              "btn btn-primary waves-effect waves-effect",
                            on: {
                              click: function($event) {
                                return _vm.initCommEdit()
                              }
                            }
                          },
                          [_vm._v("\n              Comentar\n            ")]
                        )
                      ]
                    )
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _vm.comm_editing
              ? _c("div", [
                  _c("div", { staticClass: "form-group row" }, [
                    _c("div", { staticClass: "col-sm-10" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.text_comment,
                            expression: "text_comment"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "textarea", rows: "3" },
                        domProps: { value: _vm.text_comment },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.text_comment = $event.target.value
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group row" }, [
                    _c("div", { staticClass: "col-sm-4" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-white btn-sm",
                          attrs: { type: "submit" }
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-sm",
                          attrs: { type: "submit" },
                          on: {
                            click: function($event) {
                              return _vm.postComentario()
                            }
                          }
                        },
                        [_vm._v("Salvar comentário")]
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.topico.comentarios.length > 0
              ? _c(
                  "div",
                  [
                    _c("h5", [_vm._v(_vm._s(_vm.comentariosTitle()))]),
                    _vm._v(" "),
                    _vm._l(_vm.topico.comentarios, function(
                      comentario,
                      indexComm
                    ) {
                      return _c("comentario_item", {
                        key: _vm.refreshIndex + indexComm,
                        attrs: { options: comentario }
                      })
                    })
                  ],
                  2
                )
              : _vm._e()
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-3" }, [
      _c(
        "div",
        {
          staticClass: "ibox",
          staticStyle: { border: "none", "margin-bottom": "0px" }
        },
        [
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ibox-content", staticStyle: { border: "none" } },
            [
              _c("div", { staticClass: "feed-activity-list" }, [
                _c(
                  "div",
                  {
                    staticClass: "feed-element",
                    staticStyle: { display: "grid" }
                  },
                  _vm._l(_vm.topico.grupos, function(grupo, indexTopico) {
                    return _c(
                      "a",
                      {
                        key: _vm.refreshIndex + indexTopico,
                        staticClass: "btn btn-primary btn-rounded",
                        staticStyle: { "margin-top": "1px" },
                        attrs: { href: grupo.url }
                      },
                      [
                        _c("div", { staticClass: "media-body " }, [
                          _vm._v(
                            "\n\n                " +
                              _vm._s(grupo.label) +
                              "\n              "
                          )
                        ])
                      ]
                    )
                  }),
                  0
                )
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ibox",
          staticStyle: { border: "none", "margin-bottom": "0px" }
        },
        [
          _vm._m(2),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ibox-content", staticStyle: { border: "none" } },
            [
              _c("div", { staticClass: "feed-activity-list" }, [
                _c(
                  "div",
                  { staticClass: "feed-element" },
                  _vm._l(_vm.topico.tags, function(tag, indexTopico) {
                    return _c(
                      "a",
                      {
                        key: _vm.refreshIndex + indexTopico,
                        staticClass: "btn btn-primary btn-rounded",
                        attrs: { href: tag.url }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(tag.label) +
                            "\n            "
                        )
                      ]
                    )
                  }),
                  0
                )
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.topico.arquivos && _vm.topico.arquivos.length > 0
        ? _c(
            "div",
            {
              staticClass: "ibox",
              staticStyle: { border: "none", "margin-bottom": "0px" }
            },
            [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ibox-content",
                  staticStyle: { border: "none" }
                },
                [
                  _c("div", { staticClass: "feed-activity-list" }, [
                    _c(
                      "div",
                      {
                        staticClass: "feed-element",
                        staticStyle: { display: "grid" }
                      },
                      _vm._l(_vm.topico.arquivos, function(arq, indexArq) {
                        return _c(
                          "a",
                          {
                            key: _vm.refreshIndex + indexArq,
                            attrs: { href: arq.url }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(arq.label) +
                                "\n            "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ]
              )
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("strong", [_vm._v("Resumo:")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "ibox-title", staticStyle: { border: "none" } },
      [_c("h3", [_vm._v("Grupos")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "ibox-title", staticStyle: { border: "none" } },
      [_c("h3", [_vm._v("Tags")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "ibox-title", staticStyle: { border: "none" } },
      [_c("h3", [_vm._v("Arquivos anexos")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }