var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row col-sm-12", class: _vm.klass },
    [
      _c("div", { staticClass: "col-sm-12 secoes-header" }, [
        _c("h3", [_vm._v(_vm._s(_vm.formCaption))])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12 secoes-list" }, [
        _c(
          "form",
          {
            staticClass: "simple_form",
            attrs: {
              id: _vm.form_id,
              action: _vm.url_teste,
              "data-remote": "true"
            }
          },
          _vm._l(_vm.groupsCollection, function(group, index) {
            return _c("group-render", {
              key: index,
              staticClass: "list-group",
              attrs: {
                group_opts: _vm.groupGroupOpts(group, index),
                options: _vm.groupOptions
              }
            })
          }),
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row col-sm-3 col-sm-offset-9  secoes-footer" },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: {
                type: "submit",
                form: _vm.form_id,
                value: "Teste seu formulário!"
              }
            },
            [_vm._v("\n           Teste seu formulário!\n    ")]
          )
        ]
      ),
      _vm._v(" "),
      _c("teste-form-render", {
        ref: "testeformrender",
        attrs: { receivedrecord: _vm.result }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }