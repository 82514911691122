// topico/grupo_line_item.vue
<template>
  <div class="forum-item active">
    <div class="row">
      <div class="col-md-9">
        <div class="forum-icon">
          <i class="fa fa-shield"></i>
        </div>
        <a href="forum_post.html"
           class="forum-item-title">{{ options.nome }}</a>
           <div class="forum-sub-title">{{ options.descricao }}</div>
      </div>
      <div class="col-md-1 forum-info">
        <span class="views-number">{{ options.views }}</span>
        <div>
          <small>Visualizações</small>
        </div>
      </div>
      <div class="col-md-1 forum-info">
        <span class="views-number">{{ options.topicos_count }}</span>
        <div>
          <small>Tópicos</small>
        </div>
      </div>
      <div class="col-md-1 forum-info">
        <span class="views-number">{{ options.novos_topicos }}</span>
        <div>
          <small>Novos Tópicos</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'grupo_line_item',
    props: ['options'],
    components: {
      // 'ibox2' : Ibox2
    },
    data: function () {
      return {
        refreshIndex: 0,
        loaded: false,
        storeName: 'TopicoStore'
      }
    },
    created: function () {
    },
    methods: {
    },
    computed: {
    }
  }

</script>

