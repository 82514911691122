var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ibox float-e-margins" },
    [_vm._t("ibox-title"), _vm._v(" "), _vm._t("ibox-content")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }