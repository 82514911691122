<template>
  <div :class="klass">

    <div v-if="action == 'show'"
         class="ibox float-e-margins">
      <div class="ibox-content"
           style="border-style: none;">
        <div class="row">
          <div class="col-sm-10"
               v-html="text"
               style="min-height: 350px; white-space: pre-wrap;">
          </div>
          <div class="col-sm-2">
            <div v-if="options.can_edit"
                 class="pull-right">
              <div class="btn-group">
                <button type="button"
                        class="btn btn-w-m btn-primary"
                        @click="setEdit()">Editar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="action == 'edit'"
         class="ibox float-e-margins">
      <div class="ibox-content"
           style="border-style: none;">
        <div class="row">
          <textarea class="form-control text optional"
             id="text_editxx"
             rows="5"
             v-model="text"
          ></textarea>
        </div>
      </div>
      <div class="ibox-footer"
           style="border-style: none;">
        <div class="pull-right">
          <div class="btn-group">
            <button type="button"
                    class="btn btn-w-m btn-default"
                    @click="cancelEdit()">Cancelar</button>
            <button type="button"
                    class="btn btn-w-m btn-primary active"
                    @click="saveEdit()">Salvar</button>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

  export default {
    name: 'CrAbaTextEditor',
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'cr-aba-text-editor',
        opts: this.options,

        text: '',
        text_org: '',
        action: 'show'
      }
    },
    mounted: function() {
      this.text = this.opts.text ;
    },
    methods: {
      setEdit: function () {
        this.text_org = this.text ;
        this.action = 'edit' ;
      },
      cancelEdit: function () {
        this.text = this.text_org ;
        this.action = 'show' ;
      },
      saveEdit: function () {
        var that = this;
        $.ajax({
          method: 'PATCH',
          data: that.dataParamsToSave(),
          url: that.opts.url + '.json',
          success: function(res) {
            that.text = res.record[that.opts.field_name];
            that.action = 'show';
            window.vm.refresh() ;
          },
          error: function(res) {
          }
        })

      },
      dataParamsToSave: function() {
        var text_content = {}
        text_content[this.opts.field_name] = this.text
        var res = {}
        res.id = this.opts.text_id
        res[this.opts.param] = text_content
        return res ;
      }
    },
    computed: {
    }

  };
</script>
