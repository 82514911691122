

export default {
    namespaced: true,
    state: {
      user_id: 0,
      url_latest: '',
      url_populares: '',
      url_topicos: '',
      latest: [],
      populares: [],
      topicos: [],
      pendentes: [],
      filtro: {
        grupo_id: -1,
        tag_id:   -1
      },
      refreshIndex: 0
    },
    mutations: {
      init(state, data) {
        state.user_id     = data.user_id;
        state.url_latest       = data.url_latest;
        state.url_populares    = data.url_populares;
        state.url_topicos      = data.url_topicos;
        state.url_pendentes    = data.url_pendentes;
        return state;
      },
      updRefreshIndex(state) {
        state.refreshIndex = state.refreshIndex + 1 ;
      },
      load_latest(state, data) {
        state.latest      = data.topicos;
        return state;
      },
      load_populares(state, data) {
        state.populares = data.topicos;
        return state;
      },
      load_topicos(state, data) {
        state.topicos = data.topicos.sort( function(a,b) {
          if (a.publicado_at < b.publicado_at)
            return 1;
          if (a.publicado_at > b.publicado_at)
            return -1;
          return 0;
        });
        return state;
      },
      load_pendentes(state, data) {
        state.pendentes = data.topicos;
        return state;
      },
      seta_filtro(state, data) {
        debugger
        state.filtro.grupo_id = data.grupo_id ;
        state.filtro.tag_id   = data.tag_id ;
        return state;
      }
    },
    actions: {
      init(context, conf) {
        context.commit('init', conf)
        context.dispatch('load')
      },
      // load inicial
      load(context) {
        debugger
        context.dispatch('load_coll', 'latest');
        context.dispatch('load_coll', 'populares');
        context.dispatch('load_coll', 'topicos');
        if (context.state.url_pendentes) {
          context.dispatch('load_coll', 'pendentes');
        }
        // context.commit('updRefreshIndex')

      },
      set_filter(context, filtro) {
        debugger
        context.commit('seta_filtro', filtro)
        context.dispatch('load')
      },
      load_coll(context, col_name) {
        var that = this
        var coont = context
        var data_filtro = {}
        if (context.state.filtro.grupo_id !== -1) {
          data_filtro.grupo_id = context.state.filtro.grupo_id
        }
        if (context.state.filtro.tag_id !== -1) {
          data_filtro.tag_id = context.state.filtro.tag_id
        }

        $.ajax({
          url: context.state['url_' + col_name],
          data: data_filtro,
          type: 'get',
          format: 'json',
          success: function(data) {
            context.commit('load_' + col_name, data)
          },
          error: function(error) {
          },
          complete: function(response) {
            coont.commit('updRefreshIndex') ;
          }
        })
      }
    },
    getters: {
      latest: state => {
        return state.latest ;
      },
      populares: state => {
        return state.populares ;
      },
      topicos: state => {
        return state.topicos ;
      },
      pendentes: state => {
        return state.pendentes ;
      },
      refreshIndex: state => {
        return state.refreshIndex ;
      }
    }
  }


