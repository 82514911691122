<template>
  <div>
    <component
         v-for="(compo, indexComp) in options.components"
         :class="compo.class"
         :is="bodyCommponent(compo.ctype)"
         :id="compo.id"
         :key="(refreshId + indexComp)"
         :ref="compo.ref"
         :options="compo">

    </component>
  </div>
</template>

<script>
  import inputUtils from 'packs/components/mixins/inputUtils.vue'
  import CrDetails            from 'packs/components/crud/cr_details.vue'
  import errorStr   from 'packs/components/mixins/errorStr.vue'

  import CrTitle              from 'packs/components/crud/cr_title.vue'
  import CrProgressBar        from 'packs/components/crud/cr_progress_bar.vue'
  import CrCustomProgressBar  from 'packs/components/crud/cr_custom_progress_bar.vue'
  import CrModelBtns          from 'packs/components/crud/cr_model_btns.vue'
  import CrAbas               from 'packs/components/crud/cr_abas.vue'
  import CrDataTable          from 'packs/components/crud/cr_data_table.vue'
  import CrRawHtml            from 'packs/components/crud/cr_raw_html.vue'
  import CrImgGallery         from 'packs/components/crud/cr_img_gallery.vue'
  import ObservadoresPanel    from 'packs/components/crud/observadores_panel.vue'
  import PainelEnvio          from 'packs/components/arquivo/painel_envio.vue'
  import UserAvatar           from 'packs/components/user/user_avatar.vue'
  import CheckPanel           from 'packs/components/model_check/check_panel.vue'

  // import VcBarChart           from 'packs/components/charts/vc-bar-chart.vue'
  // import VcLineChart          from 'packs/components/charts/vc-line-chart.vue'

  import CrDocEmpVencer       from 'packs/components/relatorios_gn/form_doc_vencer_empresas.vue'

  export default {
    name: 'cr-show-body-div',
    components: {
      'cr-title'          : CrTitle,
      'cr-details'        : CrDetails,
      'cr-progress-bar'   : CrProgressBar,
      'cr-custom-progress-bar': CrCustomProgressBar,
      'cr-model-btns'     : CrModelBtns,
      'observadores-panel': ObservadoresPanel,
      'painel-envio':       PainelEnvio,
      'user-avatar'       : UserAvatar,
      'check-panel'       : CheckPanel,

      'cr-abas'           : CrAbas,

      // 'vc-bar-chart'   :    VcBarChart,
      // 'vc-line-chart'  :    VcLineChart,

      'cr-data-table'     : CrDataTable,
      'cr-raw-html'       : CrRawHtml,
      'cr-img-gallery'    : CrImgGallery,
      'rl-doc-emp-vencer' : CrDocEmpVencer
    },
    props: ['options'],
    data: function () {
      return {
        what: 'Im CrShow!',
        type: 'cr_show',
        refreshId: 0,

        model : {
          // opts: null
        }
      }
    },
    methods: {
      bodyCommponent: function(compo_type) {
        var comp_name = ''
        switch(String(compo_type)) {
          case 'details_list'     : comp_name = 'cr-details'            ; break;
          case 'progress'         : comp_name = 'cr-progress-bar'       ; break;
          case 'custom_progress'  : comp_name = 'cr-custom-progress-bar'; break;
          case 'show_buttons'     : comp_name = 'cr-model-btns'         ; break;
          case 'observadores'     : comp_name = 'observadores-panel'    ; break;
          case 'painel_envio'     : comp_name = 'painel-envio'    ; break;
          case 'user_avatar'      : comp_name = 'user-avatar'           ; break;
          case 'checks'           : comp_name = 'check-panel'           ; break;
          case 'bar_chart'        : comp_name = 'vc-bar-chart'          ; break;
          case 'line_chart'        : comp_name = 'vc-line-chart'          ; break;
          case 'abas'             : comp_name = 'cr-abas'               ; break;
          case 'data_table'       : comp_name = 'cr-data-table'         ; break;
          case 'raw_html'         : comp_name = 'cr-raw-html'           ; break;
          case 'img_gallery'      : comp_name = 'cr-img-gallery'        ; break;
          case 'rl_doc_emp_vencer':comp_name = 'rl-doc-emp-vencer'     ; break;
          case 'compo_list'       :comp_name = 'cr-show-body-div'      ; break;

          case '...': comp_name = '...'; break;
        }
        return comp_name;
      }
    },
    created: function () {
    },
    mounted: function () {
    },
    computed: {
    }
  }
</script>
