<template>
  <div class="vertical-tree-container">
    <div class="top-wrapper">
      <div class="top-scrollbar"
           id="top-scroll-bar">
      </div>
    </div>
    <div class="content-wrapper">
      <div class="tree-content"
           id="tree-content">
        <table class="table table-hover no-footer dtr-inline"
               id="table-tree">
          <tbody>
            <tr>
              <td v-for="pai in otree"
                  style="border-top: none;">
                <div class="feed-activity-list"
                     style="width: 250px;">
                  <leaf-etapan :etapan="pai" klass="etapan-pai"></leaf-etapan>
                  <div v-for="filho in pai.childs">
                    <leaf-etapan :etapan="filho" klass="etapan-filho"></leaf-etapan>
                    <div v-for="neto in filho.childs">
                      <leaf-etapan :etapan="neto" klass="etapan-neto"></leaf-etapan>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

  import LeafEtapan  from './leaf_etapan.vue'

  export default {
    name: 'CrVerticalTree',
    components: {
      'leaf-etapan':    LeafEtapan
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'cr-vertical-tree',
        otree: this.options.tree
      }
    },
    mounted: function() {
      $(function(){
        $(".top-wrapper").scroll(function(){
          $(".content-wrapper").scrollLeft($(".top-wrapper").scrollLeft());
        });
        $(".content-wrapper").scroll(function(){
          $(".top-wrapper").scrollLeft($(".content-wrapper").scrollLeft());
        });
        $("#top-scroll-bar").width(function() { return $('#table-tree').width() });
      });
    },
    methods: {
    }
  };
</script>

<style scoped>
/*
.top-wrapper, .content-wrapper {
  overflow-x: scroll;
  overflow-y:hidden;
}

.top-wrapper {
  height: 20px;
}

.top-scrollbar {

  height: 20px;
}

.tree-content {
  width: fit-content;
  overflow: auto;
}
*/
</style>
