var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("email-left-panel", {
        attrs: { options: { user_id: _vm.user_id, folder: _vm.folder } }
      }),
      _vm._v(" "),
      _c("email-folder-panel", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.action == "show_folder",
            expression: "action == 'show_folder'"
          }
        ],
        attrs: { options: { user_id: _vm.user_id, folder: _vm.folder } }
      }),
      _vm._v(" "),
      _c("email-show-panel", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.action == "show",
            expression: "action == 'show'"
          }
        ],
        attrs: { options: { user_id: _vm.user_id, folder: _vm.folder } }
      }),
      _vm._v(" "),
      _c("email-compose-panel", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.action == "compose",
            expression: "action == 'compose'"
          }
        ],
        ref: "compose",
        attrs: { options: { user_id: _vm.user_id, folder: _vm.folder } }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }