// data_detail.vue
<template>
  <div class="data_detail">
    <div class="col-sm-6">
      <div class="pmbb-view">
        <dl class="dl-horizontal">
          <dt>Corte</dt>
          <dd> {{ date.data }} </dd>
        </dl>
        <dl class="dl-horizontal">
          <dt>Pagamento</dt>
          <dd> {{ date.pagamento }} </dd>
        </dl>
        <dl class="dl-horizontal">
          <dt>Total prêmio líquido</dt>
          <dd> {{ date.totais.liquido }} </dd>
          <dt>Total prêmio bruto</dt>
          <dd> {{ date.totais.bruto }} </dd>
        </dl>
      </div>
    </div>

    <div class="col-sm-6">
      <div class="pmbb-view">
        <dl class="dl-horizontal"
            v-if="date.ciclos_nao_aprovados_href">
          <dt>Não aprovados</dt>
          <dd>
            <div v-html="date.ciclos_nao_aprovados_href"></div>
          </dd>
        </dl>
        <dl class="dl-horizontal">
          <dt>Status</dt>
          <dd> {{ date.status }} </dd>
        </dl>
        <btn-muda-status-data
          v-if="date && ! date.ciclos_nao_aprovados_href"
          :dataid="date.id"
          :mudarpara="date.mudar_para"
        ></btn-muda-status-data>
        <dl class="dl-horizontal"
            v-if="date && date.btn_historico">
          <dt>Ver</dt>
          <dd v-html="date.btn_historico">
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>

  import BtnMudaStatusData from './btn_muda_status_data.vue'

  export default {
    name: 'DataDetail',
    props: ['date'],
    components: {
      'btn-muda-status-data': BtnMudaStatusData
    },
    methods: {
      'links_ciclos_nao_aprovados': function () {
        var res = [];
        var ciclos = this.date.ciclos ;
        ciclos.forEach((ci, index) => {
          if (ci.status != 'aprovado') {
            res.push(ci.id) ;
          }
        });
        return res.join(',') ;
      }
    }
  }
</script>
