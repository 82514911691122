var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forum-item active" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-9" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "forum-item-title",
            attrs: { href: "forum_post.html" }
          },
          [_vm._v(_vm._s(_vm.options.nome))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "forum-sub-title" }, [
          _vm._v(_vm._s(_vm.options.descricao))
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-1 forum-info" }, [
        _c("span", { staticClass: "views-number" }, [
          _vm._v(_vm._s(_vm.options.views))
        ]),
        _vm._v(" "),
        _vm._m(1)
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-1 forum-info" }, [
        _c("span", { staticClass: "views-number" }, [
          _vm._v(_vm._s(_vm.options.topicos_count))
        ]),
        _vm._v(" "),
        _vm._m(2)
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-1 forum-info" }, [
        _c("span", { staticClass: "views-number" }, [
          _vm._v(_vm._s(_vm.options.novos_topicos))
        ]),
        _vm._v(" "),
        _vm._m(3)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "forum-icon" }, [
      _c("i", { staticClass: "fa fa-shield" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("small", [_vm._v("Visualizações")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("small", [_vm._v("Tópicos")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("small", [_vm._v("Novos Tópicos")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }