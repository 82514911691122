// lista_equipes.vue
<template>
  <div v-if="equipes.length > 0">
    <div class="ms-search hidden-xs p-t-0">

      <p><b>Mostrar apenas:</b></p>

      <label class="checkbox checkbox-inline m-r-20 hidden"></label>
      <label class="checkbox checkbox-inline m-r-20">
        <input
          type="checkbox"
          class="m-r-10"
          v-model="eq_so_minhas"
          v-bind:true-value="1"
          v-bind:false-value="0">
        <i class="input-helper"></i>
        Minhas equipes
      </label>

      <label class="checkbox checkbox-inline m-r-20">
        <input
          type="checkbox"
          class="m-r-10"
          v-model="eq_so_dacasa"
          v-bind:true-value="1"
          v-bind:false-value="0"
          @change="checaSoEmpreiteiraOuDaCasa">
        <i class="input-helper"></i>
        Equipes da casa
      </label>

      <label class="checkbox checkbox-inline m-r-20">
        <input
          type="checkbox"
          class="m-r-10"
          v-model="eq_so_empreiteiras"
          v-bind:true-value="1"
          v-bind:false-value="0"
          @change="checaSoEmpreiteiraOuDaCasa">
        <i class="input-helper"></i>
        Empreiteiras
      </label>

    <!-- </div>
    <div class="ms-search hidden-xs"> -->
      <div class="fg-line p-t-5">
        <i class="zmdi zmdi-search"></i>
          <input type="text"
                 class="form-control"
                 placeholder="Filtrar..."
                 v-model="eq_filtro">
      </div>
    </div>

    <div class="list-group"
         style="overflow-y: auto; height: 600px;">
      <a v-for="eq in equipes_to_show"
         class="list-group-item media"
         v-bind:class="{ 'active': eq == equipe }"
         equipe="eq.id"
         @click="setaEquipe(eq)">
        <div class="media-body">
          <div class="lgi-heading">
            {{ eq.nome }}
          </div>
          <div class="lgi-text era-small">
            {{ eq.tarefa_str }}
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'lista_equipes',
    props: ['l_equipes'],
    data: function () {
      return {
        obra: {},
        equipes: [],
        equipe: {},
        eq_filtro: '',
        eq_so_minhas:       0,
        eq_so_dacasa:       0,
        eq_so_empreiteiras: 0
      }
    },
    mounted: function () {
      var that = this ;
      this.$parent.$on('painel_apo_seta_obra', function (obra) {
        that.loadObra(obra) ;
      });
      this.$parent.$on('painel_apo_equipe_favorita', function (equipe) {
        that.favoritaEquipe(equipe) ;
      });
    },
    methods: {
      loadObra: function (obra) {
        this.obra = obra ;
        this.loadEquipes();
      },
      loadEquipes: function () {
        var that = this;
        $.ajax({
          method: 'get',
          data: {
            obra_id: that.obra.id
          },
          url: '/equipes.json',
          success: function(res) {
            that.equipes = res.equipes ;
            that.setaEquipe(null);
          }
        });
      },
      setaEquipe: function (equipe) {
        var eq = equipe;
        this.equipe = eq ;
        this.$parent.$emit('painel_apo_seta_equipe', {
          equipe: eq,
          obra: this.obra
        });
      },
      checaSoEmpreiteiraOuDaCasa: function () {
        if ((this.eq_so_dacasa == '1') && (this.eq_so_empreiteiras == '1')) {
          this.eq_so_dacasa = '0';
          this.eq_so_empreiteiras = '0';
        }
      },
      favoritaEquipe: function (p_equipe) {
        var eq = null
        this.equipes.forEach((equipe, index) => {
          if (equipe.id == p_equipe.id) {
            equipe.favorita = p_equipe.favorita
          }
        });
      }
    },
    computed: {
      equipes_to_show: function () {
        var filtered = this.equipes ;
        if (this.eq_so_minhas == '1') {
          filtered = filtered.filter(function (eq) {
            return (eq.favorita) ;
          });
        }

        if (this.eq_so_dacasa == '1') {
          filtered = filtered.filter(function (eq) {
            return (eq.da_casa) ;
          });
        }

        if (this.eq_so_empreiteiras == '1') {
          filtered = filtered.filter(function (eq) {
            return (! eq.da_casa) ;
          });
        }

        if (this.eq_filtro != '') {
          var that_filtro = this.eq_filtro ;
          filtered = filtered.filter(function (eq) {
            return ((eq.nome.toUpperCase().indexOf(that_filtro.toUpperCase()) >= 0) ||
                    (eq.tarefa_str.toUpperCase().indexOf(that_filtro.toUpperCase()) >= 0)) ;
          });
        }
        return filtered ;
      }
    }
  }

</script>



