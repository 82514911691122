// desempenho/item.vue
<template>
  <div class="col-sm-3">

    <vc-bar-chart
      :options="opts2Chart"
      ref="thechart">
    </vc-bar-chart>

  </div>
</template>

<script>
//  import VcBarChart from 'packs/components/charts/vc-bar-chart.vue';

  export default {
    name: 'item_desempenho',
    props: ['item'],
    components: {
      // 'vc-bar-chart':  VcBarChart
    },
    data: function () {
      return {
        loaded: false,
        storeName: 'DesempenhoStore',
      }
    },
    methods: {
      barData: function() {
        return {
          labels: ['Meta', 'Indicador X'],
          datasets: [
            {
              label: ['Data One', 'Data two'],
              backgroundColor: ['#f87979','#f8aa79'],
              data: [
                Number(this.item.indicador.meta),
                Number(this.item.valor)
              ]
            }
          ]
        }
      },
      barOptions: function() {
        return {
          responsive: true,
          maintainAspectRatio: false
        }
      }
    },
    computed: {
      opts2Chart: function() {
        return {
          options: this.barOptions(),
          data: this.barData()
        }
      }
    }
  }

</script>
