import axios from 'axios'

const http = axios.create({
  // baseurl: 'http://localhost:3003/',
  // timeout: 30000,
  headers:  {'Content-Type': 'application/json'},
})

http.interceptors.request.use (
  function (config) {
    let csrfToken = localStorage.getItem('token')
    if (csrfToken == 'undefined') {
      csrfToken = document.querySelector("meta[name=csrf-token]").content
    }
    if (csrfToken) {
      if (! config.headers.common) config.headers.commom = {}
      config.headers.common['X-CSRF-Token'] = csrfToken
    }
    return config;
  },
  function (error) {
    return Promise.reject (error);
  }
);

export default http ;
