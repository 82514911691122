// email_left_panel.vue
<template>
  <div class="col-lg-3 p-l-5 p-r-5">
    <div class="ibox float-e-margins">
      <div class="ibox-content">
        <div class="file-manager">
          <a class="btn btn-block btn-primary compose-mail"
             @click="newMessage()"
          >Escrever mensagem</a>
          <div class="space-25"></div>
          <h5>Pastas</h5>
          <ul class="folder-list m-b-md" style="padding: 0">
            <li :style="(folder == 'inbox' ? 'font-size: large' : '')">
              <a @click="setAction('show_folder', 'inbox')">
                <i class="fa fa-inbox "></i>
                {{ folderCaption('inbox') }}
                <span v-if="(mounted && (unread_q > 0))"
                      class="label label-warning pull-right" style="">{{ unread_q }}</span>
              </a>
            </li>
            <li :style="(folder == 'sent' ? 'font-size: large' : '')">
              <a @click="setAction('show_folder', 'sent')">
                <i class="fa fa-envelope-o"></i>
                {{ folderCaption('sent') }}
              </a>
            </li>
            <li :style="(folder == 'trash' ? 'font-size: large' : '')">
              <a @click="setAction('show_folder', 'trash')">
                <i class="fa fa-trash-o"></i>
                {{ folderCaption('trash') }}
              </a>
            </li>
          </ul>
          <div class="hidden">
            <h5>Categories</h5>
            <ul class="category-list" style="padding: 0">
              <li><a href="#"> <i class="fa fa-circle text-navy"></i> Work </a></li>
              <li><a href="#"> <i class="fa fa-circle text-danger"></i> Documents</a></li>
              <li><a href="#"> <i class="fa fa-circle text-primary"></i> Social</a></li>
              <li><a href="#"> <i class="fa fa-circle text-info"></i> Advertising</a></li>
              <li><a href="#"> <i class="fa fa-circle text-warning"></i> Clients</a></li>
            </ul>

            <h5 class="tag-title">Labels</h5>
            <ul class="tag-list" style="padding: 0">
              <li><a href=""><i class="fa fa-tag"></i> Family</a></li>
              <li><a href=""><i class="fa fa-tag"></i> Work</a></li>
              <li><a href=""><i class="fa fa-tag"></i> Home</a></li>
              <li><a href=""><i class="fa fa-tag"></i> Children</a></li>
              <li><a href=""><i class="fa fa-tag"></i> Holidays</a></li>
              <li><a href=""><i class="fa fa-tag"></i> Music</a></li>
              <li><a href=""><i class="fa fa-tag"></i> Photography</a></li>
              <li><a href=""><i class="fa fa-tag"></i> Film</a></li>
            </ul>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import mbStore from 'packs/vuex/stores/mailboxer_store.js'
  import stUtils from 'packs/vuex/stores/utils_store.js'
  import Utils   from './utils.vue'
  import { mapGetters } from 'vuex'

export default {
    name: 'email_left_panel',
    mixins: [stUtils, Utils],
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        mounted: false,
        storeName: 'mailBoxerStore'
      }
    },
    created: function () {
      this.initStore(this.storeName, mbStore, {user_id: this.user_id})
      // this.dispach(this.storeName, 'load', {box: 'unread'})
      this.setAction('show_folder', 'inbox')
      this.mounted = true
    },
    watch: {
    },
    methods: {
      newMessage: function() {
        this.$parent.conv_to_reply = null
        this.setAction('compose')
      }
    },
    computed: {
      folder: function() {
        return this.options.folder
      },
      user_id: function() {
        this.$parent.user_id
      },
      ...mapGetters('mailBoxerStore', {
        unread_q: 'unread_q'
      })
    }
  }
</script>
