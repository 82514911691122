var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "col-sm-12 form-horizontal" }, [
    _c("div", { staticClass: "form-group m-b-0" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-10" },
        [
          _c("cmp-select2", {
            attrs: {
              adata: _vm.obras_text,
              options: {
                placeholder: "Selecione uma obra",
                returnObject: true
              }
            },
            on: { change: _vm.setaObra },
            model: {
              value: _vm.obra_selecionada,
              callback: function($$v) {
                _vm.obra_selecionada = $$v
              },
              expression: "obra_selecionada"
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "control-label col-sm-2" }, [
      _c("b", [_vm._v("OBRA:")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }