<template>
  <div :class="klass">

    <table class="table table-hover"
           :id="opts.id"
           ref="table_arquivos">
      <thead>
        <tr>
          <th style="width: 10%">Documento</th>
          <th style="width: 10%">Responsavel</th>
          <th style="width: 10%">Arquivado por</th>
          <th style="width: 10%">Arquivado em</th>
          <th style="width: 10%">Validade</th>
          <th class="project-actions"
              style="width: 10%">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(arquivo, index) in arquivos">
          <td v-html="arquivo.title_link"></td>
          <td>{{ arquivo.responsavel }}</td>
          <td v-html="arquivo.user_link"></td>
          <td>{{ arquivo.data }}</td>
          <td>{{ arquivo.validade }}</td>
          <td v-html="arquivo.acoes"></td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
  export default {
    name: 'CrAbaArquivos',
    props: ['options'],
    data: function () {
      return {
        klass: 'cr-aba-arquivos',
        opts: this.options,

        url: this.options.datasource,

        arquivos: []
      }
    },
    mounted: function() {
      this.loadArquivos();
      //this.initDataTable ;
      window.vm.$on('close-modal', { modal: this.$el })
    },
    methods: {
      refresh: function () {
        this.loadArquivos()
      },
      loadArquivos: function () {
        var that = this;
        $.ajax({
          method: 'get',
          url: that.url + '.json',
          data: {
            arquivable_type: that.opts.arquivable_type,
            arquivable_id:   that.opts.arquivable_id
          },
          success: function(res) {
            that.arquivos = res.arquivos;
          },
          error: function(res) {
          }
        });
      }
    }
  };
</script>
