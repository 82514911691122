var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded && _vm.showing
    ? _c(
        "div",
        { staticClass: "p-10" },
        [
          _c("h4", { staticClass: "text-center" }, [
            _vm._v(_vm._s(_vm.item.indicador.nome))
          ]),
          _vm._v(" "),
          _c("vc-bar-chart", {
            ref: "thechart",
            attrs: { id: _vm.indicador.valor_id, options: _vm.opts2Chart }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }