<template>
  <div class="col-sm-6">
    <h5>Progresso</h5>
    <div class="progress col-sm-12"
         :class="klass"
         style="height: 20px;padding: 0px;"
         :style="stylePodeClicar()">
      <div v-for="step in steps"
           class="progress-bar" role="progressbar"
           :class="progressClass(step[0])"
           :style="barWidth(step[1])"
           @click="setValue(step[0])"
           aria-valuemin="0"
           aria-valuemax="100">
        {{ stepLabel(step[0]) }}
      </div>
    </div>
  </div>
</template>

<script>
//           :aria-valuenow="valStep"

  export default {
    name: 'ProgressBar',
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        // Foi pro computed
        // progress: this.options.options.progress,
        // progress: 60,

        steps: [ // value, width
          [  0,  4 ],
          [ 25, 24 ],
          [ 50, 24 ],
          [ 75, 24 ],
          [100, 24 ]
        ],
        wprogress: this.options.options.progress,

        klass: 'cr_progress_bar'
      }
    },
    mounted: function () {
    },
    methods: {
      stepLabel: function(step_value) {
        return step_value + '%'
      },
      setValue: function(new_val) {
        if (this.options.options.readonly) { return }
        var that  = this;
        var perc_realizado = { perc_realizado: new_val }
        $.ajax({
          method: 'PATCH',
          data: {
            perc_realizado: perc_realizado
          },
          url: that.options.options.url + '/upd_realizado.json',
          success: function(res) {
            that.progress = res.perc_realizado
            $(res.label_id).html(res.label_html)
            vm.alert('success', res.msg) ;
          },
          error: function(error) {
          },
          complete: function(response) {
            if ( response.responseJSON['errors'] ) {
              response.responseJSON.errors.forEach((erro, index) => {
                vm.alert('warning', erro) ;
              })
            }
          }
        });
      },
      progressClass: function(step_value) {
        if (((this.progress > step_value) || (this.progress == step_value)) &&
           (this.progress > 0)) {
          return 'progress-bar-primary'
        } else {
          return 'progress-bar-plain'
        }
      },
      barWidth: function(step_width) {
        return 'width: ' + step_width + '%'
      },
      stylePodeClicar: function() {
        if (! this.options.options.readonly) { return 'cursor: pointer;' }
      }
    },
    computed: {
      progress: {
        get: function() {
          return this.wprogress
        },
        set: function(newVal) {
          this.wprogress = newVal ;
        }
      }
    }
  };
</script>
