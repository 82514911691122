<template>
  <div class="col-sm-12">
    <div class="btn-group"
         :class="klass">
      <button v-for="step in options.options.steps"
           type="button"
           class="btn btn-xs"
           :class="step.klass"
           :style="styleStep(step)"
           :disabled="disabledStep(step)"
            @click="setAction(step)">
        {{ step.caption }}
      </button>

    </div>
  </div>
</template>

<script>

  export default {
    name: 'CustomProgressBar',
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'cr_custom_progress_bar'
      }
    },
    methods: {
      setAction: function(step) {
        if ((! step.action) || (step.action == '')) { return }
        var that  = this;

        $.ajax({
          method: 'PUT',
          data: {
          },
          url: that.options.options.url + '/' + step.action,
          success: function(res) {
            // vm.alert('success', res.msg) ;
          }
        });
      },
      disabledStep: function(step) {
        return (step.enabled ? null : 'disabled');
      },
      styleStep: function(step) {
        var style = ''
        style = style + 'width: ' + step.width;
        return style
      }
    },
    computed: {
    }
  };
</script>
