// table_datas_pagas.vue
<template>
  <div class="table-responsive">
    <table class="table table-striped big-datatable">
      <thead>
        <tr>
          <th v-for="cab in cab_pagas">
            {{ cab }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="ciclo in linhas_ciclos_ordenados">
          <td>
            <a v-bind:href="ciclo.equipe.tarefa.link.href">
              {{ ciclo.equipe.tarefa.link.caption }}
            </a>
          </td>
          <td> {{ ciclo.equipe.tarefa.nome }} </td>
          <td> {{ ciclo.equipe.unidade }} </td>
          <td>
            <a v-bind:href="ciclo.equipe.link.href">
              {{ ciclo.equipe.link.caption }}
            </a>
          </td>
          <td>
            <a v-bind:href="ciclo.link.href">
              {{ ciclo.link.caption }}
            </a>
          </td>

          <td> {{ ciclo.valor_total_unidades }} </td>
          <td> {{ ciclo.custo_mdo }} </td>
          <td> {{ ciclo.premio_pago_func }} </td>
          <td> {{ ciclo.premio_encarregado }} </td>
          <td> {{ ciclo.economia_gerada }} </td>
          <td>

          <td-ciclo-muda
            v-if="temos_datas_pro_ciclo"
            :datas="datas_pro_ciclo"
            :ciclo_id="ciclo.id">
          </td-ciclo-muda>

          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

  import OpcaoMudarCiclo from './opcao_mudar_ciclo.vue'

  export default {
    name: 'tabledataspagas',
    props: ['cab_pagas', 'linhas', 'datas_pro_ciclo'],
    components: {
      'td-ciclo-muda': OpcaoMudarCiclo
    },
    data: function () {
      return {
      }
    },
    computed: {
      temos_datas_pro_ciclo: function () {
        return ( this.datas_pro_ciclo.length > 0 ) ;
      },
      linhas_ciclos_ordenados: function () {
        return this.linhas.sort( function(a,b) {
          if (a.equipe.nome < b.equipe.nome)
            return -1;
          if (a.equipe.nome > b.equipe.nome)
            return 1;
          return 0;
        });
      }
    }
  }

</script>

