var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel blank-panel" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "panel-body" }, [
      _c("div", { staticClass: "tab-content" }, [
        _c(
          "div",
          { staticClass: "tab-pane active", attrs: { id: "tab-editor" } },
          [
            _c("groups-editor", {
              attrs: { model: _vm.model, storename: _vm.storename }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "tab-pane", attrs: { id: "tab-render" } },
          [_c("groups-render", { attrs: { storename: _vm.storename } })],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "panel-heading" }, [
      _c("div", { staticClass: "panel-options" }, [
        _c("ul", { staticClass: "nav nav-tabs" }, [
          _c("li", { staticClass: "active", attrs: { id: "editor" } }, [
            _c("a", { attrs: { "data-toggle": "tab", href: "#tab-editor" } }, [
              _vm._v("\n              Configuração do formulário\n            ")
            ])
          ]),
          _vm._v(" "),
          _c("li", { attrs: { id: "render" } }, [
            _c("a", { attrs: { "data-toggle": "tab", href: "#tab-render" } }, [
              _vm._v("\n              Teste da entrada de dados\n            ")
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }