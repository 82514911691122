export default {
    namespaced: true,
    state: {
      observadores: [],
      outros_usuarios: [],
      obj_type: '',
      obj_id: 0,

      loaded: false
    },
    mutations: {
      init(state, data) {
        state.obj_type= data.type;
        state.obj_id= data.id;
        return state;
      },
      load(state, data) {
        state.observadores = data.observadores;
        state.outros_usuarios = data.outros_usuarios;
        state.loaded = true;
        return state;
      }
    },
    getters: {
      index: state => {
        return state.observadores ;
      },
      list: state => {
        return state.observadores ;
      },
      outrosUsuarios: function(state, getters, rootState, rootGetters) {
        return state.outros_usuarios ;
      },
      currentObservando: function(state, getters, rootState, rootGetters) {
        var me_id = 0
        if (rootGetters.current_user) { me_id = rootGetters.current_user.id };
        return ( state.observadores.filter((observer) => {
                   return observer.user_id == me_id;
                 }).length > 0
               )
      },
      notCurrentObservando: function(state, getters, rootState, rootGetters) {
        return ! getters.currentObservando ;
      }

    },
    actions: {
      init(context, obj_pars) {
        if ((obj_pars.type != context.state.obj_type) ||
            (obj_pars.id != context.state.obj_id)) {
          context.commit('init', obj_pars);
          context.dispatch('load', context);
        }
      },
      load(context, store) {
        var that = this
        $.ajax({
          url: `/observadores.json`,
          data: {
            observable_type: store.state.obj_type,
            observable_id:   store.state.obj_id
          },
          type: 'get',
          success: function(data) {
            context.commit('load', data)
          },
          error: function(error) {
          },
          complete: function(error) {
          }
        })
      },
      // Chamado desde ibox-list
      removeItem(context, observador) {
        context.dispatch('remove', observador.id)
      },
      remove(context, id) {
        var that = this
        $.ajax({
          url: '/observadores/' + id + '.json',
          method: 'delete',
          type: 'get',
          success: function(data) {
            context.dispatch('load', context);
          }
        })
      },
      addUserItem(context, item) {
        context.dispatch('addUser', item.id)
      },
      addUser(context, user_id) {
        var that = this

        $.ajax({
          url: '/observadores.json',
          method: 'post',
          data: {
            observador: {
              observable_type: context.state.obj_type,
              observable_id:   context.state.obj_id,
              user_id: user_id
            }
          },
          success: function(data) {
            context.dispatch('load', context);
          }
        })
      }
    }
  }
