export default {
  state: {
    user: {
      name: 'Joao da Silva',
      email: ''
    },
    form: {
      schema: {
        groups: []
      }
    }
  },
  mutations: {
    loadForm (state, payload) {
      state.form = payload.form
    },
    SET_USER (store, obj) {
      store.user = obj.user
    }
  }
}
