var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row", class: _vm.klass },
    _vm._l(_vm.columns, function(col) {
      return _c(
        "div",
        { class: col.class },
        [
          col.items
            ? _c("div", { class: col.pmbb_class }, [
                col.title ? _c("h4", [_vm._v(_vm._s(col.title))]) : _vm._e(),
                _vm._v(" "),
                col.items
                  ? _c(
                      "dl",
                      { staticClass: "dl-horizontal" },
                      _vm._l(col.items, function(item) {
                        return _c("div", [
                          _c("dt", [_vm._v(_vm._s(item.label))]),
                          _vm._v(" "),
                          item.text
                            ? _c(
                                "dd",
                                { style: item.style, attrs: { id: item.id } },
                                [_vm._v(_vm._s(item.text))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          item.html
                            ? _c("dd", {
                                style: item.style,
                                attrs: { id: item.id },
                                domProps: { innerHTML: _vm._s(item.html) }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !item.text && !item.html
                            ? _c("dd", { attrs: { id: item.id } })
                            : _vm._e()
                        ])
                      }),
                      0
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          col.type == "endereco"
            ? _c("cr-endereco", { attrs: { options: col.options } })
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }