<template>
  <div class="feed-element"
       :class="klass">
    <div class="media-body ">
      <a :href="eta.href">
        <strong>{{ eta.codigo }}: {{ eta.nome }}</strong><br>
      </a>
      <div v-html="eta.foot_line"></div>
      <div class="icons-counts">
        <div v-for="icon in eta.icon_counts" v-show="icon.value > 0"
             class="icon-count-wrapper">
          <i :class="icon.icon_class"
             data-toggle="tooltip"
             data-placement="top"
             :title="icon.tooltip"
          ></i>
          <span class="label badge label-info icon-counts">{{ icon.value }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LeafEtapan',
    props: ['etapan', 'klass'],
    data: function () {
      return {
        klase: 'leaf-etapan',
        eta: this.etapan
      }
    },
    mounted: function() {
      //this.initDataTable ;
    },
    methods: {
    }
  };
</script>
