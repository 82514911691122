var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-lg-4" }, [
    _c("div", { staticClass: "ibox categoria" }, [
      _c("div", { staticClass: "ibox-title" }, [
        _c("div", { staticClass: "title-body" }, [
          _c("h5", [_vm._v(_vm._s(_vm.cat.nome))]),
          _vm._v(" "),
          _c("div", { staticClass: "ibox-tools" }, [
            _vm.cat.descricao
              ? _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        _vm.show_desc = !_vm.show_desc
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-info-circle info-cat" })]
                )
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _vm.show_desc
          ? _c("small", [_vm._v(_vm._s(_vm.cat.descricao))])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ibox-content table-responsive" }, [
        _c("table", { staticClass: "table table-hover no-margins causas" }, [
          _c(
            "tbody",
            [
              _vm._l(_vm.causas, function(causa, index) {
                return _c("tr", [
                  _c(
                    "td",
                    { staticClass: "nome-col" },
                    [
                      _c("div", [_c("strong", [_vm._v(_vm._s(causa.nome))])]),
                      _vm._v(" "),
                      _vm._l(causa.subcausas, function(subcausa, sindex) {
                        return _c("div", { staticClass: "sub-causa-nome" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(subcausa.fullname) +
                              "\n              "
                          )
                        ])
                      })
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "trash-col" }, [
                    causa.can_edit && causa.edit_url
                      ? _c(
                          "a",
                          {
                            attrs: {
                              "data-remote": "true",
                              href: causa.edit_url
                            }
                          },
                          [_c("i", { staticClass: "fa fa-pencil" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    causa.can_edit && causa.delete_url
                      ? _c(
                          "a",
                          {
                            attrs: {
                              "data-remote": "true",
                              href: causa.delete_url
                            }
                          },
                          [_c("i", { staticClass: "fa fa-trash" })]
                        )
                      : _vm._e()
                  ])
                ])
              }),
              _vm._v(" "),
              _vm.cat.can_edit
                ? _c("tr", { staticClass: "button-add" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-primary btn-xs",
                        attrs: { "data-remote": "true", href: _vm.cat.new_url }
                      },
                      [
                        _vm._v(
                          "\n              Incluir Nova Causa\n            "
                        )
                      ]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }