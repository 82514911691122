var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row col-sm-12", class: _vm.klass }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        key: _vm.refreshGroups,
        staticClass: "col-sm-12 accordion secoes-list"
      },
      _vm._l(_vm.groupsCollection, function(group, index) {
        return _c("group-editor", {
          key: index,
          staticClass: "list-group",
          attrs: { index: index, groupsLength: _vm.groupsCollection.length }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-12 secoes-header" }, [
      _c("h3", [_vm._v("Seções")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }