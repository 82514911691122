// data_store.vue
<script>

  export default {
    methods: {
      initStore: function(planStore, dataInit) {
        const store = this.$store;

        // Carrega o módulo da store
        if (!(store && store.state && store.state[this.storeName])) {
          store.registerModule(this.storeName, planStore);
        } else {
          console.log(`reusing module: ${this.storeName}`);
        }

        // Inicia com parametros da obra
        this.$store.dispatch( this.storeName + '/init', dataInit)
      },
      selectEquipe: function(equipe) {
        this.$store.dispatch( this.storeName + '/selectEquipe', equipe)
      }
    },
    computed: {
    }

  }
</script>

