<template>
  <div :class="klass">
    <div class="ibox float-e-margins col-sm-12">
      <div class="row">
        <div class="col-sm-2">
          <button
            class="btn btn-primary waves-effect"
            v-show="(state == 'closed')"
            @click="openFiltro()"
          > Filtrar registros
          <i class="fa fa-filter"></i>
          </button>
        </div>
      </div>

      <div v-if="(state == 'open')">
        <filtro-fin-tr
          ref="filtro_form"
          :options="opts"
        >
        </filtro-fin-tr>
      </div>

      <div class="row">
        <div class="col-sm-4 col-sm-offset-8"
             v-show="(state == 'open')"
        >
          <button
            class="btn btn-outline btn-success"
            @click="cleanFilter()"
          > Limpar filtro
          </button>
          <button
            class="btn btn-primary waves-effect"
            @click="applyFilter()"
          > Aplicar filtro
          <i class="fa fa-filter"></i>
          </button>
          <button
            class="btn btn-outline btn-success"
            @click="closeFilter()"
          > Fechar
          <i class="fa fa-angle-double-up"></i>
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import FiltroFinTr from 'packs/components/filtro/fin_tr.vue';

  export default {
    name: 'CrDataFiltro',
    components: {
      'filtro-fin-tr':  FiltroFinTr
    },
    props: ['options'],
    data: function () {
      return {
        type: 'cr_chart',
        klass: 'cr-data-filtro',
        opts: this.options,

        state: 'closed'
      }
    },
    mounted: function() {
    },
    methods: {
      openFiltro: function() {
        this.state = 'open'
      },
      applyFilter: function() {
        this.$parent.refresh_com_filtro(this.$refs.filtro_form.filtro) ;
      },
      cleanFilter: function() {
        this.$refs.filtro_form.cleanFiltro() ;

        this.applyFilter() ;
      },
      closeFilter: function() {
        this.state = 'closed'
      }
    },
    computed: {
    }
  };
</script>
