// model_check/check_panel.vue
<template>
  <div class="row clearfix p-b-10">
    <ibox3 :opened="false">
      <div slot="ibox-title"
           style="float: left;">
        <h5 class="no-margins"
            style="display: block; float: none;">
          Condições para conclusão
        </h5>
      </div>

      <div slot="ibox-content"
           style="padding: 10px;max-height: 200px;overflow-x: auto;"
           :style="'display: none;'"
           class="ibox-content inspinia-timeline"
           >
        <ul class="todo-list small-list">
          <li v-show="loaded"
              v-for="(check, index) in checks">
            <a class="check-link"
               @click="toggleCheck(check)">
              <i class="fa"
                 :class="(check.used ? 'fa-check-square' : 'fa-square-o')">
              </i>
            </a>
            <span class="m-l-xs ">{{ check.title}}</span>
            <i v-show="check.used"
               class="fa"
               :class="classCheckPassed(check)"
               style="float: right; font-size: x-large; padding: 2px 5px;"
               :style="styleCheckPassed(check)"
               >
            </i>
          </li>
        </ul>
      </div>
    </ibox3>

  </div>
</template>

<script>

  import Ibox3 from       'packs/components/widgets/ibox3.vue'
  import ModuleStore from 'packs/components/mixins/module_store.vue'

  const checkStore = {
    namespaced: true,
    state: {
      url: '',
      model: {
        checkable_type: null,
        checkable_id: null
      },
      checks: [],
      storeLoaded: false
    },
    mutations: {
      init(state, data) {
        state.url   = data.url
        state.model.checkable_type   = data.checkable_type
        state.model.checkable_id     = data.checkable_id
        return state;
      },
      load(state, data) {
        state.checks      = data.checks
        state.storeLoaded = true
        return state;
      }
    },
    actions: {
      init(context, data) {
        context.commit('init', data)
        context.dispatch('load')
      },
      // load inicial
      load(context) {
        var that = this
        $.ajax({
          url: context.state.url + '.json',
          data: {
            checkable_type: context.state.model.checkable_type,
            checkable_id: context.state.model.checkable_id,
            zummy: true
          },
          type: 'get',
          format: 'json',
          success: function(data) {
            context.commit('load', data)
          },
          error: function(error) {
          },
          complete: function(response) {
          }
        })
      },
      toggleCheck(context, check) {
        $.ajax({
          url: context.state.url + '/toggle.json' ,
          data: {
            checkable_type: context.state.model.checkable_type,
            checkable_id: context.state.model.checkable_id,
            checkagem: check.checkagem,
            zummy: true
          },
          type: 'get',
          format: 'json',
          success: function(data) {
            context.commit('load', data)
          },
          error: function(error) {
          },
          complete: function(response) {
          }
        })

      },
      setCheck(context, check) {
        context.commit('setCheck', check)
      }
    },
    getters: {
      checks: (state, getters, rootState, rootGetters) => {
        return state.checks
      }
    }
  };


  export default {
    name: 'ChecksPanel',
    components: {
      'ibox3' : Ibox3
    },
    props: ['options'],
    mixins: [ModuleStore],

    data: function () {
      return {
        klass: 'checks-panel',
        // permite vm_mail fazer refresh
        domid: '',
        type: 'checks-panel',

        storeName: 'CheckStore',
        loaded   : false
      }
    },

    mounted: function() {
      this.initStore() ;
    },

    methods: {
      reload: function() {
        this.$store.dispatch( this.storeName + '/load' )
      },
      refresh: function() {
        this.reload()
      },
      initStore: function() {
        this.mountCustomStore(this.storeName, checkStore, this.options.options)
        this.$set(this, 'loaded', true)
        var opts = this.options.options
        this.domid = opts.checkable_type + opts.checkable_id
      },
      toggleCheck: function(check) {
        // check.used = ! check.used
        this.$store.dispatch(this.storeName + '/toggleCheck', check)
      },
      classCheckPassed: function(check) {
        if (check.passed) {
          return 'fa-check-square-o'
        } else {
          return 'fa-square-o'
        }
      },
      styleCheckPassed: function(check) {
        if (check.passed) {
          return 'color: green;'
        } else {
          return 'color: grey; padding-right: 8px'
        }
      }
    },

    computed: {
      checks() {
        if (this.loaded) {
          return this.$store.getters[this.storeName + '/checks']
        }
      }
    }
  };
</script>
