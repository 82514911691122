var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row col-sm-12" }, [
    _c("div", { staticClass: "ibox col-sm-9" }, [
      _c("div", { class: _vm.options.class, attrs: { id: _vm.options.id } }, [
        _c("div", { staticClass: "form-group string required titulo" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.topico.title,
                expression: "topico.title"
              }
            ],
            staticClass: "form-control string required",
            attrs: { type: "text", id: "titulo" },
            domProps: { value: _vm.topico.title },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.topico, "title", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group string required resumo" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.topico.resumo,
                expression: "topico.resumo"
              }
            ],
            staticClass: "form-control string required",
            attrs: { type: "text", id: "resumo" },
            domProps: { value: _vm.topico.resumo },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.topico, "resumo", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group string required body" },
          [
            _vm._m(2),
            _vm._v(" "),
            _c("wysiwyg", {
              model: {
                value: _vm.topico.body,
                callback: function($$v) {
                  _vm.$set(_vm.topico, "body", $$v)
                },
                expression: "topico.body"
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-3" }, [
      _c(
        "div",
        { staticClass: "modal-footer", staticStyle: { "border-top": "none" } },
        [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "submit" },
              on: {
                click: function($event) {
                  return _vm.saveChanges()
                }
              }
            },
            [_vm._v("\n        Gravar Alterações\n      ")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn",
              attrs: { id: "close_save_plus_btn", href: "#" },
              on: {
                click: function($event) {
                  return _vm.cancelChanges()
                }
              }
            },
            [_vm._v("\n        Cancelar\n      ")]
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ibox",
          staticStyle: { border: "none", "margin-bottom": "0px" }
        },
        [
          _vm._m(3),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ibox-content", staticStyle: { border: "none" } },
            [
              _c("cmp-v-select", {
                attrs: {
                  input_class: "form-control select select2",
                  options: _vm.all_grupos,
                  required: false,
                  multiple: true,
                  name: "funcionario_id"
                },
                model: {
                  value: _vm.topico.grupo_ids,
                  callback: function($$v) {
                    _vm.$set(_vm.topico, "grupo_ids", $$v)
                  },
                  expression: "topico.grupo_ids"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ibox",
          staticStyle: { border: "none", "margin-bottom": "0px" }
        },
        [
          _vm._m(4),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ibox-content", staticStyle: { border: "none" } },
            [
              _c("cmp-v-select", {
                key: _vm.refreshIndex,
                attrs: {
                  input_class: "form-control select select2",
                  options: _vm.all_tags,
                  required: false,
                  multiple: true,
                  name: "funcionario_id"
                },
                model: {
                  value: _vm.topico.tag_list,
                  callback: function($$v) {
                    _vm.$set(_vm.topico, "tag_list", $$v)
                  },
                  expression: "topico.tag_list"
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.topico.id
        ? _c(
            "div",
            {
              staticClass: "ibox",
              staticStyle: { border: "none", "margin-bottom": "0px" }
            },
            [
              _vm._m(5),
              _vm._v(" "),
              _vm.topico.arquivos.length > 0
                ? _c(
                    "div",
                    {
                      staticClass: "ibox-content",
                      staticStyle: { border: "none" }
                    },
                    [
                      _c("div", { staticClass: "feed-activity-list" }, [
                        _c(
                          "div",
                          {
                            staticClass: "feed-element",
                            staticStyle: { display: "grid" }
                          },
                          _vm._l(_vm.topico.arquivos, function(arq, indexArq) {
                            return _c("div", { staticClass: "col-sm-12" }, [
                              _c(
                                "a",
                                {
                                  key: _vm.refreshIndex + indexArq,
                                  staticClass: "col-sm-10",
                                  attrs: { href: arq.url }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(arq.label) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "ibox-tools col-sm-2",
                                  attrs: {
                                    "data-remote": "true",
                                    "data-toggle": "tooltip",
                                    "data-placement": "top",
                                    title: "Excluir",
                                    href: arq.del_url
                                  }
                                },
                                [_c("i", { staticClass: "fa fa-trash" })]
                              )
                            ])
                          }),
                          0
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "modal-footer",
                  staticStyle: { "border-top": "none" }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        type: "submit",
                        "data-remote": "true",
                        href: _vm.topico.arquivos_new_url
                      }
                    },
                    [_vm._v("\n          Novo arquivo anexo\n        ")]
                  )
                ]
              )
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "control-label string required",
        attrs: { for: "titulo" }
      },
      [
        _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")]),
        _vm._v("\n          Título\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      {
        staticClass: "control-label string required",
        attrs: { for: "resumo" }
      },
      [
        _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")]),
        _vm._v("\n          Resumo\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "label",
      { staticClass: "control-label string required", attrs: { for: "body" } },
      [
        _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")]),
        _vm._v("\n          Corpo do tópico\n        ")
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "ibox-title", staticStyle: { border: "none" } },
      [_c("h3", [_vm._v("Grupos")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "ibox-title", staticStyle: { border: "none" } },
      [_c("h3", [_vm._v("Tags")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "ibox-title", staticStyle: { border: "none" } },
      [_c("h3", [_vm._v("Arquivos anexos")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }