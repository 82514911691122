var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "dropdown" }, [
    _c(
      "a",
      {
        staticClass: "dropdown-toggle count-info",
        attrs: { id: "show_noto_btn", "data-toggle": "dropdown", href: "#" }
      },
      [
        _c("i", { class: _vm.options.icon }),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total_count > 0,
                expression: "total_count > 0"
              }
            ],
            staticClass: "label label-warning"
          },
          [_vm._v(_vm._s(_vm.total_count))]
        )
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "ibox dropdown-menu dropdown-messages" }, [
      _c("div", { staticClass: "ibox-content p-0" }, [
        _c(
          "ul",
          {
            staticClass: "dropdown-messages p-0",
            staticStyle: {
              height: "auto",
              "max-height": "330px",
              "overflow-x": "hidden"
            }
          },
          _vm._l(_vm.notificacoes, function(notificacao) {
            return _c("div", [
              _c("li", [
                _c("div", { staticClass: "dropdown-messages-box" }, [
                  notificacao.user.url
                    ? _c(
                        "a",
                        {
                          staticClass: "pull-left p-l-10",
                          attrs: { href: notificacao.user.url }
                        },
                        [
                          _c("img", {
                            staticClass: "img-circle",
                            attrs: {
                              alt: "image",
                              src: _vm.secureUrl(notificacao.user.image_url)
                            }
                          })
                        ]
                      )
                    : _c(
                        "a",
                        {
                          staticClass: "pull-left p-l-10",
                          staticStyle: { cursor: "default" }
                        },
                        [
                          _c("img", {
                            staticClass: "img-circle",
                            attrs: {
                              alt: "image",
                              src: _vm.secureUrl(notificacao.user.image_url)
                            }
                          })
                        ]
                      ),
                  _vm._v(" "),
                  _c("div", { staticClass: "media-body m-r-10" }, [
                    _c("span", { class: notificacao.unread ? "bold" : "" }, [
                      _vm._v(_vm._s(notificacao.user.nome))
                    ]),
                    _vm._v(
                      "\n                  " +
                        _vm._s(notificacao.tkey) +
                        "\n                  "
                    ),
                    _c("span", { class: notificacao.unread ? "bold" : "" }, [
                      _vm._v(_vm._s(notificacao.trackable.to_s))
                    ]),
                    _vm._v(". "),
                    _c("br"),
                    _vm._v(" "),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(notificacao.time_ago))
                    ]),
                    _vm._v(" "),
                    _c("small", { staticClass: "pull-right" }, [
                      _vm._v(_vm._s(notificacao.created_at))
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("li", { staticClass: "divider" })
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ibox-footer p-0" }, [
        _c("div", { staticClass: "text-center link-block" }, [
          _c(
            "a",
            {
              on: {
                click: function($event) {
                  return _vm.markAllRead()
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-envelope" }),
              _vm._v(" "),
              _c("strong", [_vm._v("Marcar tudo como lido")])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }