// btn_novo_funcionario.vue
<template>
  <div>
    <div id="btn_novo_membro">
      <button
         type="button"
         class="btn btn-info waves-effect waves-effect"
         @click="showModalForm">

        Incluir funcionário
      </button>
    </div>
    <div id="modal-novo-funcionario"
         ref="modal-novo-funcionario"
         role="dialog"
         class="modal fade in"
         aria-hidden="false"
         style="display: none;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Novo Funcionário</h4>
          </div>
          <div class="modal-body card-padding">
            <div class="row">
              <div class="col-sm-8">
                <div class="form-group funcao_id optional novo_membro_funcao_id">
                  <label class="funcao_id optional" for="novo_membro_funcao_id">Função</label>
<!--
                  <select id="sel_novo_funcao"
                    class="select2"
                    data-placeholder="Escolha uma função ..."
                    v-model="funcao_id"
                    @change="setaFuncao"
                    tabindex="-1">
                    <option v-for="funcao in l_funcoes"
                            v-bind:value="funcao.id">
                      {{ funcao.nome }}
                    </option>
                  </select>
                    :value="funcao_id"
 -->


                  <cmp-v-select
                    input_class="form-control select select2"
                    :options="this.lFuncoes2Sel"
                    :required="true"
                    :multiple="false"
                    v-model="funcao_id">
                  </cmp-v-select>

                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group boolean optional permanente">
                  <label class="boolean optional" for="user_ativo">Membro Permanente</label>
                  <label class="checkbox">
                    <input checked="checked"
                       id="permanente"
                       type="checkbox"
                        v-model="permanente"
                        v-bind:true-value="1"
                        v-bind:false-value="0">
                    <i class="input-helper"></i>
                  </label>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-sm-8">
                <div class="form-group funcionario_id optional novo_membro_funcionario_id">
                  <label class="funcionario_id optional" for="novo_membro_funcionario_id">Funcionário</label>
<!--
                  <select id="sel_novo_funcionario"
                    class="select2"
                    data-placeholder="Escolha um funcionario ..."
                    tabindex="-1">
                    <option v-for="funcionario in l_funcionarios"
                    :value="funcionario_id"
                            v-bind:value="funcionario_id">
                      {{ funcionario.text }}
                    </option>
                  </select>
 -->
                  <cmp-v-select
                    input_class="form-control select select2"
                    :options="lFuncionarios2Sel"
                    :required="true"
                    :multiple="false"
                    v-model="funcionario_id">
                  </cmp-v-select>


                </div>
              </div>
            </div>
          </div>
        <div class="modal-footer">
          <button name="button"
                  class="btn btn btn-default primary"
                  @click="alocaFuncionario">
            Alocar Funcionário
          </button>
          <a class="btn" data-dismiss="modal" href="#">Cancelar</a>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>

  import CmpVSelect from 'packs/components/comp/cmp-v-select.vue'


  export default {
    name: 'btn_novo_funcionario',
    props: ['funcoes', 'funcionarios'],
    components: {
      'cmp-v-select'           : CmpVSelect
    },
    data: function () {
      return {
        l_funcoes: this.funcoes,
        l_funcionarios: this.funcionarios,
        funcao_id: -1,
        funcionario_id: 0,
        permanente: 0,
        sel_func: []
      }
    },
    mounted: function () {
    },
    computed: {
      lFuncoes2Sel: function () {
        return this.funcoes.map(function(item){
          return {
            value: item.id,
            label: item.nome
          }
        });
      },
      lFuncionarios2Sel: function () {
        var funcao_id = this.funcao_id
        if (funcao_id == -1) {return [];}
        return this.funcionarios[funcao_id].map(function(item){
          return {
            value: item.id,
            label: item.text
          }
        });
      }
    },

    methods: {
      showModalForm: function () {
        // $("#modal-novo-funcionario").modal("show");
        this.atualiza_funcionarios_filtrados();
      },
      atualiza_funcionarios_filtrados: function () {
        var href_atualiza_ff = this.$parent.equipe.href_equipe + '/reload_ffuncionarios.json'
        var that = this;
        $.ajax({
          method: 'get',
          url: href_atualiza_ff,
          success: function(res) {
            that.funcoes = res.funcoes ;
            that.funcionarios = res.funcionarios ;

            $("#modal-novo-funcionario").modal("show");
          }
        });

      },
      setaFuncao: function () {
        // this.sel_func = l_funcionarios[$('#sel_novo_funcao').val()]
      },
      alocaFuncionario: function () {
        // var funci_id = $('#sel_novo_funcionario').val()
        this.$parent.addNovoFuncionario({
          funci_id: this.funcionario_id,
          permanente: this.permanente
        });
        $("#modal-novo-funcionario").modal("hide");
      }
    }
  }

</script>

