// cr_gantt.vue
<template>
  <div>
    <div
      class="col-sm-12"
         id="btn">
      <button
        class=" btn btn-primary"
        @click="show_mapa()">
        Mostrar Gantt
      </button>
    </div>
    <div class="row col-sm-12">

      <GChart
        type="Gantt"
        :create-chart="drawGanttChart"
        :key="show_inc"
        :settings="ganttSettings"
        :data="chartDataComp()"
        :options="chartOptions()"
      />

    </div>

  </div>
</template>

<script>

  import { GChart } from 'vue-google-charts'


  export default {
    name: 'CrGantt',
    components: {
      GChart
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'gantt',

        ganttSettings: {
          packages: ['gantt'],
          language: 'pt_BR'
        },

        crudData: [],

        columnsDef: [
          { type: 'string', label: 'Task ID' },
          { type: 'string', label: 'Task Name' },
          { type: 'string', label: 'Resource' },
          { type: 'date', label: 'Start Date' },
          { type: 'date', label: 'End Date' },
          { type: 'number', label: 'Duration' },
          { type: 'number', label: 'Percent Complete' },
          { type: 'string', label: 'Dependencies' },
          { type: 'string', role: 'tooltip', 'p': {'html': true}}
        ],

        show_inc: 0

      }
    },
    mounted: function() {
      // this.show_mapa()
    },
    methods: {

      drawGanttChart: function(el, google){
        if(el === undefined){
          return
        }
        return new google.visualization.Gantt(el)
      },

      chartOptions: function() {
        var opts = {
          width:  1200,
          height:  1200,

          // Use an HTML tooltip.
          tooltip: { isHtml: true },

          hAxis: {
          },
          gantt: {
            labelMaxWidth: 150,

            criticalPathEnabled: true,
            criticalPathStyle: {
              stroke: '#e64a19',
              strokeWidth: 5
            }
          }
        };
        opts['height'] = this.crudData.length * 44 ;
        return opts ;
      },

      chartDataComp: function() {
        return [this.columnsDef].concat(this.parsedGanttDataTable) ;
      },

      show_mapa: function() {
        var that = this ;

        $.ajax({
          url: that.options.url + '.json',
          type: 'get',
          format: 'json',
          success: function(data) {
            that.$set(that, 'crudData', data);
            that.show_inc += 1;
          },
          complete: function(response) {
          }
        });

      }

    },
    computed: {
      parsedGanttDataTable() {
        var that = this ;
        // debugger
        this.crudData.forEach((linha, index) => {
          if (index > -1) {   // Trap, sempre é
            // if (linha[2]) {
            //   that.crudData[index][2] = new Date(linha[2])
            // }
            if (linha[3]) {
              that.crudData[index][3] = new Date(linha[3])
            }
            if (linha[4]) {
              that.crudData[index][4] = new Date(linha[4])
            }
          }

        });
        return this.crudData ;
      }
    }
  }
</script>

<style scoped>
/*
  #chart_div {
    height: 400px;
    position: relative;
    overflow: hidden;
  }
*/
</style>
