// show_calendar.vue
<template>
  <div>
    <div id="show_calendar">
      <div v-if="equipe"
           class="row linha-header">
        <h4 v-if="equipe"
            class="col-sm-10"
            style="word-break: break-all;">
          Equipe:
          <a :href="equipe.href_equipe">{{ equipe.nome }}</a>
          <br>
          Serviço:
          <a :href="equipe.href_tarefa">{{ equipe.tarefa_str }}</a>
        </h4>
        <div class="col-sm-2 text-center">
          <h4>Marcar como minha equipe</h4>
          <a data-toggle="tooltip"
             data-placement="auto top"
             class="text-center"
             title=""
             @click="setFavorita"
             data-original-title="Favorito">
             <i class="zmdi"
                :class="classeObraFavorita"
                style="font-size: xx-large; color: red;"></i>
          </a>
        </div>
      </div>
      <div v-else
           class="row linha-header">
        &nbsp
      </div>
      <div class="row">
        <div id="calendar_el"
             ref="calendar_el">
        </div>
      </div>

    </div>
  </div>
</template>

<script>

  export default {
    name: 'show_calendar',
    props: ['equipe_id'],
    data: function () {
      return {
        obra:   null,
        equipe: null,
        eq_id: 0,
        currentDate: null,
        currentView: null,
        equipe_favorita: false
      }
    },
    mounted: function () {
      var that = this;
      this.$parent.$on('painel_apo_seta_equipe', function (p_equipe) {
        if (p_equipe) { that.updateEquipe(p_equipe.equipe); }
        else          { that.updateEquipe(null) }

        that.obra = p_equipe.obra ;
      });
      this.initCalendar() ;
    },
    methods: {
      initCalendar: function () {
        var that = this;
        var urlFeed = null ;
        if ((this.equipe) && (this.equipe.id)) {
          urlFeed = '/equipes/' + this.equipe.id + '/calendario.json'
        } ;

        var currentDate = new Date() ;
        if (this.currentDate) { currentDate = this.currentDate } ;
        var currentView = 'basicWeek' ;
        if (this.currentView) { currentView = this.currentView } ;

        $('#calendar_el').fullCalendar({
          contentHeight: 'auto',
          lang: 'pt-br',
          firstDay: 1,   // Inicio em Segunda-feira
          fixedWeekCount: false,  // Exibir 6 semanas x mês?
          defaultView: currentView,
          customButtons: {
            cbPrintPlanilha: {
              text: 'Imprimir Planilha',
              click: function() {
                that.printPlanilha();
              }
            }
          },

          header: {
            right:  'cbPrintPlanilha prev,next',
            // right:  'prev,next',
            center: 'title',
            left:   'today'
          },
          titleFormat: 'DD, MMMM/YYYY',
          defaultDate: currentDate,
          editable: false,
          resourceOrder: 'ciclo_id, aponta_id',
          events: {
            type: 'POST',
            url: urlFeed,
            data: {
              apontamento: true
            },
            error: function() {
                alert('there was an error while fetching events!');
            },
          },
          eventClick: function(event) {
            if (event.dataremote) {
              $.ajax({
                url: event.url + '.js',
                dataType: "script"
              });
              return false;
            };
            if ((event.url) && (event.url) != 'xxx')  {
              window.location.assign(event.url);
              return false;
            }
            if (event.novo_apo) {
              window.vm.$emit('painel_apo_novo_apo',
                { apo:    event.novo_apo,
                  equipe: that.equipe,
                  obra:   that.obra
                }
              );
              return false;
            }
          }
        });
      },
      updateEquipe: function (equipe) {
        this.equipe = equipe ;
        this.eq_id = ((equipe) ? equipe.id : 0) ;
        console.log('Atualizando para: ' + this.eq_id);
        this.refreshEquipe() ;
      },
      refreshEquipe: function() {
        // if (!this.equipe) {
        //   return null ;
        // }
        this.currentDate = $('#calendar_el').fullCalendar('getDate');

        var view = $('#calendar_el').fullCalendar('getView');
        this.currentView = view.name ;

        $('#calendar_el').fullCalendar('destroy');
        this.initCalendar();
        this.askEquipeFavorita();
        this.loadEquipe();

        window.vm.$emit('painel_apo_novo_apo', {
          equipe: this.equipe,
          obra: this.obra,
          apo: null
        });
      },
      showEquipe: function (equipe_id) {
        this.eq_id = equipe_id ;
      },
      setFavorita: function () {
        var that = this;
        $.ajax({
          method: 'get',
          data: {
            id: that.equipe.id,
            type: 'Equipe'
          },
          url: '/favoritos/set.json',
          success: function(res) {
            that.$parent.alert('success', res.msg);
            that.askEquipeFavorita();
          },
          error: function(res) {
            that.$parent.alert('warning', res.msg);
          }
        });
      },
      askEquipeFavorita: function () {
        if ((!this.equipe) || (!this.equipe.id)) { return }
        var that = this;
        $.ajax({
          method: 'get',
          data: {
            id: that.equipe.id,
            type: 'Equipe'
          },
          url: '/favoritos/get.json',
          success: function(res) {
            that.equipe_favorita = res.favorito ;
            that.equipe.favorita = that.equipe_favorita ;
            that.$parent.$emit('painel_apo_equipe_favorita', that.equipe)
          }
        });
      },
      loadEquipe: function () {
        if ((!this.apontamento) || (this.apontamento.equipe_id == 0)) {return}
        var that = this;
        $.ajax({
          method: 'get',
          data: {
            data: that.apontamento.data
          },
          url: '/equipes/' + that.apontamento.equipe_id + '.json',
          success: function(res) {
            that.equipe = res.equipe ;
          }
        });
      },
      printPlanilha: function() {
        if (! this.equipe) {return}
        var that = this;
        var qdata = moment(Date.now()).format('DD/MM/YYYY')
        if ((this.apontamento) && (this.apontamento.data)) {
          qdata = this.apontamento.data
        }

        var url_req = '/equipes/' + that.equipe.id + '/print_planilha.js?data=' + qdata ;

        window.open(url_req, '_blank');

        // $.ajax({
        //   method: 'get',
        //   data: {
        //     data: qdata
        //   },
        //   url: '/equipes/' + that.equipe.id + '/print_planilha.js';

        //   success: function(res) {
        //     that.$parent.alert('success', res.msg);
        //   },
        //   error: function(res) {
        //     that.$parent.alert('warning', res.msg);
        //   }
        // });

      }
    },
    computed: {
      controle: function () {
        return 'controle!!'
      },
      classeObraFavorita: function () {
        if (!this.equipe) {return null};
        if (this.equipe_favorita) {
          return 'zmdi-favorite' }
        else {
          return 'zmdi-favorite-outline'
        }
      }
    }
  }

</script>

