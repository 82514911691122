var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-lg-3 p-l-5 p-r-5" }, [
    _c("div", { staticClass: "ibox float-e-margins" }, [
      _c("div", { staticClass: "ibox-content" }, [
        _c("div", { staticClass: "file-manager" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-block btn-primary compose-mail",
              on: {
                click: function($event) {
                  return _vm.newMessage()
                }
              }
            },
            [_vm._v("Escrever mensagem")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "space-25" }),
          _vm._v(" "),
          _c("h5", [_vm._v("Pastas")]),
          _vm._v(" "),
          _c(
            "ul",
            {
              staticClass: "folder-list m-b-md",
              staticStyle: { padding: "0" }
            },
            [
              _c(
                "li",
                { style: _vm.folder == "inbox" ? "font-size: large" : "" },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.setAction("show_folder", "inbox")
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-inbox " }),
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.folderCaption("inbox")) +
                          "\n              "
                      ),
                      _vm.mounted && _vm.unread_q > 0
                        ? _c(
                            "span",
                            { staticClass: "label label-warning pull-right" },
                            [_vm._v(_vm._s(_vm.unread_q))]
                          )
                        : _vm._e()
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                { style: _vm.folder == "sent" ? "font-size: large" : "" },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.setAction("show_folder", "sent")
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-envelope-o" }),
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.folderCaption("sent")) +
                          "\n            "
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "li",
                { style: _vm.folder == "trash" ? "font-size: large" : "" },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          return _vm.setAction("show_folder", "trash")
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fa fa-trash-o" }),
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.folderCaption("trash")) +
                          "\n            "
                      )
                    ]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "clearfix" })
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "hidden" }, [
      _c("h5", [_vm._v("Categories")]),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "category-list", staticStyle: { padding: "0" } },
        [
          _c("li", [
            _c("a", { attrs: { href: "#" } }, [
              _c("i", { staticClass: "fa fa-circle text-navy" }),
              _vm._v(" Work ")
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: "#" } }, [
              _c("i", { staticClass: "fa fa-circle text-danger" }),
              _vm._v(" Documents")
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: "#" } }, [
              _c("i", { staticClass: "fa fa-circle text-primary" }),
              _vm._v(" Social")
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: "#" } }, [
              _c("i", { staticClass: "fa fa-circle text-info" }),
              _vm._v(" Advertising")
            ])
          ]),
          _vm._v(" "),
          _c("li", [
            _c("a", { attrs: { href: "#" } }, [
              _c("i", { staticClass: "fa fa-circle text-warning" }),
              _vm._v(" Clients")
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _c("h5", { staticClass: "tag-title" }, [_vm._v("Labels")]),
      _vm._v(" "),
      _c("ul", { staticClass: "tag-list", staticStyle: { padding: "0" } }, [
        _c("li", [
          _c("a", { attrs: { href: "" } }, [
            _c("i", { staticClass: "fa fa-tag" }),
            _vm._v(" Family")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "" } }, [
            _c("i", { staticClass: "fa fa-tag" }),
            _vm._v(" Work")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "" } }, [
            _c("i", { staticClass: "fa fa-tag" }),
            _vm._v(" Home")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "" } }, [
            _c("i", { staticClass: "fa fa-tag" }),
            _vm._v(" Children")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "" } }, [
            _c("i", { staticClass: "fa fa-tag" }),
            _vm._v(" Holidays")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "" } }, [
            _c("i", { staticClass: "fa fa-tag" }),
            _vm._v(" Music")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "" } }, [
            _c("i", { staticClass: "fa fa-tag" }),
            _vm._v(" Photography")
          ])
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "" } }, [
            _c("i", { staticClass: "fa fa-tag" }),
            _vm._v(" Film")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }