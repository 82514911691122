// cr_raw_html.vue
<template>
  <div :class="options.class"
       :id="options.id"
       v-html="content"></div>
</template>

<script>

  export default {
    name: 'CrRawHtml',
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        domid: (this.options.domid || 'fakedomid'),

        klass: 'cr-raw-html',
        content: this.options.options
      }
    },
    methods: {
      refresh: function () {
        if (! this.options.url) {return false} ;
        var that = this;
        $.ajax({
          method: 'get',
          url: that.options.url + '.json',
          success: function(res) {
            debugger
            that.$set(that, 'content', res.options);
          },
          error: function(res) {
          }
        });
      }
    }
  }
</script>

