<template>
  <div id="app">
    <div id="wrapper">

      <slot></slot>

    </div>
  </div>
</template>

<script>

  import Vue   from 'vue/dist/vue.esm';
  // import Vue   from 'vue';
  // import store from 'vuex';

  // import LeftPanel      from 'packs/components/shared/left_panel.vue';
  // import TopBar         from 'packs/components/shared/top_bar.vue';
  // import BottomBar      from 'packs/components/shared/bottom_bar.vue';
  // import CrShow         from 'packs/components/crud/cr_show.vue';


  export default {
    data: function () {
      return {
        name: 'MainApp',
        ref_children: true,
        ref_self: false,
        message: "Hello xxx yyVue!"
      }
    },
    components: {
      // 'cr-show'    : CrShow,
      // 'left-panel' : LeftPanel,
      // 'top-bar'    : TopBar,
      // 'bottom-bar' : BottomBar
    },
    mounted: function() {
      console.log('App mounted!')
      this.$store.dispatch("CurrentUserStore/loadCurrentUser")
    }

  }

</script>

