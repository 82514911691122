// agenda/index.vue
<template>
  <ibox2 class="ibox"
         :options="{class_with: 'nenhuma' }"
         v-if="eventos.length > 0"
         :key="refreshIndex">
    <div slot="ibox-title">
      <h5>Agenda</h5>
    </div>

<!--
v-if="eventos.lenght > 1"
 -->
    <div slot="ibox-content"
         class="ibox-content inspinia-timeline">
      <div v-if="eventos.length > 0"
           v-for="(evento, index) in eventos"
           class="timeline-item">
        <div class="row">
          <div class="col-xs-2 date">
            <i :class="evento.i_class"></i>
              <span :class="evento.data_color">
                {{ evento.data }}
              </span>
              <br>
              <span :class="evento.data_color">
                {{ evento.horario }}
              </span>
            <br>
            <small class="text-navy">{{ evento.horario_relativo }}</small>
          </div>
          <div class="col-xs-9 content no-top-border">
            <p class="m-b-xs">
              <div v-html="evento.title"></div>
            </p>
            <p v-html="evento.html"></p>

          </div>
        </div>
      </div>
    </div>
  </ibox2>
</template>

<script>

  import Ibox2 from        'packs/components/widgets/ibox2.vue'
  import DataStore from    './data_store.vue'

  import AgendaStore from 'packs/vuex/stores/agenda_store.js'

  export default {
    name: 'agenda_index',
    props: ['options'],
    components: {
      'ibox2' : Ibox2
    },
    mixins: [DataStore],
    data: function () {
      return {
        refreshIndex: 0,
        loaded: false,
        storeName: 'AgendaStore'
      }
    },
    created: function () {
      this.initStore(AgendaStore);
      // this.refreshIndex += 1;
      this.$set(this, 'refreshIndex', this.refreshIndex + 1)
    },
    methods: {
    },

    computed: {
      eventos() {
        return this.$store.getters[this.storeName + '/eventos']
      }
    }
  }

</script>

