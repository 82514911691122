// select_obras.vue
<template>

  <form class="col-sm-12 form-horizontal">
    <div class="form-group m-b-0">
      <label class="control-label col-sm-2"><b>OBRA:</b></label>
      <div class="col-sm-10">

        <cmp-select2
          :adata="obras_text"
          v-model="obra_selecionada"
          :options="{
            placeholder: 'Selecione uma obra',
            returnObject: true}"
          @change="setaObra">

        </cmp-select2>

      </div>
    </div>
  </form>

</template>

<script>

  import CmpSelect2  from 'packs/components/comp/cmp-select2.vue'

  export default {
    name: 'select_obras',
    props: ['l_obras'],
    components: {
      'cmp-select2': CmpSelect2
    },
    data: function () {
      return {
        obra: {},
        obras: this.l_obras,
        obra_id: 0,
        funcoes: [],
        funcionarios: []
      }
    },
    mounted: function () {
      if(this.obras.length == 1) {
        this.obra = this.obras[0] ;
        this.$nextTick(function () {
          this.setaObra();
        })
      }
    },
    methods: {
      setaObra: function () {
        if (! this.obra) { return null };
        this.$parent.$emit('painel_apo_seta_obra', this.obra);
        // console.log('Emitida obra: ' + this.obra.id);
        this.carregaFuncFuncionarios();
      },
      carregaFuncFuncionarios: function () {
        var that = this;
        $.ajax({
          method: 'get',
          data: {
            obra_id: that.obra.id
          },
          url: '/obras/' + this.obra.id + '/func_funcionarios.json',
          success: function(res) {
            that.funcoes = res.funcoes ;
            that.funcionarios = res.funcionarios ;
          }
        });
      }
    },
    computed: {
      obras_text: function() {
        var obrs = this.obras.map(function(obra) {
          return {
            id: obra.id,
            text: obra.apelido
           }
        })
      return obrs ;
      },
      obra_selecionada: {
        get: function() { return this.obra } ,
        set: function(newObra) {
          this.obra = newObra ;
          this.setaObra() ;
        }
      }
    }
  }

</script>

