var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.mounted
    ? _c("div", { staticClass: "quemequem row col-sm-12" }, [
        _c("div", { staticClass: "col-sm-12 header p-b-20" }, [
          _c("h1", { staticClass: "col-sm-2" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-4" },
            [
              _c("cmp-v-select", {
                attrs: {
                  options: _vm.asetores,
                  value: _vm.selected_setor_index
                },
                model: {
                  value: _vm.selected_setor_index,
                  callback: function($$v) {
                    _vm.selected_setor_index = $$v
                  },
                  expression: "selected_setor_index"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _vm.setor
            ? _c("img", {
                staticClass: "quem-e-quem",
                attrs: { src: _vm.setor.imagem_url }
              })
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }