// email_right_folder.vue
<template>
  <div class="col-lg-9 animated fadeInRight p-l-5 p-r-5">
    <div class="mail-box-header">
      <form method="get" action="index.html" class="pull-right mail-search">
        <div class="input-group">
          <input type="text"
                 class="form-control input-sm"
                 name="search"
                 v-model="filtroItens"
                 placeholder="Filtrar assunto">
          <div class="input-group-btn">
            <button type="submit" class="btn btn-sm btn-primary">
              Filtrar
            </button>
          </div>
        </div>
      </form>
      <h2>{{ inboxCaption }}</h2>
      <div class="mail-tools tooltip-demo m-t-md">
        <div class="btn-group pull-right">
          <button class="btn btn-white btn-sm"><i class="fa fa-arrow-left"></i></button>
          <button class="btn btn-white btn-sm"><i class="fa fa-arrow-right"></i></button>

        </div>
        <button class="btn btn-white btn-sm"
                data-toggle="tooltip"
                data-placement="left"
                @click="refreshBox()"
                :title="'Atualizar ' + inboxCaption">
          <i class="fa fa-refresh"></i>
          Atualizar
        </button>
        <button v-show="CanLoadNextPage()"
                class="btn btn-white btn-sm"
                data-toggle="tooltip"
                data-placement="left"
                @click="LoadNextPage()"
                title="Carregar próxima página">
          <i class="fa fa-refresh"></i>
          {{ LoadNextPageCaption() }}
        </button>
      </div>
      <div v-if="false">
        <button class="btn btn-white btn-sm" data-toggle="tooltip" data-placement="top" title="Mark as read"><i class="fa fa-eye"></i> </button>
        <button class="btn btn-white btn-sm" data-toggle="tooltip" data-placement="top" title="Mark as important"><i class="fa fa-exclamation"></i> </button>
        <button class="btn btn-white btn-sm" data-toggle="tooltip" data-placement="top" title="Move to trash"><i class="fa fa-trash-o"></i> </button>

      </div>
    </div>
    <div class="mail-box">

      <table class="table table-hover table-mail">
        <thead v-if="true">
          <th class="text-center">Nova?</th>
          <th>Remetente</th>
          <th class="text-center">Enviado</th>
          <th>Assunto</th>
          <th class="text-center">Ação</th>
        </thead>
        <tbody>
          <tr v-for="(item, index) in itemsToShow"
              :class="(item.un_read ? 'unread' : 'read')">
            <td class="check-mail"
                style="font-size: large;">
              <a v-if="item.un_read"
                 data-toggle="tooltip"
                 data-placement="top"
                 title="Nova"
                 @click="setReaded(item)">
                <span class="label label-warning pull-right">Nova</span>
              </a>

            </td>
            <td class="mail-contact">
              <a @click="readMessage(item)">
                <div class="pull-left text-center">
                  <img alt="image"
                       class="img-circle message-avatar"
                       :src="item.sender.img">
                </div>
              </a>
              <a @click="readMessage(item)">
                {{item.sender.name}}
              </a>
            </td>
            <td class="text-right mail-date">{{item.time_ago}}</td>
            <td class="mail-subject">
              <a @click="readMessage(item)">
                {{item.subject}}
              </a>
              <br>
              <a v-if="item.link_obj && (item.link_obj != '')"
                 :href="item.link_obj.href"
                 target="_blank">
                {{ item.link_obj.cap }}
                <i class="fa fa-external-link"></i>
              </a>
            </td>
            <td class=""
                style="font-size: x-large; color: gray;">
              <a data-toggle="tooltip" data-placement="top" title="Responder"
                 @click="replyMsg(item)">
                <i class="fa fa-mail-reply-all"></i>
              </a>
              <a data-toggle="tooltip" data-placement="top" title="Excluir"
                 @click="deleteConv(item)">
                <i class="fa fa-trash"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  import mbStore from 'packs/vuex/stores/mailboxer_store.js'

  import stUtils from 'packs/vuex/stores/utils_store.js'
  import Utils from './utils.vue'
  import { mapGetters } from 'vuex'

export default {
    name: 'email_folder_panel',
    mixins: [stUtils, Utils],
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        mounted: false,
        storeName: 'mailBoxerStore',
        user_id: this.options.user_id,

        filtroItens: ''
      }
    },
    created: function () {
      this.initStore(this.storeName, mbStore, {user_id: this.user_id})
      // this.dispach(this.storeName, 'load', {box: this.folder})
      this.mounted = true
    },
    watch: {
    },
    methods: {
      deleteConv: function(conv) {
        this.dispach(this.storeName, 'deleteConv', {conv: conv, folder: this.options.folder})
        this.$forceUpdate()
      },
      readMessage: function(conv) {
        this.dispach(this.storeName, 'loadConversa', conv.id)
        this.setAction('show')
        this.setReaded(conv)
      },
      setReaded: function(conv) {
        this.dispach(this.storeName, 'readConv', {conv: conv, folder: this.options.folder})
      },
      refreshBox: function() {
        this.dispach(this.storeName, 'load', {box: this.options.folder, refresh: true})
      },
      CanLoadNextPage: function() {
        return (this.current_box.page < this.current_box.total_pages)
      },
      LoadNextPage: function() {
        this.dispach(this.storeName, 'load', {box: this.options.folder, refresh: true, next_page: true})
      },
      LoadNextPageCaption: function() {
        return 'Carregar mais'
      }
    },
    computed: {
      folder: function() {
        return this.options.folder
      },
      current_box: function() {
        return this.state_box(this.folder)
      },
      ...mapGetters('mailBoxerStore', {
        box: 'box',
        state_box: 'state_box',
        unread_q: 'unread_q'
      }),
      items: function() {
        return this.box(this.folder)
      },
      itemsToShow: function() {
        var filtered = this.items ;
        if (! this.filtroItens == '') {
          var that_filtro = this.filtroItens ;
          filtered = filtered.filter(function (em) {
            return (em.subject.toUpperCase().indexOf(that_filtro.toUpperCase()) >= 0) ;
          });
        }
        return filtered
      },
      inboxCaption: function() {
        var cap = this.folderCaption(this.folder) + ' '
        if ((this.folder == 'inbox') &&(this.unread_q > 0)) {
          cap = cap + `(${this.unread_q})`
        }
        return cap
      }
    }
  }
</script>
