<template>
  <div :class="klass">
    <columns-list :columns="options.options">
    </columns-list>
  </div>
</template>

<script>
  import ColumnsList        from './columns_list.vue'

  export default {
    name: 'CrDetails',
    components: {
      'columns-list': ColumnsList
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'cr_details'
      }
    }
  };
</script>
