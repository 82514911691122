var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.klass }, [
    _vm.bigFiltro()
      ? _c("form", [
          _c("div", { staticClass: "col-sm-12" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "control-label text optional" }, [
                _vm._v("Obra/Negócio")
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("cmp-v-select", {
                    key: _vm.refreshId,
                    attrs: {
                      input_class: "form-control select select2",
                      options: _vm.obrasOptions(),
                      required: true,
                      multiple: true
                    },
                    model: {
                      value: _vm.obra_ids,
                      callback: function($$v) {
                        _vm.obra_ids = $$v
                      },
                      expression: "obra_ids"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label text optional" }, [
                  _vm._v("Data de Emissão (desde)")
                ]),
                _vm._v(" "),
                _c("cmp-datepicker", {
                  key: _vm.refreshId,
                  attrs: { id: "emissao_ini" },
                  model: {
                    value: _vm.emissao_ini,
                    callback: function($$v) {
                      _vm.emissao_ini = $$v
                    },
                    expression: "emissao_ini"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label text optional" }, [
                  _vm._v("Data de Emissão (até)")
                ]),
                _vm._v(" "),
                _c("cmp-datepicker", {
                  key: _vm.refreshId,
                  attrs: { id: "emissao_fim" },
                  model: {
                    value: _vm.emissao_fim,
                    callback: function($$v) {
                      _vm.emissao_fim = $$v
                    },
                    expression: "emissao_fim"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2 col-sm-offset-2" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "control-label text optional" }, [
                _vm._v("Linhas por página")
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("cmp-v-select", {
                    key: _vm.refreshId,
                    attrs: {
                      input_class: "form-control select select2",
                      options: _vm.perpageOptions(),
                      required: true
                    },
                    model: {
                      value: _vm.per_page,
                      callback: function($$v) {
                        _vm.per_page = $$v
                      },
                      expression: "per_page"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-12" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2 col-sm-offset-4" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label text optional" }, [
                  _vm._v("Data de Vencimento (desde)")
                ]),
                _vm._v(" "),
                _c("cmp-datepicker", {
                  key: _vm.refreshId,
                  attrs: { id: "vencimento_ini" },
                  model: {
                    value: _vm.vencimento_ini,
                    callback: function($$v) {
                      _vm.vencimento_ini = $$v
                    },
                    expression: "vencimento_ini"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label text optional" }, [
                  _vm._v("Data de Vencimento (até)")
                ]),
                _vm._v(" "),
                _c("cmp-datepicker", {
                  key: _vm.refreshId,
                  attrs: { id: "vencimento_fim" },
                  model: {
                    value: _vm.vencimento_fim,
                    callback: function($$v) {
                      _vm.vencimento_fim = $$v
                    },
                    expression: "vencimento_fim"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-12" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2 col-sm-offset-4" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label text optional" }, [
                  _vm._v("Data Prevista (desde)")
                ]),
                _vm._v(" "),
                _c("cmp-datepicker", {
                  key: _vm.refreshId,
                  attrs: { id: "prevista_ini" },
                  model: {
                    value: _vm.prevista_ini,
                    callback: function($$v) {
                      _vm.prevista_ini = $$v
                    },
                    expression: "prevista_ini"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label text optional" }, [
                  _vm._v("Data Prevista (até)")
                ]),
                _vm._v(" "),
                _c("cmp-datepicker", {
                  key: _vm.refreshId,
                  attrs: { id: "prevista_fim" },
                  model: {
                    value: _vm.prevista_fim,
                    callback: function($$v) {
                      _vm.prevista_fim = $$v
                    },
                    expression: "prevista_fim"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-12" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "control-label text optional" }, [
                _vm._v("Status")
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("cmp-v-select", {
                    key: _vm.refreshId,
                    attrs: {
                      input_class: "form-control select select2",
                      options: _vm.statusOptions(),
                      required: true,
                      multiple: true
                    },
                    model: {
                      value: _vm.status,
                      callback: function($$v) {
                        _vm.status = $$v
                      },
                      expression: "status"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label text optional" }, [
                  _vm._v("Valor (desde)")
                ]),
                _vm._v(" "),
                _c("cmp-autonumeric", {
                  key: _vm.refreshId,
                  attrs: { decimal_places: 2, min: 0 },
                  model: {
                    value: _vm.valor_ini,
                    callback: function($$v) {
                      _vm.valor_ini = $$v
                    },
                    expression: "valor_ini"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label text optional" }, [
                  _vm._v("Valor (até)")
                ]),
                _vm._v(" "),
                _c("cmp-autonumeric", {
                  key: _vm.refreshId,
                  attrs: { decimal_places: 2, min: 0 },
                  model: {
                    value: _vm.valor_fim,
                    callback: function($$v) {
                      _vm.valor_fim = $$v
                    },
                    expression: "valor_fim"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-12" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group optional text_search" }, [
              _c(
                "label",
                { staticClass: "control-label text_search optional" },
                [_vm._v("Pesquisar texto")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.text_ts,
                    expression: "text_ts"
                  }
                ],
                key: _vm.refreshId,
                staticClass: "form-control string text_search optional",
                attrs: { type: "text", id: "text_search" },
                domProps: { value: _vm.text_ts },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.text_ts = $event.target.value
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "control-label text optional" }, [
                _vm._v("... nos seguntes campos")
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("cmp-v-select", {
                    key: _vm.refreshId,
                    attrs: {
                      input_class: "form-control select select2",
                      options: _vm.fieldsOptions(),
                      required: true,
                      multiple: true
                    },
                    model: {
                      value: _vm.fields_ts,
                      callback: function($$v) {
                        _vm.fields_ts = $$v
                      },
                      expression: "fields_ts"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      : _c("form", [
          _c("div", { staticClass: "col-sm-12" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "control-label text optional" }, [
                _vm._v("Obra/Negócio")
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("cmp-v-select", {
                    key: _vm.refreshId,
                    attrs: {
                      input_class: "form-control select select2",
                      options: _vm.obrasOptions(),
                      required: true,
                      multiple: true
                    },
                    model: {
                      value: _vm.obra_ids,
                      callback: function($$v) {
                        _vm.obra_ids = $$v
                      },
                      expression: "obra_ids"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label text optional" }, [
                  _vm._v("Data de Vencimento (desde)")
                ]),
                _vm._v(" "),
                _c("cmp-datepicker", {
                  key: _vm.refreshId,
                  attrs: { id: "vencimento_ini" },
                  model: {
                    value: _vm.vencimento_ini,
                    callback: function($$v) {
                      _vm.vencimento_ini = $$v
                    },
                    expression: "vencimento_ini"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label text optional" }, [
                  _vm._v("Data de Vencimento (até)")
                ]),
                _vm._v(" "),
                _c("cmp-datepicker", {
                  key: _vm.refreshId,
                  attrs: { id: "vencimento_fim" },
                  model: {
                    value: _vm.vencimento_fim,
                    callback: function($$v) {
                      _vm.vencimento_fim = $$v
                    },
                    expression: "vencimento_fim"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2 col-sm-offset-2" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { staticClass: "control-label text optional" }, [
                _vm._v("Linhas por página")
              ]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("cmp-v-select", {
                    key: _vm.refreshId,
                    attrs: {
                      input_class: "form-control select select2",
                      options: _vm.perpageOptions(),
                      required: true
                    },
                    model: {
                      value: _vm.per_page,
                      callback: function($$v) {
                        _vm.per_page = $$v
                      },
                      expression: "per_page"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-12" }),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2 col-sm-offset-4" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label text optional" }, [
                  _vm._v("Data Prevista (desde)")
                ]),
                _vm._v(" "),
                _c("cmp-datepicker", {
                  key: _vm.refreshId,
                  attrs: { id: "prevista_ini" },
                  model: {
                    value: _vm.prevista_ini,
                    callback: function($$v) {
                      _vm.prevista_ini = $$v
                    },
                    expression: "prevista_ini"
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-2" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "control-label text optional" }, [
                  _vm._v("Data Prevista (até)")
                ]),
                _vm._v(" "),
                _c("cmp-datepicker", {
                  key: _vm.refreshId,
                  attrs: { id: "prevista_fim" },
                  model: {
                    value: _vm.prevista_fim,
                    callback: function($$v) {
                      _vm.prevista_fim = $$v
                    },
                    expression: "prevista_fim"
                  }
                })
              ],
              1
            )
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }