// quem_e_quem.vue
<template>
  <div v-if="mounted"
       class="quemequem row col-sm-12">
    <div class="col-sm-12 header p-b-20">
      <h1 class="col-sm-2"></h1>
      <div class="col-sm-4">
        <cmp-v-select
          :options="asetores"
          :value="selected_setor_index"
          v-model="selected_setor_index"
        ></cmp-v-select>
      </div>
    </div>

    <div>
      <img v-if="setor"
           class="quem-e-quem"
           :src="setor.imagem_url">
    </div>

  </div>
</template>

<script>

  import CmpVSelect         from 'packs/components/comp/cmp-v-select.vue'

  export default {
    name: 'QuemEQuem',
    props: ['options'],
    data: function () {
      return {
        mounted: false,
        menu_ativo: [],

        asetores: this.options.setores,
        selected_setor_index: null
      }
    },
    components: {
      'cmp-v-select'    : CmpVSelect
    },
    methods: {
    },
    computed: {
      setor: function () {
        if (this.selected_setor_index > -1) {
          return this.asetores[this.selected_setor_index]
        } else {
          return null
        }
      }
    },
    mounted: function() {
      if ((this.selected_setor_index != "undefined") && (this.selected_setor_index > -1)) {
        this.$set(this, 'selected_setor_index', this.asetores[0].value)
      }
      this.mounted = true
    }
  }
</script>

<style scoped>

img.quem-e-quem {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

</style>

