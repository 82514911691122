// select-user-obra.vue
<template>
  <form class="col-sm-xx form-horizontal">
    <div class="form-group select optional">
      <label class="control-label select required col-sm-2"
             for="user_id">Usuário:</label>
      <div class="col-sm-10">
        <cmp-v-select
                input_class="form-control select select2"
                :options="options.users"
                :required="true"
                :multiple="false"
                :value="user_id"
                :onChange="avisaMudanca()"
                v-model="user_id">
        </cmp-v-select>
      </div>
    </div>

    <div class="form-group select optional">
      <label class="control-label select required col-sm-2"
             for="obra_id">Obra:</label>
      <div class="col-sm-10">

        <cmp-v-select
                input_class="form-control select select2"
                :options="options.obras"
                :required="true"
                :multiple="false"
                :value="obra_id"
                v-model="obra_id">
        </cmp-v-select>
      </div>
    </div>

  </form>
</template>

<script>

  import CmpVSelect from 'packs/components/comp/cmp-v-select.vue'

  export default {
    name: 'SelectUserObra',
    components: {
      'cmp-v-select'           : CmpVSelect
    },
    props: ['options'],

    data: function () {
      return {
        loaded: false,

        user_id: this.options.current_user_id,
        obra_id: this.options.current_obra_id
      }
    },

    mounted: function() {
      this.$set(this, 'user_id', this.options.current_user_id)
      this.$set(this, 'obra_id', this.options.current_obra_id)
      this.loaded = true
    },

    methods: {
      avisaMudanca: function() {
        if (! this.loaded) {return}
        // console.log('Selecionado user: ' + this.user_id)
        // console.log('Selecionado obra: ' + this.obra_id)
        this.$parent.$emit('tarefasPendentes', {
          user_id: this.user_id,
          obra_id: this.obra_id
        })
      }
    },

    computed: {
    }

  };
</script>
