<template>
  <div :class="klass" class="index-causas">
    <div v-if="monted"
         class="">
      <cat-causa v-for="(categoria, index) in categorias2Show"
                 :categoria="categoria"
                 :key="index"
                 :id="index">
      </cat-causa>
    </div>
  </div>
</template>

<script>

  import CatCausa  from './cat_causa.vue'

  export default {
    name: 'IndexCausas',
    components: {
      'cat-causa': CatCausa
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'index-causas',
        type: 'index_causas',
        domid: this.options.id,
        categorias: [],
        monted: false,

        url: this.options.datasource,

        nada: 'nada'
      }
    },
    mounted: function() {
      this.refresh() ;
      this.monted = true

    },
    computed: {
      categorias2Show: function() {
        return this.categorias
      }
    },
    methods: {
      refresh: function() {
        var that = this
        $.ajax({
          url: that.url + '.json',
          type: 'get',
          format: 'json',
          success: function(data) {
            that.$set(that, 'categorias', data)
            // that.categorias = data
          },
          error: function(error) {
          },
          complete: function(response) {
          }
        })
      }
    }
  };
</script>

<style scoped>

</style>
