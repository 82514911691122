<template>
  <div class="auth-shared-title logo-element div-logo-big">

    <div>
      <h1 class="logo-name"></h1>
    </div>


  </div>
</template>

<script>

export default {
    name: 'AuthSharedTitle',
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        what: 'Im AuthSharedTitle!',
        opts: this.options
      }
    },
    mounted: function () {
    },
    methods: {
    },
    computed: {
      action: function () {
        return 'xxx';
      }
    }
  }
</script>
