// cr_google_maps.vue
<template>
  <div>
    <div v-if="! show_mapa_div"
         class="col-sm-12"
         id="btn">
      <button
        class=" btn btn-primary"
        @click="show_mapa()">
        Mostrar Mapa
      </button>
    </div>
    <div class="col-sm-12"
         style="height: 400px; position: relative; overflow: hidden;"
         id="chart_div"
         :key="show_inc">
    </div>
  </div>
</template>

<script>


  export default {
    name: 'CrGoogleMaps',
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'google_map',

        show_mapa_div: false,
        show_inc: 0,

        api_key: "AIzaSyC66uE1Vdw0yldIyyIANONTWx3n-gWqhHY"
      }
    },
    mounted: function() {
        // this.load_google_charts() ;

    },
    methods: {
      show_mapa: function() {
        this.show_mapa_div = true
        // this.show_inc += 1
        this.load_google_charts() ;
      },
      load_google_charts: function() {
        google.charts.load("current", {
          "packages":["map"],
          "mapsApiKey": this.api_key
        })
        google.charts.setOnLoadCallback(this.drawChart);
      },
      drawChart: function() {
        if (this.options.options.nome_maps) {
          var data = new google.visualization.DataTable();
          data.addColumn('string', 'Address');
          data.addColumn('string', 'Location');
          data.addRows([
            [ this.options.options.nome_maps,
              this.options.options.name
            ]
          ]);
        } else {
          var data = google.visualization.arrayToDataTable([
            [ 'Lat', 'Long', 'Name'],
            [ Number(this.options.options.lat),
              Number(this.options.options.long),
              this.options.options.name
            ]
          ]);
        }
        var options = {
          enableScrollWheel: true,
          mapType: 'terrain',
          zoomLevel: 12,
          showTooltip: true,
          showInfoWindow: true,
          useMapTypeControl: true
        }
        var mapa = new google.visualization.Map(document.getElementById('chart_div'));
        mapa.draw(data, options);
      }
    }
  }
</script>

<style scoped>
  #chart_div {
    height: 400px;
    position: relative;
    overflow: hidden;
  }
</style>
