// ibox-list.vue
<template>
  <div class="ibox float-e-margins">
    <slot name="ibox-title"></slot>
    <slot name="ibox-content"></slot>

    </slot>
  </div>
</template>

<script>

  export default {
    name: 'IBox',
    components: {
    },
    props: ['options'],

    data: function () {
      return {
      }
    },

    mounted: function() {
    },

    methods: {
    },

    computed: {
    }

  };
</script>
