// form-cab.vue
<template>
  <div class="test-form-render">

    <div v-if="mostraModal()"
         id="modal-teste-form"
         aria-hidden="true"
         role="dialog"
         class="modal fade"
         style="display: none; padding-right: 15px;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <form-cab :header="header"></form-cab>
          </div>
          <div class="modal-body">

            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Campo</th>
                  <th>Valor informado</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(key, index) in keyList">
                  <th>{{ index + 1 }}</th>
                  <th>{{ key }}</th>
                  <th>{{ record[key] }}</th>
                </tr>
              </tbody>

            </table>

          </div>
          <div class="modal-footer">
            <a data-dismiss="modal" class="btn">Fechar</a>
          </div>
        </div>
      </div>
    </div>

    <div v-else
         id="teste-form"
         style="display: none;">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>Campo</th>
            <th>Valor informado</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(key, index) in keyList">
            <th>{{ index + 1 }}</th>
            <th>{{ key }}</th>
            <th>{{ record[key] }}</th>
          </tr>
        </tbody>
      </table>
      <a class="btn"
         @click="closeFlat()"
      >Fechar resultado do Teste</a>
    </div>

  </div>
</template>

<script>

  import FormCab from "./form-cab.vue";

  export default {
    name: 'TesteFormRender',
    props: ['receivedrecord', 'modal'],
    data: function() {
      return {
        record: this.receivedrecord,

        header: {
          h2: 'Formulário Modelo',
          title: 'Dados ingressados'
        }
      }
    },
    components: {
      'form-cab': FormCab
    },
    methods: {
      mostraModal: function() {
        var mostra_plano = (this.modal && (this.modal == 'false'))
        return ! mostra_plano;
      },
      mostraOcultaModal: function() {
        if (this.record) {
          $('#modal-teste-form').modal('show')
          $('#teste-form').show();
        } else {
          $('#modal-teste-form').modal('hide')
          $('#teste-form').hide();
        }
      },
      setResult: function(new_res) {
        this.record = new_res
      },
      closeFlat: function() {
        $('#teste-form').hide();
      }
    },
    watch: {
      record: function(new_record) {
        this.mostraOcultaModal()
      }
    },
    computed: {
      keyList: function() {
        if (this.record) {
          return Object.keys(this.record)
        }
      }
    }
  }
</script>

