var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.options.components, function(compo, indexComp) {
      return _c(_vm.bodyCommponent(compo.ctype), {
        key: _vm.refreshId + indexComp,
        ref: compo.ref,
        refInFor: true,
        tag: "component",
        class: compo.class,
        attrs: { id: compo.id, options: compo }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }