<template>
  <div class="row clearfix p-b-10">
    <div class="ibox border-grayed m-b-5"
         style="padding: 5px;">

      <div class="ibox-title  small-margin">
        <h5>Envio de Caixa</h5>
        <div class="ibox-tools">

          <a v-for="(act, index) in options.options.actions"
            :data-remote="act.others['data-remote']"
            data-toggle="tooltip"
            data-placement="top" title=""
            :href="act.href"
            :data-original-title="act.others.title">
            <i :class="act.i_class"></i>
          </a>

          <a
             class="collapse-link">
            <i class="fa fa-chevron-up"></i>
          </a>
        </div>
      </div>
      <div class="ibox-content">
        <table class="table table-hover no-margins no-entrelinhas">
          <tbody>
            <tr>
              <td>Assunto:</td>
              <td>{{options.options.envio.nome}}</td>
            </tr>
            <tr>
              <td>Status:</td>
              <td>{{options.options.envio.status}}</td>
            </tr>
            <tr>
              <td>Remetente:</td>
              <td>{{options.options.envio.remetente}}</td>
            </tr>
            <tr>
              <td>Destinatário:</td>
              <td>{{options.options.envio.destinatario}}</td>
            </tr>
            <tr>
              <td>Emprestimo:</td>
              <td>{{options.options.envio.emprestimo}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="ibox-footer">
        <a v-for="(btt, index) in buttons"
          type="button"
          :href="btt[1]"
          :key="refresh_buttons"
          data-remote="true"
          class="btn btn-primary btn-xs m-l-5 m-r-5">{{ btt[0] }}</a>
        <span
          v-if="(options.options.aviso && (buttons.length == 0))"
          :key="refresh_buttons"
        >
          {{options.options.aviso}}
        </span>
      </div>
    </div>

  </div>
</template>

<script>


  export default {
    name: 'PainelEnvio',
    components: {
    },
    props: ['options'],

    data: function () {
      return {
        klass: 'observadores-panel',
        type: 'painel_envio',
        domid: 'painel_envio',
        buttons: this.options.options.buttons,
        refresh_buttons: 0,
        incluindo: false
      }
    },

    mounted: function() {
      window.painel_envio = this;
      this.buttons = this.options.options.buttons;
    },

    computed: {
    },

    methods: {
      refresh: function() {
        var that = this
        $.ajax({
          url: that.options.options.refresh_url + '.json',
          type: 'get',
          format: 'json',
          success: function(data) {
            that.$set(that.options, 'options', data.options)
            that.buttons = that.options.options.buttons;
            that.refresh_buttons += 1
          },
          error: function(error) {
          },
          complete: function(response) {
          }
        })
      },
      stopEditing: function() {
        this.incluindo = false
      }
    }
  };
</script>

<style type="text/css">

  table.no-entrelinhas > tbody > tr > td {
    border-top-style: none;
    padding: 2px;
  }

</style>
