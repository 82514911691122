// tableUtils.vue

<script>
  export default {
    methods: {
      tableId: function(tab_id) {
        // Tem '_data_table' no final?
        var last11 = tab_id.substr(tab_id.length - 11)
        var sufix = ((last11 == '_data_table') ? '' : '_data_table' )

        return tab_id + sufix ;
      }
    }
  }
</script>

