<template>
  <div :class="klass">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h2>{{ options.title }}</h2>
        <div class="ibox-tools">
          <a @click="refresh()"
             class="btn btn-primary btn-xs">
           Atualizar
         </a>
       </div>
      </div>
      <div class="ibox-content"
           style="border-style: none;">
        <div class="row">
          <div class="col-sm-12">
            <vc-line-chart
              :options="opts2Chart"
              ref="thechart"
            >
            </vc-line-chart>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

  // import VcLineChart from 'packs/components/charts/vc-line-chart.vue';

  export default {
    name: 'CrAbaChart',
    components: {
      // 'vc-line-chart':  VcLineChart
    },
    props: ['options'],
    data: function () {
      return {
        type: 'cr_chart',
        domid: 'cr_chart',
        klass: 'cr-aba-chart',
        opts: this.options,

        text: '',
        text_org: '',
        action: 'show'
      }
    },
    mounted: function() {
      this.text = this.opts.text ;
    },
    methods: {
      refresh: function () {
        var that = this;
        $.ajax({
          method: 'get',
          url: that.opts.url + '.json',
          data: { refresh: true },
          success: function(res) {
            that.$set(that.opts, 'dados', res.aba_grafico.dados);
            that.$refs.thechart.drawChart()
          }
        });
      },
    },
    computed: {
      opts2Chart: function() {
        return {
          options: this.options.options,
          data: this.opts.dados
        }
      }
    }
  };
</script>
