<template>
  <div v-if="model.opts">

    <div v-if="model.opts.scopes" class="select_scope">
      <div v-for="(scope, index) in model.opts.scopes"
           :class="scope.div_class">
        <cmp-v-select v-if="scope.options"
                input_class="form-control select"
                :options="scope.options"
                :key="'refreshId88'"
                :required="true"
                :value="scope.value"
                v-model="scope_sel[index]">
        </cmp-v-select>
        <button v-if="scope.type == 'button'"
                :class="scope.btn_class"
                v-on:click="getButton(scope.url)">
          {{ scope.caption }}
        </button>
      </div>
    </div>

    <cr-show
      :opts="opts2cr_show"
      :key="refreshId"
      ref="show_comp"
    >
    </cr-show>

  </div>
</template>

<script>
  import CrTitle       from './cr_title.vue'
  import CrShowMulti   from './cr_show_multi.vue'
  import CmpVSelect    from 'packs/components/comp/cmp-v-select.vue'


  export default {
    name: 'CrScopedShow',
    components: {
      'cr-title'          : CrTitle,
      'cmp-v-select'      : CmpVSelect,
      'cr-show'           : CrShowMulti
    },
    props: ['opts'],
    data: function () {
      return {
        what: 'Im Cr ScopedShow!',
        type: 'cr_show',
        domid: 'scoped_show',

        ready: false,
        refreshId: 0,

        model : {
          opts: null
        },
        scope_old: [null, null, null],
        scope_sel: [null, null, null]
      }
    },
    mounted: function() {
      if (this.opts.scopes) {
        var scopes = this.opts.scopes
        for (var i = 0, len = scopes.length; i < len; i++) {
          // this.scope_sel[i] = scopes[i].value
          this.$set(this.scope_sel, i, scopes[i].value)
          this.$set(this.scope_old, i, scopes[i].value)

        }
      }
      this.model.opts = this.opts ;
      this.refreshId += 1;
    },
    methods: {
      refresh: function () {
        this.ready = false;
        var that = this;
        var data = { refresh: true };
        data = this.prepareScopes(data);
        $.ajax({
          method: 'get',
          url: that.model.opts.model_url + '.json',
          data: data,
          success: function(res) {
            that.$set(that.model.opts, 'body', res.opts.body);
            that.refreshId += 100
            that.$refs.show_comp.refreshId += 100
          }
        });
      },
      prepareScopes: function(data) {
        if (this.opts.scopes && this.opts.scopes[0] && this.scope_sel[0]) {
          data[this.opts.scopes[0].param] = this.scope_sel[0]
        }
        if (this.opts.scopes && this.opts.scopes[1] && this.scope_sel[1]) {
          data[this.opts.scopes[1].param] = this.scope_sel[1]
        }
        if (this.opts.scopes && this.opts.scopes[2] && this.scope_sel[2]) {
          data[this.opts.scopes[2].param] = this.scope_sel[2]
        }
        return data
      }
    },
    created: function () {
    },
    updated: function () {
      this.ready = true ;

    },
    computed: {
      opts2cr_show: function() {
        return this.model.opts ;
      }
    },
    watch: {
      scope_sel: function(new_scope_sel) {
        if ((new_scope_sel[0] != this.scope_old[0]) ||
            (new_scope_sel[1] != this.scope_old[1]) ||
            (new_scope_sel[2] != this.scope_old[2])) {
          this.refresh() ;
          this.scope_old[0] = new_scope_sel[0] ;
          this.scope_old[1] = new_scope_sel[1] ;
          this.scope_old[2] = new_scope_sel[2] ;
        }
      }
    }
  }
</script>
