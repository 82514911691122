var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "index-causas", class: _vm.klass }, [
    _vm.monted
      ? _c(
          "div",
          {},
          _vm._l(_vm.categorias2Show, function(categoria, index) {
            return _c("cat-causa", {
              key: index,
              attrs: { categoria: categoria, id: index }
            })
          }),
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }