<template>
  <div class="row clearfix p-b-10">
    <ibox-list :options="optionsToIbox()"></ibox-list>
    <div v-if="((outrosUsuarios.length > 0) && ! incluindo)"
         class="pull-right text-right ibox-tools">
      <a class="btn btn-primary btn-xs"
         @click="incluindo = true">
        <i class="fa fa-eye"></i>
        Incluir seguidor
      </a>
    </div>
    <div v-if="false && ((outrosUsuarios.length > 0) && incluindo)"
         class="pull-right text-right ibox-tools">
      <a class="btn btn-primary btn-xs"
         @click="incluindo = false">
        Concluir inclusão
      </a>
    </div>
    <ibox-list v-if="((outrosUsuarios.length > 0) && incluindo)"
               :options="optionsOutrosToIbox()"></ibox-list>
  </div>
</template>

<script>

  import iboxList from 'packs/components/widgets/ibox-list.vue'


  export default {
    name: 'ObservadoresPanel',
    components: {
      'ibox-list': iboxList
    },
    props: ['options'],

    data: function () {
      return {
        klass: 'observadores-panel',
        incluindo: false
      }
    },

    mounted: function() {
      this.initStore() ;
    },

    computed: {
      observadores() {
        this.initStore()  // ???
        return this.$store.getters['ObservadorStore/index']
      },
      currentObservando() {
        return this.$store.getters['ObservadorStore/currentObservando']
      },
      outrosUsuarios() {
        return this.$store.getters['ObservadorStore/outrosUsuarios']
      }
    },

    methods: {
      initStore: function() {
        this.$store.dispatch(
          'ObservadorStore/init', {
            id:  this.options.options.id,
            type:this.options.options.type
          }
        )
      },
      optionsToIbox: function() {
        return {
          title: {
            caption: 'Seguidores',
            buttonCollapse: true,
            buttonClose: false,
            button: {
              if: {
                getter: 'notCurrentObservando'
              },
              caption: 'Seguir',
              class: 'btn btn-primary btn-xs',
              block : this.addMe
            }
          },
          store: 'ObservadorStore',
          collection: 'list',
          itemActions: {
            show: {},
            destroy: {}
          }
        }
      },
      optionsOutrosToIbox: function() {
        return {
          title: {
            caption: 'Outros funcionários',
            buttonCollapse: false,
            buttonClose: {
              block: this.stopEditing
            }
          },
          store: 'ObservadorStore',
          collection: 'outrosUsuarios',
          itemActions: {
            show: {},
          },
          itemOtherActions: [
            {
              name: 'add',
              icon: 'fa fa-eye',
              block : this.addUser
            }
          ]
        }
      },
      removeObservador: function(observer_id) {
        this.$store.dispatch(
          'ObservadorStore/remove', observer_id
        )
        this.$root.$emit('notificacao-refresh')
      },
      addMe: function() {
        var me_id = this.$store.getters.current_user.id
        this.$store.dispatch(
          'ObservadorStore/addUser', me_id
        )
        this.$root.$emit('notificacao-refresh')
      },
      addUser: function(novo) {
        this.$store.dispatch(
          'ObservadorStore/addUser', novo.id
        )
        this.$root.$emit('notificacao-refresh')
      },
      stopEditing: function() {
        this.incluindo = false
      }
    }
  };
</script>
