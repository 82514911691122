<template>
  <div :class="klass" class="project-list">
    <div v-if="(options.multiplos && options.multiplos_up)"
         class="col-sm-12">
      <div class="col-sm-9 select_multiple row">
        <div class="col-sm-12">
          <button v-for="(mbtn, index) in options.multiplos"
                  :ref="mbtn.action"
                  :class="mbtn.class"
                  :key="index"
                  @click="submMultiploBtn(mbtn)"

                  type="button">
            <i :class="mbtn.i_class"></i>
            <span :ref="mbtn.action + '_caption'">
             </span>
          </button>
        </div>
      </div>
    </div>

    <div v-if="old_filtro"
         class="old_filtros">

      <div v-if="showSearch()"
           class="row dataTables_wrapper p-b-0"
           style="display: flex;">

        <div class="input-group col-sm-3"
               style="display: flex;">
          <input v-if="! options.no_search"
              type="search"
              class="form-control"
              placeholder="Filtrar ..."
              id="datatable_filterinputxxx"
              v-model="dt_filter"
              @keypress="catchEnterKey($event)"
          >
          <div class="input-group-append"
               style="display: flex;">
            <button type="button"
                    class="btn btn-primary"
                    @click="filterApply()"
                    style="height: 34px; padding: 6px 12px; "
            >
              Filtrar
            </button>
          </div>
        </div>

        <div v-if="(options.multiplos && ! options.multiplos_up)"
             class="col-sm-7 select_multiple row">
          <div class="col-sm-9">
            <button v-for="(mbtn, index) in options.multiplos"
                    :ref="mbtn.action"
                    :class="mbtn.class"
                    :key="index"
                    @click="submMultiploBtn(mbtn)"

                    type="button">
              <i :class="mbtn.i_class"></i>
              <span :ref="mbtn.action + '_caption'">
               </span>
            </button>
          </div>
        </div>

        <div  class="col-sm-7 select_scope">
          <div v-for="(scope, index) in options.scopes"
               :class="scope.div_class">
            <cmp-v-select v-if="scope.options"
                    input_class="form-control select"
                    :options="scope.options"
                    :key="conta"
                    :required="true"
                    :value="scope.value"
                    :index="index"
                    :onSelected="updateScope"
                    v-model="scope_sel[index]">
            </cmp-v-select>
            <button v-if="scope.type == 'button'"
                    :class="scope.btn_class"
                    v-on:click="getButton(scope.url)">
              {{ scope.caption }}
            </button>
            <cmp-datepicker v-if="scope.type == 'datepicker'"
              :key="conta"
              :id="'dt_scope_' + index"
              v-model="scope_sel[index]">
            </cmp-datepicker>


          </div>
        </div>

        <div class="col-sm-2 text-right">
          <div class="dataTables_length"
            id="obra_treinamentos_realizados_data_table_length">
            <label>
              <select name="obra_treinamentos_realizados_data_table_length"   aria-controls="obra_treinamentos_realizados_data_table"
                 class="form-control input-sm"
                 v-model="per_page"
                 @change="filterApply()"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select> linhas por página
            </label>
          </div>
        </div>
      </div>

    </div>

    <div v-else>
      <cr-data-filtro
        refs="data_filtro"
        :options="opts.filtro"
      >
      </cr-data-filtro>

    </div>


    <div id="table_wrap"
         :class="table_wrap_class"
         :style="table_wrap_style">
      <table
             :class="classTable()"
             :id="tableId(options.id)"
             ref="data_table"
             data-turbolinks-permanent=""
             data-turbolinks="false">
        <thead>
          <tr>
            <th v-for="(col, index_col) in cols"
                :style="styleColumnHead(col)"
                :key="'th_index_' + index_col"
                :id="'th_index_' + index_col"
                :ref="'th_' + col.name">
              <span>{{ col.title }}</span>
            </th>
          </tr>
        </thead>
        <tbody
               ref="tbody"
        ></tbody>
        <tfoot></tfoot>
      </table>
    </div>
  </div>
</template>

<script>
  import tableUtils from 'packs/components/mixins/tableUtils.vue' ;
  import CmpVSelect from  'packs/components/comp/cmp-v-select.vue'
  import CmpCheckbox from 'packs/components/comp/cmp-checkbox.vue'
  import CmpDatePicker from 'packs/components/comp/cmp-datepicker.vue'
  import numberMask from 'packs/components/mixins/numberMask.vue'

  import CrDataFiltro from 'packs/components/crud/cr_data_filtro.vue'

  export default {
    name: 'CrDataTable',
    components: {
      'cmp-v-select'    : CmpVSelect,
      'cmp-checkbox'    : CmpCheckbox,
      'cmp-datepicker'  : CmpDatePicker,
      'cr-data-filtro'  : CrDataFiltro
    },
    mixins: [tableUtils, numberMask],
    props: ['options', 'index'],
    data: function () {
      return {
        klass: 'cr-data-table',

        type: 'cr_datatable',
        domid: this.options.id,

        conta: 0,

        opts: this.options,

        dt_filter: '',
        per_page: (this.options.per_page || 10),

        scope_old: [null, null, null],
        scope_sel: [null, null, null],

        multiple_on: false,
        multiple_ids: [],
        multiple_valor_selecionado: 0,
        multi_inc: 0,

        tableMounted: false,

        form_filtro: null,

        outroqqer: null

      }
    },
    mounted: function() {
      if (this.options.scopes) {
        var scopes = this.options.scopes
        for (var i = 0, len = scopes.length; i < len; i++) {
          // this.scope_sel[i] = scopes[i].value
          this.$set(this.scope_sel, i, scopes[i].value)

        }
      }

      this.initDataTable();

      this.conta += 1
      if (this.options.multiplos) {
        this.multiple_on = true
      } else {
        this.multiple_on = false
      }

    },
    watch: {
//       per_page: function (val) {
//         var table = $(this.$refs.data_table).DataTable();
//
//         table.page.len( Number(val) );
//         table.draw();
//       },
      // dt_filter: function (val) {
      //   if (this.options.collection) {}
      // },
      multiple_on: function(val) {
        (val) ? this.initMultiple() : this.closeMultiple()
      },
      scope_sel: function(new_scope_sel) {
        if ((new_scope_sel[0] != this.scope_old[0]) ||
            (new_scope_sel[1] != this.scope_old[1]) ||
            (new_scope_sel[2] != this.scope_old[2])) {

          this.scope_old[0] = new_scope_sel[0] ;
          this.scope_old[1] = new_scope_sel[1] ;
          this.scope_old[2] = new_scope_sel[2] ;


          if (this.options.scopes[0].url && this.tableMounted) {
            this.refresh_table_ajax(this.options.scopes[0].url);
          }

          if (this.options.multiplos && (this.options.multiplos.length > 0)) {
            this.resetMultiplosSelected()
          }
        }
      }
    },
    computed: {
      table_wrap_class: function() {
        var tw = '' ;
        if (this.options.table_wrap_class) { tw += this.options.table_wrap_class }
        return tw ;
      },
      table_wrap_style: function() {
        var tw = '' ;
        if (this.options.table_wrap_style) { tw += this.options.table_wrap_style }
        return tw ;
      },
      tableDom: function() {
        var dom = ""
        var quantReg = (this.options.record_count || 0)
        if ((this.options.collection) &&
            (this.options.collection.size > 10)) {
          quantReg = this.options.collection.size
        }

        var search = ((this.options.search) ||
                      (quantReg > 9)) &&
                      ! this.options.hide_search

        // if (search) {
        //   dom = "<'row'<'col-sm-3'f>" +
        //            "<'col-sm-6 select_scope'>" +
        //            "<'col-sm-3 text-right'l>>"
        // } else {
        //   dom = "<'row'<'col-sm-3'>" +
        //            "<'col-sm-6 select_scope'>" +
        //            "<'col-sm-3 text-right'>>"
        // }

        // A prpria tabela
        dom = dom + "<'row'<'col-sm-12'tr>>"

        if ((search) || (this.options.force_footer)) {
          dom = dom + "<'row'<'col-sm-5'i><'col-sm-2's><'col-sm-5'p>>"
        } else {
          dom = dom + "<<'hidden'i>>"
        }
        return dom ;
      },
      cols: function() {
        return this.options.columns.adef
      },
      collection_filt: function() {
        // var filtered = this.options.collection
        var filtered = this.opts.collection
        if (this.dt_filter && (this.dt_filter != '')) {
          var that_filtro = this.dt_filter.toUpperCase() ;
          var cols2 = this.columns2search
          filtered = filtered.filter(function (lin) {
            let matched = false
            cols2.every(function(col) {
              // debugger
              if ((! matched) && (lin[col]))  {
                matched = (lin[col].toUpperCase().indexOf(that_filtro) >= 0)
              }
              return true
            })
            return matched
          })
        }
        return filtered;
      },
      columns2search: function() {
        let cols = this.cols ;
        let index_cols = []
        cols.forEach(function(col, index) {
          if (col.search) { index_cols.push(index) }
        })
        return index_cols ;
      },

      old_filtro: function() {
        return ! this.options.filtro ;
      },

      somandoValoresSelecionados: function() {
        var pos = this.cols.findIndex(col => col.name == 'float_valor')
        return (pos > -1);
      }

    },
    methods: {
      catchEnterKey: function ($event) {
        if ($event.which == 13) {
          this.filterApply()
        }
      },
      updateScope: function(index, obj, id) {
        this.scope_sel[index] = obj.value ;
        this.filterApply()
      },
      filterApply: function(ele) {

        if (this.options.collection) {
          this.initDataTable(true)
        } else {
          var table = $(this.$refs.data_table).DataTable();

          table.page.len( Number(this.per_page) );
          table.draw();
        }
      },
      styleColumnHead: function(col) {
        var sty = {
          width: col.width,
          'background-color': col.colorback,
          color: col.colorfront
        }
        return sty ;
      },
      classTable: function() {
        var def = "table table-hover display";
        if (this.options.table_wrap) {
          def += ' wrap'
        } else {
          def += ' wrap'
        }
        return def;
      },
      dataTableOptions: function() {
        var that = this;
        var opts_responsive = {
          responsive: true,
          scrollX: false
        }
        // if (this.options.scrollx) {
        //   opts_responsive.responsive = false;
        //   opts_responsive.scrollX    = true;
        // }
        var opts = {
          destroy: true,

          // autoWidth: false,

          // responsive: true,
          // scrollX: false,

          // responsive: {
          //   details: false
          // },
          processing: true,

          fixedHeader: {
            header: true,
            footer: false
          },

          drawCallback: function( settings ) {
            // $('.best_in_place').best_in_place();

            if (that.options.multiplos && (that.options.multiplos.length > 0)) {
               that.resetMultiplosSelected()
            }

          },

          infoCallback: function(settings, start, end, max, total, pre ) {
            that.atualizaLineFromAjax(settings, start, end, max, total)
            return pre ;
          },

          headerCallback: function( thead, data, start, end, display ) {
          },

          pagingType: 'full_numbers',
          columns: this.cols,
          language: {
            "sEmptyTable": "Nenhum registro encontrado",
            "sInfo": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
            "sInfoEmpty": "Mostrando 0 até 0 de 0 registros",
            "sInfoFiltered": "(Filtrados de _MAX_ registros)",
            "sInfoPostFix": "",
            "sInfoThousands": ".",
            "sLengthMenu": "_MENU_ resultados por página",
            "sLoadingRecords": "Carregando...",
            "sProcessing": "Processando...",
            "sZeroRecords": "Nenhum registro encontrado",
            "sSearch": "Pesquisar",
            "oPaginate": {
                "sNext": ">",
                "sPrevious": "<",
                "sFirst": "<<",
                "sLast": ">>"
            },
            "oAria": {
                "sSortAscending": ": Ordenar colunas de forma ascendente",
                "sSortDescending": ": Ordenar colunas de forma descendente"
            }
          },

          dom: this.tableDom,
          buttons: [],
          autoWidth: false
        }



        opts = Object.assign(opts, opts_responsive);

        var special_opts = (this.options.datatable_opts || {})
        opts = Object.assign(opts, special_opts);

        if (this.options.collection) {
          // opts.data = this.options.collectionButtonsSelect

          opts.data = this.collection_filt
        } else {
          if (this.opts.datasource) {
            opts.serverSide = true
            opts.ajax = {
              url: this.opts.datasource + '.json',
              data: function(d) {
                return that.prepareAjaxData(d)
              }
            }
          }
          // if (this.options.element_id_source) {
          //   opts.ajax = function(data, callback, settings) {
          //     callback(
          //       meusRegistros()
          //     );
          //   }
          // }
        }
        if (this.options.per_page) {
          opts.pageLength = this.options.per_page
        }
        if (this.per_page) {
          opts.pageLength = this.per_page
        }

        if (this.options.lazy_fill_data) {
          opts.language['sEmptyTable'] = 'Utilize o filtro para selecionar registros desejados' ;
        }

        if (this.options.columns.initial_order) {
          opts.order = this.options.columns.initial_order
        }

        if (that.options.multiplos && (that.options.multiplos.length > 0)) {
          // opts.select = {
          //   items: 'row',
          //   style: 'multi'
          // }
          opts.select = {
            style: 'os', // 'single', 'multi', 'os', 'multi+shift'
            // selector: 'td:first-child',
          }

          // opts.order = [[ 4, 'asc' ]]
          // opts.select = true
        }

        return opts
      },
      initDataTable: function (mk_now) {
        var that = this ;

        if (mk_now) {
          console.log(`Init ${that.domid} DT now`)
            var tabl = $(that.$refs.data_table).DataTable({retrieve: true});
            tabl.destroy();

            $(that.$refs.data_table).DataTable(
              that.dataTableOptions()
            );
            that.tableMounted = true ;
        } else {
          $(document).ready(function() {
            console.log(`Init ${that.domid} DT`)
            var tabl = $(that.$refs.data_table).DataTable({retrieve: true});
            tabl.destroy();

            $(that.$refs.data_table).DataTable(
              that.dataTableOptions()
            );
            that.tableMounted = true ;
          });
        }
      },
      resetDataTable: function () {
        var that = this ;
        $(that.$refs.data_table).DataTable(
          that.dataTableOptions()
        );
      },

      refresh_com_filtro: function(filtro) {
        this.form_filtro = filtro ;
        this.refresh() ;
        // this.form_filtro = null ;
      },

      refresh_table_ajax(url) {
        var that = this;
        var format = 'json'
        var smurl = url + '.' + format
        var scopes = that.options.scopes ;
        var data = {}

        if (scopes && scopes[0] && this.scope_sel[0]) {
          data[scopes[0].param] = this.scope_sel[0]
        }
        if (scopes && scopes[1] && this.scope_sel[1]) {
          data[scopes[1].param] = this.scope_sel[1]
        }
        if (scopes && scopes[2] && this.scope_sel[2]) {
          data[scopes[2].param] = this.scope_sel[2]
        }
        $.ajax({
          url: smurl,
          data: data,
          type: 'get',
          format: format,
          success: function(data) {
            that.$set(that.opts, 'collection', data.collection)

            that.loaded = true
            that.initDataTable(true) ;
          },
        });
      },

      atualizaLineFromCollection: function() {
        if (this.options.collection) {
          this.atualizaLineCounter(this.options.collection.length)
        }
      },
      atualizaLineFromAjax: function(settings, start, end, max, total) {
        this.atualizaLineCounter(total)
      },
      atualizaLineCounter: function(count) {
        this.$parent.$emit('setlineCount', {
          id: this.index,
          count: count
        })
      },
      showSearch: function() {
        var quantReg = (this.options.record_count || 0)
        if ((this.options.collection) &&
            (this.options.collection.size > 10)) {
          quantReg = this.options.collection.size
        }
        return (((this.options.search) || (quantReg > 9)) &&
                 (! this.options.hide_search))

      },
      refresh: function () {
        this.resetMultiplosSelected()
        var dataTable = $(this.$refs.data_table).dataTable();
        dataTable.fnDraw();
      },
      getButton: function(urlBtn) {
        var data = {};
        data = this.prepareScopes(data);

        var url_req = urlBtn + '?scope=' + data['scope'] ;
        window.open(url_req, '_blank');
      },
      prepareScopes: function(data) {
        if (this.options.scopes && this.options.scopes[0] && this.scope_sel[0]) {
          data[this.options.scopes[0].param] = this.scope_sel[0]
        }
        return data
      },
      prepareAjaxData: function(data) {
        for (var i = 0, len = data.columns.length; i < len; i++) {
          delete data.columns[i];
        }

        data.search.value = this.dt_filter ;

        data.length = this.per_page ;
        // Mais disto abaixo, ref a form_filtro
        data.table_id = this.options.id ;

        if (this.options.scopes && this.options.scopes[0] && this.scope_sel[0]) {
          data[this.options.scopes[0].param] = this.scope_sel[0]
        }
        if (this.options.scopes && this.options.scopes[1] && this.scope_sel[1]) {
          data[this.options.scopes[1].param] = this.scope_sel[1]
        }
        if (this.options.scopes && this.options.scopes[2] && this.scope_sel[2]) {
          data[this.options.scopes[2].param] = this.scope_sel[2]
        }

        if (this.options.aditional_pars) {
          let novos_pars = {}
          novos_pars = this.options.aditional_pars()
          data = Object.assign(data, novos_pars)
        }

        if (this.options.multiplos) {
          data.multiplos = true
        }

        if (this.form_filtro) {
          data.filtro_form = this.form_filtro ;
          data.length = this.form_filtro.per_page ;
        }

        return data ;
      },
      initMultiple: function() {
        var that = this;

        var firstCol = this.options.columns.adef[0];
        this.options.columns.adef[0].className = this.options.columns.adef[0].className.replace('hidden', '');

        $(this.$refs.tbody).on( 'click', 'td.select-checkbox', function (event) {

          var thisRow = $(this.parentElement);
          var id = thisRow.find('td').eq(1).html()
          var index = that.multiple_ids.indexOf(id);

          var valor = 0;
          if (that.somandoValoresSelecionados) {
             valor = Number(thisRow.find('td.value-cell').html());
          }
          if (index > -1) {
            that.remIdSelected(id, valor);
            thisRow.removeClass('selected');
          } else {
            that.addIdSelected(id, valor);
            thisRow.addClass('selected');
          }
        });
        console.log('Iniciando Multiple')
      },
      addIdSelected: function(id, valor) {
        var index = this.multiple_ids.indexOf(id);
        if (index === -1) {
          this.multiple_ids.push(id);
          this.multiple_valor_selecionado += valor
          this.atualizaMultiples()
        }
      },
      remIdSelected: function(id, valor) {
        var index = this.multiple_ids.indexOf(id);
        if (index > -1) {
          this.multiple_ids.splice(index, 1);
          this.multiple_valor_selecionado -= valor
          this.atualizaMultiples()
        }
      },
      atualizaMultiples: function() {
        var that = this;
        if (! this.options.multiplos) {return}
        var quantDocs = this.multiple_ids.length ;
        this.options.multiplos.forEach((btn, btnIndex) => {
          var eleBtn = $(this.$refs[btn.action])
          if (quantDocs >= btn.quant_min ) {
            eleBtn.removeClass('hidden')

            var eleSpan = $(that.$refs[btn.action + '_caption'])
            var templ = that.options.multiplos[btnIndex].caption ;
            var capt = templ.replace('nItens', quantDocs)

            if (that.somandoValoresSelecionados) {
              var valor_str = that.number2strBr(that.multiple_valor_selecionado, 2);
              capt = capt.replace('strValorBr', valor_str);
            }

            eleSpan.html(capt)
          } else {
            eleBtn.addClass('hidden')
          }
        })

        // Firulas
        console.log( quantDocs +' id(s) selected: ' + this.multiple_ids);
      },
      btnMultiCaption: function(btnIndex) {
        var temp = this.options.multiplos[btnIndex].caption ;
        var quant = this.multiple_ids.length ;
        return temp.replace('nItens', quant)
      },
      resetMultiplosSelected: function() {
        this.multiple_ids = [];
        this.multiple_valor_selecionado = 0;
        this.multi_inc = 0;
        this.atualizaMultiples()
      },
      submMultiploBtn: function(mbtn) {
        if (mbtn.action == 'delete_varios') {
          this.submDeleteMultiploBtn(mbtn)
        } else if (mbtn.action == 'download_varios') {
          this.submDownloadMultiploBtn(mbtn)
        } else if (mbtn.format =='js') {
          this.submDeleteMultiploBtn(mbtn)
        }
      },
      submDeleteMultiploBtn: function(mbtn) {
        var that = this;
        var smurl = mbtn.url + '.' + mbtn.format
        var data = {
              ids: that.multiple_ids
        }
        $.ajax({
          url: smurl,
          data: data,
          type: 'get',
          format: mbtn.format,
          error: function(error) {
            window.vm.alert('warning', 'Erro na solicitação de exclusão!');
          }
        });
      },
      submDownloadMultiploBtn: function(mbtn) {
        var smurl = mbtn.url + '.' + mbtn.format
        var data = {
            ids: this.multiple_ids,
            filename: mbtn.filename
        }
        smurl += '?' + $.param(data)
        window.open(smurl, '_blank');
      },

      closeMultiple: function() {
        $(this.$refs.tbody).off( 'click', 'td.select-checkbox');
        this.resetMultiplosSelected()
        console.log('Fechando Multiple')
      }
    }
  }
</script>
