var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "feed-element" }, [
    _c("a", { attrs: { href: _vm.options.link } }, [
      _c("div", { staticClass: "media-body " }, [
        _c("strong", [
          _vm._v("\n        " + _vm._s(_vm.options.title) + "\n      ")
        ]),
        _vm._v(" "),
        _c("br"),
        _vm._v("\n      " + _vm._s(_vm.options.publicado_by) + "\n      "),
        _c("small", { staticClass: "text-muted" }, [
          _vm._v(_vm._s(_vm.options.publicado_desde))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }