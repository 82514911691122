var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.klass }, [
    _vm.action == "show"
      ? _c("div", { staticClass: "ibox float-e-margins" }, [
          _c(
            "div",
            {
              staticClass: "ibox-content",
              staticStyle: { "border-style": "none" }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", {
                  staticClass: "col-sm-10",
                  staticStyle: {
                    "min-height": "350px",
                    "white-space": "pre-wrap"
                  },
                  domProps: { innerHTML: _vm._s(_vm.text) }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-2" }, [
                  _vm.options.can_edit
                    ? _c("div", { staticClass: "pull-right" }, [
                        _c("div", { staticClass: "btn-group" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-w-m btn-primary",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.setEdit()
                                }
                              }
                            },
                            [_vm._v("Editar")]
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              ])
            ]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.action == "edit"
      ? _c("div", { staticClass: "ibox float-e-margins" }, [
          _c(
            "div",
            {
              staticClass: "ibox-content",
              staticStyle: { "border-style": "none" }
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.text,
                      expression: "text"
                    }
                  ],
                  staticClass: "form-control text optional",
                  attrs: { id: "text_editxx", rows: "5" },
                  domProps: { value: _vm.text },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.text = $event.target.value
                    }
                  }
                })
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "ibox-footer",
              staticStyle: { "border-style": "none" }
            },
            [
              _c("div", { staticClass: "pull-right" }, [
                _c("div", { staticClass: "btn-group" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-w-m btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.cancelEdit()
                        }
                      }
                    },
                    [_vm._v("Cancelar")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-w-m btn-primary active",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.saveEdit()
                        }
                      }
                    },
                    [_vm._v("Salvar")]
                  )
                ])
              ])
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }