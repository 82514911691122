var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { attrs: { id: "show_calendar" } }, [
      _vm.equipe
        ? _c("div", { staticClass: "row linha-header" }, [
            _vm.equipe
              ? _c(
                  "h4",
                  {
                    staticClass: "col-sm-10",
                    staticStyle: { "word-break": "break-all" }
                  },
                  [
                    _vm._v("\n        Equipe:\n        "),
                    _c("a", { attrs: { href: _vm.equipe.href_equipe } }, [
                      _vm._v(_vm._s(_vm.equipe.nome))
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v("\n        Serviço:\n        "),
                    _c("a", { attrs: { href: _vm.equipe.href_tarefa } }, [
                      _vm._v(_vm._s(_vm.equipe.tarefa_str))
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-2 text-center" }, [
              _c("h4", [_vm._v("Marcar como minha equipe")]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "text-center",
                  attrs: {
                    "data-toggle": "tooltip",
                    "data-placement": "auto top",
                    title: "",
                    "data-original-title": "Favorito"
                  },
                  on: { click: _vm.setFavorita }
                },
                [
                  _c("i", {
                    staticClass: "zmdi",
                    class: _vm.classeObraFavorita,
                    staticStyle: { "font-size": "xx-large", color: "red" }
                  })
                ]
              )
            ])
          ])
        : _c("div", { staticClass: "row linha-header" }, [
            _vm._v("\n       \n    ")
          ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { ref: "calendar_el", attrs: { id: "calendar_el" } })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }