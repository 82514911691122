// topico/comentario_item.vue
<template>

    <div class="media nrm">

      <div class="media-body ">
        <!-- <small class="float-right">5m ago</small> -->

        <div>
          <p>
            {{ comentario.body }}
          </p>
        </div>

        <div class="text-right" style="display: block;">
          <span >{{ comentario.autor }}</span>
          <small class="text-muted">{{ comentario.publicado_desde }}</small>
        </div>

        <div v-if="(comentario.comentarios.length > 0)">
          <comentario_item v-for="(comentario, indexComm) in comentario.comentarios"
            :options="comentario"
            :key="refreshIndex + indexComm">
          </comentario_item>
        </div>

      </div>

    </div>

</template>

<script>

  export default {
    name: 'comentario_item',
    props: ['options'],
    components: {
      // 'ibox2' : Ibox2
    },
    data: function () {
      return {
        refreshIndex: 0,
        loaded: false,

        comentario: this.options
      }
    },
    created: function () {
    },
    methods: {
    },
    computed: {
    }
  }

</script>

