var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.buttonsLargeClass() }, [
    _c(
      "div",
      { staticClass: "ibox-tools" },
      _vm._l(_vm.buttons, function(button) {
        return _c(
          "a",
          {
            class: button.class,
            attrs: { href: button.href, "data-remote": button.data_remote }
          },
          [
            _c("div", [
              button.i_class
                ? _c("i", { staticClass: "p-r-5", class: button.i_class })
                : _vm._e(),
              _vm._v("\n        " + _vm._s(button.caption) + "\n      ")
            ]),
            _vm._v(" "),
            _c("small", { style: button.small_style }, [
              _vm._v("  " + _vm._s(button.small) + "\n      ")
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }