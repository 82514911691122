// mathUtils.vue
<script>
  export default {
    methods: {
      strRandom: function(size) {
        var strram = String(this.random(1000))
        return strram.substr(strram.length - size)
      },

      random: function(limit) {
        var d = new Date();
        var seed = d.getTime() / 1000;
        var x = Math.sin(seed++) * limit;
        return x - Math.floor(x);
      },

      strBr2Number: function(str_number_br) {
        if (typeof str_number_br == 'number') { return str_number_br;}
        if ((typeof str_number_br !== 'undefined')  && (str_number_br !== null)) {
          var resp = '';
          resp = str_number_br.replace(".", "");
          resp = resp.replace(",", ".");
          if (resp === "") {resp = 0} ;
          return parseFloat(resp);
        } else {
          return 0;
        }
      },
      str2Number: function(str_number) {
        if (typeof str_number == 'number') { return str_number;}
        if ((typeof str_number !== 'undefined')
             && (str_number !== null)
             && (str_number !== '')) {
          str_number = str_number.replace(",", "");
          return parseFloat(str_number);
        } else {
          return 0;
        }
      },
      number2strBr: function(value, decimals) {
        decimals = typeof decimals !== 'undefined' ? decimals : 0 ;
        if ((typeof value !== 'undefined') && (value !== null)) {
          value = typeof value == 'string' ? parseFloat(value) : value
          return value.toLocaleString("pt-BR", {
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals });
        } else {
          return '0'
        }
      },
      number2Real: function(value) {
        return value.toLocaleString("pt-BR", { style: 'currency', currency: 'BRL' });
      },
      number2str: function(value, decimals) {
        value = typeof value == 'string' ? parseFloat(value) : value ;
        // var resp = value.toString() ;
        // return resp;
        decimals = typeof decimals !== 'undefined' ? decimals : 0 ;
        if ((typeof value !== 'undefined') && (value !== null)) {
          return (Math.trunc(value * 1000000000000) / 1000000000000).toFixed(decimals);
        } else {
          return '0'
        }
      }

    }
  }

</script>
