<template>
  <div class="auth-forgot-pass">

    <h5 class="font-bold">Esqueceu a sua senha</h5>
    <p>
      <h5>Informe seu email e receberá instruções para configurar uma nova</h5>
    </p>

    <form class="m-t" role="form"
          method="post"
          :action="opts.forgot_pass_url">

      <input name="utf8" type="hidden" value="✓">
      <input type="hidden" name="authenticity_token"
             :value="opts.form_token">

      <div class="form-group">
        <label class="string required" id="user_email" for="user_email"
               style="width: 100%;text-align: left !important;">Email</label>
        <input type="email" class="form-control"
               placeholder="Email" required=""
               name="user[email]">
      </div>

      <button type="submit" class="btn btn-primary block full-width m-b">Cadastrar nova senha</button>

    </form>

  </div>
</template>

<script>


  export default {
    name: 'AuthForgotPass',
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        what: 'Im AuthForgotPass!',
        opts: this.options
      }
    },
    mounted: function () {
    },
    methods: {
    }
  }
</script>
