var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-sm-6" }, [
    _c("h5", [_vm._v("Progresso")]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "progress col-sm-12",
        class: _vm.klass,
        staticStyle: { height: "20px", padding: "0px" },
        style: _vm.stylePodeClicar()
      },
      _vm._l(_vm.steps, function(step) {
        return _c(
          "div",
          {
            staticClass: "progress-bar",
            class: _vm.progressClass(step[0]),
            style: _vm.barWidth(step[1]),
            attrs: {
              role: "progressbar",
              "aria-valuemin": "0",
              "aria-valuemax": "100"
            },
            on: {
              click: function($event) {
                return _vm.setValue(step[0])
              }
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.stepLabel(step[0])) + "\n    ")]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }