var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "left-main-menu" } },
    _vm._l(_vm.menu_ativado, function(menu, index) {
      return _c("div", { key: _vm.refreshId + index, staticClass: "menu" }, [
        _c(
          "ul",
          {
            staticClass: "nav metismenu",
            attrs: { id: "left-metismenu", onload: _vm.ativaMetisMenu() }
          },
          _vm._l(menu, function(li, index2) {
            return _c(
              "li",
              { key: _vm.refreshId + 3 + index2, class: { active: li.active } },
              [
                _c("a", { class: li.class, attrs: { href: li.href } }, [
                  li.i ? _c("i", { class: li.i }) : _vm._e(),
                  _vm._v(" "),
                  _c("span", { class: li.span }, [
                    _vm._v(
                      "\n            " + _vm._s(li.caption) + "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  li.itens ? _c("span", { staticClass: "fa arrow" }) : _vm._e()
                ]),
                _vm._v(" "),
                li.itens
                  ? _c(
                      "ul",
                      {
                        staticClass: "nav nav-second-level collapse",
                        class: { on: li.active },
                        style: { "height: 0px;": !li.active }
                      },
                      _vm._l(li.itens, function(item) {
                        return _c("li", { class: { active: item.active } }, [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: item.href,
                                "data-remote": item.remote
                              }
                            },
                            [
                              item.span
                                ? _c("span", { class: item.span })
                                : _vm._e(),
                              _vm._v(
                                "\n              " +
                                  _vm._s(item.caption) +
                                  "\n              "
                              ),
                              item.itens
                                ? _c("span", { staticClass: "fa arrow" })
                                : _vm._e()
                            ]
                          ),
                          _vm._v(" "),
                          item.itens
                            ? _c(
                                "ul",
                                {
                                  staticClass: "nav nav-third-level collapse",
                                  class: { on: item.active },
                                  style: { "height: 0px;": !item.active }
                                },
                                _vm._l(item.itens, function(item3) {
                                  return _c(
                                    "li",
                                    { class: { active: item3.active } },
                                    [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: item3.href,
                                            "data-remote": item3.remote
                                          }
                                        },
                                        [
                                          item3.span
                                            ? _c("span", { class: item3.span })
                                            : _vm._e(),
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(item3.caption) +
                                              "\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e()
                        ])
                      }),
                      0
                    )
                  : _vm._e()
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "divider", staticStyle: { height: "20px" } })
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }