var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("dl", { staticClass: "dl-horizontal" }, [
    _c("dt", [_vm._v("Ação")]),
    _vm._v(" "),
    _c(
      "dd",
      _vm._l(_vm.mudarpara, function(opcao) {
        return _c(
          "a",
          {
            staticClass: "dropdown-toggle btn btn-info waves-effect",
            on: {
              click: function($event) {
                return _vm.mudaStatus(opcao)
              }
            }
          },
          [_vm._v("\n    " + _vm._s(_vm.btn_caption(opcao)) + "\n    ")]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }