var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.klass },
    [
      _c("cr-model-btns", { attrs: { options: _vm.opts.options.buttons } }),
      _vm._v(" "),
      _c("div", { attrs: { id: "calendar_wrap" } }, [
        _c("div", {
          ref: "calendar_div",
          attrs: {
            id: _vm.opts.options.calendar_id,
            onload: _vm.initCalendar()
          }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }