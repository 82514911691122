// packs/components/mapa/tree_foam.vue''
<template>
  <div class="mapa_canteiro_foam">
    <div id="visualizationTree"
         style="height: 900px;"
    >
    </div>
<!--
         :onload="initTree()"
 -->

  </div>
</template>

<script>

  export default {
    name: 'MapaCanteiroFoam',
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'mapa_tree_foam'

      }
    },
    mounted: function() {
      var that = this
      window.addEventListener("load", function() {
        that.initTree()
      });
    },
    methods: {
      initTree: function() {
        var foamtree = new CarrotSearchFoamTree({
          id: "visualizationTree",

          layout: 'ordered',
          stacking: 'flattened',

          dataObject: {
            groups: this.groups
          }
        });
      }
    },
    computed: {
      groups: function() { return this.options.groups }
    }
  };
</script>

