// topico/user_index.vue
<template>
  <div class="user_index">

    <div class="ibox-title ch-alt col-sm-12">
      <div class="col-sm-4">
        <h2 id="show_page_title">Gestão do Conhecimento</h2>
        <h5>  </h5>
      </div>
      <div class="col-sm-4">

        <div v-for="(scope, index) in options.scopes"
             :class="scope.div_class">
          <cmp-v-select v-if="scope.options"
                  input_class="form-control select"
                  :options="scope.options"
                  :key="scopeRefresh"
                  :required="true"
                  :value="scope.value"
                  :index="index"
                  :onSelected="updateScope"
                  v-model="scope_sel[index]">
          </cmp-v-select>
        </div>

      </div>
      <div class="col-sm-4">
        <div class="ibox-tools">
          <a href="/topic/topicos/new"
             target="_blank"
             class="btn btn-primary waves-effect">
            <div>
              <i class="p-r-5 fa fa-external-link"></i>
              Novo tópico
            </div>
          </a>
          <a href="/topic/conf_page"
             class="btn btn-primary waves-effect">
            Configuração
          </a>
        </div>
      </div>
    </div>

    <div class="ibox-title col-sm-12">

      <div class="ibox col-sm-9">
        <div class="">
          <div class="ibox-content" style="border: none;">
            <topico_item_big v-for="(topico, indexTopico) in topicos"
               :options="topico"
               :key_id="topico.id"
               :key="refreshIndex * 10 + indexTopico">
            </topico_item_big>
          </div>
        </div>
      </div>

      <div class="col-sm-3">

        <div class="ibox">
          <div class="ibox-title">
            <div class="forum-title" style="border: none;">
              <h3>Novos Tópicos</h3>
            </div>
            <div class="ibox-content">
              <div class="feed-activity-list">

                <topico_item v-for="(topico, indexTopico) in latest"
                  :options="topico"
                  :key="refreshIndex * 10000  + indexTopico">
                </topico_item>

              </div>
            </div>
          </div>
        </div>

        <div class="ibox">
          <div class="ibox-title" style="border: none;">
            <div class="forum-title">
              <h3>Tópicos mais populares</h3>
            </div>
          </div>
          <div class="ibox-content">
            <div class="feed-activity-list">

              <topico_item v-for="(topico, indexTopico) in populares"
                 :options="topico"
                :key="refreshIndex * 1000 + indexTopico">
              </topico_item>

            </div>
          </div>
        </div>

        <div class="ibox"
             v-if="this.options.pendentes_url">
          <div class="ibox-title" style="border: none;">
            <div class="forum-title">
              <h3>Pendentes de publicação</h3>
            </div>
          </div>
          <div class="ibox-content">
            <div class="feed-activity-list">

              <topico_item v-for="(topico, indexTopico) in pendentes"
                 :options="topico"
                :key="refreshIndex * 100 + indexTopico">
              </topico_item>

            </div>
          </div>
        </div>

      </div>

    </div>

   </div>
 </template>

<script>

  // import DataStore from    './data_store.vue'

  import TopicoStore from 'packs/vuex/stores/topico_store.js'
  import StoreLoader from 'packs/vuex/stores/shared/store_loader.js'

  import CmpVSelect from  'packs/components/comp/cmp-v-select.vue'

  import TopicosIndex   from './topicos_index.vue'

  import TopicoItemBig   from './topico_item_big.vue'

  import GrupoItem    from './grupo_line_item.vue'
  import TopicoItem   from './topico_item.vue'


  export default {
    name: 'topico_user_index',
    props: ['options'],
    components: {
      'cmp-v-select'    : CmpVSelect,
      'topicos_index' : TopicosIndex,
      'grupo_item'    : GrupoItem,
      'topico_item_big' : TopicoItemBig,
      'topico_item'   : TopicoItem
    },
    mixins: [StoreLoader],
    data: function () {
      return {
        refreshIndex: 1,
        loaded: false,
        storeName: 'TopicoStore',

        scopeRefresh: 0,
        scope_old: [null, null, null],
        scope_sel: [null, null, null]

      }
    },
    created: function () {
      var configs = {
        user_id:   this.options.user_id,
        url_latest:       this.options.latest_url,
        url_populares:    this.options.populares_url,
        url_topicos:      this.options.topicos_url
      }
      if (this.options.pendentes_url) {
        configs.url_pendentes = this.options.pendentes_url
      }

      this.scope_sel[0] = this.options.scopes[0].value
      this.scope_sel[1] = this.options.scopes[1].value

      // this.$store.dispatch(this.storeName + '/init', configs)

      this.initStore(TopicoStore, configs);
      // this.refreshIndex += 1;
      // this.$set(this, 'refreshIndex', this.refreshIndex + 1)
    },
    methods: {
      updateScope: function(index, obj, id) {

        this.scope_sel[index] = obj.value ;
        this.filterApply()

      },
      filterApply: function(ele) {
        var filtro = {
          grupo_id: this.scope_sel[0],
          tag_id:  this.scope_sel[1]
        } ;
        this.$store.dispatch(this.storeName + '/set_filter', filtro)
        // this.$set(this, 'refreshIndex', this.refreshIndex + 1)
      },
      f_topicos_ordenadas: function() {
        return this.topicos.sort( function(a,b) {
          if (a.publicado_at < b.publicado_at)
            return 1;
          if (a.publicado_at > b.publicado_at)
            return -1;
          return 0;
        });
      }

    },

    computed: {
      // grupos() {
      //   return this.$store.getters[this.storeName + '/grupos']
      // },

      // topicos_ordenados: function () {
      //   return this.topicos.sort( function(a,b) {
      //     if (a.publicado_at < b.publicado_at)
      //       return 1;
      //     if (a.publicado_at > b.publicado_at)
      //       return -1;
      //     return 0;
      //   });
      // },


      topicos() {
        return this.$store.getters[this.storeName + '/topicos']
      },
      latest() {
        return this.$store.getters[this.storeName + '/latest']
      },
      populares() {
        return this.$store.getters[this.storeName + '/populares']
      },
      pendentes() {
        if (this.options.pendentes_url) {
          return this.$store.getters[this.storeName + '/pendentes']
        }
      // },
      // refreshIndex() {
      //   return this.$store.getters[this.storeName + '/refreshIndex']
      }
    }
  }

</script>

