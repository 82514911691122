console.log('Init load Main App')

import '../stylesheets/application'
require.context('../images', true)

import 'vue-multiselect/dist/vue-multiselect.min.css'

import Vue         from 'vue/dist/vue.esm';
import store       from './vuex';

import customAxios from 'packs/plugins/custom-axios.js'

// import i18n_plug   from './plugins/ti18n.js';
// import plug18      from './mixins/i18n.vue';

// Vue.mixin(plug18) ;
// Vue.use(i18n_plug) ;

// Otimização
  import VcBarChart          from 'packs/components/charts/vc-bar-chart.vue'
  import VcLineChart         from 'packs/components/charts/vc-line-chart.vue'

Vue.component('vc-bar-chart',       VcBarChart) ;
Vue.component('vc-line-chart',       VcLineChart) ;


import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {}); // config is optional. more below

import AppComp         from './../app.vue';
import LeftMainMenu    from 'packs/components/menus/left-main-menu.vue';
import NotiMenu        from 'packs/components/notificacoes/noti_menu.vue';
import LiMenuIcon      from 'packs/components/mailboxer/li_menu_icon.vue';
import RightPanel      from 'packs/components/mailboxer/right_panel.vue';
import EMailIndex      from 'packs/components/mailboxer/email_index.vue';
Vue.component('app',            AppComp) ;
Vue.component('left-main-menu', LeftMainMenu) ;

Vue.component('noti-menu',       NotiMenu) ;
Vue.component('li-menu-icon',    LiMenuIcon) ;
Vue.component('msg-right-panel', RightPanel) ;
Vue.component('email-index',     EMailIndex) ;

import AuthIndex       from 'packs/components//auth/index.vue';
Vue.component('auth-index',      AuthIndex) ;

// home
import UserAgenda      from 'packs/components/agenda/index.vue';
import UserTopicos     from 'packs/components/topicos/user_index.vue';
import QueQuem         from 'packs/components/home/quem_e_quem.vue';
import QueInformes     from 'packs/components/home/quem_e_quem_informes.vue';
import DesempIndex     from 'packs/components/desempenho/index.vue';

import UserAgPend     from 'packs/components/agenda_pendentes/index.vue'
import SelUserObra    from 'packs/components/agenda_pendentes/select_user_obra.vue'
import SelObras       from 'packs/components/painel_apo/select_obras.vue'
import ListaEquips    from 'packs/components/painel_apo/lista_equipes.vue'
import ShowCale       from 'packs/components/painel_apo/show_calendar.vue'
import NovoApo        from 'packs/components/painel_apo/novo_apo.vue'
import NewApo         from 'packs/components/apontamento/new_apontamento.vue'


Vue.component('user-agenda',          UserAgenda) ;
Vue.component('user-topicos',         UserTopicos) ;
Vue.component('quem-e-quem',          QueQuem) ;
Vue.component('quem-e-quem-informes', QueInformes) ;
Vue.component('desempenho-index',     DesempIndex) ;

Vue.component('user-tarefas-pendentes', UserAgPend) ;
Vue.component('select-user-obra',       SelUserObra) ;
Vue.component('select-obras',           SelObras) ;
Vue.component('lista-equipes',          ListaEquips) ;
Vue.component('show-calendar',          ShowCale) ;
Vue.component('novo-apo',               NovoApo) ;
Vue.component('new-apontamento',        NewApo) ;

import CrIndex        from 'packs/components/crud/cr_index.vue'
Vue.component('cr-index',          CrIndex) ;
import CrShow        from 'packs/components/crud/cr_show.vue'
Vue.component('cr-show',          CrShow) ;

import FuncHNAIndex        from 'packs/components/funcionarios/index_hna.vue'
Vue.component('funcionarios-hna-index',          FuncHNAIndex) ;

import CrCalShow210        from 'packs/components/crud/calendar_show210.vue'
Vue.component('calendar-show210', CrCalShow210);

import ArquivoUpload from './components/arquivo/upload_arquivo.vue';
Vue.component('upload-arquivoj',  ArquivoUpload) ;     // window.UpA



Vue.prototype.$axios = customAxios;
// Vue.prototype.$t = function(x) {
//   // Chamando t() definida no mixim ela pega
//   //  as conf carregadas no application.html
//   return this.$root.t(x)
// }

const app = new Vue({
  store,

  // mixins: [mixin],

  name: 'vmMain',
  data: {
    nome: 'vm principal',
    ref_children: true,
    ref_self: false,
    logging : true
  },
  created: function() {
  },
  methods: {
    alert: function(ptype, pmsg) {
      this.alerta([{type: ptype, msg: pmsg}]);
    },
    alerta: function(amsgs) {
      var that = this;
      amsgs.forEach(function(msg) {
        that.each_alert(msg.type, msg.msg)
      })
    },
    each_alert: function(type, msg) {
      var toastr_opts = {
        closeButton: true,
        debug: false,
        progressBar: true,
        preventDuplicates: false,
        positionClass: 'toast-top-right',
        onclick: null,
        showDuration: 400,
        hideDuration: 1000,
        timeOut: 7000,
        extendedTimeOut: 1000,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn'
      }
      if (msg != '') {
        if (type == 'success') { toastr.success(msg, toastr_opts) };
        if (type == 'warning') { toastr.warning(msg, toastr_opts) };
        if (type == 'error')   { toastr.error(msg, toastr_opts)   };
        if (type == 'info')    { toastr.info(msg, toastr_opts)    };
        console.log('Alert: >>#{msg}<< type: #{type}');
      }
      return msg;
    },
    seekDomIdInChildren: function(compo, domid, storeTarget, all2Refresh) {
      var that = this;
      compo.$children.some(function(child) {

        if (child.domid) {
          console.log(child.domid)
          if(child.domid == domid) {
            storeTarget.target = child;
            return true; // break
          } else {
            if ((child.type == 'cr_show') ||
                (child.type == 'cr_datatable') ||
                (child.type == 'cr_aba_chart') ||
                (child.type == 'cr_aba_messagens') ||
                (child.type == 'checks-panel') ||
                (child.type == 'painel_envio') ||
                (child.type == 'index_causas') ||
                (child.type == 'cr_index')) {
              all2Refresh.list.push(child)
            }
          }
        }
        if (!storeTarget.target) {
          that.seekDomIdInChildren(child, domid, storeTarget, all2Refresh)
        }
      })
    },
    refresh: function(domid) {
      if (this.logging) {console.log('Refreshing => DomId: ' + domid)}
      var targetRefresh = {
        target: null }
      var all2Refresh = {
        list: [] }
      var that = this;

      this.$children.some(function(child) {
        if (domid && (child.domid == domid)) {
          targetRefresh.target = child;
          return true; // break
        }
        if (! targetRefresh.target) {
          // Só no primeiro nivel

          if ((child.type == 'cr_show') ||
              (child.type == 'cr_scoped_show') ||
              (child.type == 'cr_datatable') ||
              (child.type == 'cr_aba_chart') ||
              (child.type == 'checks-panel') ||
              (child.type == 'cr_index')) {
            all2Refresh.list.push(child)
          }
          // Buscar nos filhos
          that.seekDomIdInChildren(child, domid, targetRefresh, all2Refresh)
        }
      })

      if (targetRefresh.target) {
        targetRefresh.target.refresh()
      } else {
        all2Refresh.list.forEach(function(compo) {//
          compo.refresh()
        })
      }

      this.$emit('notificacao-refresh')
    }
  }
}).$mount('#vue-vm-slot')

window.vm = app






// /* eslint no-console:0 */
// // This file is automatically compiled by Webpack, along with any other files
// // present in this directory. You're encouraged to place your actual application logic in
// // a relevant structure within app/javascript and only use these pack files to reference
// // that code so it'll be compiled.
// //
// // To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// // layout file, like app/views/layouts/application.html.erb


// // Uncomment to copy all static images under ../images to the output folder and reference
// // them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// // or the `imagePath` JavaScript helper below.
// //
// // const images = require.context('../images', true)
// // const imagePath = (name) => images(name, true)
// import 'core-js/stable'
// import 'regenerator-runtime/runtime'

// console.log('Hello World from Webpacker')
