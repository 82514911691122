<template>
  <div :class="klass">

    <div v-if="false"
         class="mail-box-header">
      <h2>Observações</h2>
    </div>
    <div class="mail-box"
      :key="contRefresh"
    >
      <div v-if="conversa">
        <div v-if="conversa != null"
             v-for="(mess, index) in conversa.messages"
             class="social-feed-box">
          <div class="social-avatar">
            <a href=""
               :class="euADireita(mess)">
              <img alt="image"
                  :src="mess.sender.img">
            </a>
            <div class="media-body">
              <a href="#">{{ mess.sender.name }}</a>
              <small class="text-muted">{{ mess.time_ago }}</small>
            </div>
          </div>
          <div class="social-body"
               :key="contRefresh"
               v-html="mess.body"
          >
          </div>
        </div>
      </div>

      <model-message-reply v-if="can_reply"
           :conversa="conversa"
           :model="opts.model"
           :user="opts.user">
      </model-message-reply>

    </div>

  </div>
</template>

<script>
  import ModelMessageReply  from './model_message_reply.vue'

  export default {
    name: 'CrAbaMessagenss',
    components: {
      'model-message-reply' : ModelMessageReply
    },
    props: ['options', 'index'],
    data: function () {
      return {
        klass: 'cr-aba-messagens',
        domid: 'msgs_domid',
        type: 'cr_aba_messagens',

        opts: this.options,

        conversa: this.options.conversa,
        contRefresh: 0,
        can_reply: true,
        messagens: []
      }
    },
    mounted: function() {
      this.conversa = this.options.conversa;
      this.loadMessages()
    },
    methods: {
      refresh: function () {
        this.loadMessages()
      },
      euADireita: function(mess) {
        if ((mess.sender.id == this.opts.user.id) &&
            (mess.sender.type == this.opts.user.type)) {
            return 'pull-right' } else { return 'pull-left'
        }
      },
      loadMessages: function() {
        // if (!this.conversa) { return }

        var that = this;
        $.ajax({
          method: 'get',
          data: {
            model_type: that.opts.model.type,
            model_id:   that.opts.model.id,
            box:        that.opts.box
          },

          url: '/messages/model_conv.json',
          success: function(res) {
            if (res.conversas.length > 0) {
              that.conversa = res.conversas[0];
              that.contRefresh++ ;
              //that.$set(that, 'conversa', res.conversas[0])
              that.$parent.action = 'show' ;
              that.$parent.$emit('messages_refresh_list', 'dummy');

              if (that.conversa.messages) {
                that.$parent.$emit('setlineCount', {
                  id: that.index,
                  count: that.conversa.messages.length
                })
              }
            }
          },
          error: function(res) {
            that.$parent.alert('warning', res.msg);
          }
        });
      }
    },
    computed: {
      // conversa: function () {
      //   return this.options.conversa ;
      // }
    }
  };
</script>
