var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "social-feed-box conversa_reply" }, [
    _c("div", { staticClass: "media-body" }, [
      _c("div", { staticClass: "social-avatar" }, [
        _c("a", { staticClass: "pull-left", attrs: { href: _vm.user.href } }, [
          _c("img", { attrs: { alt: "image", src: _vm.user.img } })
        ]),
        _vm._v("\n\n      " + _vm._s(_vm.user.name) + "\n\n      "),
        _c("div", { staticClass: "pull-right tooltip-demo" }, [
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.replybody != "",
                  expression: "replybody != ''"
                }
              ],
              staticClass: "btn btn-primary btn-sm",
              attrs: {
                "data-toggle": "tooltip",
                "data-placement": "top",
                title: "",
                "data-original-title": "Reply"
              },
              on: {
                click: function($event) {
                  return _vm.sendMessagem()
                }
              }
            },
            [
              _c("i", { staticClass: "fa fa-reply" }),
              _vm._v("\n          Salvar\n        ")
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "social-body" }, [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.replybody,
              expression: "replybody"
            }
          ],
          staticClass: "form-control",
          attrs: { placeholder: "Observação..." },
          domProps: { value: _vm.replybody },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.replybody = $event.target.value
            }
          }
        })
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }