var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ibox float-e-margins",
      class: _vm.opts.class,
      staticStyle: {
        "border-width": "1px",
        "border-color": "lightgray",
        "border-style": "solid",
        display: "table"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "ibox-title small-margin",
          staticStyle: { "border-top-width": "1px" }
        },
        [
          _vm._t("ibox-title"),
          _vm._v(" "),
          _c("div", { staticClass: "ibox-tools" }, [
            _c(
              "a",
              {
                staticClass: "collapse-link",
                on: {
                  click: function($event) {
                    return _vm.toggleOpen()
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "fa",
                  class: _vm.show_open ? "fa-chevron-up" : "fa-chevron-down"
                })
              ]
            ),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c("ul", { staticClass: "dropdown-menu dropdown-user" }, [
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        _vm.localWidth = "col-sm-12"
                      }
                    }
                  },
                  [_vm._v("Largo 100%")]
                )
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        _vm.localWidth = "col-sm-6"
                      }
                    }
                  },
                  [_vm._v("Largo 1/2")]
                )
              ]),
              _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        _vm.localWidth = "col-sm-3"
                      }
                    }
                  },
                  [_vm._v("Largo 1/4")]
                )
              ])
            ])
          ])
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("ibox-content")
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "dropdown-toggle",
        attrs: { "data-toggle": "dropdown", href: "#" }
      },
      [_c("i", { staticClass: "fa fa-gear" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }