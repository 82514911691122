var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ibox-title ch-alt", class: _vm.klass }, [
    _c("div", { staticClass: "media-body m-t-5" }, [
      _c("div", { class: _vm.titleLargeClass() }, [
        _c("h2", { attrs: { id: "show_page_title" } }, [
          _vm._v(" " + _vm._s(_vm.title) + " ")
        ]),
        _vm._v(" "),
        _c("h5", [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]),
        _vm._v(" "),
        _vm.raw_subtitle
          ? _c("h5", {
              class: _vm.raw_subtitle.class,
              domProps: { innerHTML: _vm._s(_vm.raw_subtitle.content) }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { class: _vm.buttonsLargeClass() }, [
        _c(
          "div",
          { staticClass: "ibox-tools" },
          [
            _vm._l(_vm.buttons, function(button) {
              return _c(
                "a",
                {
                  class: button.class,
                  attrs: {
                    href: button.href,
                    target: button.target,
                    "data-remote": button.data_remote
                  }
                },
                [
                  _c("div", [
                    button.i_class
                      ? _c("i", { staticClass: "p-r-5", class: button.i_class })
                      : _vm._e(),
                    _vm._v(
                      "\n            " + _vm._s(button.caption) + "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("small", { style: button.small_style }, [
                    _vm._v("  " + _vm._s(button.small) + "\n          ")
                  ])
                ]
              )
            }),
            _vm._v(" "),
            _vm._l(_vm.button_downs, function(bd) {
              return _c("div", { staticClass: "btn-group" }, [
                _c(
                  "button",
                  { class: bd.class, attrs: { "data-toggle": "dropdown" } },
                  [
                    _c("i", { class: bd.i_class }),
                    _vm._v(
                      "\n            " + _vm._s(bd.caption) + "\n            "
                    ),
                    _c("span", { staticClass: "caret" })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "dropdown-menu" },
                  _vm._l(bd.buttons, function(btn) {
                    return _c("li", [
                      _c(
                        "a",
                        {
                          attrs: {
                            "data-remote": btn.data_remote,
                            href: btn.href
                          }
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(btn.caption) +
                              "\n              "
                          )
                        ]
                      )
                    ])
                  }),
                  0
                )
              ])
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "click-actions", attrs: { id: "header_actions" } },
        [
          _vm.actions && typeof _vm.actions == "object"
            ? _c(
                "div",
                { staticClass: "ibox-tools" },
                _vm._l(_vm.actions, function(action) {
                  return _c(
                    "a",
                    {
                      attrs: {
                        "data-remote": action.others["data-remote"],
                        "data-toggle": action.others["data-toggle"],
                        "data-placement": action.others["data-placement"],
                        title: action.others.title,
                        href: action.href
                      }
                    },
                    [
                      _c("i", {
                        class: action.i_class,
                        attrs: { id: action.others.id_li }
                      })
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.actions && typeof _vm.actions == "string"
            ? _c("div", {
                staticClass: "ibox-tools",
                domProps: { innerHTML: _vm._s(_vm.actions) }
              })
            : _vm._e()
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }