import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';
Vue.use(Vuex);

import AgendaStore        from 'packs/vuex/stores/agenda_store.js';
import CurrentUserStore   from 'packs/vuex/stores/current_user_store.js';
import DesempenhoStore    from 'packs/vuex/stores/desempenho_store.js';
import FormularioStore    from 'packs/vuex/stores/formulario_store.js';
import FormDataStore      from 'packs/vuex/stores/form_data_store.js';
import FuncionarioStore   from 'packs/vuex/stores/funcionarios_hna_store.js';
import MailboxerStore     from 'packs/vuex/stores/mailboxer_store.js';
import NotificacaoStore   from 'packs/vuex/stores/notificacao_store.js';
import ObservadorStore    from 'packs/vuex/stores/observador_store.js';
import PlanejamentoStore  from 'packs/vuex/stores/planejamento_store.js';
import ProjetoStore       from 'packs/vuex/stores/projeto_store.js';
import TextEditorStore    from 'packs/vuex/stores/text_editor_store.js';

const store = new Vuex.Store({
  modules: {
    AgendaStore,
    CurrentUserStore,
    DesempenhoStore,
    FormularioStore,
    FormDataStore,
    FuncionarioStore,
    MailboxerStore,
    NotificacaoStore,
    ObservadorStore,
    PlanejamentoStore,
    ProjetoStore,
    TextEditorStore
  }
});

export default store;
