export default {
    namespaced: true,

    state: {
      obra_id: 0,
      data:    null,
      url:     null,
      col_def: [],
      linhas:  [],
      fator_extra: 0,
      data_d: null,
      funcoes: [],
      secoes: [],

      loaded:  false
    },
    mutations: {
      init(state, data) {
        state.obra_id  = data.obra_id;
        state.data     = data.data;
        state.url      = data.url_hna;
        state.col_def  = data.col_def;
        state.linhas   = data.linhas;

        return state;
      },
      load(state, data) {
        state.linhas       = data.linhas;
        state.fator_extra  = data.fator_extra;
        state.data_d       = data.data_d;

        state.funcoes  = data.funcoes;
        state.secoes   = data.secoes;

        state.loaded       = true
        return state;
      },
      changeData(state, data) {
        state.data = data
      }
    },
    actions: {
      init(context, conf) {
        context.commit('init', conf)
        context.dispatch('load')
      },
      load(context) {
        $.ajax({
          url: context.state.url,
          data: {
            data: context.state.data
          },
          type: 'get',
          format: 'json',
          success: function(data) {
            context.commit('load', data)
          },
          error: function(error) {
          },
          complete: function(error) {
          }
        })
      },
      setData(context, data) {
        context.commit('changeData', data)
        context.dispatch('load')
      }
    },
    getters: {
      linhas: state => {
        return state.linhas ;
      },
      col_def: state => {
        return state.col_def ;
      },
      data: state => {
        return state.data ;
      },
      funcoes: state => {
        return state.funcoes ;
      },
      secoes: state => {
        return state.secoes ;
      }
    }
  }
