var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "media nrm" }, [
    _c("div", { staticClass: "media-body " }, [
      _c("div", [
        _c("p", [
          _vm._v("\n        " + _vm._s(_vm.comentario.body) + "\n      ")
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-right", staticStyle: { display: "block" } },
        [
          _c("span", [_vm._v(_vm._s(_vm.comentario.autor))]),
          _vm._v(" "),
          _c("small", { staticClass: "text-muted" }, [
            _vm._v(_vm._s(_vm.comentario.publicado_desde))
          ])
        ]
      ),
      _vm._v(" "),
      _vm.comentario.comentarios.length > 0
        ? _c(
            "div",
            _vm._l(_vm.comentario.comentarios, function(comentario, indexComm) {
              return _c("comentario_item", {
                key: _vm.refreshIndex + indexComm,
                attrs: { options: comentario }
              })
            }),
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }