<template>
  <div class="ibox">
    <cr-title v-if="((model.title != '') || (model.actions.length > 0) || (model.buttons.length > 0))"
       :title="model.title"
       :subtitle="model.subtitle"
       :raw_subtitle="model.raw_subtitle"
       :actions="model.actions"
       :buttons="model.buttons"
       :button_downs="model.button_downs">
    </cr-title>

    <div v-if="model.tabledef.type == 'topicos_page'"
         class="row topicos_page">
      <user-topicos :options="model.tabledef.options">
      </user-topicos>
    </div>

    <div v-else
         class="ibox-content">
      <div v-if="model.tabledef.type == 'data_table'"
           class="project-list">
        <cr-data-table :options="model.tabledef"
                       :ref="'data_table_' + model.tabledef.id"
        >
        </cr-data-table>
      </div>
      <div v-if="model.tabledef.type == 'abas'"
           class="abas">
        <cr-abas :options="options_for_abas(model.tabledef.abas)">
        </cr-abas>
      </div>
      <div v-if="model.tabledef.type == 'scoped_show'"
           class="scoped_show">
        <cr-scoped-show :opts="model.tabledef">
        </cr-scoped-show>
      </div>
      <div v-if="model.tabledef.type == 'raw'"
           class="raw_content">
        <div v-html="model.tabledef.raw_content"></div>
      </div>
      <div v-if="model.tabledef.type == 'email_index'"
           class="emailindex">
        <email-index></email-index>
      </div>
      <div v-if="model.tabledef.type == 'tree_foam'"
           class="tree_foam">
        <tree-foam :options="model.tabledef.options">
        </tree-foam>
      </div>
      <div v-if="model.tabledef.type == 'vertical_tree_etapans'"
           class="vertical_tree_etapans">
        <cr-vertical-tree-etapans :options="model.tabledef.tree">
        </cr-vertical-tree-etapans>
      </div>
<!--       <div v-if="model.tabledef.type == 'topicos_page'"
           class="row topicos_page">
        <user-topicos :options="model.tabledef.options">
        </user-topicos>
      </div>
 -->    </div>
  </div>
</template>

<script>
  import CrTitle        from 'packs/components/crud/cr_title.vue'
  import CrDataTable    from 'packs/components/crud/cr_data_table.vue'
  import CrAbas         from 'packs/components/crud/cr_abas.vue'
  import CrScopedShow   from 'packs/components/crud/cr_scoped_show.vue'
  import inputUtils     from 'packs/components//mixins/inputUtils.vue'
  import errorStr       from 'packs/components//mixins/errorStr.vue'
  import CrVerticalTreeEtapans   from 'packs/components/etapan/cr_vertical_tree_etapans.vue'
  import EmailIndex              from 'packs/components/mailboxer/email_index.vue'
  import TreeFoam        from 'packs/components/mapa/tree_foam.vue'

  export default {
    name: 'CrIndex',
    components: {
      'cr-title': CrTitle,
      'cr-data-table': CrDataTable,
      'cr-abas': CrAbas,
      'cr-scoped-show': CrScopedShow,
      'email-index': EmailIndex,
      'tree-foam': TreeFoam,

      'cr-vertical-tree-etapans': CrVerticalTreeEtapans
    },
    props: ['opts'],
    data: function () {
      return {
        what: 'Im CrIndex!',
        type: 'cr_index',
        domid: this.opts.domid,
        model: this.opts
      }
    },
    methods: {
      refresh: function() {
        if (this.model.tabledef.type == 'data_table') {
          this.$refs['data_table_' + this.model.tabledef.id].refresh()
        }
      },
      options_for_abas: function(abas) {
        var res = {}
        res.options = abas
        return res
      }
    },
    mounted: function () {
      // if (this.opts.tabledef.type == 'data_table') {
      //   var atables = []
      //   atables.push({
      //     collection: this.opts.tabledef.id,
      //     table:      this.opts.tabledef.id + '_data_table'
      //   })
      //   this.$root.clearSets()
      //   this.$root.setTables( atables )
      // }
    }
  }
</script>
