export default {
    namespaced: true,

    state: {
      url_loader: '',
      url_obra: '',
      obras: [],
      periodos: [],
      selected: {
        obra_id: null,
        periodo_id: null
      },

      indicadores: [],

      loadedCount:  0
    },
    mutations: {
      init(state, data) {
        state.obras = data.obras
        state.url_obra = data.url_obra

        state.selected.obra_id    = data.index.obra_id ;
        state.selected.periodo_id = data.index.periodo_id ;
        state.periodos            = data.index.periodos;
        state.indicadores         = data.index.indicadores;

      },
      incLoaded(state) {
        state.loadedCount = state.loadedCount + 1
      },
      setObra(state, obra_id) {
        state.selected.obra_id = obra_id
      },
      setPeriodo(state, periodo_id) {
        state.selected.periodo_id = periodo_id
      },
      loadIndex(state, index) {
        state.selected.obra_id    = index.obra_id ;
        state.selected.periodo_id = index.periodo_id ;
        state.periodos            = index.periodos;
        state.indicadores         = index.indicadores;

        return state;
      // },
      // changeData(state, data) {
      //   state.data = data
      }
    },
    actions: {
      init(context, conf) {
        context.commit('init', conf)
        // context.dispatch('load')
      },
      setObra(context, obra_id) {
        // context.commit('setLoaded', false)
        context.commit('setObra', obra_id)
        context.dispatch('loadObra')
      },
      setPeriodo(context, periodo_id) {
        // context.commit('setLoaded', false)
        context.commit('setPeriodo', periodo_id)
        context.dispatch('loadObra')
      },
      refreshObra(context) {
        context.dispatch('loadObra', true)
      },
      loadObra(context, refresh) {
        let urlobra = context.state.url_obra
        urlobra = urlobra.replace('obra_id', context.state.selected.obra_id.toString())
        urlobra = urlobra + '.json'

        $.ajax({
          url: urlobra,
          data: {
            periodo_id: context.state.selected.periodo_id,
            refresh: refresh
          },
          type: 'get',
          format: 'json',
          success: function(data) {
            context.commit('loadIndex', data.index)
            context.commit('incLoaded')
          },
          error: function(error) {
            context.commit('incLoaded')
          },
          complete: function(error) {
          }
        })
      },
      setData(context, data) {
        context.commit('changeData', data)
        context.dispatch('load')
      },
      incLoaded(context, value) {
        context.commit('incLoaded')
      },
      setToggle(context) {
        context.commit('incLoaded')
      }
    },
    getters: {
      obras: state => {
        return state.obras ;
      },
      periodos: state => {
        return state.periodos ;
      },
      selected_obra_id: state => {
        return state.selected.obra_id ;
      },
      selected_periodo_id: state => {
        return state.selected.periodo_id ;
      },
      indicadores: state => {
        return state.indicadores ;
      },
      indicadoresCusto: state => {
        var filtered = state.indicadores ;
        filtered = filtered.filter(function (indic) {
          if ((indic) && (indic.indicador)) {
            return (indic.indicador.grupo == 'custo') ;
          }
        });
        return filtered
      },
      col_def: state => {
        return state.col_def ;
      },
      data: state => {
        return state.data ;
      },
      loaded: state => {
        return state.loadedCount ;
      },
      funcoes: state => {
        return state.funcoes ;
      },
      secoes: state => {
        return state.secoes ;
      }
    }
  }
