var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-index" }, [
    _c(
      "div",
      { staticClass: "middle-box text-center loginscreen animated fadeInDown" },
      [
        _c("shared-title"),
        _vm._v(" "),
        _vm.action == "login"
          ? _c("auth-login", { attrs: { options: _vm.opts } })
          : _vm._e(),
        _vm._v(" "),
        _vm.action == "forgot_pass"
          ? _c("forgot-pass", { attrs: { options: _vm.opts } })
          : _vm._e(),
        _vm._v(" "),
        _vm.action == "change_pass"
          ? _c("change-pass", { attrs: { options: _vm.opts } })
          : _vm._e(),
        _vm._v(" "),
        _c("shared-links", { attrs: { options: _vm.opts } })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }