// cr_endereco.vue
<template>
  <div class="ibox float-e-margins">
    <div class="ibox-title"
         style="border-width: 0; min-height: 10px; padding: 0px;">
      <h5>Endereço</h5>
      <div class="ibox-tools">
        <a class="collapse-link">
          <i class="fa fa-chevron-up"></i>
        </a>
        <a v-if="options.options.can_edit && ! editing"
           class="edit-link"
           @click="editing = true">
          <i class="fa fa-pencil"></i>
        </a>
      </div>
    </div>
    <div class="ibox-content">
      <div v-if="! editing"
           class="feed-element">
        <div>
          <small class="pull-right">{{endereco.tipo}}</small>
          <strong>{{ endereco.logradouro }}</strong>
          <div>{{endereco.complemento}}</div>
          <div>{{municipoEstadoCep}}</div>
        </div>
      </div>
      <div v-if="editing">
        <form v-if="editing"
              role="form">
          <div class="form-group col-sm-12">
            <label>Logradouro</label>
            <input type="text" v-model="endereco.logradouro" class="form-control">
          </div>
          <div class="form-group col-sm-12">
            <label>Complemento</label>
            <input type="text" v-model="endereco.complemento" class="form-control">
          </div>
          <div class="form-group col-sm-6">
            <label>Municipio</label>
            <input type="text" v-model="endereco.municipio" class="form-control">
          </div>
          <div class="form-group col-sm-2">
            <label>UF</label>
            <input type="text" v-model="endereco.uf"
                   maxlength="2" class="form-control">
          </div>
          <div class="form-group col-sm-4">
            <label>CEP</label>
            <input type="text" v-model="endereco.cep"
                   maxlength="10" class="form-control">
          </div>
          <div class="form-group col-sm-6">
            <label>Latitude</label>
            <input type="text" v-model="endereco.latitude"
                   class="form-control">
          </div>
          <div class="form-group col-sm-6">
            <label>Longitude</label>
            <input type="text" v-model="endereco.longitude"
                   class="form-control">
          </div>
          <div class="form-group col-sm-12">
            <label>Nome no Google Maps</label>
            <input type="text" v-model="endereco.nome_maps"
                   class="form-control">
          </div>
        </form>
        <button class="btn btn-sm btn-primary pull-right m-t-n-xs"
                @click="saveEndereco">Gravar</button>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'CrEndereco',
    props: ['options'],
    data: function () {
      return {
        klass: 'endereco',

        endereco: {
          tipo: ''

        },
        editing: false
      }
    },

    mounted: function() {
      this.endereco = this.options.options.model ;
    },

    methods: {
      saveEndereco: function() {
        if (! this.options.options.can_edit) {return }
        var that  = this;
        $.ajax({
          method: 'PUT',
          data: {
            endereco: that.endereco
          },
          url: that.options.options.url_patch + '.json',
          success: function(res) {
            that.endereco = res.record ;
            vm.alert('success', res.msg) ;
          },
          error:  function(res) {
            vm.alert('warning', res.msg) ;
          }
        });

      }
    },

    computed: {
      municipoEstadoCep: function() {
        if (this.endereco.municipio != '') {
          return this.endereco.municipio + ' - ' + this.endereco.uf + ' CEP: ' + this.endereco.cep
        } else {
          return 'Não informado'
        }
      }
    }
  }
</script>

