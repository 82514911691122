// in_columns_list.vue
<template>
  <div style="display: inline-block;">
    <a class="dropdown-toggle"
       data-toggle="dropdown"
       data-placement="auto top"
       :title="options.others.title"
       href="#"
       aria-expanded="false">
      <i :class="options.icon_class"></i>
    </a>
    <ul class="dropdown-menu dropdown-menu-right">
      <li v-for="subact in options.d_actions">
        <a :href="subact.href">{{ subact.caption }}</a>
      </li>
    </ul>
  </div>
</template>

<script>

  export default {
    name: 'CrActionDrop',
    components: {
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'cr-action-drop'
      }
    }
  }
</script>

