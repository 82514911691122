// form_doc_vencer_empresas
<template>
  <div class="doc_vencer_empresas">
    <div class="row">
      <form novalidate="novalidate"
            class="simple_form docs-vencer">

        <div class="col-sm-4">
          <div class="form-group string optional arquivo_title">
            <label for="arquivo_title" class="control-label string optional">
              {{ options.select_caption }}
            </label>

            <cmp-v-select
              input_class="form-control select select2"
              name="select_id"
              :options="options.options_obj"
              :required="false"
              :multiple="false"
              :value="form.select_id"
              v-model="form.select_id">

            </cmp-v-select>

          </div>
        </div>

        <div class="col-sm-2">
          <div class="form-group datepicker optional arquivo_emissao">
            <label for="arquivo_emissao" class="control-label datepicker optional">Vencendo até</label>

            <cmp-datepicker
              id="arquivo_emissao"
              v-model="form.data_limite"
              :value="form.data_limite">
            </cmp-datepicker>
          </div>
        </div>

        <div class="col-sm-4 p-t-20">
          <div class="pull-left">
            <div class="btn-group">
              <button type="button"
                      class="btn btn-w-m btn-primary active m-r-5"
                      @click="submitForm()">
                {{ 'Relacionar arquivos a vencer' }}
              </button>
              <button v-if="options.can_xlsx"
                      type="button"
                      class="btn btn-w-m btn-primary active m-r-5"
                      @click="submitFormXlsx()">
                <i class="fa fa-file-excel-o"></i>
                Planilha Excel
              </button>
            </div>
          </div>
        </div class="modal-footer">
      </form>

    </div>
    <div class="row">
      <cr-data-table
         :options="dataTableOpts()"
         ref="arq_data_table"
      >
      </cr-data-table>

    </div>
  </div>
</template>

<script>

  import inputUtils     from 'packs/components/mixins/inputUtils.vue'
  import CompDatePicker from 'packs/components/comp/cmp-datepicker.vue'
  import CmpVSelect     from 'packs/components/comp/cmp-v-select.vue'
  import CrDataTable    from 'packs/components/crud/cr_data_table.vue'


  export default {
    name: 'DocsVencerEmpresas',
    mixins: [inputUtils],
    components: {
      'cmp-datepicker': CompDatePicker,
      'cmp-v-select'  : CmpVSelect,
      'cr-data-table' : CrDataTable
    },
    props: ['options'],
    data: function () {
      return {
        klass: 'docs_vencer_empresas',

        form: {
          select_id: this.options.select_id,
          data_limite: this.options.data_limite
        },
        docs_collection: [],

        token: this.options.token

      }
    },
    mounted: function() {
      // this.submitForm()
    },
    methods: {
      dataTableOpts: function() {
        var opts = {
          id: 'table_docs',
          columns: this.options.table.columns,
          record_count: 99,
          // collection: this.docs_collection,
          datasource: this.options.url,
          no_search: ! this.options.query_name,
          per_page: 50

        }
        opts.aditional_pars = this.filterParams
        return opts;
      },
      filterParams: function() {
        var params = {
          select_id: this.form.select_id,
          data_limite: this.form.data_limite
        }
        return params
      },
      submitForm: function() {
        var that = this;
        $.ajax({
          type: 'get' ,
          data: {
            select_id: that.form.select_id,
            data_limite: that.form.data_limite
          },
          url: that.options.url + '.json',
          success: function(res) {
            that.docs_collection = res.data
            that.$refs.arq_data_table.refresh();
          },
          error: function(res) {
            window.vm.alert('warning', 'Erro ao recuperar consulta!') ;
          }
        });
      },
      submitFormXlsx: function() {
        var that = this;
        var url_req = ''
        url_req += this.options.url + '.xlsx?'
        url_req += 'select_id=' + this.form.select_id
        url_req += '&data_limite=' + this.form.data_limite

        window.open(url_req, '_blank');
      }
    },
    computed: {
      xlsxCommit: function() {
        return this.options.url + '.xlsx'
      }
    }

  };
</script>
