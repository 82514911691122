// btn_muda_status_data.vue
<template>
  <dl class="dl-horizontal">
    <dt>Ação</dt>
    <dd>
      <a
      v-for="opcao in mudarpara"
      class="dropdown-toggle btn btn-info waves-effect"
      v-on:click="mudaStatus(opcao)">
      {{ btn_caption(opcao) }}
      </a>
    </dd>
  </dl>
</template>

<script>

  export default {
    name: 'BtnMudaStatusData',
    data: function() {
      return {
        url_path: '/data_cortes/',
        algoaqui: 'Algoaqui'
      }
    },
    props: ['dataid', 'mudarpara'],
    mounted: function () {
      window.MudaStatusData = this ;
    },
    methods: {
      btn_caption: function (acao) {
        console.log(acao);
        if (acao == 'liberar') {
          return 'Liberar data de corte' ;
        };
        if (acao == 'pagar') {
          return 'Marcar PAGA a data de corte' ;
        };
        if (acao == 'reabrir') {
          return 'Reabrir data' ;
        };
      },
      mudaStatus: function (event) {
        var that = this;
        $.ajax({
          method: 'GET',
          data: {
            mudar_para: event
          },
          url: that.url_path + that.dataid.toString() + '/ask_mudar.js',
          complete: function(res) {
            if (res.status == 200 && res.statusText == 'OK') {
              $("#modal-main").html(res.responseText);
              $("#modal-main").modal("show");
            }
            else {
              mdnotify('Problemas', 'danger', 'top', 'right');
            }
          }
        });
        return false;
      }
    }
  }
</script>

