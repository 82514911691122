// in_columns_list.vue
<template>
  <div class="row"
      :class="klass">
    <div v-for="col in columns" :class="col.class">

      <div v-if="col.items"
           :class="col.pmbb_class">
        <h4 v-if="col.title">{{ col.title }}</h4>
        <dl v-if="col.items"
            class='dl-horizontal'>
          <div v-for="item in col.items">
            <dt>{{ item.label }}</dt>
            <dd v-if="item.text"
                :id="item.id"
                :style="item.style"
            >{{ item.text }}</dd>
            <dd v-if="item.html"
                :id="item.id"
                :style="item.style"
                v-html="item.html"></dd>
            <dd v-if="!item.text && !item.html"
                :id="item.id"
            ></dd>
          </div>
        </dl>
      </div>
      <cr-endereco v-if="col.type == 'endereco'"
                   :options="col.options"
      ></cr-endereco>
<!--
      <div v-if="col.title">
        <h4 v-if="col.title">{{ col.title }}</h4>

        <p v-if="col.text">{{ col.text }}</p>
        <p v-if="col.html" v-html="col.html"></p>
      </div>
 -->
   </div>
  </div>
</template>

<script>

  import CrEndereco     from './cr_endereco.vue'

  export default {
    name: 'ColumnList',
    components: {
      'cr-endereco':      CrEndereco
    },
    props: ['columns'],
    data: function () {
      return {
        klass: 'columns-list'
      }
      // body...
    }
  }
</script>

